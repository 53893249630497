import { Sidebar } from "../Sidebar"
import React, { useEffect, useState } from "react";
import { iUser } from '../../../shared/models/adminModels'
import { useParams } from 'react-router-dom'
import { cUser } from '../../../shared/models/tempBulkData'
import { useTranslation } from 'react-i18next';

const EditUser = () => {
    const { t } = useTranslation()
    const [user, setUser] = useState<iUser>()
    const { id } = useParams()
    console.log('id', id)
    useEffect(() => {
        setUser(new cUser())
    }, [])
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1>{t('Manage_users')}</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item"><a href="/admin/users/list">{t('Manage_users')}</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('Edit_user')}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <% if(success && success.length > 0) { %> */}
                                <div className="alert alert-success alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- success %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}

                                {/* <% if(error && error.length > 0) { %> */}
                                <div className="alert alert-danger alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- error %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}
                                <form className="sign-up-form" action="#" method="POST" id="signup-edit-form">
                                    <div className="card">
                                        <h5 className="card-header">{t('Edit_user')}</h5>
                                        <div className="card-body">
                                            <p> {t('Fill_all_details_of_user')} </p>
                                            <div className="row">
                                                <div className="form-group col-md-4 mb-2">
                                                    <label >{t('Name')}</label>
                                                    <input type="text" className="form-control" id="" name="name"
                                                        value={user?.firstName + ' ' + user?.lastName}></input>
                                                </div>
                                                <div className="form-group col-md-4 mb-2">
                                                    <label >{t('User_Type')}</label>
                                                    <select id="" className="form-control" name="user_type" required>
                                                        <option value="">{t('Select_User_type')}</option>
                                                        {/* <% if(user_types.length){
                                                                user_types.forEach((user_type, index) => { %>	
                                                    <option value="<%= user_type.id %>" <%= user_type.id == user_detail.user_type_id ? 'selected' : '' %>>
                                                        <%= user_type.name %>
                                                        </option>
                                                        <% }) %>
                                                        <% } %> */}
                                                    </select>
                                                </div>
                                                <div className="form-group col-md-4 mb-2">
                                                    <label >{t('Email')}</label>
                                                    <input type="email" className="form-control" id="" name="email_id" placeholder="Email Id" value={user?.email} required readOnly></input>
                                                </div>
                                                <div className="form-group col-md-4 mb-2">
                                                    <label >{t('Phone_Number')}</label>
                                                    <input type="tel" name="phone_no" className="form-control" id="" value={user?.phone_no} placeholder="Phone No"></input>
                                                </div>
                                                <div className="form-group col-md-4">
                                                    <label >{t('Status')}</label>
                                                    <select id="inputState" className="form-control" name="status" required>
                                                        <option value="1" >
                                                            {t('Enable')}
                                                        </option>
                                                        <option value="0" >
                                                            {t('Disable')}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer bg-light text-right">
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <input type="hidden" value="<%= user_detail.id %>" id="id" name="id"></input>
                                                        <input type="submit" value="Update" id="updateBtn" className="btn btn-primary btn-rounded"></input>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div >
                    </section >
                </div >
            </div >
        </div >
    )
}

export default EditUser