import React, { useEffect, useState } from "react";
import { Sidebar } from "./Sidebar"
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartType,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { HttpUserRankingService } from "../../services/admin.service";
import * as appConst from '../../shared/constants/constants'
import Pagination from "../../shared/components/Pagination";
import { iUserRanking } from "../../shared/models/adminModels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};


const Dashboard = () => {
  // -------------- Sales Pagination --------------------
  const [totalSalesResults, setTotalSalesResults] = useState(-1);
  const [totalSalesPages, setTotalSalesPages] = useState(-1)
  const [currentSalesPages, setCurrentsalesPages] = useState(-1)
  // -------------- Sales Pagination End --------------------

  // -------------- Purchase Pagination --------------------
  const [totalPurchaseResults, setTotalPurchaseResults] = useState(-1);
  const [totalPurchasePages, setTotalPurchasePages] = useState(-1)
  const [currentPurchasePages, setCurrentPurchasePages] = useState(-1)
  // -------------- Purchase Pagination End --------------------


  // -------------- Obtain Amount Pagination --------------------
  const [totalObtainAmountResults, setTotalObtainAmountResults] = useState(-1);
  const [totalObtainAmountPages, setTotalObtainAmountPages] = useState(-1)
  const [currentObtainAmountPages, setCurrentObtainAmountPages] = useState(-1)
  // -------------- Obtain Amount Pagination End --------------------


  // -------------- Spent Amount Pagination --------------------
  const [totalSpentAmountResults, setTotalSpentAmountResults] = useState(-1);
  const [totalSpentAmountPages, setTotalSpentAmountPages] = useState(-1)
  const [currentSpentAmountPages, setCurrentSpentAmountPages] = useState(-1)
  // -------------- Spent Amount Pagination End --------------------

  const { t } = useTranslation()
  const [salesLabel, setSalesLabel] = useState<string[]>([]);
  const [purchaseLabel, setPurchaseLabel] = useState<string[]>([]);
  const [obtainAmountLabel, setObtainAmountLabel] = useState<string[]>([]);
  const [spentAmountLabel, setSpentAmountLabel] = useState<string[]>([]);
  const [sales, setSales] = useState<any>()
  const [purchase, setPurchase] = useState<any>()
  const [spentAmountData, setSpentAmountData] = useState<any>()
  const [ObtainAmountData, setObtainAmountData] = useState<any>()
  const httpUserRankingService: HttpUserRankingService = new HttpUserRankingService();

  useEffect(() => {
    getsales();
    getpurchase();
    getAmountObtainData();
    getAmountSpentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getsales = (currentPage = 1, reset = false) => {
    httpUserRankingService.getAsParams<iUserRanking>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT }, "sales")
      .then((value: any) => {
        if (value.data.items.length > 0) {
          setTotalSalesPages(value.data.totalRows)
          setTotalSalesPages(Math.ceil(value.totalRows / appConst.PAGE_LIMIT))
          let label: string[] = [], data: number[] = [];
          value.data.items.forEach((val: iUserRanking) => {
            label.push(val.username)
            data.push(val.salesCount)
          })
          setSales(data);
          setSalesLabel(label);
        }
      })
      .catch((error) => console.log(error))
  }
  const getpurchase = (currentPage = 1, reset = false) => {
    httpUserRankingService.getAsParams<iUserRanking>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT }, "purchase")
      .then((value: any) => {
        if (value.data.items.length > 0) {
          setTotalPurchasePages(value.data.totalRows)
          setTotalPurchasePages(Math.ceil(value.totalRows / appConst.PAGE_LIMIT))
          let label: string[] = [], data: number[] = [];
          value.data.items.forEach((val: iUserRanking) => {
            label.push(val.username)
            data.push(val.purchaseCount)
          })
          setPurchase(data);
          setPurchaseLabel(label);
        }
      })
      .catch((error) => console.log(error))
  }
  const getAmountObtainData = (currentPage = 1, reset = false) => {
    httpUserRankingService.getAsParams<iUserRanking>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT }, "obtain")
      .then((value: any) => {
        if (value.data.items.length > 0) {
          setTotalObtainAmountPages(value.data.totalRows)
          setTotalObtainAmountPages(Math.ceil(value.totalRows / appConst.PAGE_LIMIT))
          let label: string[] = [], data: number[] = [];
          value.data.items.forEach((val: iUserRanking) => {
            label.push(val.username)
            data.push(val.totalPurchaseAmount)
          })
          setObtainAmountData(data);
          setObtainAmountLabel(label);
        }
      })
      .catch((error) => console.log(error))
  }
  const getAmountSpentData = (currentPage = 1, reset = false) => {
    httpUserRankingService.getAsParams<iUserRanking>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT }, "spent")
      .then((value: any) => {
        if (value.data.items.length > 0) {
          setTotalSpentAmountPages(value.data.totalRows)
          setTotalSpentAmountPages(Math.ceil(value.totalRows / appConst.PAGE_LIMIT))
          let label: string[] = [], data: number[] = [];
          value.data.items.forEach((val: iUserRanking) => {
            label.push(val.username)
            data.push(val.totalSaleAmount)
          })
          setSpentAmountData(data);
          setSpentAmountLabel(label);
        }
      })
      .catch((error) => console.log(error))
  }

  const onSalesPageChanged = (data: any) => {
    setCurrentsalesPages(data.currentPage);
    getAmountObtainData(data.currentPage)
  }
  const onPurchasePageChanged = (data: any) => {
    setCurrentPurchasePages(data.currentPage);
    getAmountObtainData(data.currentPage)
  }
  const onObtainAmountPageChanged = (data: any) => {
    setCurrentObtainAmountPages(data.currentPage);
    getAmountObtainData(data.currentPage)
  }
  const onSpentAmountPageChanged = (data: any) => {
    setCurrentSpentAmountPages(data.currentPage);
    getAmountObtainData(data.currentPage)
  }
  return (
    <div id="app">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">

          <div className="mx-4 row">

            {sales && <div className="col-md-6 col-sm-12">
              <Bar options={options} data={
                {
                  labels: salesLabel,
                  datasets: [
                    {
                      label: t`Sales` ?? "",
                      data: sales,
                      backgroundColor: 'rgb(112,173,71)',
                    },
                  ],
                }} />
              <div className="nav-direction">
                {
                  totalSalesResults > appConst.PAGE_LIMIT && totalSalesPages ?
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination totalRecords={totalSalesResults}
                        totalPages={totalSalesPages}
                        currentPage={currentSalesPages}
                        pageLimit={appConst.PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onSalesPageChanged} />
                    </div>
                    :
                    null
                }
              </div>
            </div>}

            {purchase && <div className="col-md-6 col-sm-12">
              <Bar options={options} data={{
                labels: purchaseLabel,
                datasets: [
                  {
                    label: t`Purchases` ?? "",
                    data: purchase,
                    backgroundColor: 'rgb(155,0,0)',
                  },
                ],
              }} />
              <div className="nav-direction">
                {
                  totalPurchaseResults > appConst.PAGE_LIMIT && totalPurchasePages ?
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination totalRecords={totalPurchaseResults}
                        totalPages={totalPurchasePages}
                        currentPage={currentPurchasePages}
                        pageLimit={appConst.PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onPurchasePageChanged} />
                    </div>
                    :
                    null
                }
              </div>
            </div>}

            {spentAmountData && <div className="col-md-6 col-sm-12">
              <Bar options={options} data={{
                labels: spentAmountLabel,
                datasets: [
                  {
                    label: t`Total_amt_spent` ?? "",
                    data: spentAmountData,
                    backgroundColor: 'rgb(0,112,192)',
                  },
                ],
              }} />

              <div className="nav-direction">
                {
                  totalSpentAmountResults > appConst.PAGE_LIMIT && totalSpentAmountPages ?
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination totalRecords={totalSpentAmountResults}
                        totalPages={totalSpentAmountPages}
                        currentPage={currentSpentAmountPages}
                        pageLimit={appConst.PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onSpentAmountPageChanged} />
                    </div>
                    :
                    null
                }
              </div>
            </div>}

            {ObtainAmountData && <div className="col-md-6 col-sm-12">
              <Bar options={options} data={{
                datasets: [
                  {
                    label: t`Total_amount_obtained` ?? "",
                    data: ObtainAmountData,
                    backgroundColor: 'rgb(112,48,160)',
                  },
                ], labels: obtainAmountLabel
              }} />
              <div className="nav-direction">
                {
                  totalObtainAmountResults > appConst.PAGE_LIMIT && totalObtainAmountPages ?
                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                      <Pagination totalRecords={totalObtainAmountResults}
                        totalPages={totalObtainAmountPages}
                        currentPage={currentObtainAmountPages}
                        pageLimit={appConst.PAGE_LIMIT}
                        pageNeighbours={1}
                        onPageChanged={onObtainAmountPageChanged} />
                    </div>
                    :
                    null
                }
              </div>
            </div>}


          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;