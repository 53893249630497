import React, { useState } from "react";
import FileUpload from './fileUpload'

export interface iOptionFileUpload {
    optionNo: number
    setOptionSelected: (optionNo: number) => boolean
    selectedOptionNo: number
    apiUrlRelativePath: string
    existingFileName: string
    uploadedRelativePath: string
}

export type Ref = HTMLElement;

const OptionFileUpload = React.forwardRef<Ref, iOptionFileUpload>((props, ref) => {
    const [image_file_name, setImage_file_name] = useState<string>(props.existingFileName)
    // console.log('in OptionFileUpload')
    const ImageUploadedSuccessfully = (file_name: string) => {
        console.log('brandImageUploadedSuccessfully called file_name=', file_name)
        setImage_file_name(file_name)
    }
    const ImageUploadedFailed = (error_string: string) => {
        console.log('brandImageUploadedFailed called Error:', error_string)

    }

    return (
        <>
            <div className="custom-file">
                <input type="radio" className="me-1" id={"option" + props.optionNo} name="Option" value="Option"
                    onChange={() => props.setOptionSelected(props.optionNo)}
                    checked={props.selectedOptionNo === props.optionNo}
                >
                </input>
                <label htmlFor={"option" + props.optionNo}>{"Option" + props.optionNo}<span className="required">*</span></label>
                <FileUpload key={props.optionNo} optionNo={props.optionNo}
                    apiUrlRelativePath={props.apiUrlRelativePath}
                    existingFileName={image_file_name}
                    uploadedRelativePath={props.uploadedRelativePath}
                    onSuccess={ImageUploadedSuccessfully}
                    onFailure={ImageUploadedFailed} ></FileUpload>

                {/* <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input"
                    id={"option" + optionNo}
                    name="Option" value="Option"
                />
                <label className="custom-control-label"
                    htmlFor={"option" + optionNo}
                >
                    {"option" + optionNo}
                </label>


            </div> */}
            </div>
        </>
    )

}
)

export default OptionFileUpload