import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { iUserType, iSignUpMedium, iUser,  iCategory } from '../../../shared/models/adminModels'
import { useParams } from 'react-router-dom'
import { cCategory, cUser } from '../../../shared/models/tempBulkData'
import { HttpAdminService } from '../../../services/http.service'
import FileUpload, { iFileUpload } from '../../../shared/components/admin/fileUpload'


const AddBrandCategoryLevelPage = () => {
    const [brandsCategories, setBrandCategories] = useState<iCategory[]>([new cCategory()])
    const [brands, setBrands] = useState<iCategory[]>([])
    const [brandSelected, setBrandSelected] = useState<iCategory>()
    const [ brandCategoryName,setBrandCategoryName] = useState<string>()
    const navigate = useNavigate();


    useEffect(() => {
        setBrands([new cCategory()])
    }, [])


    useEffect(() => {
        // setBrand(new cBrand())
        function enableSubmitButton() {
            const input = document.getElementById('saveBtn') as HTMLInputElement | null;
            if (brandSelected && brandCategoryName) {
                input?.removeAttribute('disabled')
            } else {
                input?.setAttribute('disabled', '')
            }
        }
        enableSubmitButton()
    }, [brandSelected,brandCategoryName])



    const onSubmit = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        evt.preventDefault()
        console.log('onSubmit called')
        //TODO post api to use for creating new brandCategory


        navigate("/admin/brands-category-level/list")
    }

    const onBrandsOptionChange = (brand_id:number) => {
        let filteredBrands = brands.filter((value)=> value.id == brand_id)
        setBrandSelected(filteredBrands[0])
        // TODO: fetch brand categories, all the decendents of this brand_id
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Manage brand category level</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item"><a href="/admin/brands-category-level/list">Manage brand category level</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add new catagory</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <% if(success && success.length > 0) { %> */}
                                <div className="alert alert-success alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- success %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}

                                {/* <% if(error && error.length > 0) { %> */}
                                <div className="alert alert-danger alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- error %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}
                                <form className="sign-up-form" action="#" method="POST" id="signup-edit-form">
                                    <div className="card">
                                        <h5 className="card-header">Add brand category level</h5>
                                        <div className="card-body">
                                            <p> Please fill all the required details of new brand. </p>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="">Brands</label><span className="required">*</span>
                                                    <select onChange={(evt)=> {onBrandsOptionChange(+evt.target.value)}} className="form-control in_language_id" name="in_language_id">
                                                        <option value="">Select one brand</option>
                                                        {brands.length && brands.map((brand) =>
                                                            <option value={brand.id}>
                                                                {brand.name}
                                                            </option>
                                                        )

                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="">Parent category</label><span className="required">*</span>
                                                <select id="inputState" className="form-control" name="parent_cat" required>
                                                    <option value="" >Select parent category</option>
                                                    <option value="0" >No Parent</option>
                                                    {brandsCategories && brandsCategories.map((brandsCategory, index)=> 
                                                        <option value={brandsCategory.id}>
                                                            {brandsCategory.name}
                                                        </option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Category name</label>
                                                    <input type="text" className="form-control" id="" name="name"
                                                        onChange={(evt) => setBrandCategoryName(evt.target.value)}
                                                        value={brandCategoryName}></input>
                                                </div>
                                                {/* <input type="hidden" id="subscription_plans_languages_count" name="subscription_plans_languages_count" value="0" ></input>
                                                <button id="add" onClick={addNewModelCategory}>+</button>  */}
                                            </div>
                                        </div>
                                        <div className="card-footer bg-light text-right">
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <input type="submit" onClick={(evt) => onSubmit(evt)} id="saveBtn" className="btn btn-primary btn-rounded"></input>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div >
                    </section >
                </div >
            </div >
        </div >
    )
}

export default AddBrandCategoryLevelPage