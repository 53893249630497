import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


var resBundle = require("i18next-resource-store-loader" + "?include=\\.json$!./assets/i18n/index.js");
const options = {
  order: ['cookie', 'localStorage', 'navigator'],
  caches: ['cookie', 'localStorage']
}
i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // debug: true,
    // fallbackLng: 'en',
    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
    // resources: {
    //   en: {
    //     translation: {
    //       "adminloginpagetitle": "Admin Login",
    //       "Landinginfo": "Landing Page"
    //     }
    //   },
    //   de: {
    //     translation: {
    //       "adminloginpagetitle": "Admin-Anmeldung",
    //       "Landinginfo": "Landseite"

    //     }
    //   }
    // }
    detection: options,
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    /*
    backend: {
        allowMultiLoading: true,
 
    },*/
    resources: resBundle,
    supportedLngs: ['en', 'de'],
    //lng: localLang,
    fallbackLng: 'en', // use en if detected lng is not available   
  });



export default i18next;