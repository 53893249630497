import { Sidebar } from "../Sidebar"
import React, { useEffect, useState } from "react";
import { iUser, eUserType, IPaymentMethod, iAddress, iRewardLevel, iRewardsLevel } from '../../../shared/models/adminModels'
import { Link, useParams } from 'react-router-dom'
import { HttpUsersService } from "../../../services/admin.service";
import { useTranslation } from 'react-i18next';
import useModal from "../../../hooks/general";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/stateTypes";
import moment from "moment";

enum LevelActivity  {
    INCREASE = 'up',
    DECREASE = 'down'
};

const ViewUser = () => {
    const { t } = useTranslation()
    const [user, setUser] = useState<iUser>();
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [addresses, setAddresses] = useState<iAddress[]>([]);
    const { id } = useParams();
    const { isOpen, toggle } = useModal();
    const [modalTitle, setModalTitle] = useState<string>('');
    const [reason, setReason] = useState<string>('');
    const [activity, setActivity] = useState<LevelActivity>(LevelActivity.INCREASE);
    const [level, setLevel] = useState<iRewardsLevel>();
    const rewards = useSelector((state: RootState) => state.rewardSlice);
    const httpUsersService: HttpUsersService = new HttpUsersService();


    const getUserDetail = (id: number) => {
        httpUsersService.get(id)
            .then((value: any) => {
                console.log('getUserDetail', value.data)
                setUser(value.data);
                const level = rewards.find(reward =>  reward.id === value.data.levelsForRewardsId);
                if(level) setLevel(level);
            })
            .catch((error) => console.log(error))
    }

    const getUserPaymentMethods = (id: number) => {
        httpUsersService.getUserPaymentMethod<IPaymentMethod>(id)
            .then((response: IPaymentMethod[]) => {
                setPaymentMethods(response);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getUserAddresses = (id: number) => {
        httpUsersService.getUserAddresses<iAddress>(id)
            .then((response: iAddress[]) => {
                setAddresses(response);
            })
            .catch(err => {
                console.log(err);
            })
    }
    

    useEffect(() => {
        if (id) {
            getUserDetail(+id);
            getUserPaymentMethods(+id);
            getUserAddresses(+id);
        }
    }, []);

    const banUser = (id: number) => {
        httpUsersService.banUser(id)
            .then((value: any) => {
               
            })
            .catch((error) => console.log(error))
    }
    const suspendUser = (id: number, toDate: Date) => {
        // httpUsersService.suspendUser(id, moment(toDate).format('MM/DD/YYYY'))
        //     .then((value: any) => {
               
        //     })
        //     .catch((error) => console.log(error))
    }
    const adjustLevel = () => {
        if(id && reason) {
            httpUsersService.updateUserLevel(+id, reason, activity)
                .then(response => {
                    toast.success(t('level_request'));
                    getUserDetail(+id);
                })
                .catch(err => {
                    toast.error(err.response.data.errorMessage);
                })
                .finally(() => {
                    toggle();
                    setReason('');
                })
        }
        
    }

    const openModal = (activity: LevelActivity) => {
        setActivity(activity);
        activity === LevelActivity.INCREASE ? setModalTitle(t('increase_level') || '') : setModalTitle(t('decrease_level') || '');
        toggle();
    }

    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="mr-auto">
                                    <h1>{t('Manage_users')}</h1>
                                    <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                            <li className="breadcrumb-item"><a href="/admin/users/list">{t('Manage_users')}</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">{t('View_user')}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 text-end">
                                <button className="btn btn-primary me-2"

                                >{t('Ban_user')}</button>
                                <button className="btn btn-primary"
                                >{t('Suspend_user')}</button>
                            </div> */}
                            
                        </div>
                    </header>
                    <section className="page-content container-fluid pt-0">
                        <div className="row">
                            <div className="col-12">
                                <div>

                                </div>
                                
                                <form className="sign-up-form" action="#" method="POST" id="signup-edit-form">
                                    <div className="card">
                                        <h5 className="card-header">{t('View_user')}</h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Name')}:</label>
                                                    <div> {user?.name}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('DOB')}:</label>
                                                    <div> {moment(user?.dateOfBirth).format("DD/MM/YYYY")}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('User_Role')}:</label>
                                                    <div> {user?.userTypeId && eUserType[user.userTypeId]}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Email')}:</label>
                                                    <div>{user?.email}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Phone_Number')}:</label>
                                                    <div>{user?.details?.phoneNo}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Company_Name')}:</label>
                                                    <div>{user?.details?.companyName}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Business_Name')}:</label>
                                                    <div>{user?.details?.businessName}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Company_VAT')}:</label>
                                                    <div>{user?.details?.companyVAT}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Unique_Code')}:</label>
                                                    <div>{user?.details?.uniqueCode}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Account_Name')}:</label>
                                                    <div>{user?.details?.accountName}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('Privacy_Policy_Concent')}:</label>
                                                    <div>{user?.details?.privacyPolicyConcent ? "Yes" : "No"}</div>
                                                </div>
                                                <div className="form-group col-md-3 mb-3">
                                                    <label className="txt-c">{t('News_Letter_Concent')}:</label>
                                                    <div>{user?.details?.newslettersConcent ? "Yes" : "No"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <h5 className="card-header">{t('User Rewards')}</h5>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label className="txt-c">{t('Total Points')}:</label>
                                                        <div>{user?.totalPoints}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <div className="form-group">
                                                        <label className="txt-c">{t('Reward Level')}:</label>
                                                        <div>{level ? level.name : 'Level 0'}</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-3">
                                                    <Link to={'#'} onClick={() => openModal(LevelActivity.INCREASE)} className="bulk-primary-btn bulk-sm btn-block text-center add-brand" >Increase Reward Level <span className="la la-arrow-up"></span></Link>
                                                </div>
                                                <div className="col-sm-3">
                                                    <Link to={'#'} onClick={() => openModal(LevelActivity.DECREASE)}className="bulk-primary-btn bulk-sm btn-block text-center add-brand">Decrease Reward Level <span className="la la-arrow-down"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <h5 className="card-header">{t('Addresses')}</h5>
                                        <div className="card-body">
                                        <table className="table mb-0 table-striped table-bordered table-hover" width="100%" cellSpacing="0">                                            
                                            <tbody>
                                                {addresses.length > 0 ?
                                                    addresses.map(address => 
                                                    <tr>
                                                        <td>{`${address.streetNo}, ${address.address}, ${address.city}, ${address.country}-${address.postalCode}`}</td>
                                                    </tr> ) : <tr><td>No address addeed</td></tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                    <div className="card mt-3">
                                        <h5 className="card-header">{t('Payment Methods')}</h5>
                                        <div className="card-body">
                                        <table className="table mb-0 table-striped table-bordered table-hover" width="100%" cellSpacing="0">                                            
                                            <tbody>
                                                {
                                                    paymentMethods.length > 0 ?
                                                    paymentMethods.map(payment => 
                                                        <tr>
                                                        <td>{payment.brandType}</td>
                                                    </tr>
                                                    ) : 
                                                    <tr><td>No Paymentmethod added</td></tr> 
                                                }                                     
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>                                    
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <ModalDialog
                toggle={toggle}
                cancel={toggle}
                execute={adjustLevel}
                isOpen={isOpen}
                modalTitle={modalTitle}
                executeButtonTitle={() => t('MD_Update')}
                enableExecute={reason ? true : false}
                useButtons={t("MD_Cancel") + '/'  + t("MD_Update")}
            >
                <div className="row">
                    <textarea name="reason" id="reason" value={reason} placeholder={t('enter_reason') || 'Enter Reason'} onChange={(event) => setReason(event?.target.value)}></textarea>
                </div>

            </ModalDialog>
        </div>
    )
}

export default ViewUser