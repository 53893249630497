
// import "../../assets/admin/css/vendor/bootstrap.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/admin/css/icons/line-awesome.min.css"
import "../../assets/admin/css/icons/simple-line-icons.css"
import "../../assets/admin/css/icons/dripicons.min.css"

import "../../assets/admin/css/icons/material-design-iconic-font.min.css"
import "../../assets/admin/css/icons/dripicons.min.css"
import "../../assets/admin/css/icons/dripicons.min.css"
import "../../assets/admin/css/icons/dripicons.min.css"
import "../../assets/admin/css/layouts/vertical/core/main.css"
import "../../assets/admin/css/layouts/vertical/menu-type/default.css"
import "../../assets/admin/css/layouts/vertical/themes/theme-a.css"


import "../../assets/admin/css/pnotify.custom.min.css"
import "../../assets/admin/css/common/custom.css"
import "../../assets/admin/css/common/style.css"
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useCookies } from "react-cookie";
import { HttpAuthService } from '../../services/admin.service';
import { useDispatch } from 'react-redux';
import { appPageSliceActions, iPageState } from "../../store/appPageSlice";

export const Sidebar = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const [cookies, setCookie,  removeCookie] = useCookies(['admin_user'])
    const httpAuthService: HttpAuthService = new HttpAuthService()
    const dispatch = useDispatch();

    const showHide = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        console.log(evt.currentTarget.getAttribute('data-target-id'))
        let dataTarget = evt.currentTarget.getAttribute('data-target-id')
        //let toHideOrShowID = href?.replace('#','')
        if (dataTarget) {
            console.log(dataTarget)
            let elem = document.getElementById(dataTarget)
            if (elem) {
                elem.style.display = elem.style.display == 'block' ? 'none' : 'block'
                //window.scrollTo(0, 0);
            }
        }
    }
    const gotoUsersList = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        navigate('/admin/users/list', { replace: true })
        navigate(0)
        // href="/admin/users/list"
    }

    const gotoUsersTypeList = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        navigate('/admin/user-types/list', { replace: true })
        navigate(0)
        //href="/admin/user-types/list"
    }
    const logout = async() => {
        let userId = cookies['admin_user'].id
        dispatch(appPageSliceActions.clearPagesState({}))
        await httpAuthService
            .logout(userId)
        
        localStorage.setItem('active-nav-item', "log-out")
        removeCookie('admin_user', { path: '/' })
    }
    return (
        <>
            <aside className="sidebar sidebar-left">
                <div className="sidebar-content">
                    <div className="aside-toolbar">
                        <ul className="site-logo">
                            <li>

                                <a href="/admin/dashboard">
                                    <span className="brand-text">{t('Bulk')}</span>
                                </a>

                            </li>
                        </ul>
                        {/* <ul className="header-controls">

                            <li className="nav-item">

                                <button type="button" className="btn btn-link btn-menu" data-toggle-state="mini-sidebar">
                                    <i className="la la-dot-circle-o"></i>
                                </button>

                            </li>
                        </ul> */}
                    </div>
                    <nav className="main-menu">
                        <ul className="nav metismenu">


                            <li className="sidebar-header"><span>{t('NAVIGATION')}</span></li>
                            <li >
                                <a href="/admin/dashboard"><i className="icon dripicons-meter"></i><span>{t('Dashboard')}</span></a>
                            </li>


                            <li className="sidebar-header"><span>{t('USER_MODULE')}</span></li>

                            <li className={localStorage.getItem('active-nav-item') == "users-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="users-list"
                                onClick={() => localStorage.setItem('active-nav-item', "users-list")}>
                                <a className="has-arrow" href="/admin/users/list" aria-expanded="false" data-target-id="users_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-user-group"></i><span >{t('Users')}</span></a>
                                {/* <ul className="collapse nav-sub" aria-expanded="false" id="users_links" >
                                    <li><a onClick={(evt) => gotoUsersList(evt)} ><span>{t('All_Users')}</span></a></li>
                                    <li><a href="/admin/users/create"><span>{t('Add_new')}</span></a></li>
                                </ul> */}
                            </li>
                            {/* <li className={localStorage.getItem('active-nav-item') == "users-types-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="users-types-list"
                                onClick={() => localStorage.setItem('active-nav-item', "users-types-list")}>
                                <a className="has-arrow" href="#" aria-expanded="false" data-target-id="users_types_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-user"></i><span>{t('User_Types')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="users_types_links">
                                    <li><a onClick={(evt) => gotoUsersTypeList(evt)}><span>{t('All_User_Types')}</span></a></li>
                                    
                                </ul>
                            </li> */}
                            {/* <li className="nav-dropdown">
                                <a className="has-arrow" href="#" aria-expanded="false" data-target-id="languages_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-map"></i><span>Languages</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="languages_links">
                                    <li><a href="/admin/languages/list"><span>All languages</span></a></li>

                                </ul>
                            </li> */}

                            {/* <li className="sidebar-header"><span>CATEGORIES</span></li> */}





                            <li className="sidebar-header"><span>{t('Master_Modules')}</span></li>

                            <li className={localStorage.getItem('active-nav-item') == "brands-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="brands-list"
                                onClick={() => localStorage.setItem('active-nav-item', "brands-list")}>
                                <a href="/admin/brands/list" className="has-arrow" aria-expanded="false" data-target-id="brand_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Taxonomy')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="brand_links">
                                    {/* <li><a href="/admin/brands/list"><span>All Taxonomy</span></a></li>
                                    <li><a href="/admin/brands/create"><span>Add Taxonomy</span></a></li> */}
                                </ul>
                            </li>

                            {/* <li className="nav-dropdown">
                                <a className="has-arrow" href="#" aria-expanded="false" data-target-id="brand_category_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>Brand category</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="brand_category_links">
                                    <li><a href="/admin/brands-category-level/list"><span>All category(with brands)</span></a></li>
                                    <li><a href="/admin/brands-category-level/create"><span>Add category</span></a></li>
                                </ul>
                            </li> */}

                            <li className={localStorage.getItem('active-nav-item') == "shoe-models-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="shoe-models-list"
                                onClick={() => localStorage.setItem('active-nav-item', "shoe-models-list")}>
                                <a href="/admin/shoe-models/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Shoe_Model')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                    {/* <li><a href="/admin/shoe-models/list"><span>All shoe models</span></a></li> */}
                                    {/* <li><a href="/admin/shoe-models/create"><span>Add shoe model</span></a></li> */}
                                </ul>
                            </li>
                            <li className={localStorage.getItem('active-nav-item') == "profile-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="shoe-models-list"
                                onClick={() => localStorage.setItem('active-nav-item', "profile-list")}>
                                <a href="/admin/profile/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Profile')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                    {/* <li><a href="/admin/shoe-models/list"><span>All shoe models</span></a></li> */}
                                    {/* <li><a href="/admin/shoe-models/create"><span>Add shoe model</span></a></li> */}
                                </ul>
                            </li>
                            <li className={localStorage.getItem('active-nav-item') == "colors-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="colors-list"
                                onClick={() => localStorage.setItem('active-nav-item', "colors-list")}>
                                <a href="/admin/colors/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Colors')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                    {/* <li><a href="/admin/shoe-models/list"><span>All shoe models</span></a></li> */}
                                    {/* <li><a href="/admin/shoe-models/create"><span>Add shoe model</span></a></li> */}
                                </ul>
                            </li>





                            <li className={localStorage.getItem('active-nav-item') == "sizes-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="sizes-list"
                                onClick={() => localStorage.setItem('active-nav-item', "sizes-list")}>
                                <a href="/admin/sizes/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Sizes')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                    {/* <li><a href="/admin/shoe-models/list"><span>All shoe models</span></a></li> */}
                                    {/* <li><a href="/admin/shoe-models/create"><span>Add shoe model</span></a></li> */}
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "warehouse-b2b-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="warehouse-b2b-list"
                                onClick={() => localStorage.setItem('active-nav-item', "warehouse-b2b-list")}>
                                <a href="/admin/warehouseb2b/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Warehouse_B2B')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "search-by" ? "nav-dropdown active" : "nav-dropdown"}
                                id="search-by"
                                onClick={() => localStorage.setItem('active-nav-item', "search-by")}>
                                <a href="/admin/searchby/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Search_criteria')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "coupons-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="coupons-list"
                                onClick={() => localStorage.setItem('active-nav-item', "coupons-list")}>
                                <a href="/admin/coupons/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Coupons')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "rewards-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="rewards-list"
                                onClick={() => localStorage.setItem('active-nav-item', "rewards-list")}>
                                <a href="/admin/rewards/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Rewards')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "prize-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="termsandconditions-list"
                                onClick={() => localStorage.setItem('active-nav-item', "prize-list")}>
                                <a className="has-arrow" href="/admin/prize/list" aria-expanded="false" data-target-id="prize_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Prizes')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="prize_links">
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "orders-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="orders-list"
                                onClick={() => localStorage.setItem('active-nav-item', "orders-list")}>
                                <a href="/admin/orders/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Orders')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>
                            <li className={localStorage.getItem('active-nav-item') == "fees-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="fees-list"
                                onClick={() => localStorage.setItem('active-nav-item', "fees-list")}>
                                <a href="/admin/fees/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Fees')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>
                            <li className={localStorage.getItem('active-nav-item') == "penalty-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="penalty-list"
                                onClick={() => localStorage.setItem('active-nav-item', "penalty-list")}>
                                <a href="/admin/penalty/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Penalty')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li>

                            {/* <li className={localStorage.getItem('active-nav-item') == "sanction-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="sanction-list"
                                onClick={() => localStorage.setItem('active-nav-item', "sanction-list")}>
                                <a href="/admin/sanction/list" className="has-arrow" aria-expanded="false" data-target-id="shoe_model_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>Sanction</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_links">
                                </ul>
                            </li> */}
                            {/* <li className="nav-dropdown">
                                <a className="has-arrow" href="#" aria-expanded="false" data-target-id="shoe_model_photos__links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>Shoe model photos</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="shoe_model_photos__links">
                                    <li><a href="/admin/shoe-model-photos/list"><span>All shoe model photos</span></a></li>
                                    <li><a href="/admin/shoe-model-photos/create"><span>Add shoe model photo</span></a></li>
                                </ul>
                            </li> */}

                            {/* <li className={localStorage.getItem('active-nav-item') == "advertisement-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="advertisement-list"
                                onClick={() => localStorage.setItem('active-nav-item', "advertisement-list")}>
                                <a className="has-arrow" href="#" aria-expanded="false" data-target-id="advertisement_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>Advertisement</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="advertisement_links">
                                    <li><a href="/admin/advertisement/list"><span>All advertisement</span></a></li>
                                    <li><a href="/admin/advertisement/create"><span>Add advertisement</span></a></li>
                                </ul>
                            </li> */}






                            <li className={localStorage.getItem('active-nav-item') == "overlay-notification" ? "nav-dropdown active" : "nav-dropdown"}
                                id="termsandconditions-list"
                                onClick={() => localStorage.setItem('active-nav-item', "overlay-notification")}>
                                <a className="has-arrow" href="/admin/overlay-notification" aria-expanded="false" data-target-id="faq_links" onClick={(evt) => showHide(evt)}>
                                    <i className="icon dripicons-basket"></i>
                                    <span>{t('Overlay_Notification')}</span>
                                </a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="faq_links">
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "news-latter" ? "nav-dropdown active" : "nav-dropdown"}
                                id="termsandconditions-list"
                                onClick={() => localStorage.setItem('active-nav-item', "news-latter")}>
                                <a className="has-arrow" href="/admin/news-latter" aria-expanded="false" data-target-id="faq_links" onClick={(evt) => showHide(evt)}>
                                    <i className="icon dripicons-basket"></i>
                                    <span>{t('News_latter')}</span>
                                </a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="faq_links">
                                </ul>
                            </li>

                            {/* <li className={localStorage.getItem('active-nav-item') == "notification" ? "nav-dropdown active" : "nav-dropdown"}
                                id="termsandconditions-list"
                                onClick={() => localStorage.setItem('active-nav-item', "notification")}>
                                <a className="has-arrow" href="/admin/notification" aria-expanded="false" data-target-id="faq_links" onClick={(evt) => showHide(evt)}>
                                    <i className="icon icon-wallet"></i>
                                    <span>Notification</span>
                                </a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="faq_links">
                                </ul>
                            </li> */}

                            <li className={localStorage.getItem('active-nav-item') == "notifications-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="notifications-list"
                                onClick={() => localStorage.setItem('active-nav-item', "notifications-list")}>
                                <a className="has-arrow" href="/admin/notification/list" aria-expanded="false" data-target-id="notifications_links"
                                    onClick={(evt) => showHide(evt)}>
                                    <i className="icon dripicons-basket"></i>
                                    <span>Notifications</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="notifications_links">

                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "notifications-settings" ? "nav-dropdown active" : "nav-dropdown"}
                                id="notifications-list"
                                onClick={() => localStorage.setItem('active-nav-item', "notifications-settings")}>
                                <a className="has-arrow" href="/admin/notification/settings" aria-expanded="false" data-target-id="notifications_links"
                                    onClick={(evt) => showHide(evt)}>
                                    <i className="icon dripicons-basket"></i>
                                    <span>Notification Settings</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="notifications_links">

                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "privacypolicy-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="privacypolicy-list"
                                onClick={() => localStorage.setItem('active-nav-item', "privacypolicy-list")}>
                                <a className="has-arrow" href="/admin/privacypolicy/list" aria-expanded="false" data-target-id="privacypolicy_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Privacy_Policy')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="privacypolicy_links">
                                    {/* <li><a href="/admin/privacypolicy/list"><span>All privacy policy</span></a></li>
                                    <li><a href="/admin/privacypolicy/create"><span>Add privacy policy</span></a></li> */}
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "termsandconditions-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="termsandconditions-list"
                                onClick={() => localStorage.setItem('active-nav-item', "termsandconditions-list")}>
                                <a className="has-arrow" href="/admin/termsandconditions/list" aria-expanded="false" data-target-id="termsandconditions_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Terms_and_Conditions')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="termsandconditions_links">
                                    {/* <li><a href="/admin/termsandconditions/list"><span>All terms & conditions</span></a>
                                    </li>
                                    <li><a href="/admin/termsandconditions/create"><span>Add terms &
                                        conditions</span></a></li> */}
                                </ul>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "faq-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="termsandconditions-list"
                                onClick={() => localStorage.setItem('active-nav-item', "faq-list")}>
                                <a className="has-arrow" href="/admin/faq/list" aria-expanded="false" data-target-id="faq_links" onClick={(evt) => showHide(evt)}><i
                                    className="icon dripicons-basket"></i><span>{t('Faq')}</span></a>
                                <ul className="collapse nav-sub" aria-expanded="false" id="faq_links">
                                </ul>
                            </li>
                            {/* <li className="sidebar-header"><span>SETTINGS</span></li> */}

                            <li className={localStorage.getItem('active-nav-item') == "setting-list" ? "nav-dropdown active" : "nav-dropdown"}
                                id="setting-list"
                                onClick={() => localStorage.setItem('active-nav-item', "setting-list")}>
                                <a href="/admin/settings"><i className="icon dripicons-gear"></i><span>{t('All_Settings')}</span></a>
                            </li>

                            <li className={localStorage.getItem('active-nav-item') == "log-out" ? "nav-dropdown active" : "nav-dropdown"}
                                id="log-out"
                                onClick={() =>
                                    logout()
                                }>
                                <a href="/admin/auth/login"><i className="icon dripicons-media-record"></i><span>{t('Logout')}</span></a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </>
    )
}