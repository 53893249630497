import { createSlice } from "@reduxjs/toolkit";
import { iSetting } from "../shared/models/adminModels"; 

export interface SettingsState {
  settings: iSetting[]
  initialized: boolean
}

const initialState: SettingsState = {
  settings: [],
  initialized: false
};
const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      return {settings: action.payload, initialized: true};
    },
  },
});
export const settingsSliceActions = settingsSlice.actions;
export default settingsSlice.reducer;
