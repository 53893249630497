import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState, useRef } from "react";
import { iCategory, iShoeModel, iShoeColor, iShoeModelPhotographs, iShoeModelFilters, iSizeType, iExitPrice, ShoeModelFilters, eAppFilters } from '../../../shared/models/adminModels'
import { CommonUtil } from '../../../shared/utils/commonUtils'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { HttpCategoryService, HttpSettingService, HttpShoeModelService, HttpShoeColorService, HttpShoeModelSizeService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import Category from '../../../shared/components/admin/category'
import Multiselect from 'multiselect-react-dropdown';
import { BlobServiceClient } from "@azure/storage-blob";
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css'
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import useOnClickOutsideInside from "../../../hooks/useOnClickOutsideInside";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/stateTypes";
import { useDispatch } from "react-redux";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import CustomPagination from "../../../shared/components/CustomPagination";
const ShoeModelPage = () => {
    const { t } = useTranslation()

    // -------------- Pagination --------------------
    const [totalCategoryResults, setTotalCategoryResults] = useState(-1);
    const [totalCategoryPages, setTotalCategoryPages] = useState(-1)
    const [currentCategoryPage, setCurrentCategoryPage] = useState(1)
    // -------------- Pagination End --------------------
    const [searchCategory, setSearchCategory] = useState<string>('')

    // -------------- Pagination --------------------
    const [totalShoeModelResults, setTotalShoeModelResults] = useState(-1);
    const [totalShoeModelPages, setTotalShoeModelPages] = useState(-1)
    // -------------- Pagination End --------------------
    //const [searchShoeModel, setSearchShoeModel] = useState<string>('')

    // -------------- Pagination --------------------
    const [totalColorsResults, setTotalColorsResults] = useState(-1);
    const [totalColorsPages, setTotalColorsPages] = useState(-1)
    const [currentColorsPage, setCurrentColorsPage] = useState(1)
    // -------------- Pagination End --------------------
    const [searchShoeColors, setSearchShoeColors] = useState<string>('')


    const [categorys, setCategorys] = useState<iCategory[]>([])
    const [shoeModels, setShoeModels] = useState<iShoeModel[]>([])
    const [allShoeColors, setAllShoeColors] = useState<iShoeColor[]>([])


    const [shoeColors, setShoeColors] = useState<iShoeColor[]>([])
    //For Modal Dialog
    const { isOpen, toggle } = useModal()
    useEffect(() => {
        console.log('isOpen', isOpen)
    }, [isOpen])
    const initialShoeModel: iShoeModel = {
        id: -1,
        categoryId: 0,
        sizeTypeId: 0,
        description: "",
        exitPrice: "",
        isHidden: false,
        modelCode: "",
        modelName: "",
        releaseDate: new Date(moment().toDate()),
        color: "",
        colors: []
        // brandId?: number
        // brandName?: string

    }
    const [shoeModelToSave, setShoeModelToSave] = useState<iShoeModel>(initialShoeModel)
    const p_iExitPrices: iExitPrice[] = [
        { id: 1, fromValue: 1, toValue: 499 },
        { id: 2, fromValue: 500, toValue: 999 },
        { id: 3, fromValue: 1000, toValue: 4999 },
        { id: 4, fromValue: 5000, toValue: 9999 },
    ]
    const shoeModelPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.shoeModel));
    const dispatch = useDispatch();
    //console.log('ShoeModel Page Filters', shoeModelPageState)
    const [exitPrices, setExitPrices] = useState<iExitPrice[]>(p_iExitPrices)

    const [shoeModelPhotographs, setShoeModelPhotographs] = useState<iShoeModelPhotographs[]>([])

    const [currentState, setCurrentState] = useState<number>(0) // 0- add, 1-Edit, 2 -Photos

    const { isOpen: isOpenPhoto, toggle: togglePhoto } = useModal()

    // =============== For Category component ====================
    // For list of Brands, got from Api call
    const [brands, setBrands] = useState<iCategory[]>([])
    const [brandSelected, setBrandSelected] = useState<iCategory>()
    const [searchBrand, setSearchBrand] = useState<string>('')

    const [sizeTypes, setSizeTypes] = useState<iSizeType[]>([])
    const [currentSizeType, setCurrentSizeType] = useState<iSizeType>()

    const [populatedCategory, setPopulatedCategory] = useState<iCategory>()
    const [category, setCategory] = useState<iCategory>()

    const [uploadURL, setUploadURL] = useState<string>('')

    //For getting the sub catogray by name.
    const [brandCategory, setBrandCategory] = useState<iCategory>()

    // const [categorys, setCategorys] = useState<iCategory[]>([])
    const [refreshCatId, setRefreshCatId] = useState<number>(-1)
    // =============== END For Category component ====================


    const p_utils = new CommonUtil()
    const httpShoeModelService: HttpShoeModelService = new HttpShoeModelService();
    const httpCategoryService: HttpCategoryService = new HttpCategoryService();
    const httpShoeColorService: HttpShoeColorService = new HttpShoeColorService()
    const httpSettingService: HttpSettingService = new HttpSettingService();
    const httpShoeModelSizeService: HttpShoeModelSizeService = new HttpShoeModelSizeService();


    const ApplyBrandFilter = () => {
        let filter = getAppliedFilters() + getBrandFilter()
        //refreshShoeModels(filter, 1)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: 1,
            filter: {
                ...shoeModelPageState!.filter,
                //brandFilterApplied: (shoeModelPageState?.filter as iShoeModelFilters).brandIds.length ? true : false,
                brandFilterApplied: true,
                brandClearedManually: false
            }
        }))
    }
    useEffect(() => {
        if (!(shoeModelPageState?.filter as iShoeModelFilters).brandFilterApplied) return
        console.log('useEffect brandFilterApplied')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).brandFilterApplied])

    const ApplyCategoryFilter = () => {
        let filter = getAppliedFilters() + getCategoryFilter()
        //refreshShoeModels(filter, 1)
        console.log('ApplyCategoryFilter', filter)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: 1,
            filter: {
                ...shoeModelPageState!.filter,
                //categoryFilterApplied: (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.length ? true : false,
                categoryFilterApplied: true,
                categoryClearedManually: false
            }
        }))
    }
    useEffect(() => {
        if (!(shoeModelPageState?.filter as iShoeModelFilters).categoryFilterApplied) return
        console.log('useEffect categoryFilterApplied')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).categoryFilterApplied])

    const ApplyColorFilter = () => {
        let filter = getAppliedFilters() + getColorFilter()
        //refreshShoeModels(filter, 1)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: 1,
            filter: {
                ...shoeModelPageState!.filter,
                //colorFilterApplied: (shoeModelPageState?.filter as iShoeModelFilters).colorIds.length ? true : false,
                colorFilterApplied: true,
                colorClearedManually: false
            }
        }))
    }
    useEffect(() => {
        if (!(shoeModelPageState?.filter as iShoeModelFilters).colorFilterApplied) return
        console.log('useEffect colorFilterApplied')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).colorFilterApplied])

    const ApplyReleaseDateFilter = () => {
        let filter = getAppliedFilters() + getReleaseDateFilter()
        //refreshShoeModels(filter, 1)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: 1,
            filter: {
                ...shoeModelPageState!.filter,
                // releaseDateFilterApplied: (shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate &&
                //     (shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate ? true : false,
                releaseDateFilterApplied: true,
                releaseDateClearedManually: false
            }
        }))
    }
    useEffect(() => {
        if (!(shoeModelPageState?.filter as iShoeModelFilters).releaseDateFilterApplied) return
        console.log('useEffect releaseDateFilterApplied')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).releaseDateFilterApplied])

    const ApplyExitPriseFilter = () => {
        let filter = getAppliedFilters() + getExitPriceFilter()
        //refreshShoeModels(filter, 1)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: 1,
            filter: {
                ...shoeModelPageState!.filter,
                exitPriceFilterApplied: true,
            }
        }))
    }
    useEffect(() => {
        if (!(shoeModelPageState?.filter as iShoeModelFilters).exitPriceFilterApplied) return
        console.log('useEffect exitPriceFilterApplied')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).exitPriceFilterApplied])

    const ApplyCreationDateFilter = () => {
        let filter = getAppliedFilters() + getCreationDateFilter()
        refreshShoeModels(filter, 1)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: 1,
            filter: {
                ...shoeModelPageState!.filter,
                // creationDateFilterApplied: (shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate &&
                //     (shoeModelPageState?.filter as iShoeModelFilters).toCreationDate ? true : false,
                creationDateFilterApplied: true,
                creationDateClearedManually: false
            }
        }))
    }
    useEffect(() => {
        if (!(shoeModelPageState?.filter as iShoeModelFilters).creationDateFilterApplied) return
        console.log('useEffect creationDateFilterApplied')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).creationDateFilterApplied])

    useEffect(() => {
        console.log('--searchShoeModel')
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
    }, [(shoeModelPageState?.filter as iShoeModelFilters).searchShoeModel])

    const getQueryFilter = () => {
        let filter = ''
        if ((shoeModelPageState?.filter as iShoeModelFilters).searchShoeModel) {
            filter += '&query=' + (shoeModelPageState?.filter as iShoeModelFilters).searchShoeModel
            filter += '&includeSearch=' + true
        }
        return filter
    }
    const getBrandFilter = () => {
        return (shoeModelPageState?.filter as iShoeModelFilters).brandIds.length ?
            '&brandIds=' + (shoeModelPageState?.filter as iShoeModelFilters).brandIds.map((item) => item.id).join(',')
            : ''
    }
    const getCategoryFilter = () => {
        return (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.length ?
            '&subCategoryIds=' + (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.map((item) => item.id).join(',')
            : ''
    }
    const getColorFilter = () => {
        return (shoeModelPageState?.filter as iShoeModelFilters).colorIds.length ?
            '&colorIds=' + (shoeModelPageState?.filter as iShoeModelFilters).colorIds.map((item) => item.color).join(',')
            : ''
    }
    const getReleaseDateFilter = () => {
        let filter = ''
        if ((shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate && (shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate) {
            filter += '&fromReleaseDate=' + moment((shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate as Date).format("MM/DD/YYYY")
            filter += '&toReleaseDate=' + moment((shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate as Date).format("MM/DD/YYYY")
        }
        return filter
    }
    const getExitPriceFilter = () => {
        let filter = ''
        filter += '&fromExitPrice=' + (shoeModelPageState?.filter as iShoeModelFilters).exitPrice?.fromValue
        filter += '&toExitPrice=' + (shoeModelPageState?.filter as iShoeModelFilters).exitPrice?.toValue
        return filter
    }
    const getCreationDateFilter = () => {
        let filter = ''
        if ((shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate && (shoeModelPageState?.filter as iShoeModelFilters).toCreationDate) {
            filter += '&fromCreationDate=' + moment((shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate as Date).format("MM/DD/YYYY")
            filter += '&toCreationDate=' + moment((shoeModelPageState?.filter as iShoeModelFilters).toCreationDate as Date).format("MM/DD/YYYY")
        }
        return filter
    }
    const clearFilter = () => {

    }


    const getAppliedFilters = () => {
        let filter = ''
        filter += getQueryFilter()
        if ((shoeModelPageState?.filter as iShoeModelFilters).brandFilterApplied) {
            filter += getBrandFilter()
        }
        if ((shoeModelPageState?.filter as iShoeModelFilters).categoryFilterApplied) {
            filter += getCategoryFilter()
        }
        if ((shoeModelPageState?.filter as iShoeModelFilters).colorFilterApplied) {
            filter += getColorFilter()
        }
        if ((shoeModelPageState?.filter as iShoeModelFilters).releaseDateFilterApplied) {
            filter += getReleaseDateFilter()
        }
        if ((shoeModelPageState?.filter as iShoeModelFilters).exitPriceFilterApplied) {
            filter += getExitPriceFilter()
        }
        if ((shoeModelPageState?.filter as iShoeModelFilters).creationDateFilterApplied) {
            filter += getCreationDateFilter()
        }
        console.log('getAppliedFilters=', filter)
        return filter
    }

    const refreshShoeModels = (filters: string,
        currentPage = 1) => {
        console.log('refreshShoeModels', currentPage)
        console.log('refreshShoeModels filters', filters)
        console.log('refreshShoeModels shoeModelPageState?.currentPage!', shoeModelPageState?.currentPage!)
        setTotalShoeModelResults(0)
        setTotalShoeModelPages(0)
        httpShoeModelService
            .filterBySearchFilters<iShoeModel>(
                { currentPage: currentPage, pageLimit: shoeModelPageState!.pageLimit },
                { filters: filters })
            .then((value) => {
                //console.log('items', value.items)
                let transformShoeModel =
                    (value.items as iShoeModel[]).map((value: iShoeModel, index, arr) => {
                        return {
                            ...value,
                            searchableName: value.modelName! + ' - ' + value.modelCode!
                        }
                    })

                //console.log('transformShoeModel', transformShoeModel)
                setShoeModels(transformShoeModel)
                setTotalShoeModelResults(value.totalRows)
                console.log('totalRows', value.totalRows)
                console.log('pages', Math.ceil(value.totalRows / shoeModelPageState!.pageLimit))
                setTotalShoeModelPages(Math.ceil(value.totalRows / shoeModelPageState!.pageLimit))
            })
            .catch((error) => console.log('aaaa', error))
    }
    useEffect(() => {
        console.log('--Toggle Refresh', shoeModelPageState?.currentPage)
        refreshShoeModels(getAppliedFilters(), shoeModelPageState!.currentPage);
    }, [shoeModelPageState?.toggleRefresh])

    useEffect(() => {
        console.log('--Page Changed', shoeModelPageState?.currentPage)
        refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage);
    }, [shoeModelPageState?.currentPage, shoeModelPageState?.pageLimit])

    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            currentPage: data.currentPage
        }))
    }
    const resetCategorys = () => {
        setCategorys([])
        setTotalCategoryResults(0)
        setTotalCategoryPages(0)
        setCurrentCategoryPage(1)
    }
    const refreshCategorys = (currentPage = 1) => {
        let brandIds = (shoeModelPageState?.filter as iShoeModelFilters).brandIds.map((item) => item.id)
        if (!brandIds || !brandIds.length) {
            resetCategorys()
            return
        }
        httpCategoryService
            .filterByBrandIds(
                { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT_ALL_BRANDS },
                { ids: brandIds, query: searchCategory },
            )
            .then((value) => {
                console.log('categorys', value)
                setCategorys(value.items)
                setTotalCategoryResults(value.totalRows)
                setTotalCategoryPages(Math.ceil(value.totalRows / appConst.PAGE_LIMIT))
                setCurrentCategoryPage(currentPage)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        refreshCategorys()
    }, [(shoeModelPageState?.filter as iShoeModelFilters).brandIds, searchCategory])

    useEffect(() => {
        fetchAllShoeColors()
    }, [searchShoeColors])

    const fetchAllShoeColors = (currentPage = 1) => {
        httpShoeColorService.filterBy<iShoeColor>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT_COLOR_MULTISELECT },
            { query: searchShoeColors })
            .then((value) => {
                setAllShoeColors(value.items)
                setTotalColorsResults(value.totalRows)
                setTotalColorsPages(Math.ceil(value.totalRows / appConst.PAGE_LIMIT))
            })
    }

    const fetchShoeModelColors = (shoeModelId: number) => {
        httpShoeModelService.get<iShoeModel>({ id: shoeModelId })
            .then((value) => {
                if (value.data.colors) {
                    console.log('value.data.colors', value.data.colors)
                    setShoeColors(value.data.colors ? value.data.colors : [])
                }
            })
    }
    const getUploadURL = async () => {
        await httpSettingService
            .getBlobURL()
            .then((value) => {
                console.log('getUploadURL', value)
                setUploadURL(value)
            }).catch((error) => {
                console.log('getUploadURL error', error)
            })
    }
    useEffect(() => {
        console.log('ShoeModelPage')
        //refreshShoeModels()
        //refreshCategorys()
        fetchAllShoeColors()
        getUploadURL()

    }, [])
    const fetchShoeModel = async (id: number) => {
        await httpShoeModelService
            .getShoeModelColors({ id: id })
            .then((value: any) => {
                console.log('goto Shoemodel', value)
                setShoeModelToSave(value.data as iShoeModel)
            })
    }
    const gotoEditShoeModel = async (shoeModel: iShoeModel) => {
        //await fetchShoeModel(shoeModel.id)
        await httpShoeModelService
            .getShoeModelColors({ id: shoeModel.id })
            .then((val) => {
                console.log('in gotoEditShoeModel', shoeModel)
                console.log('in shoeColors', val)
                if (shoeModel.brandId) {
                    console.log('in gotoEditShoeModel', 1)
                    onBrandsOptionChange(shoeModel.brandId!)
                    console.log('in gotoEditShoeModel', 2)
                    //fetchShoeModelColors(shoeModel.id)
                    console.log('in gotoEditShoeModel', 3)
                    setShoeModelToSave({ ...shoeModelToSave, ...shoeModel, colors: val.data as iShoeColor[] })
                    console.log('in gotoEditShoeModel', shoeModel.colors)
                    setShoeColors(val.data as iShoeColor[])
                    console.log('in gotoEditShoeModel', 4)
                    refreshUI(shoeModel.categoryId)
                    console.log('in gotoEditShoeModel', 5)
                    onEditShoeClick()
                    console.log('in gotoEditShoeModel', 6)
                }
            })

    }
    const deleteShoeModel = async (shoeModel: iShoeModel) => {
        console.log('handelOnDelete', shoeModel.id)
        // let prev_cat_id: number = category ? category.id : 0
        await httpShoeModelService.delete<iShoeModel>(shoeModel.id)
            .then((value) => {
                toast.success(t`Delete_success_msg`)
                refreshShoeModels(getAppliedFilters())
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        if (shoeModelToSave.id == -1) return
        console.log('shoeModelToSave id', shoeModelToSave.id)
        console.log('currentState', currentState)
        switch (currentState) {
            case 0:
                break;
            case 1:
                break;
            case 2:
                setShoeModelPhotographs([])
                getShoeModelImages()
                onAddShoeModelPhotoClick()
                break;
        }
    }, [shoeModelToSave, currentState])

    const editShoeModelPhotos = (shoeModel: iShoeModel) => {
        console.log('editShoeModelPhotos', shoeModel)
        console.log('editShoe---ModelPhotos', { ...initialShoeModel, ...shoeModel })
        setShoeModelToSave({ ...initialShoeModel, ...shoeModel })
        setCurrentState(2)  // 0- add, 1-Edit, 2 -Photos
        console.log('shoeModelToSave', { ...shoeModelToSave, ...shoeModel })
    }

    const onSelectColor = (selectedList: iShoeColor[], selectedItem: iShoeColor) => {
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        // shoeModelToSave.colors = selectedList
        setShoeColors(selectedList)
    }
    const onRemoveColor = (selectedList: iShoeColor[], removedItem: iShoeColor) => {
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        // shoeModelToSave.colors = selectedList
        setShoeColors(selectedList)
    }
    const renderShoeModels = (): ReactNode[] => {
        // console.log('shoeModels?.length',shoeModels?.length)
        // console.log('shoeModels=',shoeModels)
        if (!shoeModels || shoeModels.length == 0) return []

        const returnNode: ReactNode[] = shoeModels?.map((shoeModel: iShoeModel) => (
            <tr key={shoeModel.id}>
                <td>{shoeModel.id}</td>
                <td>{shoeModel.title!.replaceAll("/", ">")}</td>
                {/* <td>{shoeModel.categoryName}</td> */}
                <td>{shoeModel.modelName}</td>
                <td>{shoeModel.modelCode}</td>

                <td>{shoeModel.color}</td>

                <td style={{ whiteSpace: "pre" }}>{moment(shoeModel.releaseDate).format('DD/MM/YYYY')}</td>
                <td>{shoeModel.exitPrice}</td>
                <td>{shoeModel.isHidden ? 'true' : 'false'}</td>
                <td width={100}>{<>
                    <a onClick={() => gotoEditShoeModel(shoeModel)} data-toggle="tooltip" data-placement="top" title="Edit" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-pencil-square"></i></a>
                    <a onClick={() => editShoeModelPhotos(shoeModel)} data-toggle="tooltip" data-placement="top" title="Add Images" data-original-title="Add Images" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-image"></i></a>
                    {/* <a onClick={() => deleteShoeModel(shoeModel)} data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-remove"></i></a> */}
                </>}
                </td>
            </tr>

        ))
        return returnNode
    }
    const showHideQuickActions = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        p_utils.showHideQuickActions(evt, document)

    }
    const onAddShoeModelClick = () => {
        console.log('in onAddShoeModelClick')
        setCurrentState(0)  // 0- add, 1-Edit, 2 -Photos
        setShoeColors([])
        console.log('initialShoeModel', { ...initialShoeModel, categoryId: shoeModelToSave.categoryId })
        setShoeModelToSave({ ...initialShoeModel, categoryId: shoeModelToSave.categoryId })
        toggle()

    }
    const cancelAdd = () => {
        console.log('in cancelAdd')
        //setBrandCategory(undefined)
        setBrandSelected(undefined)
        setCategory(undefined)
        setShoeColors([])
        setShoeModelToSave(initialShoeModel)
        toggle()
    }
    const onEditShoeClick = () => {
        console.log('in onEditShoeClick')
        setCurrentState(1)  // 0- add, 1-Edit, 2 -Photos
        toggle()


    }
    const onAddShoeModelPhotoClick = () => {
        console.log('in onAddShoeModelPhotoClick')
        // setCurrentState(2)
        togglePhoto()

    }
    const cancelAddPhoto = () => {
        console.log('in cancelAddPhoto')
        togglePhoto()
    }
    const onToggleClick = () => {
        console.log('in onToggleClick')
        toggle()

        if (category) {
            console.log('category.id', category.id)
            // refreshUI(category.id)
        }

    }

    const deleteImage = (item: iShoeModelPhotographs) => {
        if (item.id) {
            httpShoeModelService.deleteImage(shoeModelToSave.id, item.id).then(async (value) => await getShoeModelImages())

        }
    }
    const enableExecuteShoeModelPhotograph = () => {
        let index = shoeModelPhotographs.findIndex((value) => value.isDefault == true)
        if (shoeModelPhotographs.length > 0 && index !== -1) {
            return true
        }
        return false
    }
    const setDefaultImage = (item: iShoeModelPhotographs) => {
        let smp = shoeModelPhotographs.map((shoeModelPhotograph, index) => {
            if (shoeModelPhotograph.id === item.id) {
                return { ...shoeModelPhotograph, isDefault: true }
            } else {
                return { ...shoeModelPhotograph, isDefault: false }
            }
        })

        setShoeModelPhotographs(smp)
        // item.isDefault = true
    }
    const onExecuteAddShoeModelPhoto = async () => {
        console.log('onExecuteAddShoeModelPhoto', shoeModelPhotographs)
        for (let i = 0; i < shoeModelPhotographs.length; i++) {
            if (shoeModelPhotographs[i].isDefault == true) {
                if (shoeModelPhotographs[i].id !== -1) {
                    console.log('shoeModelToSave.id', shoeModelToSave.id)
                    console.log('shoeModelPhotographs[i].id', shoeModelPhotographs[i].id)
                    console.log('shoeModelPhotographs[i]', shoeModelPhotographs[i])
                    await httpShoeModelService.setDefaultImage(shoeModelToSave.id,
                        shoeModelPhotographs[i].id,
                        shoeModelPhotographs[i])//.then((value) => refreshShoeModels())
                }
                break;
            }
        }
        // TODO: uncomment when api is ready.
        // await httpShoeModelService.setOrderedImages(shoeModelToSave.id,shoeModelPhotographs)
        togglePhoto()

    }
    const onExecuteAddEditShoeModel = async () => {
        try {
            if (category && category.id) {
                if (currentState == 0) { //Add shoe model
                    setShoeModelToSave({
                        ...shoeModelToSave,
                        categoryId: category.id,
                        colors: shoeColors,
                        sizeTypeId: currentSizeType!.id
                    })
                    await httpShoeModelService
                        .add<iShoeModel>(
                            {
                                ...shoeModelToSave,
                                categoryId: category.id,
                                colors: shoeColors,
                                sizeTypeId: currentSizeType!.id
                            })
                    toast.success(t`Add_success_msg`);
                    dispatch(appPageSliceActions.savePageFilters({
                        ...shoeModelPageState!,
                        currentPage: 1,
                        toggleRefresh: !shoeModelPageState?.toggleRefresh
                    }))
                    //refreshShoeModels(getAppliedFilters(), 1)
                } else if (currentState == 1) {    // 0- add, 1-Edit, 2 -Photos
                    setShoeModelToSave({ ...shoeModelToSave, categoryId: category.id, colors: shoeColors })
                    await httpShoeModelService
                        .updatePUT<iShoeModel>(
                            shoeModelToSave.id,
                            {
                                ...shoeModelToSave,
                                categoryId: category.id,
                                colors: shoeColors,
                                //sizeTypeId: currentSizeType!.id
                            })
                    toast.success(t`Edit_success_msg`);
                    dispatch(appPageSliceActions.savePageFilters({
                        ...shoeModelPageState!,
                        toggleRefresh: !shoeModelPageState?.toggleRefresh
                    }))
                    //refreshShoeModels(getAppliedFilters(), shoeModelPageState?.currentPage)
                }
                onToggleClick()
            }
            // refresh list of brands
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onExecuteAddEditShoeModel error', e.message)
        }
    }
    const onEnableExecuteAddShoeModel = () => {
        console.log('bool', shoeModelToSave.modelName &&
            shoeModelToSave.modelCode &&
            brandCategory &&
            category && category.id && shoeModelToSave.releaseDate &&
            shoeModelToSave.exitPrice && shoeColors.length &&
            shoeModelToSave.description)
        if (
            shoeModelToSave.modelName &&
            shoeModelToSave.modelCode &&
            brandCategory &&
            category && category.id &&

            shoeModelToSave.releaseDate &&
            shoeModelToSave.exitPrice &&
            shoeColors.length &&
            shoeModelToSave.description
        ) {
            if (currentState == 0) {
                if (currentSizeType && currentSizeType.id) {
                    console.log('size type ret true')
                    return true;
                }
                else {
                    console.log('current state 0 size not set ret false')
                    return false
                }
            } else {
                console.log('current state 1 ret true')
                return true
            }
        } else {
            console.log('==== ret false')
            return false;
        }
    };
    useEffect(() => {
        if (refreshCatId !== -1 && brandCategory) {
            console.log('in useEffect [refreshCatId, brandCategory] ', refreshCatId)
            setCategory(p_utils.getCategoryById(refreshCatId, brandCategory))
        }
    }, [refreshCatId, brandCategory])

    function populatePathCategory(cat: iCategory): iCategory {
        p_utils.populateCategoryPath(cat)
        return cat
    }
    const refreshUI = async (idRefresh: number) => {
        console.log('in refreshUI idRefresh', idRefresh)
        if (!brandSelected) return
        await httpCategoryService.get<iCategory>({ id: +brandSelected.id })
            .then((value) => {
                // if (id && +id == idRefresh) {
                console.log('id=', brandSelected.id)
                console.log('idRefresh=', idRefresh)
                let p_cat = populatePathCategory(value.data)
                console.log('p_cat', p_cat)
                setPopulatedCategory(p_cat)
                setRefreshCatId(idRefresh)

            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        if (populatedCategory) {
            setBrandCategory(populatedCategory)
        }
    }, [populatedCategory])

    const refreshBrands = (currentPage = 1) => {
        console.log('refreshBrands called')
        // if (brands) return
        const bs = httpCategoryService
            .filterBy<iCategory>(
                { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT_ALL_BRANDS },
                { parentId: 0, query: searchBrand }
            )
            .then((value) => {
                //console.log('rB data', value.data)
                setBrands(value.items)

                //setBrandSelected(value.data[0])
            })
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        refreshBrands()
    }, [searchBrand])
    useEffect(() => {
        console.log('brands', brands)

    }, [brands])



    useEffect(() => {
        console.log('shoeModelFilters---0000', shoeModelPageState)
        if ((shoeModelPageState?.filter as iShoeModelFilters).clearFilter) {
            console.log('Clear filter', getAppliedFilters())
            refreshShoeModels(getAppliedFilters(), 1)
            //setShoeModelFilters({ ...shoeModelFilters, clearFilter: false })
            dispatch(appPageSliceActions.savePageFilters({
                ...shoeModelPageState!,
                currentPage: 1,
                filter: {
                    ...shoeModelPageState!.filter,
                    clearFilter: false
                }
            }))
        }
    }, [shoeModelPageState])

    const [brandRemoved, setBrandRemoved] = useState<boolean>(false)

    const setShoeModelFilterForBrandOnBrandsChange = (item: iCategory) => {
        let filteredBrands: iCategory[] = []
        let brandFound = (shoeModelPageState?.filter as iShoeModelFilters)
            .brandIds!
            .find((value, index) => value.id == item.id)
        if (brandFound) {
            filteredBrands = (shoeModelPageState?.filter as iShoeModelFilters)
                .brandIds!
                .filter((value, index) =>
                    value.id !== item.id
                )
        } else {
            filteredBrands = (shoeModelPageState?.filter as iShoeModelFilters).brandIds!.concat(item)
        }
        // setShoeModelFilters({
        //     ...shoeModelFilters,
        //     brandIds: filteredBrands,
        //     brandFilterApplied: false,
        //     brandClearedManually: filteredBrands.length ? false : true
        // })
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            filter: {
                ...shoeModelPageState!.filter,
                brandIds: filteredBrands,
                brandFilterApplied: false,
                brandClearedManually: filteredBrands.length ? false : true
            }
        }))
        setBrandRemoved(brandFound ? true : false)
    }
    const setShoeModelFilterForCategoryOnBrandsChange = () => {
        let catChanged = false
        let catIds: iCategory[] = []
        console.log('shoeModelFilters.brandIds.length', (shoeModelPageState?.filter as iShoeModelFilters).brandIds.length)
        if (!(shoeModelPageState?.filter as iShoeModelFilters).brandIds.length) {
            // setShoeModelFilters({
            //     ...shoeModelFilters,
            //     categoryIds: [],
            //     brandFilterApplied: false,
            //     categoryFilterApplied: false
            // })
            dispatch(appPageSliceActions.savePageFilters({
                ...shoeModelPageState!,
                filter: {
                    ...shoeModelPageState!.filter,
                    categoryIds: [],
                    brandFilterApplied: false,
                    categoryFilterApplied: false
                }
            }))

            return

        } else {
            catIds = (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.filter((item) => {
                if ((shoeModelPageState?.filter as iShoeModelFilters).brandIds
                    .find((value) =>
                        value.id == item.parentCategoryId)) {
                    return true
                } else {
                    catChanged = true
                    return false
                }
            })

        }
        // setShoeModelFilters({
        //     ...shoeModelFilters,
        //     categoryIds: catIds,
        //     brandFilterApplied: false,
        //     categoryFilterApplied: !catChanged && shoeModelFilters.categoryIds.length > 0,
        //     categoryClearedManually: catIds.length ? false : true
        // })
        dispatch(appPageSliceActions.savePageFilters({
            ...shoeModelPageState!,
            filter: {
                ...shoeModelPageState!.filter,
                categoryIds: catIds,
                brandFilterApplied: false,
                categoryFilterApplied: !catChanged && (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.length > 0,
                categoryClearedManually: catIds.length ? false : true
            }
        }))
    }

    useEffect(() => {
        if (!brandRemoved) return
        setShoeModelFilterForCategoryOnBrandsChange()
        setBrandRemoved(false)
    }, [brandRemoved])

    const getSizeTypeForBrand = async (currentPage = 1) => {
        await httpShoeModelSizeService
            .getAllSizeTypes<iSizeType>(
                { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT },
                brandSelected!.id
            )
            .then((value) => {
                console.log('getAllSizeTypes', value)
                setSizeTypes(value.data)
            })
            .catch((error) => console.log(error))
    }
    const isSizeSelected = (item: iSizeType) => {
        if (currentState == 0) {
            if (currentSizeType?.id == item.id)
                return true
            else
                return false
        } else {
            if (shoeModelToSave.sizeTypeId == item.id)
                return true
            else
                return false
        }
    }
    useEffect(() => {
        console.log('in useEffect', brandSelected)
        if (brandSelected) {
            console.log('in useEffect id', brandSelected.id)
            setCurrentSizeType(undefined)
            getSizeTypeForBrand()
            refreshUI(brandSelected.id)
        }
    }, [brandSelected])

    const onBrandsOptionChange = (brand_id: number) => {
        if (!brand_id) {
            return
        }
        let filteredBrands = brands.filter((value) => value.id == brand_id)
        console.log('onBrandsOptionChange', filteredBrands[0])
        setBrandSelected(filteredBrands[0])
        // TODO: fetch brand categories, all the decendents of this brand_id
    }
    const onSizeTypeOptionChange = (sizeTypeId: number) => {
        let filteredSizeTypes = sizeTypes.filter((value) => value.id == sizeTypeId)
        console.log('onSizeTypeOptionChange', filteredSizeTypes[0])
        setCurrentSizeType(filteredSizeTypes[0])
    }

    const handelSetCurrentCategory = (p_Cat: iCategory) => {
        // console.log('p_Cat.name', p_Cat.name)
        setCategory(p_Cat)
    }

    const handelSetCurrentCategoryByName = (cat_name: string) => {
        // console.log('handelSetCurrentCategoryByName cat_name', cat_name)
        if (brandCategory) {
            console.log('brandCategory.name', brandCategory.name)
            let x: iCategory | undefined = p_utils.getCategoryByName(cat_name, brandCategory)
            console.log('x?.name', x?.name)

            setCategory(x)
        }
    }
    const handelGetCategoryChildCount = (cat_name: string): number | undefined => {
        //console.log('handelGetCategoryChildCount cat_name', cat_name)
        if (brandCategory) {
            //console.log('handelGetCategoryChildCount brandCategory.name', brandCategory.name)
            let x: iCategory | undefined = p_utils.getCategoryByName(cat_name, brandCategory)
            //console.log('x?.name', x?.name)
            //console.log('x.childCategories.length', x?.childCategories.length)
            return (x) ? x.childCategories.length : 0

        }
    }

    //START _MANJU_ CHANGES
    const [file, setFile] = useState(null);

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };

    useEffect(() => {
        if (!file) return
        console.log('useEffect called')
        handleAddImage()
    }, [file])

    const getShoeModelImages = async (currentPage = 1) => {
        await httpShoeModelService.getImages<iShoeModelPhotographs>(shoeModelToSave.id,
            { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value) => {
                console.log('Shoe model imgs', value)
                let sorted
                if (value.length) {
                    sorted = value.sort((a, b) =>
                        a.posn && b.posn ? a.posn - b.posn : a.id - b.id
                    )
                } else {
                    sorted = value
                }
                sorted.forEach((item, index, arr) => arr[index].posn = index + 1)
                setShoeModelPhotographs(sorted)
            })
    }
    const handleAddImage = async () => {
        console.log('handleAddImage called')
        // event.preventDefault();
        uploadImage("shoemodel/480", file).then(async (fileId) => {
            if (file != null && fileId != "") {
                const imageToSave: iShoeModelPhotographs = {
                    id: -1,
                    // fileId: fileId,
                    url: fileId,
                    name: fileId, //file.name 
                    isDefault: false // or 1 if default
                }
                console.log('shoeModelToSave.id', shoeModelToSave.id)
                let resp = await httpShoeModelService.addImage(shoeModelToSave.id, imageToSave)
                    .then((value) => {
                        console.log('value', value)
                        setFile(null)

                        getShoeModelImages()

                    })

                console.log('handleAddImage', resp)
            }

        });
    };

    async function uploadImage(containerName: string, file: any): Promise<string> {
        console.log('uploadURL', uploadURL)
        var tempArr: Array<string> = file.name.split('.');
        var extension: string = tempArr[tempArr.length - 1];
        let p_Utils = new CommonUtil()
        if (p_Utils.isAttachmentSupported(extension)) {
            const blobServiceClient = new BlobServiceClient(
                uploadURL
                //process.env.REACT_APP_BLOB_STORAGE_URL!
            );
            let modelName, modelCode, color
            modelName = shoeModelToSave.modelName.replace(/[^a-zA-Z^0-9]+/g, '')
            modelCode = shoeModelToSave.modelCode.replace(/[^a-zA-Z^0-9]+/g, '')
            color = shoeModelToSave.color!.replace(/[^a-zA-Z^0-9]+/g, '')
            let fileName = modelName + '-' +
                modelCode + '-' +
                color! + '-' +
                p_Utils.getTimeStamp()
            const blobname = fileName + "." + extension;
            console.log('blobname', blobname)
            console.log('blobname-shoeModelToSave', shoeModelToSave)
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobClient = containerClient.getBlobClient(blobname);
            const blockBlobClient = blobClient.getBlockBlobClient();
            await blockBlobClient.uploadBrowserData(file, {
                blockSize: 4 * 1024 * 1024,
                concurrency: 20,
                onProgress: ev => console.log(ev)
            });
            return blobname;
        }
        return "";
    }
    //END _MANJU_ CHANGES


    const dragItem = useRef<number>(-1);
    const dragOverItem = useRef<number>(-1);
    const dragStart = (e: React.DragEvent<HTMLDivElement>, position: number) => {
        dragItem.current = position; //shoeModelPhotographs[position] ;
        console.log('dragStart position', position)
        console.log('dragStart', e.target);
    };
    const dragEnter = (e: React.DragEvent<HTMLDivElement>, position: number) => {
        dragOverItem.current = position; //shoeModelPhotographs[position] ;
        console.log('dragEnter position', position)
        console.log('dragEnter', e.target);
    };
    const drop = (e: React.DragEvent<HTMLDivElement>) => {
        console.log('drop', e.target)
        const copyListItems = [...shoeModelPhotographs];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = -1;
        dragOverItem.current = -1;
        console.log('copyListItems', copyListItems)
        copyListItems.forEach((item, index, arr) => {
            arr[index].posn = index + 1
        })
        console.log('copyListItems', copyListItems)
        httpShoeModelService.setOrderedImages(shoeModelToSave.id, copyListItems)
            .then((value) => {
                setShoeModelPhotographs([])
                getShoeModelImages()
            })
        // setShoeModelPhotographs(copyListItems)

    };
    let releaseDateRef = useRef<HTMLDivElement>(null);
    const [resleaseDateInside, setReleaseDateInside] = useState<boolean | undefined>(undefined)
    useOnClickOutsideInside(releaseDateRef, () => {
        setReleaseDateInside(false)
    },
        () => {
            setReleaseDateInside(true)
        }
    );
    const [releaseDateHasFocus, setReleaseDateHasFocus] = useState<string | URL>(); //Initial value
    // //Custom input
    // const CustomInput = React.forwardRef((props, ref:LegacyRef<HTMLInputElement> | undefined) => {
    //     return <input type="text" ref={ref} {...props} 
    //                     autoFocus={releaseDateHasFocus}
    //             />;
    // });

    // const DatePickerInput  = ({ onFocus, onBlur, ...props }) => {
    //     //Ref needed for custom input
    //     const refCustomInput = useRef();

    //     return <DatePicker customInput={<CustomInput ref={refCustomInput} />} {...props} />;
    // };
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">

                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>{t('Shoe_Model')}</h3>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    <div className="action-btn">
                                        {/* <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                debounceTimeout={500}
                                                onChange={event => setSearchShoeModel(event.target.value)}
                                                placeholder={"Search..."}
                                                value={searchShoeModel} />
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                            </div>
                                        </div> */}
                                        <a onClick={() => onAddShoeModelClick()} href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-shoe-model" data-toggle="modal" data-target="#addShoeModel">{t('Add_Shoe_Model')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="shoe-model-grid">
                            {/* == Begin Filter ==*/}
                            <div className="gird-filter">
                                <div className="row align-items-center">
                                    <div className="col-sm-4">
                                        <span>Selected <span>2</span> 0f <span className="t-fade">3</span></span> |
                                        <a href="javascript:void(0)" className="reset-link">Reset</a>
                                        <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm me-2"><span className="la la-plus"></span> Import</a>
                                        <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm"><span className="la la-close"></span></a>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="row">
                                            <div className="col-sm-7 align-txt-right">
                                                <div className="filter-check">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="id1" />
                                                        <label className="form-check-label">Show only results to be imported</label>
                                                    </div>
                                                    <div className="filter-icon">
                                                        <div className="dropdown">
                                                            <a className="bulk-secondary-btn bulk-sm dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><span className="la la-bars"></span></a>
                                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink">
                                                                <li className="dropdown dropend"

                                                                >
                                                                    <a className="dropdown-item dropdown-toggle" href="#" id="multilevelDropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Brands <span className="badge">{(shoeModelPageState?.filter as iShoeModelFilters).brandIds!.length}</span></a>
                                                                    <div className="dropdown-menu dropdown-submenu-left" aria-labelledby="multilevelDropdownMenu1"
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                            e.stopPropagation();
                                                                            return false
                                                                        }}
                                                                    //onFocus={(e:any)=> setReleaseDateHasFocus(open)}
                                                                    >
                                                                        <div className="filter-in-serach">
                                                                            <div className="search-input">
                                                                                <span className="icon la la-search"></span>
                                                                                {/* <input type="text" className="form-control" placeholder="Research" /> */}
                                                                                <DebounceInput


                                                                                    className="form-control"
                                                                                    minLength={1}
                                                                                    debounceTimeout={500}
                                                                                    onChange={event => setSearchBrand(event.target.value)}
                                                                                    placeholder={"Search..."}
                                                                                    value={searchBrand} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="filter-in-content">
                                                                            {brands && brands.length && brands.map((item, index) =>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox"
                                                                                        value={item.id} id="id2"
                                                                                        checked={(shoeModelPageState?.filter as iShoeModelFilters).brandIds!.filter((value, index) => value.id == item.id).length ? true : false}
                                                                                        onChange={() =>
                                                                                            setShoeModelFilterForBrandOnBrandsChange(item)
                                                                                        } />
                                                                                    <label className="form-check-label">{item.name}</label>
                                                                                </div>
                                                                            )}
                                                                            {/* <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Nike Jordans</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Nike</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">New Balance</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Puma</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Yeezy</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Nike</label>
                                                                            </div> */}
                                                                        </div>
                                                                        <div className="filter-footer">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-3 pe-0">{(shoeModelPageState?.filter as iShoeModelFilters).brandIds!.length} of <span className="t-fade">{brands.length}</span></div>
                                                                                <div className="col-9 ps-0 text-end">
                                                                                    <a href="javascript:void(0)"
                                                                                        className={//!shoeModelFilters.brandFilterApplied 
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).brandIds.length == 0
                                                                                                ? "bulk-primary-btn bulk-sm me-2 disable"
                                                                                                : "bulk-primary-btn bulk-sm me-2"
                                                                                        }
                                                                                        // style={{
                                                                                        //     pointerEvents: shoeModelFilters.brandFilterApplied ? 'none'
                                                                                        //         : 'auto'
                                                                                        // }}
                                                                                        onClick={() => {
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     brandIds: [],
                                                                                            //     brandFilterApplied: false,
                                                                                            //     brandClearedManually: false,
                                                                                            //     clearFilter: true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    brandIds: [],
                                                                                                    brandFilterApplied: false,
                                                                                                    brandClearedManually: false,
                                                                                                    clearFilter: true
                                                                                                }
                                                                                            }))
                                                                                        }}

                                                                                    >{t('Clear')}</a>
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(!(shoeModelPageState?.filter as iShoeModelFilters).brandFilterApplied &&
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).brandIds.length)
                                                                                            || ((shoeModelPageState?.filter as iShoeModelFilters).brandClearedManually)
                                                                                            ?
                                                                                            "bulk-primary-btn bulk-sm "
                                                                                            : "bulk-primary-btn bulk-sm disable"
                                                                                        }
                                                                                        // style={{
                                                                                        //     pointerEvents: shoeModelFilters.brandFilterApplied ? 'none'
                                                                                        //         : 'auto'
                                                                                        // }}
                                                                                        onClick={() => ApplyBrandFilter()}
                                                                                    >Apply Filter</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="dropdown dropend">
                                                                    <a className="dropdown-item dropdown-toggle" href="#" id="multilevelDropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Categories <span className="badge">{(shoeModelPageState?.filter as iShoeModelFilters).categoryIds!.length}</span></a>
                                                                    <div className="dropdown-menu dropdown-submenu-left" aria-labelledby="multilevelDropdownMenu1"
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                            e.stopPropagation();
                                                                            return false
                                                                        }}
                                                                    >
                                                                        <div className="filter-in-serach">
                                                                            <div className="search-input">
                                                                                <span className="icon la la-search"></span>
                                                                                {/* <input type="text" className="form-control" placeholder="Research" /> */}
                                                                                <DebounceInput
                                                                                    className="form-control"
                                                                                    minLength={1}
                                                                                    debounceTimeout={500}
                                                                                    onChange={event => setSearchCategory(event.target.value)}
                                                                                    placeholder={"Search..."}
                                                                                    value={searchCategory} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="filter-in-content">
                                                                            {categorys && categorys.length && categorys.map((item, index) =>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" value={item.id} id="id2"
                                                                                        checked={(shoeModelPageState?.filter as iShoeModelFilters).categoryIds!.filter((value, index) => value.id == item.id).length ? true : false}
                                                                                        onChange={() => {
                                                                                            let found = (shoeModelPageState?.filter as iShoeModelFilters).categoryIds!.find((value, index) => value.id == item.id)
                                                                                            let catids = []
                                                                                            if (found) {
                                                                                                catids = (shoeModelPageState?.filter as iShoeModelFilters).categoryIds!.filter((value, index) => value.id !== item.id)
                                                                                            } else {
                                                                                                catids = (shoeModelPageState?.filter as iShoeModelFilters).categoryIds!.concat(item)
                                                                                            }
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     categoryIds: catids,
                                                                                            //     categoryFilterApplied: false,
                                                                                            //     categoryClearedManually: catids.length ? false : true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    categoryIds: catids,
                                                                                                    categoryFilterApplied: false,
                                                                                                    categoryClearedManually: catids.length ? false : true
                                                                                                }
                                                                                            }))
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    <label className="form-check-label">{item.name}</label>
                                                                                </div>
                                                                            )}
                                                                            {/* <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Nike Jordans</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Nike</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">New Balance</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Puma</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Yeezy</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Nike</label>
                                                                            </div> */}
                                                                        </div>
                                                                        <div className="filter-footer">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-3 pe-0">{(shoeModelPageState?.filter as iShoeModelFilters).categoryIds!.length} of <span className="t-fade">{shoeModels.length}</span></div>
                                                                                <div className="col-9 ps-0 text-end">
                                                                                    <a href="javascript:void(0)"
                                                                                        className={//!shoeModelFilters.categoryFilterApplied &&
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.length == 0 ?
                                                                                                "bulk-primary-btn bulk-sm me-2 disable"
                                                                                                : "bulk-primary-btn bulk-sm me-2"
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     categoryIds: [],
                                                                                            //     categoryFilterApplied: false,
                                                                                            //     clearFilter: true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                currentPage: 1,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    categoryIds: [],
                                                                                                    categoryFilterApplied: false,
                                                                                                    clearFilter: true
                                                                                                }
                                                                                            }))
                                                                                        }}
                                                                                    // style={{
                                                                                    //     pointerEvents: shoeModelFilters.categoryFilterApplied ? 'none'
                                                                                    //         : 'auto'
                                                                                    // }}
                                                                                    >{t('Clear')}</a>
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(!(shoeModelPageState?.filter as iShoeModelFilters).categoryFilterApplied &&
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).categoryIds.length)
                                                                                            || (shoeModelPageState?.filter as iShoeModelFilters).categoryClearedManually
                                                                                            ?
                                                                                            "bulk-primary-btn bulk-sm "
                                                                                            : "bulk-primary-btn bulk-sm disable"
                                                                                        }

                                                                                        // style={{
                                                                                        //     pointerEvents: shoeModelFilters.categoryFilterApplied ? 'none'
                                                                                        //         : 'auto'
                                                                                        // }}
                                                                                        onClick={() => ApplyCategoryFilter()}
                                                                                    >Apply Filter</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="dropdown dropend">
                                                                    <a className="dropdown-item dropdown-toggle" href="#" id="multilevelDropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Color <span className="badge">{(shoeModelPageState?.filter as iShoeModelFilters).colorIds!.length}</span></a>
                                                                    <div className="dropdown-menu dropdown-submenu-left" aria-labelledby="multilevelDropdownMenu1"
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                            e.stopPropagation();
                                                                            return false
                                                                        }}
                                                                    >
                                                                        <div className="filter-in-serach">
                                                                            <div className="search-input">
                                                                                <span className="icon la la-search"></span>
                                                                                <input type="text" className="form-control" placeholder="Research" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="filter-in-content">
                                                                            {allShoeColors && allShoeColors.length && allShoeColors.map((item, index) =>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" type="checkbox" value={item.id} id="id2"
                                                                                        checked={(shoeModelPageState?.filter as iShoeModelFilters).colorIds!.filter((value, index) => value.id == item.id).length ? true : false}
                                                                                        onChange={() => {
                                                                                            let found = (shoeModelPageState?.filter as iShoeModelFilters).colorIds!.find((value, index) => value.id == item.id)
                                                                                            let colorids = []
                                                                                            if (found) {
                                                                                                colorids = (shoeModelPageState?.filter as iShoeModelFilters).colorIds!.filter((value, index) => value.id !== item.id)
                                                                                            } else {
                                                                                                colorids = (shoeModelPageState?.filter as iShoeModelFilters).colorIds!.concat(item)
                                                                                            }
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     colorIds: colorids,
                                                                                            //     colorFilterApplied: false,
                                                                                            //     colorClearedManually: colorids.length ? false : true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    colorIds: colorids,
                                                                                                    colorFilterApplied: false,
                                                                                                    colorClearedManually: colorids.length ? false : true
                                                                                                }
                                                                                            }))
                                                                                        }
                                                                                        }
                                                                                    />
                                                                                    <label className="form-check-label">{item.color}</label>
                                                                                </div>
                                                                            )}
                                                                            {/* <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Navy Blue</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Red</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">Pink</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">White</label>
                                                                            </div> */}
                                                                        </div>
                                                                        <div className="filter-footer">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-3 pe-0">{(shoeModelPageState?.filter as iShoeModelFilters).colorIds!.length} of <span className="t-fade">{allShoeColors!.length}</span></div>
                                                                                <div className="col-9 ps-0 text-end">
                                                                                    <a href="javascript:void(0)"
                                                                                        className={//!shoeModelFilters.colorFilterApplied &&
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).colorIds.length == 0 ?
                                                                                                "bulk-primary-btn bulk-sm me-2 disable"
                                                                                                : "bulk-primary-btn bulk-sm m2-2"
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     colorIds: [],
                                                                                            //     colorFilterApplied: false,
                                                                                            //     colorClearedManually: false,
                                                                                            //     clearFilter: true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    colorIds: [],
                                                                                                    colorFilterApplied: false,
                                                                                                    colorClearedManually: false,
                                                                                                    clearFilter: true
                                                                                                }
                                                                                            }))
                                                                                        }}
                                                                                    // style={{
                                                                                    //     pointerEvents: shoeModelFilters.colorFilterApplied ? 'none'
                                                                                    //         : 'auto'
                                                                                    // }}
                                                                                    >{t('Clear')}</a>
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(!(shoeModelPageState?.filter as iShoeModelFilters).colorFilterApplied &&
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).colorIds.length)
                                                                                            || (shoeModelPageState?.filter as iShoeModelFilters).colorClearedManually ?
                                                                                            "bulk-primary-btn bulk-sm me-2 "
                                                                                            : "bulk-primary-btn bulk-sm me-2 disable"
                                                                                        }
                                                                                        // style={{
                                                                                        //     pointerEvents: shoeModelFilters.colorFilterApplied ? 'none'
                                                                                        //         : 'auto'
                                                                                        // }}
                                                                                        onClick={() => ApplyColorFilter()}
                                                                                    >Apply Filter</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="dropdown dropend" >
                                                                    <a className="dropdown-item dropdown-toggle" href="#" id="multilevelDropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Release Date</a>
                                                                    <div className="dropdown-menu dropdown-submenu-left" aria-labelledby="multilevelDropdownMenu1"
                                                                        //style={{  display: resleaseDateInside !== undefined && resleaseDateInside ?  'block' : ''}}
                                                                        ref={releaseDateRef}
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                            e.stopPropagation();
                                                                            return false
                                                                        }}
                                                                    >
                                                                        <div className="filter-in-content release-date">
                                                                            <div className="search-input mb-2" >
                                                                                <span className="icon la la-calendar"></span>
                                                                                {/* <input type="text" className="form-control" placeholder="From" /> */}
                                                                                <DatePicker
                                                                                    strictParsing={true}
                                                                                    placeholderText={t('From')!}
                                                                                    dateFormat={'dd/MM/yyyy'}
                                                                                    //dateFormat={'MM/dd/yyyy'}
                                                                                    selected={(shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate ? new Date(moment((shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate).toDate()) : null}
                                                                                    onChange={(date) => {
                                                                                        console.log('date', date);
                                                                                        // setShoeModelFilters({
                                                                                        //     ...shoeModelFilters,
                                                                                        //     fromReleaseDate: date ? new Date(moment(date).toDate()) : null,
                                                                                        //     releaseDateFilterApplied: false,
                                                                                        //     releaseDateClearedManually: date && shoeModelFilters.toReleaseDate ? false : true
                                                                                        // })
                                                                                        dispatch(appPageSliceActions.savePageFilters({
                                                                                            ...shoeModelPageState!,
                                                                                            filter: {
                                                                                                ...shoeModelPageState!.filter,
                                                                                                fromReleaseDate: date ? new Date(moment(date).toDate()) : null,
                                                                                                releaseDateFilterApplied: false,
                                                                                                releaseDateClearedManually: date && (shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate ? false : true
                                                                                            }
                                                                                        }))
                                                                                        //r_DP.current?.setFocus()
                                                                                    }}

                                                                                // maxDate={new Date()}
                                                                                />
                                                                            </div>
                                                                            <div className="search-input">
                                                                                <span className="icon la la-calendar"></span>
                                                                                {/* <input type="text" className="form-control" placeholder="To" /> */}
                                                                                {/* <input type="text" className="form-control" placeholder="From" /> */}
                                                                                <DatePicker
                                                                                    strictParsing={true}
                                                                                    placeholderText={t('To')!}
                                                                                    dateFormat={'dd/MM/yyyy'}
                                                                                    //dateFormat={'MM/dd/yyyy'}
                                                                                    selected={(shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate ? new Date(moment((shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate).toDate()) : null}
                                                                                    onChange={(date) => {
                                                                                        console.log('date', date);
                                                                                        // setShoeModelFilters({
                                                                                        //     ...shoeModelFilters,
                                                                                        //     toReleaseDate: date ? new Date(moment(date).toDate()) : null,
                                                                                        //     releaseDateFilterApplied: false,
                                                                                        //     releaseDateClearedManually: date && shoeModelFilters.fromReleaseDate ? false : true
                                                                                        // })
                                                                                        dispatch(appPageSliceActions.savePageFilters({
                                                                                            ...shoeModelPageState!,
                                                                                            filter: {
                                                                                                ...shoeModelPageState!.filter,
                                                                                                toReleaseDate: date ? new Date(moment(date).toDate()) : null,
                                                                                                releaseDateFilterApplied: false,
                                                                                                releaseDateClearedManually: date && (shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate ? false : true
                                                                                            }
                                                                                        }))
                                                                                    }}
                                                                                //minDate={new Date('2020/01/01')}
                                                                                // maxDate={new Date()}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="filter-footer">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-12 ps-0 text-end">
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(shoeModelPageState?.filter as iShoeModelFilters).releaseDateFilterApplied &&
                                                                                            ((shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate &&
                                                                                                (shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate) ?
                                                                                            "bulk-primary-btn bulk-sm me-2 "
                                                                                            : "bulk-primary-btn bulk-sm me-2 disable"
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     fromReleaseDate: null,
                                                                                            //     toReleaseDate: null,
                                                                                            //     releaseDateFilterApplied: false,
                                                                                            //     releaseDateClearedManually: false,
                                                                                            //     clearFilter: true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                currentPage: 1,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    fromReleaseDate: null,
                                                                                                    toReleaseDate: null,
                                                                                                    releaseDateFilterApplied: false,
                                                                                                    releaseDateClearedManually: false,
                                                                                                    clearFilter: true
                                                                                                }
                                                                                            }))
                                                                                        }}
                                                                                    // style={{
                                                                                    //     pointerEvents: !shoeModelFilters.releaseDateFilterApplied &&
                                                                                    //         (shoeModelFilters.fromReleaseDate &&
                                                                                    //             shoeModelFilters.toReleaseDate) ? 'auto'
                                                                                    //         : 'none'
                                                                                    // }}
                                                                                    >{t('Clear')}</a>
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(!(shoeModelPageState?.filter as iShoeModelFilters).releaseDateFilterApplied &&
                                                                                            ((shoeModelPageState?.filter as iShoeModelFilters).fromReleaseDate &&
                                                                                                (shoeModelPageState?.filter as iShoeModelFilters).toReleaseDate))
                                                                                            || (shoeModelPageState?.filter as iShoeModelFilters).releaseDateClearedManually ?
                                                                                            "bulk-primary-btn bulk-sm me-2 "
                                                                                            : "bulk-primary-btn bulk-sm me-2 disable"
                                                                                        }
                                                                                        onClick={() => ApplyReleaseDateFilter()}

                                                                                    // style={{
                                                                                    //     pointerEvents: !shoeModelFilters.releaseDateFilterApplied &&
                                                                                    //         (shoeModelFilters.fromReleaseDate &&
                                                                                    //             shoeModelFilters.toReleaseDate) ? 'auto'
                                                                                    //         : 'none'
                                                                                    // }}
                                                                                    >Apply Filter</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="dropdown dropend">
                                                                    <a className="dropdown-item dropdown-toggle" href="#" id="multilevelDropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Exit Price</a>
                                                                    <div className="dropdown-menu dropdown-submenu-left" aria-labelledby="multilevelDropdownMenu1"
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                            e.stopPropagation();
                                                                            return false
                                                                        }}
                                                                    >
                                                                        <div className="filter-in-serach">
                                                                            <div className="search-input">
                                                                                <span className="icon la la-search"></span>
                                                                                <input type="text" className="form-control" placeholder="Research" />
                                                                            </div>
                                                                        </div>
                                                                        {exitPrices && exitPrices.length ?
                                                                            <div className="filter-in-content">
                                                                                {exitPrices.map((item) =>
                                                                                    <div className="form-check">
                                                                                        <input className="form-check-input" type="checkbox"
                                                                                            value={item.id} id="id2"
                                                                                            checked={(shoeModelPageState?.filter as iShoeModelFilters).exitPrice && (shoeModelPageState?.filter as iShoeModelFilters).exitPrice!.id == item.id ? true : false}
                                                                                            onChange={() =>
                                                                                                // setShoeModelFilters({
                                                                                                //     ...shoeModelFilters,
                                                                                                //     exitPrice: item,
                                                                                                //     exitPriceFilterApplied: false
                                                                                                // })
                                                                                                dispatch(appPageSliceActions.savePageFilters({
                                                                                                    ...shoeModelPageState!,
                                                                                                    filter: {
                                                                                                        ...shoeModelPageState!.filter,
                                                                                                        exitPrice: item,
                                                                                                        exitPriceFilterApplied: false
                                                                                                    }
                                                                                                }))
                                                                                            } />
                                                                                        <label className="form-check-label">{item.fromValue} - {item.toValue}</label>
                                                                                    </div>
                                                                                )}
                                                                                {/* <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">500 - 1000</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">1000 - 5000</label>
                                                                            </div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="checkbox" value="" id="id2" />
                                                                                <label className="form-check-label">5000 - 10000</label>
                                                                            </div> */}
                                                                            </div>
                                                                            : null}
                                                                        <div className="filter-footer">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-12 text-end">
                                                                                    <a href="javascript:void(0)"
                                                                                        className={
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).exitPrice ?
                                                                                                "bulk-primary-btn bulk-sm me-2 "
                                                                                                : "bulk-primary-btn bulk-sm me-2 disable"
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     exitPrice: null,
                                                                                            //     exitPriceFilterApplied: false,
                                                                                            //     clearFilter: true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                currentPage: 1,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    exitPrice: null,
                                                                                                    exitPriceFilterApplied: false,
                                                                                                    clearFilter: true
                                                                                                }
                                                                                            }))

                                                                                        }}
                                                                                    // style={{
                                                                                    //     pointerEvents: !shoeModelFilters.exitPriseFilterApplied &&
                                                                                    //         !shoeModelFilters.exitPriseFrom ||
                                                                                    //         !shoeModelFilters.exitPriseTo ? 'none'
                                                                                    //         : 'auto'
                                                                                    // }}
                                                                                    >{t('Clear')}</a>
                                                                                    <a href="javascript:void(0)"
                                                                                        className={!(shoeModelPageState?.filter as iShoeModelFilters).exitPriceFilterApplied &&
                                                                                            (shoeModelPageState?.filter as iShoeModelFilters).exitPrice ?
                                                                                            "bulk-primary-btn bulk-sm  "
                                                                                            : "bulk-primary-btn bulk-sm disable "
                                                                                        }
                                                                                        onClick={() => ApplyExitPriseFilter()}
                                                                                    >Apply Filter</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Research Profile</a></li>
                                                                <li className="dropdown dropend">
                                                                    <a className="dropdown-item dropdown-toggle" href="#" id="multilevelDropdownMenu1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Creation Date</a>
                                                                    <div className="dropdown-menu dropdown-submenu-left" aria-labelledby="multilevelDropdownMenu1"
                                                                        //ref={releaseDateRef}
                                                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                                            e.stopPropagation();
                                                                            return false
                                                                        }}
                                                                    >
                                                                        <div className="filter-in-content release-date">
                                                                            <div className="search-input mb-2">
                                                                                <span className="icon la la-calendar"></span>
                                                                                {/* <input type="text" className="form-control" placeholder="From" /> */}
                                                                                <DatePicker
                                                                                    strictParsing={true}
                                                                                    placeholderText={t('From')!}
                                                                                    dateFormat={'dd/MM/yyyy'}
                                                                                    //dateFormat={'MM/dd/yyyy'}
                                                                                    selected={(shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate ? new Date(moment((shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate).toDate()) : null}
                                                                                    onChange={(date) => {
                                                                                        console.log('date', date);
                                                                                        // setShoeModelFilters({
                                                                                        //     ...shoeModelFilters,
                                                                                        //     fromCreationDate: date ? new Date(moment(date).toDate()) : null,
                                                                                        //     creationDateFilterApplied: false,
                                                                                        //     creationDateClearedManually: date && shoeModelFilters.toCreationDate ? false : true
                                                                                        // })
                                                                                        dispatch(appPageSliceActions.savePageFilters({
                                                                                            ...shoeModelPageState!,
                                                                                            filter: {
                                                                                                ...shoeModelPageState!.filter,
                                                                                                fromCreationDate: date ? new Date(moment(date).toDate()) : null,
                                                                                                creationDateFilterApplied: false,
                                                                                                creationDateClearedManually: date && (shoeModelPageState?.filter as iShoeModelFilters).toCreationDate ? false : true
                                                                                            }
                                                                                        }))
                                                                                    }}

                                                                                // maxDate={new Date()}
                                                                                />
                                                                            </div>
                                                                            <div className="search-input">
                                                                                <span className="icon la la-calendar"></span>
                                                                                {/* <input type="text" className="form-control" placeholder="To" /> */}
                                                                                <DatePicker
                                                                                    strictParsing={true}
                                                                                    placeholderText={t('To')!}
                                                                                    dateFormat={'dd/MM/yyyy'}
                                                                                    //dateFormat={'MM/dd/yyyy'}
                                                                                    selected={(shoeModelPageState?.filter as iShoeModelFilters).toCreationDate ? new Date(moment((shoeModelPageState?.filter as iShoeModelFilters).toCreationDate).toDate()) : null}
                                                                                    onChange={(date) => {
                                                                                        console.log('date', date);
                                                                                        // setShoeModelFilters({
                                                                                        //     ...shoeModelFilters,
                                                                                        //     toCreationDate: date ? new Date(moment(date).toDate()) : null,
                                                                                        //     creationDateFilterApplied: false,
                                                                                        //     creationDateClearedManually: date && shoeModelFilters.fromCreationDate ? false : true
                                                                                        // })
                                                                                        dispatch(appPageSliceActions.savePageFilters({
                                                                                            ...shoeModelPageState!,
                                                                                            filter: {
                                                                                                ...shoeModelPageState!.filter,
                                                                                                toCreationDate: date ? new Date(moment(date).toDate()) : null,
                                                                                                creationDateFilterApplied: false,
                                                                                                creationDateClearedManually: date && (shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate ? false : true
                                                                                            }
                                                                                        }))
                                                                                    }}

                                                                                // maxDate={new Date()}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="filter-footer">
                                                                            <div className="row align-items-center">
                                                                                <div className="col-12 ps-0 text-end">
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(shoeModelPageState?.filter as iShoeModelFilters).creationDateFilterApplied &&
                                                                                            ((shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate &&
                                                                                                (shoeModelPageState?.filter as iShoeModelFilters).toCreationDate) ?
                                                                                            "bulk-primary-btn bulk-sm me-2 "
                                                                                            : "bulk-primary-btn bulk-sm me-2 disable"
                                                                                        }
                                                                                        onClick={() => {
                                                                                            // setShoeModelFilters({
                                                                                            //     ...shoeModelFilters,
                                                                                            //     fromCreationDate: null,
                                                                                            //     toCreationDate: null,
                                                                                            //     creationDateFilterApplied: false,
                                                                                            //     creationDateClearedManually: false,
                                                                                            //     clearFilter: true
                                                                                            // })
                                                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                                                ...shoeModelPageState!,
                                                                                                currentPage: 1,
                                                                                                filter: {
                                                                                                    ...shoeModelPageState!.filter,
                                                                                                    fromCreationDate: null,
                                                                                                    toCreationDate: null,
                                                                                                    creationDateFilterApplied: false,
                                                                                                    creationDateClearedManually: false,
                                                                                                    clearFilter: true
                                                                                                }
                                                                                            }))
                                                                                        }}
                                                                                    // style={{
                                                                                    //     pointerEvents: !shoeModelFilters.creationDateFilterApplied &&
                                                                                    //         (shoeModelFilters.fromCreationDate &&
                                                                                    //             shoeModelFilters.toCreationDate) ? 'auto'
                                                                                    //         : 'none'
                                                                                    // }}
                                                                                    >{t('Clear')}</a>
                                                                                    <a href="javascript:void(0)"
                                                                                        className={(!(shoeModelPageState?.filter as iShoeModelFilters).creationDateFilterApplied &&
                                                                                            ((shoeModelPageState?.filter as iShoeModelFilters).fromCreationDate &&
                                                                                                (shoeModelPageState?.filter as iShoeModelFilters).toCreationDate))
                                                                                            || (shoeModelPageState?.filter as iShoeModelFilters).creationDateClearedManually
                                                                                            ? "bulk-primary-btn bulk-sm"
                                                                                            : "bulk-primary-btn bulk-sm disable"}
                                                                                        // style={{
                                                                                        //     pointerEvents: !shoeModelFilters.creationDateFilterApplied &&
                                                                                        //         (shoeModelFilters.fromCreationDate &&
                                                                                        //             shoeModelFilters.toCreationDate) ? 'auto'
                                                                                        //         : 'none'
                                                                                        // }}
                                                                                        onClick={() => ApplyCreationDateFilter()}
                                                                                    >Apply Filter</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="filter-footer text-center">
                                                                    <a href="javascript:void(0)" className="clear-link"
                                                                        onClick={() => {
                                                                            console.log('Reset called')
                                                                            dispatch(appPageSliceActions.resetPageFilter({ filterType: eAppFilters.shoeModel }))
                                                                            //setShoeModelFilters(p_iShoeModelFilters) 
                                                                        }}
                                                                    ><span className="la la-rotate-right"></span> Reset</a>
                                                                </li>
                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-5">
                                                <div className="input-group">
                                                    <DebounceInput
                                                        className="form-control"
                                                        minLength={1}
                                                        debounceTimeout={500}
                                                        onChange={event =>
                                                            //setSearchShoeModel(event.target.value)
                                                            dispatch(appPageSliceActions.savePageFilters({
                                                                ...shoeModelPageState!,
                                                                currentPage: 1,
                                                                filter: {
                                                                    ...shoeModelPageState!.filter,
                                                                    searchShoeModel: event.target.value
                                                                }
                                                            }))
                                                        }
                                                        placeholder={"Search..."}
                                                        value={(shoeModelPageState?.filter as iShoeModelFilters).searchShoeModel} />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                                    </div>
                                                </div>
                                                {/* <div className="search-input">
                                                    <span className="icon la la-search"></span>
                                                    <input type="text" className="form-control" placeholder="Research" />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* == End Filter ==*/}
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t('Id')}</th>
                                        <th>{t('Brand_Category')}</th>
                                        {/* <th>{t('Category_Name')}</th> */}
                                        <th>{t('Model_Name')}</th>
                                        <th>{t('Model_Code')}</th>

                                        <th>{t('Colors')}</th>
                                        <th>{t('Release_date')}</th>
                                        <th>{t('Exit_price_code')}</th>
                                        <th>{t('Is_hidden')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {renderShoeModels()}

                                </tbody>
                            </table>

                        </div>
                        {totalShoeModelResults && totalShoeModelPages ?
                            <CustomPagination
                                totalResults={totalShoeModelResults}
                                totalPages={totalShoeModelPages}
                                p_iPageState={shoeModelPageState!}
                                onPageChanged={onPageChanged}
                            />
                            : null
                        }
                        <ModalDialog toggle={() => onAddShoeModelClick()}
                            cancel={() => cancelAdd()}
                            executeButtonTitle={() => currentState == 0 ? t('MD_Add') : t('MD_Update')}
                            isOpen={isOpen}
                            execute={onExecuteAddEditShoeModel}
                            enableExecute={onEnableExecuteAddShoeModel()}
                            modalTitle={currentState == 0 ? t('Add_Shoe_Model') : t('Edit_Shoe_Model')}
                            useButtons={t("MD_Cancel") + '/' + (currentState == 0 ? t("MD_Add") : t('MD_Update'))}
                        >

                            {
                                <div className="row">
                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group">
                                            <label>{t('Model_Name')}{'*'}</label>
                                            <input type="text" className="form-control"
                                                value={shoeModelToSave.modelName}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, modelName: evt.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group">
                                            <label>{t('Model_Code')}{'*'}</label>
                                            <input type="text" className="form-control"
                                                value={shoeModelToSave.modelCode}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, modelCode: evt.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <div className="form-group">
                                            <label>{t('Brand_Brand_Name')}{'*'}</label>
                                            <select onChange={(evt) => { onBrandsOptionChange(+evt.target.value) }} className="form-control in_language_id" name="in_language_id">
                                                <option value="">{t('Select_one_brand')}</option>
                                                {brands.length && brands.map((brand) =>
                                                    <option value={brand.id} selected={brandSelected?.id == brand.id ? true : false}>
                                                        {brand.name}
                                                    </option>
                                                )

                                                }

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <div className="form-group modal-category-level">
                                            <label>{t('Category_Level')}{'*'}</label>
                                            <Category p_Category={category!}
                                                setCurrentCategory={handelSetCurrentCategory}
                                                setCurrentCategoryByName={handelSetCurrentCategoryByName}
                                                getCategoryChildCount={handelGetCategoryChildCount}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 mb-2">
                                        <div className="form-group">
                                            <label>{t('SizeType')}{'*'}</label>
                                            <select onChange={(evt) => { onSizeTypeOptionChange(+evt.target.value) }} className="form-control in_language_id" name="in_language_id"
                                                disabled={currentState == 1}
                                            >
                                                <option value="">{t('Select_one_size_type')}</option>
                                                {sizeTypes.length && sizeTypes.map((item) =>
                                                    <option value={item.id} selected={isSizeSelected(item)}>
                                                        {item.name}
                                                    </option>
                                                )}

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group bulk-datepicker"

                                        >
                                            <label>{t('Release_date')}{'*'}</label>
                                            {/* <label>{shoeModelToSave.releaseDate.toString()}</label> */}

                                            <DatePicker
                                                strictParsing={true}
                                                dateFormat={'dd/MM/yyyy'}
                                                selected={shoeModelToSave.releaseDate ? new Date(moment(shoeModelToSave.releaseDate).toDate()) : new Date(moment().toDate())}
                                                onChange={(date) => { console.log('date', date); setShoeModelToSave({ ...shoeModelToSave, releaseDate: date }) }}
                                                // shoeModelToSave.releaseDate ? new Date(format(shoeModelToSave.releaseDate, 'MM-DD-YYYY')) : new Date()
                                                // value={moment(shoeModelToSave.releaseDate).format('YYYY-MM-DD')}
                                                maxDate={new Date(moment().toDate())}

                                            />
                                            {/* <input type="text" className="form-control"
                                                value={shoeModelToSave.releaseDate.toString()}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, releaseDate: new Date(evt.target.value) })}
                                            /> */}
                                        </div>
                                    </div>

                                    <div className="col-6 mb-2">
                                        <div className="form-group">
                                            <label>{t('Exit_Price')}{'*'}</label>
                                            <input type="text" className="form-control"
                                                value={shoeModelToSave.exitPrice}
                                                onChange={(evt) => {
                                                    const val = evt.target.value;
                                                    var floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
                                                    if (val.match(floatValues) && !isNaN(+val)) {
                                                        setShoeModelToSave({ ...shoeModelToSave, exitPrice: val.valueOf() })
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 mb-2">
                                        <div className="form-group">
                                            <label>{t('Color')}{'*'}</label>
                                            {/* <select className="form-control">
                                                        <option>Red</option>
                                                        <option>Green</option>
                                                    </select> */}
                                            <Multiselect
                                                onSearch={(value) => {
                                                    if (value.length >= 1) setSearchShoeColors(value);
                                                }}
                                                options={allShoeColors} // Options to display in the dropdown
                                                selectedValues={shoeColors} // Preselected value to persist in dropdown
                                                onSelect={onSelectColor} // Function will trigger on select event
                                                onRemove={onRemoveColor} // Function will trigger on remove event
                                                displayValue="color" // Property name to display in the dropdown options
                                            />
                                            {/* <select className="form-control" multiple >
                                                        <option value='1'>Red</option>
                                                        <option value='2'>Green</option>
                                                        <option value='3'>Green1</option>
                                                        <option value='4'>Green2</option>
                                                        <option value='5'>Green3</option>
                                                        <option value='6'>Green4</option>
                                                        <option value='7'>Green5</option>
                                                    </select> */}
                                        </div>
                                    </div>

                                    <div className="col-12 mb-2">
                                        <div className="form-group">
                                            <label>{t('Description')}{'*'}</label>
                                            <textarea className="form-control"
                                                value={shoeModelToSave.description}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, description: evt.target.value })}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group mb-0">
                                            <input type="checkbox" id="" name="model_name"
                                                checked={shoeModelToSave.isHidden}
                                                value={shoeModelToSave.isHidden ? 1 : 0}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, isHidden: +evt.target.value == 1 ? false : true })} />
                                            <label className="ms-2">Is hidden</label>
                                        </div>
                                    </div>
                                </div>


                            }

                        </ModalDialog>
                        <ModalDialog toggle={() => onAddShoeModelPhotoClick()}
                            cancel={() => cancelAddPhoto()}
                            executeButtonTitle={() => t('Save')}
                            isOpen={isOpenPhoto}
                            execute={onExecuteAddShoeModelPhoto}
                            enableExecute={enableExecuteShoeModelPhotograph()}
                            modalTitle={t('Add_Edit_Photographs')}
                            useButtons={t("MD_Cancel") + '/' + t("Save")}
                        >

                            {
                                <div className="add-shoe-model-images">
                                    <div className="added-images">
                                        {shoeModelPhotographs && shoeModelPhotographs.map((item, index) =>
                                            <div className="img">
                                                <a onClick={() => deleteImage(item)} href="javascript:void(0)" className="close"><span className="la la-close"></span></a>
                                                <div className="img-in"
                                                    draggable
                                                    onDragStart={(e) => dragStart(e, index)}
                                                    onDragEnter={(e) => dragEnter(e, index)}
                                                    onDragEnd={drop}
                                                    key={index}
                                                >
                                                    {/* <img src="images/adidas.png" /> */}
                                                    <img src={item.url} />
                                                </div>
                                                <div className="form-check">
                                                    <input checked={item.isDefault} onChange={() => setDefaultImage(item)} className="form-check-input" type="checkbox" value="" id="imgoption1" />
                                                    <label className="form-check-label">
                                                        {t('Default')}
                                                    </label>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                    <div className="add-image-btn">
                                        <div className="add-img-center">
                                            <input type="file"
                                                accept=".png, .jpg, .jpeg"
                                                onChange={(evt) => handleFileChange(evt)}
                                                className="file_upload custom-file-input"
                                                name="file"></input>
                                            <a href="javascript:void(0)" className="add-browse-img-btn">{t('Add_Image')}</a>
                                        </div>

                                    </div>
                                </div>

                                // <div className="add-shoe-model-images">
                                //     <div className="added-images">
                                //         <div className="img">
                                //             <a href="javascript:void(0)" className="close"><span className="la la-close"></span></a>
                                //             <div className="img-in">
                                //                 <img src="images/adidas.png" />
                                //             </div>
                                //             <div className="form-check">
                                //                 <input className="form-check-input" type="checkbox" value="" id="imgoption1" />
                                //                 <label className="form-check-label">
                                //                     Default
                                //                 </label>
                                //             </div>
                                //         </div>
                                //         <div className="img">
                                //             <div className="img-in">
                                //                 <img src="images/nike.png" />
                                //             </div>
                                //             <div className="form-check">
                                //                 <input className="form-check-input" type="checkbox" value="" id="imgoption2" />
                                //                 <label className="form-check-label">
                                //                     Option1
                                //                 </label>
                                //             </div>
                                //         </div>
                                //     </div>
                                //     <div className="add-image-btn">
                                //         <button type="button" className="btn btn-primary">Add Image</button>
                                //     </div>
                                // </div>

                            }

                        </ModalDialog>
                        {/* -- Start Modal -- */}
                        <div className="modal fade" id="addShoeModel" role="dialog" aria-labelledby="addShoeModelLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add Shoe Model</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body add-category-modal">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Model Name:</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Model Code:</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Category level1:</label>
                                                    <select className="form-control">
                                                        <option>Category1</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Category level2:</label>
                                                    <select className="form-control">
                                                        <option>Category1</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Release Date:</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Exit Price:</label>
                                                            <input type="text" className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <label>Color:</label>
                                                            <select className="form-control">
                                                                <option>Red</option>
                                                                <option>Green</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label>Description:</label>
                                                    <textarea className="form-control"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-group mb-0">
                                                    <input type="checkbox" id="" name="model_name" value="0" />
                                                    <label className="ms-2">Is hidden</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-primary">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -- End Modal -- */}
                        {/* -- Start- Add Images Modal -- */}

                        <div className="modal fade" id="addImages" role="dialog" aria-labelledby="addImagesLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add Images</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body add-category-modal">
                                        <div className="add-shoe-model-images">
                                            <div className="added-images">
                                                <div className="img">
                                                    <a href="javascript:void(0)" className="close"><span className="la la-close"></span></a>
                                                    <div className="img-in">
                                                        <img src="images/adidas.png" />
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="imgoption1" />
                                                        <label className="form-check-label">
                                                            Default
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="img">
                                                    <div className="img-in">
                                                        <img src="images/nike.png" />
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="imgoption2" />
                                                        <label className="form-check-label">
                                                            Option1
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="add-image-btn">
                                                <button type="button" className="btn btn-primary">Add Image</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-primary">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -- Start- Add Images Modal -- */}
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div >

    )
};

export default ShoeModelPage;