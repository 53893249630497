import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { useEffect, useState } from "react";
import { iCategory } from '../../../shared/models/adminModels'
import FileUpload from '../../../shared/components/admin/fileUpload'


const AddBrand = () => {
    const [brand, setBrand] = useState<iCategory>({
        id: 1,
        image: '',
        bgImage: '',
        name: '',
        childCategories: []

    })
    const navigate = useNavigate();


    useEffect(() => {
        // setBrand(new cBrand())
        function enableSubmitButton() {
            const input = document.getElementById('saveBtn') as HTMLInputElement | null;
            if (brand.name && brand.image) {
                input?.removeAttribute('disabled')
            } else {
                input?.setAttribute('disabled', '')
            }
        }
        enableSubmitButton()
    }, [brand])


    const brandImageUploadedSuccessfully = (file_name: string) => {
        console.log('brandImageUploadedSuccessfully called file_name=', file_name)
        setBrand({ ...brand, image: file_name })
    }
    const brandImageUploadedFailed = (error_string: string) => {
        console.log('brandImageUploadedFailed called Error:', error_string)

    }
    const onSubmit = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        evt.preventDefault()
        console.log('onSubmit called')
        navigate("/admin/brands/list")
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Manage brands</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item"><a href="/admin/brands/list">Manage brand</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add new brand</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <% if(success && success.length > 0) { %> */}
                                <div className="alert alert-success alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- success %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}

                                {/* <% if(error && error.length > 0) { %> */}
                                <div className="alert alert-danger alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- error %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}
                                <form className="sign-up-form" action="#" method="POST" id="signup-edit-form">
                                    <div className="card">
                                        <h5 className="card-header">Add brand</h5>
                                        <div className="card-body">
                                            <p> Please fill all the required details of new brand. </p>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Name</label>
                                                    <input type="text" className="form-control" id="" name="name"
                                                        onChange={(evt) => setBrand({ ...brand, name: evt.target.value })}
                                                        value={brand.name}></input>
                                                </div>

                                            </div>
                                            <FileUpload
                                                apiUrlRelativePath="/uploadBrandimage"
                                                existingFileName={brand.image}
                                                uploadedRelativePath={'/brands'}
                                                onSuccess={brandImageUploadedSuccessfully}
                                                onFailure={brandImageUploadedFailed} />

                                        </div>
                                        <div className="card-footer bg-light text-right">
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <input type="submit" onClick={(evt) => onSubmit(evt)} id="saveBtn" className="btn btn-primary btn-rounded"></input>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div >
                    </section >
                </div >
            </div >
        </div >
    )
}

export default AddBrand