import { combineReducers, createStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import settingsSlice from './settingsSlice';
import rewardSlice from './rewardSlice';
import { Persistor, persistReducer, persistStore } from 'redux-persist';
import { RootState } from './stateTypes';
import shoeModelSlice from './shoeModelSlice';
import appPageSlice from './appPageSlice';

const persistConfig = {
  key: "root", // key for the root of the storage
  storage,
  blacklist: ["shoeModelSlice"],
};

const rootReducer: any = combineReducers({
  settingsSlice: settingsSlice,
  rewardSlice: rewardSlice,
  shoeModelSlice: shoeModelSlice,
  appPageSlice : appPageSlice
});

const persistedReducer = persistReducer<RootState, any>(
  persistConfig,
  rootReducer
);

const store = createStore(persistedReducer);
const persistor: Persistor = persistStore(store);

export { store, persistor };
