import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import dashlogo from "../../assets/dash-logo.png";
import landingpageimg from "../../assets/admin/css/images/landing-page-img.png";

import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/admin/css/icons/font-icons.css"
import "../../assets/admin/css/common/landingpage.css"



import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { appPageSliceActions, iPageState } from "../../store/appPageSlice";

const Landing = () => {
    const { t } = useTranslation()
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const openInNewTab = (url: string | URL | undefined) => {
        window.open(url, "_blank", "noreferrer");
    };

    useEffect(()=>{
        dispatch(appPageSliceActions.clearPagesState({}))
    },[])
    
    return (
        <div className="landingpage-wrapper">
            <div className="landingpage-header">
                <div className="container">
                    <a href="#" className="brand">
                        <img style={{ height: '30px' }} src={dashlogo} alt="Bulk" />
                    </a>
                </div>
            </div>
            <div className="landingpage-content">
                <div className="container">
                    <div className="landingpage-content-inner banner-shapes position-relative">
                        <div className="row align-items-center flex-lg-row-reverse">
                            <div className="col-sm-12 col-md-12 col-lg-5 text-center">
                                <img src={landingpageimg} alt="Bulk" />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-7">
                                <div className="infomation">
                                    <h1>La nuova app per comprare e vendere lotti di scarpe</h1>
                                    <h5>Scarica ora l’app Bulk. Entra a far parte della community per vendere e comprare le scarpe più hot del momento!</h5>
                                    <div className="mt-3" style={{ display: "none" }}>
                                        <button type="button" className="contact-btn btn-shapes"><span className="icon icon-contact-us"></span> Contattaci!</button>
                                    </div>
                                    <div className="app-links mt-4">
                                        <button type="button" 
                                            role="link"
                                            onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.thebulk")}                                            
                                            className="store-link"><span className="icon icon-google-play"></span><span className="small">Get it on</span><span className="big">Google Play</span>
                                        </button>
                                        <button type="button" 
                                            role="link"
                                            onClick={() => openInNewTab("https://apps.apple.com/be/app/bulk/id6474439847")}                                            
                                            className="store-link"><span className="icon icon-apple-store"></span><span className="small">Download on the</span><span className="big">Apple Store</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="landingpage-footer">
                <div className="container">
                    <div><h6>Support</h6></div>
                    <div className="mb-3">
                        <ul>
                            <li><a href="javascript:void(0)"
                                    onClick={()=>navigate('/support?section=faq')}
                            >{t('Faq_Only')}</a></li>
                            <li>|</li>
                            <li><a href="javascript:void(0)"
                                    onClick={()=>navigate('/support?section=tc')}
                            >{t('Terms_and_Conditions')}</a></li>
                            <li>|</li>
                            <li><a href="javascript:void(0)"
                                    onClick={()=>navigate('/support?section=pp')}
                            >{t('Privacy_Policy')}</a></li>
                        </ul>
                    </div>
                    <div><h6>Seguici</h6></div>
                    <div className="mb-3">
                        <ul className="social">
                            <li><a href="javascript:void(0)"><span className="icon icon-facebook"></span> Facebook</a></li>
                            <li><a href="javascript:void(0)"><span className="icon icon-instagram"></span> Instagram</a></li>
                            <li><a href="javascript:void(0)"><span className="icon icon-twitter"></span> Twitter</a></li>
                            <li><a href="javascript:void(0)"><span className="icon icon-linkedin"></span> LinkedIn</a></li>
                            <li><a href="javascript:void(0)"><span className="icon icon-tiktok"></span> TicTok</a></li>
                        </ul>
                    </div>
                    <div className="copyright"><span className="me-4">&copy; TheBulk 2023</span> <span className="me-4">Tutti i diritti riservati</span> <a href="javascript:void(0)">Privacy</a></div>
                </div>
            </div>
        </div>
    );
};

export default Landing;