import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { eAppFilters, ePaymentMethods, iBatchModel, iImageModel, iModerationLotsFilters, iModerationRequestsFilters, iWarehouseb2b } from '../../../shared/models/adminModels'
import { HttpWarehouseb2bService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import 'react-datepicker/dist/react-datepicker.css'
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'
import { DebounceInput } from "react-debounce-input";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import useModal from "../../../hooks/general";
import moment from 'moment';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import CustomPagination from "../../../shared/components/CustomPagination";
import { RootState } from "../../../store/stateTypes";
// import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { CommonUtil } from "../../../shared/utils/commonUtils";
// import { useDispatch } from "react-redux";

const Warehouseb2bPage = () => {
    const navigate = useNavigate();
    let p_Utils = new CommonUtil()
    // -------------- Pagination --------------------
    const [totalWarehouseb2bResultsLots, setTotalWarehouseb2bResultsLots] = useState(-1);
    const [totalWarehouseb2bPagesLots, setTotalWarehouseb2bPagesLots] = useState(-1)
    // const [currentWarehouseb2bPageLots, setCurrentWarehouseb2bPageLots] = useState(1)
    // -------------- Pagination End --------------------
    // -------------- Pagination --------------------
    const [totalWarehouseb2bResultsRequests, setTotalWarehouseb2bResultsRequests] = useState(-1);
    const [totalWarehouseb2bPagesRequests, setTotalWarehouseb2bPagesRequests] = useState(-1)
    // -------------- Pagination End --------------------
    const { t } = useTranslation()
    const { isOpen, toggle } = useModal()

    const [warehouseb2bLots, setWarehouseb2bLots] = useState<iWarehouseb2b[]>([])
    const [warehouseb2bRequests, setWarehouseb2bRequests] = useState<iWarehouseb2b[]>([])
    const [warehouseb2bDetail, setWarehouseb2bDetail] = useState<iWarehouseb2b>()


    const lotsPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.b2b_moderation_lots));
    const requestsPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.b2b_moderation_requests));
    console.log('lotsPageState', lotsPageState)
    console.log('requestsPageState', requestsPageState)
    const dispatch = useDispatch();

    const httpWarehouseb2bService: HttpWarehouseb2bService = new HttpWarehouseb2bService();

    const refreshWarehouseb2bLots = (currentPage = 1) => {
        setTotalWarehouseb2bResultsLots(0)
        setTotalWarehouseb2bPagesLots(0)
        httpWarehouseb2bService.getLots({
            currentPage: currentPage,
            pageLimit: lotsPageState!.pageLimit
        },
            (lotsPageState?.filter as iModerationLotsFilters).search)
            .then((value: any) => {
                console.log('lots totalRows', value.totalRows)
                console.log('lots pages', Math.ceil(value.totalRows / lotsPageState!.pageLimit))
                console.log('lots data', value.items)
                setWarehouseb2bLots(value.items)
                setTotalWarehouseb2bResultsLots(value.totalRows)
                setTotalWarehouseb2bPagesLots(Math.ceil(value.totalRows / lotsPageState!.pageLimit))
            })
            .catch((error) => console.log(error))
    }
    const refreshWarehouseb2bRequests = (currentPage = 1) => {
        setTotalWarehouseb2bResultsRequests(0)
        setTotalWarehouseb2bPagesRequests(0)
        httpWarehouseb2bService.getRequests({
            currentPage: currentPage,
            pageLimit: requestsPageState!.pageLimit
        },
            (requestsPageState?.filter as iModerationRequestsFilters).search)
            .then((value: any) => {
                console.log('requests totalRows', value.totalRows)
                console.log('requests pages', Math.ceil(value.totalRows / requestsPageState!.pageLimit))
                console.log('requests data', value.items)
                setWarehouseb2bRequests(value.items)
                setTotalWarehouseb2bResultsRequests(value.totalRows)
                setTotalWarehouseb2bPagesRequests(Math.ceil(value.totalRows / requestsPageState!.pageLimit))
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        console.log('--searchLots')
        refreshWarehouseb2bLots()
    }, [(lotsPageState?.filter as iModerationLotsFilters).search])
    useEffect(() => {
        console.log('--searchRequests')
        refreshWarehouseb2bRequests()
    }, [(requestsPageState?.filter as iModerationRequestsFilters).search])

    useEffect(() => {
        refreshWarehouseb2bLots(lotsPageState?.currentPage)
    }, [lotsPageState?.currentPage, lotsPageState?.pageLimit])
    useEffect(() => {
        refreshWarehouseb2bRequests(requestsPageState?.currentPage)
    }, [requestsPageState?.currentPage, requestsPageState?.pageLimit])

    const onPageChangedLots = (data: any) => {
        console.log('onPageChangedLots', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...lotsPageState!,
            currentPage: data.currentPage
        }))
    }

    const onPageChangedRequests = (data: any) => {
        console.log('onPageChangedRequests', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...lotsPageState!,
            currentPage: data.currentPage
        }))
    }

    useEffect(() => {
        console.log('--Toggle Refresh', lotsPageState?.currentPage)
        refreshWarehouseb2bLots(lotsPageState!.currentPage);
    }, [lotsPageState?.toggleRefresh])
    useEffect(() => {
        console.log('--Toggle Refresh', requestsPageState?.currentPage)
        refreshWarehouseb2bRequests(requestsPageState!.currentPage);
    }, [requestsPageState?.toggleRefresh])

    const [batchModelDetails, setBatchModelDetails] = useState<iBatchModel>()

    const viewWarehouseb2b = async (warehouseb2b: iWarehouseb2b) => {
        console.log('viewWarehouseb2b', warehouseb2b)
        if ((lotsPageState?.filter as iModerationLotsFilters).isCurrent) {
            dispatch(appPageSliceActions.savePageFilters({
                ...lotsPageState!,
                isCurrent: true
            }))
            dispatch(appPageSliceActions.savePageFilters({
                ...requestsPageState!,
                isCurrent: false
            }))
        } else {
            dispatch(appPageSliceActions.savePageFilters({
                ...lotsPageState!,
                isCurrent: false
            }))
            dispatch(appPageSliceActions.savePageFilters({
                ...requestsPageState!,
                isCurrent: true
            }))
        }
        setWarehouseb2bDetail(warehouseb2b)
        let current = (lotsPageState?.filter as iModerationLotsFilters).isCurrent ? 'Lot' : 'Request'
        navigate('/admin/warehouseb2b/view/' + current, { state: warehouseb2b })
        // setWarehouseb2bDetail(warehouseb2b)
        // await httpWarehouseb2bService.get<iBatchModel>({ id: warehouseb2b.id })
        //     .then((value) => {
        //         setBatchModelDetails(value.data)
        //         toggle()
        //         console.log('== value', value.data)
        //     })
        //     .catch((error) => console.log(error))
        //     ;
    }
    const onDeleteWarehouseb2b = async (warehouseb2b: iWarehouseb2b) => {
        setWarehouseb2bDetail(warehouseb2b)
        onDelete()
    }

    const deleeteWarehouseb2b = async () => {
        await httpWarehouseb2bService.delete<iWarehouseb2b>(warehouseb2bDetail!.id)
            .then((value) => {
                toast.success(t`Delete_success_msg`)
                
                toggle()
                if ((lotsPageState?.filter as iModerationLotsFilters).isCurrent) {
                    dispatch(appPageSliceActions.savePageFilters({
                        ...lotsPageState!,
                        currentPage: 1,
                        toggleRefresh: !lotsPageState?.toggleRefresh
                    }))
                } else {
                    dispatch(appPageSliceActions.savePageFilters({
                        ...lotsPageState!,
                        currentPage: 1,
                        toggleRefresh: !lotsPageState?.toggleRefresh
                    }))
                }
            })
            .catch((error) => {
                console.log(error)
                p_Utils.showError(error, toast)
            })
    }

    const renderWarehouseb2bRequests = (): ReactNode[] => {
        console.log('renderWarehouseb2bRequests length', warehouseb2bRequests?.length)
        const returnNode: ReactNode[] = warehouseb2bRequests?.map((warehouseb2b: iWarehouseb2b) => (

            <tr key={warehouseb2b.id}>
                <td>{warehouseb2b.id}</td>
                {/* <td style={{ whiteSpace: "pre" }}>{warehouseb2b.shoeModel?.releaseDate ? moment(warehouseb2b.shoeModel?.releaseDate).format('DD/MM/YYYY') : "-"}</td> */}
                <td>
                    <img width={'100px'} height={'100px'} src={warehouseb2b.shoeModel?.defaultImage} alt="shoeimg" />
                </td>
                <td>{warehouseb2b.shoeModel?.title!.replaceAll("/", ">")} ({warehouseb2b.shoeModel?.modelName} , {warehouseb2b.shoeModel?.modelCode})</td>
                <td>{warehouseb2b.shoeModel?.color ? warehouseb2b.shoeModel?.color : "-"}</td>
                <td>{warehouseb2b.sizes}</td>
                <td>€{warehouseb2b.averagePricePerPair}</td>
                {/* <td>{warehouseb2b.size}</td> */}

                <td>{warehouseb2b.totalQuantity}</td>
                <td>€{warehouseb2b.totalPrice}</td>
                <td width={130}>{<>
                    <a onClick={() => viewWarehouseb2b(warehouseb2b)}
                        data-toggle="tooltip"
                        data-placement="top" title="View" data-original-title="Edit"
                        className="btn btn-bulk small me-1" data-rel="tooltip" >
                        <i className="la la-eye"></i>
                    </a>
                    <a onClick={() => onDeleteWarehouseb2b(warehouseb2b)}
                        data-toggle="tooltip"
                        data-placement="top" title="Delete" data-original-title="Edit"
                        className="btn btn-bulk small" data-rel="tooltip" >
                        <i className="la la-remove"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }
    const renderWarehouseb2bLots = (): ReactNode[] => {
        console.log('renderWarehouseb2bLots length', warehouseb2bLots?.length)
        const returnNode: ReactNode[] = warehouseb2bLots?.map((warehouseb2b: iWarehouseb2b) => (

            <tr key={warehouseb2b.id}>
                <td>{warehouseb2b.id}</td>
                {/* <td style={{ whiteSpace: "pre" }}>{warehouseb2b.shoeModel?.releaseDate ? moment(warehouseb2b.shoeModel?.releaseDate).format('DD/MM/YYYY') : "-"}</td> */}
                <td style={{ verticalAlign: 'middle', alignContent: 'center' }}>
                    <img width={'100px'} height={'100px'} src={warehouseb2b.shoeModel?.defaultImage} alt="shoeimg" />
                </td>
                <td>{warehouseb2b.shoeModel?.title!.replaceAll("/", ">")} ({warehouseb2b.shoeModel?.modelName} , {warehouseb2b.shoeModel?.modelCode})</td>
                <td>{warehouseb2b.shoeModel?.color ? warehouseb2b.shoeModel?.color : "-"}</td>
                <td>{warehouseb2b.sizes}</td>
                <td>€{warehouseb2b.averagePricePerPair}</td>
                {/* <td>{warehouseb2b.size}</td> */}

                <td>{warehouseb2b.totalQuantity}</td>
                <td>€{warehouseb2b.totalPrice}</td>
                <td width={130}>{<>
                    <a onClick={() => viewWarehouseb2b(warehouseb2b)}
                        data-toggle="tooltip"
                        data-placement="top" title="View" data-original-title="Edit"
                        className="btn btn-bulk small me-1" data-rel="tooltip" >
                        <i className="la la-eye"></i>
                    </a>
                    <a onClick={() => onDeleteWarehouseb2b(warehouseb2b)}
                        data-toggle="tooltip"
                        data-placement="top" title="Delete" data-original-title="Edit"
                        className="btn btn-bulk small" data-rel="tooltip" >
                        <i className="la la-remove"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const onDelete = () => {
        toggle();
    }

    const onClose = () => {
        setWarehouseb2bDetail(undefined);
        toggle();
    }

    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">

                <div className="content">

                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>{t('Warehouse_B2B')}</h3>
                                </div>

                            </div>
                        </div>
                        <div className="sizes-tabs">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">

                                <li className="nav-item" role="presentation">

                                    <button
                                        className={
                                            (lotsPageState?.filter as iModerationLotsFilters).isCurrent
                                                ? "nav-link active"
                                                : "nav-link"
                                        }
                                        id="men-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={"#" + 'Lot'}
                                        type="button"
                                        role="tab"
                                        aria-controls="men"
                                        aria-selected="true"
                                        onClick={() => {
                                            // setCurrentModeration('Lot')
                                            dispatch(appPageSliceActions.savePageFilters({
                                                ...lotsPageState!,
                                                filter: { ...lotsPageState?.filter, isCurrent: true }
                                            }))
                                            dispatch(appPageSliceActions.savePageFilters({
                                                ...requestsPageState!,
                                                filter: { ...requestsPageState?.filter, isCurrent: false }

                                            }))
                                        }
                                        }
                                    >{'Lot'}</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={
                                            (requestsPageState?.filter as iModerationRequestsFilters).isCurrent ?
                                                "nav-link active"
                                                : "nav-link"
                                        }
                                        id="men-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target={"#" + 'Request'}
                                        type="button"
                                        role="tab"
                                        aria-controls="men"
                                        aria-selected="true"
                                        onClick={() => {
                                            // setCurrentModeration('Request')
                                            dispatch(appPageSliceActions.savePageFilters({
                                                ...lotsPageState!,
                                                filter: { ...lotsPageState?.filter, isCurrent: false }
                                            }))
                                            dispatch(appPageSliceActions.savePageFilters({
                                                ...requestsPageState!,
                                                filter: { ...requestsPageState?.filter, isCurrent: true }
                                            }))
                                        }
                                        }
                                    >{'Request'}</button>
                                </li>
                            </ul>

                            <div className="tab-content" id="myTabContent">
                                {(lotsPageState?.filter as iModerationLotsFilters).isCurrent &&
                                    <div
                                        // className={
                                        //     currentSizeType == eTabsProduct.Shipped
                                        //         ? "tab-pane fade active show"
                                        //         : "tab-pane fade"
                                        // }
                                        className="tab-pane fade show active"
                                        id={'Lot'}
                                        role="tabpanel"
                                        aria-labelledby="men-tab">
                                        <div className="brand-categories-list">
                                            <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                                <div className="action-btn">
                                                    <div className="input-group">
                                                        <DebounceInput
                                                            className="form-control"
                                                            minLength={1}
                                                            debounceTimeout={500}
                                                            onChange={event => {
                                                                // const val = event.target.value
                                                                // setSearchTextLots(val);
                                                                // handelSearchLots(1, val)
                                                                dispatch(appPageSliceActions.savePageFilters({
                                                                    ...lotsPageState!,
                                                                    currentPage: 1,
                                                                    filter: {
                                                                        ...lotsPageState!.filter,
                                                                        search: event.target.value
                                                                    }
                                                                }))
                                                            }}
                                                            placeholder={t('Search')!}
                                                            value={(lotsPageState!.filter as iModerationLotsFilters).search}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                                        </div>
                                                    </div>
                                                    {/* <a onClick={() => onAddShoeModelClick()} href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-shoe-model" data-toggle="modal" data-target="#addShoeModel">Add Shoe Model</a> */}
                                                </div>
                                            </div>
                                            <div className="shoe-model-grid">

                                                <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('Lot_Id')}</th>
                                                            {/* <th>{t('Release_date')}</th> */}
                                                            <th>{t('Thumbnail')}</th>
                                                            <th>{t('Shoemodel')}</th>
                                                            <th>{t('Color')}</th>
                                                            <th>{t('Sizes')}</th>
                                                            <th>{t('Avg_priceperpair')}</th>
                                                            {/* <th>{t('Size')}</th> */}

                                                            <th>{t('Total_quantity')}</th>
                                                            <th>{t('Total_Price')}</th>
                                                            <th>{t('Action')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {renderWarehouseb2bLots()}
                                                    </tbody>
                                                </table>
                                                {totalWarehouseb2bResultsLots && totalWarehouseb2bPagesLots ?
                                                    <CustomPagination
                                                        totalResults={totalWarehouseb2bResultsLots}
                                                        totalPages={totalWarehouseb2bPagesLots}
                                                        p_iPageState={lotsPageState!}
                                                        onPageChanged={onPageChangedLots}
                                                    />
                                                    : null
                                                }
                                                {/*<div className="nav-direction">
                                                    {
                                                        totalWarehouseb2bResultsLots > appConst.PAGE_LIMIT && totalWarehouseb2bPagesLots ?
                                                            <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                                                <Pagination totalRecords={totalWarehouseb2bResultsLots}
                                                                    totalPages={totalWarehouseb2bPagesLots}
                                                                    currentPage={currentWarehouseb2bPageLots}
                                                                    pageLimit={appConst.PAGE_LIMIT}
                                                                    pageNeighbours={1}
                                                                    onPageChanged={onPageChangedLots} />
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(requestsPageState?.filter as iModerationRequestsFilters).isCurrent &&
                                    <div
                                        // className={
                                        //     currentSizeType == eTabsProduct.Shipped
                                        //         ? "tab-pane fade active show"
                                        //         : "tab-pane fade"
                                        // }
                                        className="tab-pane fade show active"
                                        id={'Request'}
                                        role="tabpanel"
                                        aria-labelledby="men-tab">
                                        <div className="brand-categories-list">
                                            <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                                <div className="action-btn">
                                                    <div className="input-group">
                                                        <DebounceInput
                                                            className="form-control"
                                                            minLength={1}
                                                            debounceTimeout={500}
                                                            onChange={event => {
                                                                // const val = event.target.value
                                                                // setSearchTextRequests(val);
                                                                // handelSearchRequests(1, val)
                                                                dispatch(appPageSliceActions.savePageFilters({
                                                                    ...requestsPageState!,
                                                                    currentPage: 1,
                                                                    filter: {
                                                                        ...requestsPageState!.filter,
                                                                        search: event.target.value
                                                                    }
                                                                }))
                                                            }}
                                                            placeholder={t('Search')!}
                                                            value={(requestsPageState!.filter as iModerationRequestsFilters).search}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                                        </div>
                                                    </div>
                                                    {/* <a onClick={() => onAddShoeModelClick()} href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-shoe-model" data-toggle="modal" data-target="#addShoeModel">Add Shoe Model</a> */}
                                                </div>
                                            </div>
                                            <div className="shoe-model-grid">
                                                <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th>{t('Request_Id')}</th>
                                                            {/* <th>{t('Release_date')}</th> */}
                                                            <th>{t('Thumbnail')}</th>
                                                            <th>{t('Shoemodel')}</th>
                                                            <th>{t('Color')}</th>
                                                            <th>{t('Sizes')}</th>
                                                            <th>{t('Avg_priceperpair')}</th>
                                                            {/* <th>{t('Size')}</th> */}

                                                            <th>{t('Total_quantity')}</th>
                                                            <th>{t('Total_Price')}</th>
                                                            <th>{t('Action')}</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {renderWarehouseb2bRequests()}
                                                    </tbody>
                                                </table>
                                                {totalWarehouseb2bResultsRequests && totalWarehouseb2bPagesRequests ?
                                                    <CustomPagination
                                                        totalResults={totalWarehouseb2bResultsRequests}
                                                        totalPages={totalWarehouseb2bPagesRequests}
                                                        p_iPageState={requestsPageState!}
                                                        onPageChanged={onPageChangedRequests}
                                                    />
                                                    : null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* <div className="tab-pane fade" id="women" role="tabpanel" aria-labelledby="women-tab">Women...</div>
                                    <div className="tab-pane fade" id="preschool" role="tabpanel" aria-labelledby="preschool-tab">Preschool...</div>
                                    <div className="tab-pane fade" id="toddler" role="tabpanel" aria-labelledby="toddler-tab">Toddler...</div>
                                    <div className="tab-pane fade" id="infant" role="tabpanel" aria-labelledby="infant-tab">Infant...</div> */}
                            </div>
                        </div>
                        <ModalDialog toggle={() => onDelete()}
                            isOpen={isOpen}
                            enableExecute={true}

                            modalTitle={"Delete " +
                                ((lotsPageState?.filter as iModerationLotsFilters).isCurrent ?
                                    "Lot" : "Request")}
                            cancel={onClose}
                            execute={deleeteWarehouseb2b}
                            executeButtonTitle={() => ""}
                            hideFooter={false}
                            useButtons={t("MD_Cancel") + '/' + t("Ok")}
                        >
                            <div className="row mb-2">
                                <div className="col-4 pe-0"><div className="colon-text">{t('Id')}</div></div>
                                <div className="col-8">{warehouseb2bDetail?.id}</div>
                            </div>

                        </ModalDialog>
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div >
        </div >

    )
};

export default Warehouseb2bPage;