// import GolfVacations from "pages/GolfVacations";

import { Routes, Route } from "react-router-dom";
// import LandingPage from "../pages/Landing";
import Dashboard from "../pages/admin/dashboard";
import UsersPage from "../pages/admin/users";
import Login from "../pages/admin/login";
// import { useCookies } from "react-cookie";
import { Navigate } from 'react-router-dom'


import AdminPrivateRoute from './AdminPrivateRoute'
import BrandPage from "../pages/admin/brand";
import EditUser from "../pages/admin/users/edit-user";
import AddBrand from "../pages/admin/brand/add-brand";
import BrandCategoryLevelPage from "../pages/admin/brand-category-level";
import AddBrandCategoryLevelPage from "../pages/admin/brand-category-level/add-brand-category";
import ShoeModelPage from "../pages/admin/shoemodel";
import AddShoeModel from "../pages/admin/shoemodel/add-shoemodel";
import EditShoeModel from "../pages/admin/shoemodel/edit-shoemodel";
import ShoeModelPhotosPage from "../pages/admin/shoemodel-photos";

import ColorsPage from "../pages/admin/colors";
import SizesPage from "../pages/admin/sizes";
import Warehouseb2bPage from "../pages/admin/warehouseb2b";

import OrdersPage from "../pages/admin/orders";
import CouponsPage from "../pages/admin/coupons";
// import SearchByPage from "../pages/admin/searchby";


import SettingsPage from "../pages/admin/settings";

import UserTypesPage from "../pages/admin/userTypes";
import SearchByPage from "../pages/admin/search";
import TermsAndConditionPage from "../pages/admin/termsandcondition";
import FAQPage from "../pages/admin/faq";
import PrivacyPolicyPage from "../pages/admin/privacy-policy";
import RewardsPage from "../pages/admin/rewards";
import OverlayNotificationPage from "../pages/admin/Overlay-notification";
import ViewUser from "../pages/admin/users/view-user";
import PrizePage from "../pages/admin/prizes";
import OrderDetailsPage from "../pages/admin/orders/order-details";
import NotificationPage from "../pages/admin/notification";
import PenaltyPage from "../pages/admin/penalties";
import NotificationSettingsPage from "../pages/admin/notification/notification-setting";
import NewsLatterPage from "../pages/admin/news-latter";
import ProfilePage from "../pages/admin/profile";
import FeesPage from "../pages/admin/fees/fees";
import Landing from "../pages/admin/landing";
import Support from "../pages/admin/support/support";
import Warehouseb2bViewPage from "../pages/admin/warehouseb2b/Warehouseb2bViewPage";
// import SanctionPage from "../pages/admin/sanctions";



const AppRoutes = () => {

  return (
    <Routes>
      {/* <Route index path="/" element={<Navigate to="/admin/auth/login" />} /> */}
      <Route index path="/" element={<Landing />} />
      <Route path="support" element={<Support />} />
      <Route path="admin/auth/login" element={<Login />} />
      {/* =========== Admin Routes =========== */}
      <Route path="admin" element={<AdminPrivateRoute />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users/list" element={<UsersPage />} />
        <Route path="users/edit/:id" element={<EditUser />} />
        <Route path="users/detail/:id" element={<ViewUser />} />
        <Route path="brands/list" element={<BrandPage />} />
        <Route path="brands/create" element={<AddBrand />} />
        <Route path="brands-category-level/list/:id?" element={<BrandCategoryLevelPage />} />
        <Route path="brands-category-level/create" element={<AddBrandCategoryLevelPage />} />
        <Route path="shoe-models/create" element={< AddShoeModel />} />
        <Route path="shoe-models/edit/:id" element={< EditShoeModel />} />
        <Route path="shoe-models/list" element={<ShoeModelPage />} />
        <Route path="profile/list" element={<ProfilePage />} />

        <Route path="shoe-model-photos/list" element={<ShoeModelPhotosPage />} />
        <Route path="user-types/list" element={<UserTypesPage />} />
        <Route path="colors/list" element={< ColorsPage />} />
        <Route path="sizes/list" element={< SizesPage />} />
        <Route path="settings" element={< SettingsPage />} />
        <Route path="warehouseb2b/list" element={< Warehouseb2bPage />} />
        <Route path="warehouseb2b/view/:type" element={< Warehouseb2bViewPage />} />
        <Route path="orders/list" element={< OrdersPage />} />
        <Route path="orders/details/:id" element={< OrderDetailsPage />} />
        <Route path="coupons/list" element={< CouponsPage />} />
        <Route path="searchby/list" element={< SearchByPage />} />
        <Route path="rewards/list" element={<RewardsPage />} />
        <Route path="fees/list" element={< FeesPage />} />
        <Route path="termsandconditions/list" element={<TermsAndConditionPage />} />
        <Route path="privacypolicy/list" element={<PrivacyPolicyPage />} />
        <Route path="faq/list" element={<FAQPage />} />
        <Route path="overlay-notification" element={<OverlayNotificationPage />} />
        <Route path="notification/list" element={<NotificationPage />} />
        <Route path="prize/list" element={<PrizePage />} />
        <Route path="penalty/list" element={<PenaltyPage />} />
        <Route path="notification/settings" element={<NotificationSettingsPage />} />
        <Route path="news-latter" element={<NewsLatterPage />} />
        {/* <Route path="sanction/list" element={<SanctionPage />} /> */}
      </Route>

      <Route path="*" element={<div>Error</div>} />
    </Routes>
  );
};

export default AppRoutes;