import * as yup from "yup";
const percentageValidation = yup
  .string()
  .required("required")
  .matches(/^[0-9]+(\.[0-9]{1,2})?$/, "Invalid value")
  .test("isInRange", "Invalid value", (value: string | undefined) => {
    if (!value) return true;
    const numericValue = parseInt(value, 10);
    return numericValue >= 0 && numericValue <= 100;
  });
export const feesFormValidationSchema = yup.object().shape({
  b2BBuyTransactionFeePercent: percentageValidation,
  b2BSellTransactionFeePercent: percentageValidation,
  b2BBuyPaymentProcessingFeePercent: percentageValidation,
  b2BSellPaymentProcessingFeePercent: percentageValidation,
  b2CBuyTransactionFeePercent: percentageValidation,
  b2CSellTransactionFeePercent: percentageValidation,
  b2CBuyPaymentProcessingFeePercent: percentageValidation,
  b2CSellPaymentProcessingFeePercent: percentageValidation,
  b2CSellShippingFeeValue: percentageValidation,
  b2CBuyShippingFeeValue: percentageValidation,
});
