import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iShoeModelSize, iSizeType } from '../../../shared/models/adminModels'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { HttpShoeModelSizeService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import * as appConst from '../../../shared/constants/constants'
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const SizesPage = () => {

    // -------------- Pagination --------------------
    // const [totalSizesResults, setTotalSizesResults] = useState(-1);
    // const [totalSizesPages, setTotalSizesPages] = useState(null)
    // const [currentSizesPage, setCurrentSizesPage] = useState(1)
    // -------------- Pagination End --------------------

    const { t } = useTranslation()

    const HttpSizeService: HttpShoeModelSizeService = new HttpShoeModelSizeService();


    const [sizes, setSizes] = useState<iShoeModelSize[]>([])
    const { isOpen, toggle } = useModal()
    const { isOpen: isEditOpen, toggle: toggleEdit } = useModal()

    //TODO: Replace with api response.
    const [sizeTypes, setSizeTypes] = useState<iSizeType[]>([])
    const [currentSizeType, setCurrentSizeType] = useState<number>(0)
    const intialSize = {
        id: -1,
        size: "",
    }
    const [sizeToSave, setSizeToSave] = useState<iShoeModelSize>(intialSize)
    const getSize = (currentPage = 1) => {
        HttpSizeService
            .getAllByBrandAndSizeType<iShoeModelSize>(
                { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT }, 
                currentSizeType!)
            .then((value) => {
                setSizes(value.data)
            })
            .catch((error) => console.log(error))
    }
    const getSizeTypes = () => {
        HttpSizeService
            .getSizeTypes<iSizeType>()
            .then((value) => {
                console.log('getAllSizeTypes', value)
                setSizeTypes(value.data)
                setCurrentSizeType(value.data[0].id)
            })
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        if (!currentSizeType) return
        getSize()
    }, [ currentSizeType])

    useEffect(() => {
        getSizeTypes()
    }, [])

    const populateSizeTypes = (): ReactNode => {
        return (
            <select
                onChange={(evt) => {
                    setCurrentSizeType(+evt.target.value)
                }}
                className="form-control"
                name="Marketplace_id"
            >
                {/* <option value='-1'>Select Coupon type</option> */}
                {sizeTypes.length &&
                    sizeTypes.map((item, index) => (
                        <option
                            key={index}
                            value={item.id}
                            selected={currentSizeType === item.id}
                        >
                            {item.name}
                        </option>
                    ))}
            </select>
        );
    };
    const onToggleClick = () => {
        toggle()
    }
    const cancelAdd = () => {
        setSizeToSave(intialSize)
        toggle()
    }
    const onToggleEditClick = (size: iShoeModelSize) => {
        setSizeToSave(size)
        toggleEdit()
    }
    const cancelEdit = () => {
        setSizeToSave(intialSize)
        toggleEdit()
    }
    const onExecuteAdd = async () => {
        try {
            await HttpSizeService
                    .addShoeSize<iShoeModelSize>(
                        sizeToSave, 
                        currentSizeType!
                        )
            toast.success(t`Add_success_msg`)
            setSizeToSave(intialSize)
            getSize()
            toggle()

        } catch (error) {
            let e = error as AxiosError
            console.log('Error', e.message)

        }
    }

    const onExecuteEdit = async () => {
        try {
            await HttpSizeService.updatePUT<iShoeModelSize>(sizeToSave.id, sizeToSave)
            toast.success(t`Edit_success_msg`)
            setSizeToSave({ ...intialSize })
            getSize()
            toggleEdit()
        } catch (error) {
            let e = error as AxiosError
            console.log('Error', e.message)
        }
    }

    const renderSize = (): ReactNode[] => {
        const returnNode: ReactNode[] = sizes?.map((size: iShoeModelSize) => (
            <div className="brand-categories-box" key={size.id}>
                <div className="brand-categories-box-in colors-sizes">
                    <div>
                        <a href="javascript:void(0)" className="list-link">{size.size}</a>
                    </div>
                    <div className="box-action-btns">
                        {/* <a href="javascript:void(0)" className="delete"><span className="icon dripicons-trash"></span></a> */}
                        <a href="javascript:void(0)" title="Edit" className="edit" onClick={() => onToggleEditClick(size)}><span className="icon dripicons-pencil"></span></a>
                    </div>
                </div>
            </div>
        ))
        return returnNode
    }

    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                <div className="content">

                    {/* -- Begin Sizes -- */}
                    <section className="page-content container-fluid">
                        <div className="admin-content-wrapper">
                            <div className="admin-header">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-6">
                                        <h3>{t('Taxonomy')}</h3>
                                    </div>
                                    <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    </div>
                                </div>
                            </div>
                            <div className="admin-breadcrumb">
                                <div className="mb-1">{t('Sizes')}</div>
                            </div>
                            <div className="sizes-top-header">
                                <div className="row">
                                    <div className="col-6 col-sm-3 col-md-3 col-lg-3">
                                        {populateSizeTypes()}
                                    </div>
                                    <div className="col-6 col-sm-9 col-md-9 col-lg-9 text-end">
                                        <div className="action-btn">
                                            <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                                                data-toggle="modal"
                                                data-target="#addSize"
                                                id="id-add-brand"
                                                onClick={onToggleClick}
                                            >{t('Add_Size')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {sizeTypes && sizeTypes.length ?
                            <div className="sizes-tabs">
                                
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id={currentSizeType.toString()}
                                        role="tabpanel"
                                        aria-labelledby="men-tab">
                                        <div className="brand-categories-list">
                                            {renderSize()}
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="women" role="tabpanel" aria-labelledby="women-tab">Women...</div>
                                    <div className="tab-pane fade" id="preschool" role="tabpanel" aria-labelledby="preschool-tab">Preschool...</div>
                                    <div className="tab-pane fade" id="toddler" role="tabpanel" aria-labelledby="toddler-tab">Toddler...</div>
                                    <div className="tab-pane fade" id="infant" role="tabpanel" aria-labelledby="infant-tab">Infant...</div> */}
                                </div>
                                
                            </div>
                            : null }
                            {/* <div className="nav-direction">
                                {
                                    totalSizesResults > appConst.PAGE_LIMIT && totalSizesPages ?
                                        <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                            <Pagination totalRecords={totalSizesResults}
                                                totalPages={totalSizesPages}
                                                currentPage={currentSizesPage}
                                                pageLimit={appConst.PAGE_LIMIT}
                                                pageNeighbours={1}
                                                onPageChanged={onPageChanged} />
                                        </div>
                                        :
                                        null
                                }
                            </div> */}
                        </div>

                        {/* -- Begin Modal -- */}
                        <ModalDialog toggle={() => onToggleClick()}
                            cancel={() => cancelAdd()}
                            executeButtonTitle={() => t('MD_Add')}
                            isOpen={isOpen}
                            execute={onExecuteAdd}
                            enableExecute={(sizeToSave.size) ? true : false}
                            modalTitle={t('Add_Size')}
                            useButtons={t("MD_Cancel") + '/' +  t("MD_Add") }

                        >
                            {

                                <div className="form-group">
                                    <label>{t('Size')}:</label>
                                    <input type="text" className="form-control"
                                        value={sizeToSave.size}
                                        onChange={(evt) => {
                                            const val = evt.target.value;
                                            var floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
                                            if (!val.length || (val.match(floatValues) && !isNaN(+val))) {
                                                setSizeToSave({ ...sizeToSave, size: val.valueOf() })
                                            }
                                        }} />
                                </div>

                            }
                        </ModalDialog>
                        {/* -- End Modal -- */}

                        <ModalDialog toggle={() => toggleEdit()}
                            cancel={() => cancelEdit()}
                            executeButtonTitle={() => t('MD_Update')}
                            isOpen={isEditOpen}
                            execute={onExecuteEdit}
                            enableExecute={(sizeToSave.size) ? true : false}
                            modalTitle={t('Edit_Size')}
                            useButtons={t("MD_Cancel") + '/' +  t('MD_Update')}
                        >{
                                <div className="form-group">
                                    <label>{t('Size')}:</label>
                                    <input type="text" className="form-control"
                                        value={sizeToSave.size}
                                        onChange={(evt) => {
                                            const val = evt.target.value;
                                            var floatValues = /[+-]?([0-9]*[.])?[0-9]+/;
                                            if (val.match(floatValues) && !isNaN(+val)) {
                                                setSizeToSave({ ...sizeToSave, size: val.valueOf() })
                                            }
                                        }
                                        } />
                                </div>
                            }

                        </ModalDialog>
                    </section>
                    {/* -- End Sizes -- */}
                </div>
            </div>
        </div>






    );
};

export default SizesPage;