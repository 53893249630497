import { createSlice } from "@reduxjs/toolkit";
import { iShoeModel } from "../shared/models/adminModels";

export interface ShoeModelState {
    totalRows: number
    items: iShoeModel[]
    pageNumber: number
    searchValue: string
    loading: boolean
}

class initial implements ShoeModelState {
    totalRows: number = 0;
    items: iShoeModel[] = [];
    pageNumber: number = 1;
    searchValue: string = "";
    loading: boolean = false;
};
const initialState: initial = new initial()

const shoeModelSlice = createSlice({
    name: "shoeModelSlice",
    initialState,
    reducers: {
        updateSearchResult: (state, action) => {
            const { data, loadMore } = action.payload;
            return {
                ...state,
                items: loadMore ? [...state.items, ...data.items] : data.items,
                totalRows: data.totalRows,
            };
        },
    },
});
export const shoeModelSliceActions = shoeModelSlice.actions;
export default shoeModelSlice.reducer;
