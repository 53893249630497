import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import { ReactNode, useEffect, useState, useCallback } from "react";
import { useParams } from 'react-router-dom'

import { HttpLabelService, HttpOrdersService, HttpOrderHistoryService } from '../../../services/admin.service'
import { iAddress, iOrderModel, iOrderHistory, iUserInfo, eOrderStatusType, iLabel, ePaymentMethods, iInventorySummary, iOrderFilters, iBatchOfferModel, eOfferType, eOfferStatus } from '../../../shared/models/adminModels'
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import moment from 'moment';
import useModal from "../../../hooks/general";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import { HttpWarehouseb2bService, iCustomAxiosError } from "../../../services/http.service";
import { AxiosError } from "axios";
import { CommonUtil } from "../../../shared/utils/commonUtils";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const OrderDetailsPage = () => {

    const navigate = useNavigate();
    const { t } = useTranslation()
    const { isOpen, toggle } = useModal()
    const p_CommonUtil = new CommonUtil()
    const { id } = useParams()
    console.log('id', id)
    // const [searchParams, setSearchParams] = useSearchParams();
    // const currentPage = searchParams.get('currentPage');
    // console.log('currentPage', currentPage)

    // interface propState { filter: iOrderFilters }
    // const location = useLocation();
    // let { filter } = location.state as propState;
    // console.log('OrderDetails filter', filter)

    const initialAddress: iAddress = {
        id: 0,
        userId: 0,
        address: '',
        streetNo: '',
        village: '',
        addressType: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        isDefault: false
    }
    const initialUserInfo: iUserInfo = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        companyVat: '',
        uniqueCode: ''
    }
    const initialOrderModel: iOrderModel = {
        id: -1,
        status: eOrderStatusType.Sold,
        description: "",
        totalPrice: 0,
        createdAt: '',
        paymentToken: '',
        paymentId: -1,
        addressId: 0,
        address: initialAddress,
        billingAddress: initialAddress,
        sellerAddress: initialAddress,
        buyer: initialUserInfo,
        seller: initialUserInfo,
        type: '',
        paymentReference: "",
        salePrice: 0,
        paymentMethod: "",
        buyerTotal: 0,
        sellerTotal: 0,
        buyerShipping: 0,
        buyerShippingDiscount:0,
        buyerFees: 0,
        buyerFeesDiscount:0,
        buyerPaymentFees: 0,
        buyerPriceDiscount:0,
        sellerFees: 0,
        sellerFeesDiscount:0,
        sellerShipping: 0,
        sellerShippingDiscount:0,
        sellerPaymentFees: 0,
        sellerPriceDiscount:0,
        orderItems: [],
        sellerPoints: 0,
        buyerPoints: 0,
        couponCode: ''
    }

    const initialLabel: iLabel = {
        addressId: 0,
        createddate: "",
        labelType: "",
        orderId: 0,
        parcels: []
    }

    const [orderModelDetails, setOrderModelDetails] = useState<iOrderModel>(initialOrderModel)
    const [batchOfferModels, setBatchOfferModels] = useState<iBatchOfferModel[]>([])
    const [orderModelDetailsPrevStatus, setOrderModelDetailsPrevStatue] = useState<eOrderStatusType>(eOrderStatusType.Sold)
    const [orderHistory, setOrderHistory] = useState<iOrderHistory[]>([])

    const [orderStatusChanged, setOrderStatusChanged] = useState<boolean>(false)
    const [labelDetail, setLabelDetail] = useState<iLabel>(initialLabel)

    const httpOrdersService: HttpOrdersService = new HttpOrdersService();
    const httpOrderHistoryService: HttpOrderHistoryService = new HttpOrderHistoryService();
    const httpLabelService: HttpLabelService = new HttpLabelService();
    const httpWarehouseb2bService: HttpWarehouseb2bService = new HttpWarehouseb2bService();


    const fetchOrderModelDetails = useCallback((id: string) => {
        httpOrdersService.get<iOrderModel>({ orderId: +id })
            .then((value) => {
                console.log('fetchOrderModelDetails get value', value.data)
                setOrderModelDetails(value.data)
                setOrderModelDetailsPrevStatue(value.data.status)
            })
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    const getBatchOffer = () => {
        if (!orderModelDetails) return
        httpOrdersService.getBatchOffer<iBatchOfferModel>({ id: orderModelDetails.id })
            .then((value) => {
                console.log('getBatchOffer get value', value.data)
                setBatchOfferModels(value.data)
            })
    } // eslint-disable-line react-hooks/exhaustive-deps

    const fetchOrderHistory = useCallback((id: string) => {
        httpOrderHistoryService.get<iOrderHistory[]>({ orderId: +id })
            .then((value) => {
                console.log('fetchOrderHistory get value', value.data)
                setOrderHistory(value.data)
            })
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchOrderLabel = useCallback((id: string) => {
        httpLabelService.get<iLabel>({ orderId: +id })
            .then((value) => {
                console.log('fetchOrderLabel get Label', value.data)
                setLabelDetail(value.data)
            })
    }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchOrderHistory(id!)
        fetchOrderLabel(id!)
        fetchOrderModelDetails(id!)
    }, [id, fetchOrderModelDetails, fetchOrderHistory, fetchOrderLabel])


    useEffect(() => {
        if (JSON.stringify(orderModelDetails) == JSON.stringify(initialOrderModel)) return
        getBatchOffer()
    }, [orderModelDetails])

    useEffect(() => {
        if (orderModelDetails.status !== orderModelDetailsPrevStatus) {
            setOrderStatusChanged(true)
        } else {
            setOrderStatusChanged(false)
        }
    }, [orderModelDetails.status, orderModelDetails.description, orderModelDetailsPrevStatus])

    const setOrderStatusTypeSelected = (val: eOrderStatusType) => {
        console.log('aa val', val)
        console.log('orderModelDetails.status', orderModelDetails.status)

        //console.log('eOrderStatusType[orderModelDetails.status] !== val',eOrderStatusType[orderModelDetails.status] !== val)
        if (orderModelDetails.status !== val)
            setOrderModelDetails({ ...orderModelDetails, status: val })
    }

    const populateOrderStatusType = (toValueOS: eOrderStatusType): ReactNode => {
        console.log("populateOrderStatusType toValueOS", toValueOS)
        console.log("populateOrderStatusType", eOrderStatusType[toValueOS])
        const keys = Object.keys(eOrderStatusType).filter((v) => isNaN(Number(v)));
        return <select onChange={(evt) => { setOrderStatusTypeSelected(evt.target.value as any) }} className="form-control" name="OrderStatus_id">
            {/* <option value='-1'>Select Beneficiary type</option> */}
            {keys.length && keys.map((key, index) =>
                <option value={key} selected={+eOrderStatusType[toValueOS] === index + 1}>
                    {key}
                </option>)}
        </select>
    }


    const onExecuteEditOrder = async () => {

        console.log('onExecuteEditOrder', orderModelDetails)
        //TODO: update the order status
        try {
            await httpOrdersService.updatePUT<iOrderModel>(orderModelDetails.id, orderModelDetails)
            toast.success(t`Edit_success_msg`)

            //navigate('/admin/orders/list'+ '?currentPage=' + currentPage, { replace: true })
            navigate('/admin/orders/list', { replace: true })
        } catch (e: any) {
            p_CommonUtil.showError(e, toast)
        }
    }

    //============ Modal Dialog ===============
    const [inventorySummary, setInventorySummary] = useState<iInventorySummary>()
    const [sno, setSNo] = useState<number>()

    const viewInventoryDetails = (item: iInventorySummary, index: number) => {
        console.log('viewInventoryDetails', item)
        setInventorySummary(item)
        setSNo(index)
        toggle();
    }
    const onViewDetail = () => {
        toggle();
    }

    const onClose = () => {
        setInventorySummary(undefined)
        toggle();
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3>{t('Order_Details')}</h3>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item"><a href="/admin/orders/list">{t('Manage_orders')}</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="d-flex justify-content-end align-items-center order-details-filter">
                            <div className="d-flex align-items-center me-2 col-status">
                                <div className="colon-text sso-txt">{t('Seller_Status_Order')}</div>
                                {populateOrderStatusType(orderModelDetails.status!)}
                            </div>
                            <div className="d-flex align-items-center me-2 col-status">
                                <div className="colon-text note-txt">{t('Note')}</div>
                                <input type="text" className="form-control"
                                    value={orderModelDetails.description}
                                    onChange={(evt) =>
                                        setOrderModelDetails({ ...orderModelDetails, description: evt.target.value })}
                                />
                            </div>
                            <div>
                                <Button className="btn btn-primary" variant="primary"
                                    disabled={orderStatusChanged ? false : true}
                                    onClick={() => onExecuteEditOrder()}>{t('Save')}</Button>
                            </div>
                        </div>
                        <div className="order-details-info-box">
                            <div>
                                <div className="order-details-info-header">{t('Order_Informations')}</div>
                                <div className="d-flex align-items-center mb-3 od-row">
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Order_Id')}</div>
                                        <b>{orderModelDetails.id}</b>
                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Sales_Price')}</div>
                                        <b>€{orderModelDetails.totalPrice}</b>
                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Date_Of_Order')}</div>
                                        <b>{moment(orderModelDetails.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</b>
                                    </div>
                                </div>
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Date')}</th>
                                            <th>{t('From_Status')}</th>
                                            <th>{t('To_Status')}</th>
                                            <th>{t('Note')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderHistory.length > 0 &&
                                            orderHistory.map((item, index) =>
                                                <tr key={index}>
                                                    <td style={{ whiteSpace: "pre" }}>{moment(item.createdDate).format('DD/MM/YYYY[\n]hh:mm:ss A')}</td>
                                                    <td>{item.oldStatus} </td>
                                                    <td>{item.newStatus} </td>
                                                    <td>{item.message}</td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="order-details-info-box">
                            <div>
                                <div className="order-details-info-header">{t('Product_Informations')}</div>
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('MarketPlace')}</th>
                                            <th>{t('Thumbnail')}</th>
                                            <th>{t('Model_Name')}</th>
                                            <th>{t('Quantity')}</th>
                                            <th>{t('Size')}</th>
                                        </tr>
                                    </thead>
                                    {orderModelDetails ?
                                        <tbody>
                                            {orderModelDetails.orderItems.length &&
                                                orderModelDetails.orderItems.map((value, index, arr) =>
                                                    <tr>
                                                        {index == 0 ? <td rowSpan={orderModelDetails.orderItems.length}>{orderModelDetails.type} - {value.batchType}</td> : ''}
                                                        <td style={{verticalAlign:'middle', alignContent: 'center'}}>
                                                            <img width={'100px'} height={'100px'} src={value.shoeModel.defaultImage} alt="shoeimg" />
                                                        </td>
                                                        <td>
                                                            {value.shoeModel.title.replaceAll("/", ">")} ({value.shoeModel.modelName} , {value.shoeModel.modelCode})
                                                        </td>
                                                        {/* Total Quantity */}
                                                        <td>
                                                            {value.totalQuantity}
                                                        </td>
                                                        <td>
                                                            {orderModelDetails.type == 'B2C' &&
                                                                value.size
                                                            }
                                                            {orderModelDetails.type == 'B2B' &&
                                                                <div>{value.products?.length &&
                                                                    value.products.map((product, i) =>

                                                                        <>
                                                                            <span>{' ' + product.quantity + 'x' + ' '}</span>
                                                                            <b>{product.shoeSize?.size}</b>
                                                                        </>

                                                                    )}</div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                        : null}
                                </table>

                            </div>
                        </div>
                        <div className="order-details-info-box">
                            <div className="order-details-info-header">{t('Buyer_Informations')}</div>
                            <div className="d-flex align-items-start mb-3 od-row">
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Id')}</div>
                                    <a href="javascript:void(0)"
                                            onClick={()=>navigate('/admin/users/detail/' + orderModelDetails.buyer.id)}
                                            ><b>{orderModelDetails.buyer.id}</b></a>
                                </div>
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Personal_Information')}</div>
                                    <div>
                                        <div><b>{orderModelDetails.buyer.firstName} {orderModelDetails.buyer.lastName}</b></div>
                                        <div><b>Phone: {orderModelDetails.buyer.phone ? orderModelDetails.buyer.phone : 'N/A'}</b></div>
                                        <div><b>{orderModelDetails.buyer.email}</b></div>
                                        <div><b>Company Name: {orderModelDetails.buyer.companyName ? orderModelDetails.buyer.companyName : 'N/A'}</b></div>
                                        <div><b>Company VAT: {orderModelDetails.buyer.companyVat ? orderModelDetails.buyer.companyVat : 'N/A'}</b></div>
                                        <div><b>Unique Code: {orderModelDetails.buyer.uniqueCode ? orderModelDetails.buyer.uniqueCode : 'N/A'}</b></div>
                                    </div>
                                </div>
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Shipping_Address')}</div>
                                    <div>
                                    <div><b>{orderModelDetails.address.address},</b></div>
                                        <div>
                                            <b>{orderModelDetails.address.streetNo}, {orderModelDetails.address.village}</b>
                                        </div>
                                        <div><b>{orderModelDetails.address.city}, {orderModelDetails.address.state}</b></div>
                                        <div><b>{orderModelDetails.address.country} - {t('Pin')}: {orderModelDetails.address.postalCode}.</b></div>
                                    </div>

                                </div>
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Billing_Address')}</div>
                                    <div>
                                    <div><b>{orderModelDetails.billingAddress.address},</b></div>
                                        <div>
                                            <b>{orderModelDetails.billingAddress.streetNo}, {orderModelDetails.billingAddress.village}</b>
                                        </div>
                                        <div><b>{orderModelDetails.billingAddress.city}, {orderModelDetails.billingAddress.state}</b></div>
                                        <div><b>{orderModelDetails.billingAddress.country} - {t('Pin')}: {orderModelDetails.billingAddress.postalCode}.</b></div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-2">
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Original_Price')}</th>
                                            <th>{t('Purchase_Price')}</th>
                                            <th>{t('Fees')}</th>
                                            <th>{t('Shipping_Fees')}</th>
                                            <th>{t('Payment_Fees')}</th>
                                            <th>{t('Coupon_Applied')}</th>
                                            <th>{t('Total_Price')}</th>
                                            <th>{t('Payment_Method')}</th>
                                            <th>{t('Payment_Reference')}</th>
                                            <th>{t('Invoice_Request')}</th>
                                            <th>{t('Rewards')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderModelDetails ?
                                            <tr>
                                                <td> €{orderModelDetails.totalPrice}</td>
                                                <td> €{orderModelDetails.salePrice}</td>
                                                <td> €{orderModelDetails.buyerFees}{orderModelDetails.buyerFeesDiscount ? ' (- €' + orderModelDetails.buyerFeesDiscount + ')':''}</td>
                                                <td> €{orderModelDetails.buyerShipping}{orderModelDetails.buyerShippingDiscount ? ' (- €' + orderModelDetails.buyerShippingDiscount + ')':''}</td>
                                                <td> €{orderModelDetails.buyerPaymentFees}{orderModelDetails.buyerPriceDiscount ? ' (- €' + orderModelDetails.buyerPriceDiscount + ')':''}</td>
                                                <td> {orderModelDetails.couponCode ? orderModelDetails.couponCode : 'N/A'}</td>
                                                <td> €{orderModelDetails.buyerTotal}</td>
                                                <td> {orderModelDetails.paymentMethod}</td>
                                                <td> {orderModelDetails.paymentReference}</td>
                                                <td> {orderModelDetails.invoice ? 'Yes' : 'No'}</td>
                                                <td> {orderModelDetails.buyerPoints}</td>
                                            </tr>
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="order-details-info-box">
                            <div className="order-details-info-header">{t('Seller_Information')}</div>
                            <div className="d-flex align-items-start mb-3 od-row">
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Id')}</div>
                                    <a href="javascript:void(0)"
                                            onClick={()=>navigate('/admin/users/detail/' + orderModelDetails.seller.id)}
                                            ><b>{orderModelDetails.seller.id}</b></a>
                                </div>
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Personal_Information')}</div>
                                    <div>
                                        <div><b>{orderModelDetails.seller.firstName} {orderModelDetails.seller.lastName}</b></div>
                                        <div><b>Phone: {orderModelDetails.seller.phone ? orderModelDetails.seller.phone : 'N/A'}</b></div>
                                        <div><b>{orderModelDetails.seller.email}</b></div>
                                        <div><b>Company Name: {orderModelDetails.seller.companyName}</b></div>
                                        <div><b>Company VAT: {orderModelDetails.seller.companyVat ? orderModelDetails.seller.companyVat : 'N/A'}</b></div>
                                        <div><b>Unique Code: {orderModelDetails.seller.uniqueCode ? orderModelDetails.seller.uniqueCode : 'N/A'}</b></div>
                                    </div>
                                </div>
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('Billing_Address')}</div>
                                    <div>
                                    <div><b>{orderModelDetails.sellerAddress.address},</b></div>
                                        <div><b>{orderModelDetails.sellerAddress.streetNo}, {orderModelDetails.sellerAddress.village}</b></div>
                                        <div><b>{orderModelDetails.sellerAddress.city}, {orderModelDetails.sellerAddress.state}</b></div>
                                        <div><b>{orderModelDetails.sellerAddress.country} - {t('Pin')}: {orderModelDetails.sellerAddress.postalCode}.</b></div>
                                    </div>

                                </div>
                            </div>
                            <div className="mt-2">
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Original_Price')}</th>
                                            <th>{t('Sales_Price')}</th>
                                            <th>{t('Fees')}</th>
                                            <th>{t('Shipping_Fees')}</th>
                                            <th>{t('Payment_Fees')}</th>
                                            <th>{t('Coupon_Applied')}</th>
                                            <th>{t('Payout')}</th>
                                            <th>{t('Receiving_Method')}</th>
                                            <th>{t('Payout_Reference')}</th>
                                            <th>{t('Rewards')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderModelDetails ?
                                            <tr>
                                                <td> €{orderModelDetails.totalPrice}</td>
                                                <td> €{orderModelDetails.salePrice}</td>
                                                <td> €{orderModelDetails.sellerFees}{orderModelDetails.sellerFeesDiscount ? ' (- €' + orderModelDetails.sellerFeesDiscount + ')':''}</td>
                                                <td> €{orderModelDetails.sellerShipping}{orderModelDetails.sellerShippingDiscount ? ' (- €' + orderModelDetails.sellerShippingDiscount + ')':''}</td>
                                                <td> €{orderModelDetails.sellerPaymentFees}{orderModelDetails.sellerPriceDiscount ? ' (- €' + orderModelDetails.sellerPriceDiscount + ')':''}</td>
                                                <td> {orderModelDetails.orderItems.map((item, index) => item.couponCode).filter((item)=> item).join(',') ?
                                                        orderModelDetails.orderItems.map((item, index) => item.couponCode).filter((item)=> item).join(',') :
                                                        'N/A'}</td>
                                                <td> €{orderModelDetails.sellerTotal}</td>
                                                <td> {orderModelDetails.orderItems.map((item, index) => item.receiveMethod ? ePaymentMethods[item.receiveMethod] : '')[0]}</td>
                                                <td> {orderModelDetails.orderItems.map((item, index) => item.receiveDetails ? item.receiveDetails : '')[0]}</td>
                                                <td> {orderModelDetails.sellerPoints}</td>
                                            </tr>
                                            : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {batchOfferModels.length ?
                            <div className="order-details-info-box">
                                <div className="order-details-info-header">{t('Offer_Information')}</div>
                                <div className="mt-2">
                                    <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>{t('Created_Date')}</th>
                                                <th>{t('OfferType')}</th>
                                                <th>{t('Price')}</th>
                                                <th>{t('Status')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {batchOfferModels.map((item,index) => 
                                            <tr>
                                                <td> {moment(batchOfferModels[index].createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                                <td> {eOfferType[batchOfferModels[index].type]}</td>
                                                <td> €{batchOfferModels[index].price}</td>
                                                <td> {eOfferStatus[batchOfferModels[index].status]}</td>
                                            </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : null}
                        <div className="order-details-info-box">
                            <div className="order-details-info-header">{t('Shippment_Informations')}</div>
                            <div className="d-flex align-items-start mb-3 od-row">
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('N_Packages')}</div>
                                    <b>{labelDetail.parcels.length}</b>
                                </div>
                            </div>
                            <div className="mt-2">
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Package')}</th>
                                            <th>{t('Height')}</th>
                                            <th>{t('Depth')}</th>
                                            <th>{t('Length')}</th>
                                            <th>{t('Weight')}</th>
                                            <th>{t('Quantity')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            labelDetail.parcels.length ?
                                                labelDetail.parcels.map((parcel, index) => {
                                                    return (<tr>
                                                        <td> {parcel.id}</td>
                                                        <td> {parcel.height}</td>
                                                        <td> {parcel.depth}</td>
                                                        <td> {parcel.length}</td>
                                                        <td> {parcel.weight}</td>
                                                        <td> {parcel.totalPairs}</td>

                                                    </tr>)
                                                }
                                                ) :
                                                null}

                                    </tbody>
                                </table>
                            </div>
                        </div>



                        <ModalDialog toggle={() => onViewDetail()}
                            isOpen={isOpen}
                            enableExecute={false}

                            modalTitle={"Order Item Details"}
                            cancel={onClose}
                            execute={() => { }}
                            executeButtonTitle={() => ""}
                            hideFooter={true}
                            useButtons={t("MD_Cancel")}
                        >
                            {inventorySummary &&
                                <div>
                                    <div className="row mb-2">
                                        <div className="col-4 pe-0"><div className="colon-text">{t('SNo')}</div></div>
                                        <div className="col-8">{sno}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-4 pe-0"><div className="colon-text">{t('Shoemodel')}</div></div>
                                        <div className="col-8">{inventorySummary!.shoeModel.title.replaceAll("/", ">")} ({inventorySummary!.shoeModel.modelName} , {inventorySummary!.shoeModel.modelCode})</div>
                                    </div>
                                    <div className="row mb-2">
                                        <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>{t('Quantity')}</th>
                                                    <th>{t('Size')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {inventorySummary.batchType! == 'lot' &&
                                                    inventorySummary.products &&
                                                    inventorySummary.products.length &&

                                                    inventorySummary.products.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                {item.quantity}
                                                            </td>
                                                            <td> {item.shoeSize?.size} </td>
                                                        </tr>
                                                    )}

                                                {inventorySummary.batchType! == '' &&
                                                    <tr key={0}>
                                                        <td>
                                                            {inventorySummary.totalQuantity}
                                                        </td>
                                                        <td> {inventorySummary.size} </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-4 pe-0"><div className="colon-text">{t('Total_quantity')}</div></div>
                                        <div className="col-8">{inventorySummary.totalQuantity}</div>
                                    </div>
                                    {/* <div className="mt-4 mb-1">
                                        <b>{t`Seller`}</b>
                                    </div> */}

                                </div>
                            }
                        </ModalDialog>
                    </section >

                </div >
            </div >
        </div >
    )
}

export default OrderDetailsPage