import { createSlice } from "@reduxjs/toolkit";
import { BrandFilters, ColorFilters, CouponFilters, ModerationLotsFilters, ModerationRequestsFilters, OrderFilters, ShoeModelFilters, UserFilters, eAppFilters, iAppFilterType, iOrderFilters, iShoeModel, iUserFilters } from "../shared/models/adminModels";
import { CommonUtil } from "../shared/utils/commonUtils";
import * as appConst from "../shared/constants/constants";

export interface iAppFiltersState {
    filterType: eAppFilters,
    filter: iAppFilterType 
}
//Generic
export interface iPageState extends iAppFiltersState {
    currentPage: number,
    pageLimit: number,
    toggleRefresh: boolean
}


const initialState :  iPageState[] = [
    {
        filterType: eAppFilters.user, 
        filter : new UserFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.brand, 
        filter : new BrandFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.shoeModel, 
        filter : new ShoeModelFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.color, 
        filter : new ColorFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.coupon, 
        filter : new CouponFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.order, 
        filter : new OrderFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.b2b_moderation_lots, 
        filter : new ModerationLotsFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.b2b_moderation_requests, 
        filter : new ModerationRequestsFilters(),
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    },
    {
        filterType: eAppFilters.faq, 
        filter : null,
        currentPage : 1,
        pageLimit: appConst.PAGE_LIMIT,
        toggleRefresh: false
    }
]

const appPageSlice = createSlice({
    name: "appPageSlice",
    initialState,
    reducers: {
        savePageFilters: (state: iPageState[], action): iPageState[] => {
            const { filterType, filter, currentPage, pageLimit, toggleRefresh } = action.payload
            return state.map((pageState,index)=> 
                            pageState.filterType !== filterType ?
                            pageState : 
                            { filterType: filterType, 
                                filter: filter, 
                                currentPage: currentPage, 
                                pageLimit: pageLimit ? pageLimit: appConst.PAGE_LIMIT,
                                toggleRefresh: toggleRefresh
                            })
            
        },
        resetPageFilter : (state: iPageState[], action) : iPageState[] =>{
            const { filterType } = action.payload
            let p_CommonUtil = new CommonUtil()
            
            return state.map((pageState,index)=> 
                            pageState.filterType !== filterType ?
                            pageState : 
                            { filterType: filterType, 
                                filter: p_CommonUtil.getInitialFilter(filterType), 
                                currentPage: 1, 
                                pageLimit: appConst.PAGE_LIMIT,
                                toggleRefresh: pageState.toggleRefresh
                            })
        },
        clearPagesState : (state: iPageState[], action) : iPageState[] => {
            return initialState
        }
        // updateSearchResult: (state, action) => {
        //     const { data, loadMore } = action.payload;
        //     return {
        //         ...state,
        //         items: loadMore ? [...state.items, ...data.items] : data.items,
        //         totalRows: data.totalRows,
        //     };
        // },
    },
});
export const appPageSliceActions = appPageSlice.actions;
export default appPageSlice.reducer;
