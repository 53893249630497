import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import { HttpSettingService } from "../../../../services/HttpSettingService";
//import LoadingSpinner from "../../../../shared/components/LoadingSpinner";
//import { FAQ, FAQResponse } from "../../../../shared/models/commonInterfaces";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { HttpSettingService } from "../../../services/admin.service";
import { FAQ, FAQResponse } from "../../../shared/models/adminModels";
import LoadingSpinner from "./LoadingSpinner";
//import LoadingSpinner from "./LoadingSpinner";
interface faqParams {
  id:string
}
const Faq = (params: faqParams) => {
  const { t } = useTranslation();
  const httpSettingService: HttpSettingService = new HttpSettingService();
  const [faq, setFaq] = useState<FAQ[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  
  const [searchParams, setSearchParams] = useSearchParams();
  //let id : string | null = searchParams!.get('id') ? searchParams!.get('id') : ''
  console.log('--id', params.id)
  //const [id, setId] = useState<number>()
  useEffect(() => {
    setLoading(true);
    httpSettingService
      .getAllFAQ<FAQResponse>({ currentPage: 1, pageLimit: 10 })
      .then((response) => {
        setFaq(response.data.items);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="accordion" id="accordionExample">
      <h6>{t("FAQ")}</h6>
      {!loading && !error ? (
        <>
          {faq.length ? (
            <>
              {faq.map((item, index) => (
                <div className="accordion-item" key={item.id}>
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${item.id}`}
                      //aria-expanded="false"
                      aria-expanded={ params.id && item.id  == +params.id ?"true" : "false" }
                      aria-controls={`collapse${item.id}`}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${item.id}`}
                    className={ params.id && item.id  == +params.id ?"accordion-collapse collapse show" : "accordion-collapse collapse" }
                    //className={ "accordion-collapse collapse "  }
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>{item.answer}</p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>No FAQ available!</>
          )}
        </>
      ) : (
        <>{error ? <>{error}</> : <LoadingSpinner />}</>
      )}
    </div>
  );
};

export default Faq;
