import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import { ReactNode, useEffect, useState, useCallback, useMemo } from "react";
import { useParams } from 'react-router-dom'

import { HttpLabelService, HttpOrdersService, HttpOrderHistoryService } from '../../../services/admin.service'
import { iAddress, iOrderModel, iOrderHistory, iUserInfo, eOrderStatusType, iLabel, ePaymentMethods, iInventorySummary, iOrderFilters, iBatchOfferModel, eOfferType, eOfferStatus, iWarehouseb2b, iBatchModel, iImageModel } from '../../../shared/models/adminModels'
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import moment from 'moment';
import useModal from "../../../hooks/general";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import { HttpWarehouseb2bService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import { CommonUtil } from "../../../shared/utils/commonUtils";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router";
const Warehouseb2bViewPage = () => {

    const navigate = useNavigate();
    const { t } = useTranslation()
    const { isOpen, toggle } = useModal()
    const p_CommonUtil = new CommonUtil()
    const { type } = useParams()
    console.log('type', type)
    // const [warehouseb2bDetail, setWarehouseb2bDetail] = useState<iWarehouseb2b>()
    const location = useLocation();
    const warehouseb2bDetail = useMemo(() => {
        return location.state;
    }, [location]);

    const httpWarehouseb2bService: HttpWarehouseb2bService = new HttpWarehouseb2bService();
    const [batchModelDetails, setBatchModelDetails] = useState<iBatchModel>()

    useEffect(() => {
        if (!warehouseb2bDetail) return
        httpWarehouseb2bService.get<iBatchModel>({ id: warehouseb2bDetail.id })
            .then((value) => {
                setBatchModelDetails(value.data)
                console.log('==++ value', value.data)
            })
            .catch((error) => console.log(error))
            ;
    }, [warehouseb2bDetail])

    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h3>{t('Warehouse_B2B')} - {type}</h3>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item"><a href="/admin/warehouseb2b/list">{t('Manage_Warehouse_B2B')}</a></li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        {/* <div className="d-flex justify-content-end align-items-center order-details-filter">
                            <div className="d-flex align-items-center me-2 col-status">
                                <div className="colon-text sso-txt">{t('Seller_Status_Order')}</div>
                                {populateOrderStatusType(orderModelDetails.status!)}
                            </div>
                            <div className="d-flex align-items-center me-2 col-status">
                                <div className="colon-text note-txt">{t('Note')}</div>
                                <input type="text" className="form-control"
                                    value={orderModelDetails.description}
                                    onChange={(evt) =>
                                        setOrderModelDetails({ ...orderModelDetails, description: evt.target.value })}
                                />
                            </div>
                            <div>
                                <Button className="btn btn-primary" variant="primary"
                                    disabled={orderStatusChanged ? false : true}
                                    onClick={() => onExecuteEditOrder()}>{t('Save')}</Button>
                            </div>
                        </div> */}
                        <div className="order-details-info-box">
                            <div>
                                <div className="order-details-info-header">{type == 'Lot' ? t('Seller_Informations') : t('Buyer_Informations')}</div>
                                <div className="d-flex align-items-center mb-3 od-row">
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Id')}</div>
                                        <a href="javascript:void(0)"
                                            onClick={()=>navigate('/admin/users/detail/' + batchModelDetails?.seller?.id)}
                                            ><b>{batchModelDetails?.seller?.id}</b></a>
                                    </div>
                                    {/* <div className="d-flex od-column">
                                        <div className="colon-text">{t('Sales_Price')}</div>
                                        <b>{type == 'Lot' ? t(`Seller_Info`) : t(`Buyer_Info`)}</b>
                                    </div> */}
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Name')}</div>
                                        <b>{batchModelDetails?.seller?.firstName + ' ' + batchModelDetails?.seller?.lastName}</b>
                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Email')}</div>
                                        <b>{batchModelDetails?.seller?.email}</b>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="order-details-info-box">
                            <div>
                                <div className="order-details-info-header">{t('Product_Informations')}</div>
                                <div className="d-flex align-items-start mb-3 od-row">
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{type == 'Lot' ? t('Lot_Id') : t('Request_Id')}</div>
                                        <b>{warehouseb2bDetail?.id}</b>
                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Avg_priceperpair')}</div>
                                        <b>{warehouseb2bDetail?.averagePricePerPair}</b>
                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Release_date')}</div>
                                        <b>{
                                            moment(batchModelDetails?.shoeModel?.releaseDate).format('DD/MM/YYYY')
                                        }</b>
                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Color')}</div>
                                        <b>{warehouseb2bDetail?.shoeModel?.color}</b>

                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Exit_Price')}</div>
                                        <b>{warehouseb2bDetail?.shoeModel?.exitPrice}</b>

                                    </div>
                                    <div className="d-flex od-column">
                                        <div className="colon-text">{t('Description')}</div>
                                        <b>{warehouseb2bDetail?.shoeModel?.description}</b>

                                    </div>
                                </div>
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Batch_Moderation_Type')}</th>
                                            <th>{t('Thumbnail')}</th>
                                            <th>{t('Model_Name')}</th>
                                            <th>{t('Quantity')}</th>
                                            <th>{t('Size')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{type}</td>
                                            <td style={{verticalAlign:'middle', alignContent: 'center'}}>
                                                {/* <img width={'65%'} height={'80px'} src={batchModelDetails?.shoeModel?.images?.filter((imgage: iImageModel) => imgage.isDefault)[0].url} alt="shoeimg" /> */}
                                                <img width={'100px'} height={'100px'} src={batchModelDetails?.shoeModel?.images?.filter((imgage: iImageModel) => imgage.isDefault)[0].url} alt="shoeimg" />
                                            </td>
                                            <td>
                                                {batchModelDetails?.shoeModel?.title?.replaceAll("/", ">")} ({batchModelDetails?.shoeModel?.modelName} , {batchModelDetails?.shoeModel?.modelCode})
                                            </td>
                                            {/* Total Quantity */}
                                            <td>
                                                {warehouseb2bDetail?.totalQuantity}
                                            </td>
                                            <td>
                                                <div>{batchModelDetails?.products?.length &&
                                                    batchModelDetails?.products.map((product, i) =>

                                                        <>
                                                            <span>{' ' + product.quantity + 'x' + ' '}</span>
                                                            <b>{product.shoeSize?.size}</b>
                                                        </>

                                                    )}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>

                        <div className="order-details-info-box">
                            <div className="order-details-info-header">{t('Seller_Fees')}</div>
                            <div className="d-flex align-items-start mb-3 od-row">

                                {/* <div className="mt-2"> */}
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Original_Price')}</th>
                                            {/* <th>{t('Purchase_Price')}</th> */}
                                            <th>{t('Seller_Fees_Percentage')}</th>
                                            <th>{t('Fees')}</th>
                                            <th>{t('Seller_Payment_Fees_Percentage')}</th>
                                            <th>{t('Payment_Fees')}</th>
                                            <th>{t('Shipping_Fees')}</th>
                                            {/* <th>{t('Coupon_Applied')}</th> */}
                                            {/* <th>{t('Total_Price')}</th> */}
                                            <th>{t('Receiving_Method')}</th>
                                            {/* <th>{t('Payment_Reference')}</th> */}
                                            {/* <th>{t('Invoice_Request')}</th> */}
                                            <th>{t('Rewards')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> €{batchModelDetails?.totalPrice}</td>
                                            {/* <td> €{batchModelDetails.salePrice}</td> */}
                                            <td> {batchModelDetails?.sellerFeesPercent}</td>
                                            <td> €{batchModelDetails?.sellerFees}</td>
                                            <td> {batchModelDetails?.sellerPaymentFeesPercent}</td>
                                            <td> €{batchModelDetails?.sellerPaymentFees}</td>
                                            <td> €{batchModelDetails?.sellerShippingFees}</td>
                                            {/* <td> {''}</td> */}
                                            {/* <td> €{batchModelDetails?.sellerTotal}</td> */}
                                            <td> {batchModelDetails?.receiveMethod ? ePaymentMethods[batchModelDetails?.receiveMethod] : ''}</td>
                                            {/* <td> {batchModelDetails?.receiveReference}</td> */}
                                            <td> {batchModelDetails?.rewardPoints}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* </div> */}
                            </div>
                        </div>

                        <div className="order-details-info-box">
                            <div className="order-details-info-header">{t('Buyer_Fees')}</div>
                            <div className="mt-2">
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Original_Price')}</th>
                                            {/* <th>{t('Purchase_Price')}</th> */}
                                            <th>{t('Buyer_Fees_Percentage')}</th>
                                            <th>{t('Fees')}</th>
                                            <th>{t('Buyer_Payment_Fees_Percent')}</th>
                                            <th>{t('Payment_Fees')}</th>
                                            <th>{t('Shipping_Fees')}</th>
                                            {/* <th>{t('Coupon_Applied')}</th>
                                            <th>{t('Total_Price')}</th>
                                            <th>{t('Payment_Method')}</th>
                                            <th>{t('Payment_Reference')}</th>
                                            <th>{t('Invoice_Request')}</th>
                                            <th>{t('Rewards')}</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> €{batchModelDetails?.totalPrice}</td>
                                            {/* <td> €{orderModelDetails.salePrice}</td> */}
                                            <td> {batchModelDetails?.buyerFeesPercent}</td>
                                            <td> €{batchModelDetails?.buyerFees}</td>
                                            <td> {batchModelDetails?.buyerPaymentFeesPercent}</td>
                                            <td> €{batchModelDetails?.buyerPaymentFees}</td>
                                            <td> €{batchModelDetails?.buyerShippingFees}</td>
                                            {/* <td> {orderModelDetails.couponCode ? orderModelDetails.couponCode : 'N/A'}</td>
                                                <td> €{orderModelDetails.buyerTotal}</td>
                                                <td> {orderModelDetails.paymentMethod}</td>
                                                <td> {orderModelDetails.paymentReference}</td>
                                                <td> {orderModelDetails.invoice ? 'Yes' : 'No'}</td>
                                                <td> {orderModelDetails.buyerPoints}</td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*
                        {batchOfferModels.length ?
                            <div className="order-details-info-box">
                                <div className="order-details-info-header">{t('Offer_Information')}</div>
                                <div className="mt-2">
                                    <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                        <thead>
                                            <tr>
                                                <th>{t('Created_Date')}</th>
                                                <th>{t('OfferType')}</th>
                                                <th>{t('Price')}</th>
                                                <th>{t('Status')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {batchOfferModels.map((item,index) => 
                                            <tr>
                                                <td> {moment(batchOfferModels[index].createdAt).format('DD/MM/YYYY hh:mm:ss A')}</td>
                                                <td> {eOfferType[batchOfferModels[index].type]}</td>
                                                <td> €{batchOfferModels[index].price}</td>
                                                <td> {eOfferStatus[batchOfferModels[index].status]}</td>
                                            </tr>
                                            )}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : null}
                        <div className="order-details-info-box">
                            <div className="order-details-info-header">{t('Shippment_Informations')}</div>
                            <div className="d-flex align-items-start mb-3 od-row">
                                <div className="d-flex od-column">
                                    <div className="colon-text">{t('N_Packages')}</div>
                                    <b>{labelDetail.parcels.length}</b>
                                </div>
                            </div>
                            <div className="mt-2">
                                <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Package')}</th>
                                            <th>{t('Height')}</th>
                                            <th>{t('Depth')}</th>
                                            <th>{t('Length')}</th>
                                            <th>{t('Weight')}</th>
                                            <th>{t('Quantity')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            labelDetail.parcels.length ?
                                                labelDetail.parcels.map((parcel, index) => {
                                                    return (<tr>
                                                        <td> {parcel.id}</td>
                                                        <td> {parcel.height}</td>
                                                        <td> {parcel.depth}</td>
                                                        <td> {parcel.length}</td>
                                                        <td> {parcel.weight}</td>
                                                        <td> {parcel.totalPairs}</td>

                                                    </tr>)
                                                }
                                                ) :
                                                null}

                                    </tbody>
                                </table>
                            </div>
                        </div> */}




                    </section >

                </div >
            </div >
        </div >
    )
}

export default Warehouseb2bViewPage