export const BASE_URL = `${process.env.REACT_APP_API_URL}/`;

export const SPREEDLY_ENV_TOKEN = "XRKxLW22ggvKprrf16FdntrvtHv";
export const PAGE_LIMIT = 10;
export const PAGE_LIMIT_COLOR_MULTISELECT = 200;
export const PAGE_LIMIT_ALL_BRANDS = 200;
export const PAGE_LIMIT_SHOE_MODELS = 200;
export const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_API_URL}`,
  },
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  withCredentials: true
};

//-------done
export const axiosAdminConfig = {

  headers: {
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_API_URL}`,
  },
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  withCredentials: true
};
//----- done
export const axiosAdminAuthConfig = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_API_URL_AUTH}`,
  },
  baseURL: `${process.env.REACT_APP_API_URL_AUTH}/api`,
  withCredentials: true
};
//----- done
export const axiosAdminOrderConfig = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_API_URL_ORDERS}`,
  },
  baseURL: `${process.env.REACT_APP_API_URL_ORDERS}/api`,
  withCredentials: true
};

export const axiosAdminConfigFileUpload = {
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": `${process.env.REACT_APP_API_URL}`,
  },
  baseURL: `${process.env.REACT_APP_API_URL}/admin`,
  withCredentials: true
};

export const DROPDOWN_VALUE_FOR_BOOLEAN = [
  { name: "False", value: 0 },
  { name: "True", value: 1 },
]