import { useEffect } from "react";
type Event = MouseEvent | TouchEvent | KeyboardEvent;

const useOnClickOutsideInside = <T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  outsideHandler: (event: Event) => void,
  insideHandler: (event: Event) => void
) => {
  useEffect(() => {
    //if(!ref) return
    const listener = (event: Event) => {
      const el = ref?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        // event.stopPropagation()
        insideHandler(event)
        return;
      }
      outsideHandler(event);
    };
    //document.addEventListener("mouseenter", listener);
    //document.addEventListener("mouseleave", listener);
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      //document.removeEventListener("mouseenter", listener);
      //document.removeEventListener("mouseleave", listener);
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, outsideHandler, insideHandler]);
};

export default useOnClickOutsideInside;
