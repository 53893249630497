import { Sidebar } from "../Sidebar";
import React, { ReactNode, useEffect, useState } from "react";
import {
  eBeneficiaryType,
  iRewardsLevel,
} from "../../../shared/models/adminModels";
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general";
import { AxiosError } from "axios";
import Pagination from "../../../shared/components/Pagination";
import * as appConst from "../../../shared/constants/constants";
import "react-quill/dist/quill.snow.css";
import { HttpRewardsLevelService } from "../../../services/admin.service";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const RewardsLevelPage = () => {
  const { t } = useTranslation();
  // -------------- Pagination --------------------
  const [totalRewardsLevelResults, setTotalRewardsLevelResults] = useState(-1);
  const [totalRewardsLevelPages, setTotalRewardsLevelPages] = useState(-1);
  const [currentRewardsLevelPage, setCurrentRewardsLevelPage] = useState(1);
  // -------------- Pagination End --------------------

  //For Modal Dialog
  const { isOpen, toggle } = useModal();
  //Warning
  const initialRewardsLevel: iRewardsLevel = {
    id: -1,
    name: "",
    active: true,
    fromPoints: 0,
    // toPoints: 0,
    // beneficiaryType: eBeneficiaryType.All,
    rewardLevel: 0,
  };
  const [rewardsLevelToSave, setRewardsLevelToSave] =
    useState<iRewardsLevel>(initialRewardsLevel);
  const [rewardsLevel, setRewardsLevel] = useState<iRewardsLevel[]>([]);
  const [currentState, setCurrentState] = useState<number>(0); // 0- add, 1-Edit
  const [loading, setLoading] = useState<boolean>(false);
  const [dataEdited, setDataEdited] = useState<boolean>(false);
  const httpRewardsService: HttpRewardsLevelService =
    new HttpRewardsLevelService();

  const refreshRewardsLevel = (currentPage = 1) => {
    httpRewardsService
      .getAll<iRewardsLevel>({
        currentPage: currentPage,
        pageLimit: appConst.PAGE_LIMIT,
      })
      .then((value: any) => {
        if (value.data.items) {
          setRewardsLevel(value.data.items);
          setTotalRewardsLevelResults(value.data.totalRows);
          setTotalRewardsLevelPages(
            Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT)
          );
        } else {
          setRewardsLevel(value.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    refreshRewardsLevel();
  }, []);

  const onDeleteRewardClick = async (val: iRewardsLevel) => {
    if (window.confirm(t`Delete_confirm_msg` ?? "")) {
      await httpRewardsService
        .delete<iRewardsLevel>(val.id)
        .then((value: any) => {
          toast.success(t`Delete_success_msg`);
          refreshRewardsLevel();
        })
        .catch((error: any) => console.log(error));
    }
  };

  const onEditRewardClick = (rewardsLevel: iRewardsLevel) => {
    setCurrentState(1); // 0- add, 1-Edit, 2 -Photos
    setRewardsLevelToSave(rewardsLevel);
    toggle();
  };

  const renderRewardsLevel = (): ReactNode[] => {
    if (!rewardsLevelToSave) return [];

    const returnNode: ReactNode[] = rewardsLevel?.map(
      (reward: iRewardsLevel, index: number) => (
        <tr key={reward.id}>
          <td>{reward.id}</td>
          <td>{reward.name}</td>
          <td>{reward.fromPoints}</td>
          <td>{reward.rewardLevel}</td>
          <td>{reward.active ? "true" : "false"}</td>
          <td>
            {
              <>
                <a
                  onClick={() => onEditRewardClick(reward)}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit"
                  data-original-title="Edit"
                  className="btn btn-bulk small me-1"
                  data-rel="tooltip"
                >
                  <i className="la la-pencil-square"></i>
                </a>
              </>
            }
          </td>
        </tr>
      )
    );
    return returnNode;
  };

  const onAddRewardClick = () => {
    setCurrentState(0);
    let rewardsLevelData = initialRewardsLevel;
    if (rewardsLevel.length > 0) {
      rewardsLevelData = {
        ...initialRewardsLevel,
        name: `Level`,
        rewardLevel: rewardsLevel.length + 1,
        id: 0,
        fromPoints: 0,
      };
    } else {
      rewardsLevelData = {
        ...initialRewardsLevel,
        name: `Level`,
        rewardLevel: 1,
        id: 1,
        fromPoints: 0,
      };
    }
    setRewardsLevelToSave(rewardsLevelData);
    toggle();
  };
  const cancelAddEdit = () => {
    setCurrentState(0); // 0- add, 1-Edit, 2 -Photos
    setRewardsLevelToSave({ ...initialRewardsLevel });
    toggle();
  };
  const onExecuteAddEditReward = async () => {
    setDataEdited(true);
    if (currentState === 0) {
      setRewardsLevel((prev) => {
        return [...prev, rewardsLevelToSave];
      });
    } else if (currentState === 1) {
      const existingId = rewardsLevel.findIndex(
        (reward) => reward.id === rewardsLevelToSave.id
      );
      const existingRewards = [...rewardsLevel];
      existingRewards[existingId] = rewardsLevelToSave;
      setRewardsLevel(existingRewards);
    }
    toggle();
  };

  const onPageChanged = (data: any) => {
    setCurrentRewardsLevelPage(data.currentPage);
    refreshRewardsLevel(data.currentPage);
  };

  const onSave = () => {
    setLoading(true);
    httpRewardsService
      .updatePUT(rewardsLevel)
      .then((response) => {
        if (response) {
          refreshRewardsLevel();
          setDataEdited(false);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const confirmExit = (event: BeforeUnloadEvent) => {
      if (dataEdited) {
        event.preventDefault();
        // warningToggle();
        // // return "You have unsaved changes. Are you sure you want to leave?";
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", confirmExit);

    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, [dataEdited]);

  return (
    <div id="app">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">
          {/* == Begin - New Changes ==*/}
          <section className="page-content container-fluid">
            <div className="admin-header">
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-6">
                  <h3>{t("Reward_level")}</h3>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                  <div className="action-btn">
                    <a
                      onClick={() => onAddRewardClick()}
                      href="javascript:void(0)"
                      className="bulk-primary-btn bulk-sm add-shoe-model"
                      data-toggle="modal"
                      data-target="#addReward"
                    >
                      {t("Add_Rewards_Level")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="shoe-model-grid">
              <table
                id="dataGrid"
                className="table table-striped table-bordered table-hover"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>{t("Id")}</th>
                    <th>{t("Level_name")}</th>
                    <th>{t("From_points")}</th>
                    <th>{t("Reward_level")}</th>
                    <th>{t("Is_active")}</th>
                    <th>{t("Action")}</th>
                  </tr>
                </thead>

                <tbody>{renderRewardsLevel()}</tbody>
              </table>
            </div>
            <div className="nav-direction">
              {totalRewardsLevelResults > appConst.PAGE_LIMIT &&
              totalRewardsLevelPages ? (
                <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                  <Pagination
                    totalRecords={totalRewardsLevelResults}
                    totalPages={totalRewardsLevelPages}
                    currentPage={currentRewardsLevelPage}
                    pageLimit={appConst.PAGE_LIMIT}
                    pageNeighbours={1}
                    onPageChanged={onPageChanged}
                  />
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-end action-btn-main">
              <div className="action-btn">
                <a
                  onClick={() => onSave()}
                  href="javascript:void(0)"
                  className="bulk-primary-btn bulk-sm add-shoe-model"
                >
                  {loading ? <>Saving...</> : <>Save</>}
                </a>
              </div>
            </div>
            <ModalDialog
              toggle={() => onAddRewardClick()}
              cancel={() => cancelAddEdit()}
              executeButtonTitle={() =>
                currentState == 0 ? t("MD_Add") : t("MD_Update")
              }
              isOpen={isOpen}
              execute={onExecuteAddEditReward}
              enableExecute={
                rewardsLevelToSave.fromPoints > 0 &&
                rewardsLevelToSave.name.length > 0
              }
              modalTitle={
                currentState == 0
                  ? t("Add_Rewards_Level")
                  : t("Edit_Rewards_Level")
              }
              useButtons={t("MD_Cancel") + '/' +  (currentState == 0 ? t("MD_Add") : t('MD_Update'))}
            >
              <div className="row">
                <div className="col-sm-6 mb-2">
                  <div className="form-group">
                    <label>{t("Level_name")}:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={rewardsLevelToSave.name}
                      onChange={(evt) =>
                        setRewardsLevelToSave({
                          ...rewardsLevelToSave,
                          name: evt.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 mb-2">
                  <div className="form-group">
                    <label>{t("Reward_level")}:</label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      value={rewardsLevelToSave.rewardLevel}
                      onChange={(evt) =>
                        setRewardsLevelToSave({
                          ...rewardsLevelToSave,
                          name: evt.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-sm-6 mb-2">
                  <div className="form-group">
                    <label>{t("From_points")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        rewardsLevelToSave.fromPoints > 0
                          ? rewardsLevelToSave.fromPoints
                          : ""
                      }
                      onChange={(evt) => {
                        if (isNaN(+evt.target.value)) {
                          return;
                        }
                        setRewardsLevelToSave({
                          ...rewardsLevelToSave,
                          fromPoints: +evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group mb-0">
                    <input
                      type="checkbox"
                      id=""
                      name="model_name"
                      checked={rewardsLevelToSave.active}
                      value={rewardsLevelToSave.active ? 1 : 0}
                      onChange={(evt) =>
                        setRewardsLevelToSave({
                          ...rewardsLevelToSave,
                          active: +evt.target.value == 1 ? false : true,
                        })
                      }
                    />
                    <label className="ms-2">{t("Is_active")}</label>
                  </div>
                </div>
              </div>
            </ModalDialog>
          </section>
          {/* == End- New Changes ==*/}
        </div>
      </div>
    </div>
  );
};

export default RewardsLevelPage;
