import { Sidebar } from "../Sidebar";
import React, { useEffect, useState } from "react";
import { iLegalText, iSetting } from "../../../shared/models/adminModels";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import useModal from "../../../hooks/general";
import { AxiosError } from "axios";
import QuillEditor from "../../../shared/components/QuillEditor";
import {
  HttpLegalService,
  HttpSettingService,
} from "../../../services/admin.service";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const OverlayNotificationPage = () => {
  const { t } = useTranslation();
  //For Modal Dialog
  const { isOpen, toggle } = useModal();
  const initialPrivacyPolicy: iLegalText = {
    key: "OverlayNotification",
    content: "",
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [overlayNotificationToSave, setOverlayNotificationToSave] =
    useState<iLegalText>(initialPrivacyPolicy);
  const [overlayNotification, setOverlayNotification] = useState<iLegalText>();
  const [dataEdited, setDataEdited] = useState<boolean>(false);
  const httpSettingService: HttpSettingService = new HttpSettingService();
  const [settings, setSettings] = useState<iSetting[]>([]);

  const httpLegalService: HttpLegalService = new HttpLegalService();

  const getOverlayNotification = () => {
    setLoading(true);
    httpLegalService
      .get("OverlayNotification")
      .then((value: any) => {
        setOverlayNotification({
          key: "OverlayNotification",
          content: value.data,
        });
        setOverlayNotificationToSave({
          key: "OverlayNotification",
          content: value.data,
        });
      })
      .catch((error) => setError(error.message))
      .finally(() => {
        setLoading(false);
      });
  };

  const loadSettings = () => {
    httpSettingService
      .getAll<iSetting>()
      .then((value) => {
        setSettings(
          value.data.filter((setting) => setting.keyName === "Overlay")
        );
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const saveSettings = (settings: iSetting[]) => {
    httpSettingService
      .post(settings)
      .then(() => toast.success(t`Save_success_msg`))
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getOverlayNotification();
    loadSettings();
  }, []);

  const onEditOverlayNotificationClick = () => {
    toggle();
    setDataEdited(true);
    if (overlayNotification)
      setOverlayNotificationToSave({
        ...initialPrivacyPolicy,
        content: overlayNotification.content,
      });
  };
  const cancelAdd = () => {
    setOverlayNotificationToSave(initialPrivacyPolicy);
    toggle();
  };
  const updateOverLayNotification = () => {
    setOverlayNotification(overlayNotificationToSave);
    toggle();
  };
  const onExecuteOverlayNotification = () => {
    setDataEdited(false);
    setLoading(true);
    httpLegalService
      .updatePUT(overlayNotificationToSave)
      .then((response) => {
        if (response) {
          toast.success(t`Edit_success_msg`);
          getOverlayNotification();
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const confirmExit = (event: BeforeUnloadEvent) => {
      if (dataEdited) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };
    window.addEventListener("beforeunload", confirmExit);
    return () => {
      window.removeEventListener("beforeunload", confirmExit);
    };
  }, [dataEdited]);
  return (
    <div id="app">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">
          {/* == Begin - New Changes ==*/}
          <section className="page-content container-fluid custom-modal-lg">
            <div className="admin-header">
              <div className="row">
                <div className="col-md-6 col-12 pe-0">
                  <h3>{t("Overlay_Notification")}</h3>
                  <div className="switch-control">
                    <div className="form-check form-switch d-flex align-items-center">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        onChange={() => {
                          const updatedToValue =
                            settings[0].toValue === "on" ? "off" : "on";
                          const updatedSettings = [
                            {
                              ...settings[0],
                              toValue: updatedToValue,
                            },
                          ];
                          saveSettings(updatedSettings);
                          setSettings(updatedSettings);
                        }}
                        checked={settings[0]?.toValue === "on" ? true : false}
                      />
                      <label className="form-check-label ms-2">
                        {/* Toggle to switch on/off overlay notification */}
                        Overlay notification turned{" "}
                        {settings[0]?.toValue === "on" ? "on" : "off"}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12 text-md-end text-start mt-md-0 mt-3">
                  <div className="action-btn">
                    <a
                      onClick={() => onEditOverlayNotificationClick()}
                      href="javascript:void(0)"
                      className="bulk-primary-btn bulk-sm add-shoe-model text-center"
                      data-toggle="modal"
                      data-target="#addPrivacyPolicyModel"
                    >
                      {t("Edit_Overlay_Notification")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {!loading && !error ? (
              <>
                <div className="overlay-grid">
                  <table
                    id="dataGrid"
                    className="table table-striped table-bordered table-hover"
                    width="100%"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th>{t("Text")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {overlayNotification && (
                        <tr key={overlayNotification.key}>
                          <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                              __html: overlayNotification.content,
                            }}
                          ></div>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="action-btn">
                    <a
                      onClick={onExecuteOverlayNotification}
                      href="javascript:void(0)"
                      className="bulk-primary-btn bulk-sm add-shoe-model"
                    >
                      {t("Publish")}
                    </a>
                  </div>
                </div>
                <ModalDialog
                  toggle={() => onEditOverlayNotificationClick()}
                  cancel={() => cancelAdd()}
                  executeButtonTitle={() => {
                    return t("MD_Update");
                  }}
                  isOpen={isOpen}
                  execute={updateOverLayNotification}
                  enableExecute={
                    overlayNotificationToSave.content ? true : false
                  }
                  modalTitle={t("Edit_Overlay_Notification")}
                  useButtons={t("MD_Cancel") + '/'  + t("MD_Update")}
                >
                  <div className="row">
                    <div className="col-sm-12 mb-2">
                      <div className="form-group">
                        <label>{t("Overlay_Notification")}:</label>
                        <QuillEditor
                          value={overlayNotificationToSave.content}
                          onChange={(val: string) => {
                            setOverlayNotificationToSave({
                              ...overlayNotificationToSave,
                              content: val,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </ModalDialog>
              </>
            ) : (
              <>{error ? <>{error}</> : <div>Loading...</div>}</>
            )}
          </section>
          {/* == End- New Changes ==*/}
        </div>
      </div>
    </div>
  );
};

export default OverlayNotificationPage;
