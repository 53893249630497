import { useLocation, useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iOrderModel, iOrderFilters, eOrderStatusType, iInventorySummary, OrderFilters, eAppFilters } from '../../../shared/models/adminModels'
import { HttpOrdersService } from '../../../services/admin.service'
import 'react-datepicker/dist/react-datepicker.css'
import * as appConst from '../../../shared/constants/constants'
import Pagination from '../../../shared/components/Pagination';
import DatePicker from 'react-datepicker';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/stateTypes";
import { useDispatch } from "react-redux";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import CustomPagination from "../../../shared/components/CustomPagination";
const OrdersPage = () => {
    // const [searchParams, setSearchParams] = useSearchParams();
    // const currentPage = searchParams.get('currentPage');
    // -------------- Pagination --------------------
    const [totalOrderModelResults, setTotalOrderModelResults] = useState(-1);
    const [totalOrderModelPages, setTotalOrderModelPages] = useState(-1)
    // -------------- Pagination End --------------------

    const navigate = useNavigate();
    const { t } = useTranslation()

    const [orderModels, setOrderModels] = useState<iOrderModel[]>([])
    const httpOrdersService: HttpOrdersService = new HttpOrdersService();
    const [maskOrderModelsEvent, setMaskOrderModelsEvent] = useState<boolean>(false);

    // interface propState { filter: iOrderFilters }
    // const location = useLocation();
    // let filter: iOrderFilters | null = null
    // if(location.state)   filter  = (location.state as propState).filter;
    // console.log('Orderlist filter', filter!)

    //const p_OrderFilters: iOrderFilters = new OrderFilters()
    //const [orderFilters, setOrderFilters] = useState<iOrderFilters>(p_OrderFilters)
    const orderPageState : iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.order));
    const dispatch = useDispatch();

    const onResetFilter = () => {
        dispatch(appPageSliceActions.resetPageFilter({filterType: eAppFilters.order}))
        // setOrderFilters(p_OrderFilters);
    }
    const refreshOrders = (currentPage = 1) => {
            setTotalOrderModelResults(0)
            setTotalOrderModelPages(0)
            httpOrdersService.filterBy({ currentPage: orderPageState?.currentPage, pageLimit: orderPageState!.pageLimit }, 
                                        orderPageState?.filter)
                .then((value) => {
                    setOrderModels(value.items)
                    console.log('value.items', value.items)
                    console.log('totalRows', value.totalRows)
                    console.log('pages', Math.ceil(Math.ceil(value.totalRows / orderPageState!.pageLimit)))
                    setTotalOrderModelResults(value.totalRows)
                    setTotalOrderModelPages(Math.ceil(value.totalRows / orderPageState!.pageLimit))
                })
    }
    
    useEffect(() => {
        console.log('orderFilters', orderPageState?.filter)
        refreshOrders()
    }, [orderPageState?.filter])

    useEffect(() => {
        //console.log('orderFilters', orderPageState?.filter)
        console.log('orderPageState?.pageLimit', orderPageState?.pageLimit)
        refreshOrders()
    }, [orderPageState?.currentPage,orderPageState?.pageLimit])

    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...orderPageState!,
            currentPage: data.currentPage
        }))
    }

    interface iOrderInventory {
        inventoryArr: iInventorySummary[]
        orderId: number
    }
    



    const gotoEditOrder = (p_iOrderModel: iOrderModel) => {
        navigate('/admin/orders/details/' + p_iOrderModel.id)
    }

    const renderOrders = (): ReactNode[] => {
        console.log(orderModels?.length)
        const returnNode: ReactNode[] = orderModels!?.map((orderModel: iOrderModel) => (
            <>
                {orderModel.orderItems.length &&
                    orderModel.orderItems.map((value, index, arr) =>
                        <tr key={index}>
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length}>{orderModel.id}</td> : <></>}
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length} style={{ whiteSpace: "pre" }}>{moment(orderModel.createdAt).format('DD/MM/YYYY[\n]hh:mm:ss A')}</td> : <></>}
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length}>{orderModel.type}</td> : <></>}
                            <td style={{verticalAlign:'middle', alignContent: 'center'}}>
                                {/* <img className="thumbnail-col" src={value.shoeModel.defaultImage} alt="shoeimg" /> */}
                                <img width={'100px'} height={'100px'} src={value.shoeModel.defaultImage} alt="shoeimg" />
                            </td>
                            <td>
                                {value.shoeModel.title.replaceAll("/", ">")} ({value.shoeModel.modelName} , {value.shoeModel.modelCode})
                            </td>
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length} style={{ whiteSpace: "pre" }}>{'ID ' + orderModel.buyer.id}{"\n"}{orderModel.buyer.firstName}{orderModel.buyer.lastName}{"\n"}{orderModel.buyer.email} </td> : <></>}
                            {/* <td style={{ whiteSpace: "pre" }}>{orderModel.address.streetNo}, {orderModel.address.village} {"\n"} {orderModel.address.city} {orderModel.address.country} - {t('Pin')}: {orderModel.address.postalCode} {"\n"} {t('Phone')}:{orderModel.buyer.phone} </td> */}
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length} style={{ whiteSpace: "pre" }}>{'ID ' + orderModel.seller.id}{"\n"}{orderModel.seller.firstName} {orderModel.seller.lastName}{"\n"}{orderModel.seller.email}{"\n"}{orderModel.seller.phone ? t('Phone') + orderModel.seller.phone : ''}</td> : <></>}
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length}>€{orderModel.totalPrice}</td> : <></>}
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length}>{orderModel.status}</td> : <></>}
                            {index == 0 ? <td rowSpan={orderModel.orderItems.length}>{<>
                                <a onClick={() => gotoEditOrder(orderModel)} title="Edit" data-toggle="tooltip" data-placement="top"  data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-pencil-square"></i></a>
                                {/* <a onClick={() => cancelOrderModel(orderModel)} data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-remove"></i></a> */}

                            </>
                            }</td> : <></>}
                        </tr>
                    )}
            </>
        ))
        return returnNode
    }

    // const populateOrderStatusType = (toValueOS: number): ReactNode => {
    //     const keys = Object.keys(eOrderStatusType).filter((v) => isNaN(Number(v)));
    //     return <select onChange={(evt) => { setOrderFilters({ ...orderFilters, status: +evt.target.value }) }} className="form-control" name="OrderStatus_id">
    //         <option value='-1'>Select order status</option>
    //         {keys.length && keys.map((key, index) =>
    //             <option value={index + 2} selected={+toValueOS == index + 2}>
    //                 {key}
    //             </option>)}
    //     </select>
    // }
    const populateOrderStatusType = (toValueOS: eOrderStatusType | '-1'): ReactNode => {
        console.log("populateOrderStatusType toValueOS", toValueOS)
        console.log("populateOrderStatusType", eOrderStatusType[toValueOS])
        const keys = Object.keys(eOrderStatusType).filter((v) => isNaN(Number(v)));
        return <select onChange={(evt) => {
            dispatch(appPageSliceActions.savePageFilters({
                filterType: eAppFilters.order,
                filter: {
                    ...orderPageState!.filter,
                    status: (evt.target.value as any) as eOrderStatusType
                },
                currentPage: 1
            }))
            //setOrderFilters({ ...orderFilters, status: (evt.target.value as any) as eOrderStatusType }) 
        }}
            className="form-control" name="OrderStatus_id">
            <option value='-1'>{t('Order_Status')}</option>
            {keys.length && keys.map((key, index) =>
                <option value={key} selected={+eOrderStatusType[toValueOS] === index + 1}>
                    {key}
                </option>)}
        </select>
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">

                <div className="content">

                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <h3>{t('Orders')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="order-filter mb-3">
                                <div className="row">
                                    <div className="col-sm-3 col-md-3 col-lg-2">
                                        <div className="form-group bulk-datepicker">
                                            <label>{t('Order_ID')}:</label>
                                            <div className="input-group">
                                                <DebounceInput
                                                    className="form-control"
                                                    minLength={1}
                                                    debounceTimeout={500}
                                                    value={(orderPageState?.filter as iOrderFilters).id == -1 ? 
                                                            '' : 
                                                            (orderPageState?.filter as iOrderFilters).id}
                                                    onChange={event => {
                                                        dispatch(appPageSliceActions.savePageFilters({
                                                            filterType: eAppFilters.order,
                                                            filter: {
                                                                ...orderPageState!.filter,
                                                                id: +event.target.value ? +event.target.value : -1
                                                            },
                                                            currentPage: 1
                                                        }))
                                                        //setOrderFilters({ ...orderFilters, id: +event.target.value ? +event.target.value : -1 });
                                                    }}
                                                    placeholder={t('Order_ID')!} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-2">
                                        <div className="form-group bulk-datepicker">
                                            <label>{t('From_Date')}:</label>
                                            <DatePicker
                                                dateFormat={'dd/MM/yyyy'}
                                                //selected={orderFilters.fromDate ? new Date(moment(orderFilters.fromDate).toDate()) : null}
                                                selected={(orderPageState?.filter as iOrderFilters).fromDate ? 
                                                            new Date(moment((orderPageState?.filter as iOrderFilters).fromDate).toDate()):
                                                            null
                                                        }
                                                onChange={(date) => {
                                                    console.log('date', date);
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        filterType: eAppFilters.order,
                                                        filter: {
                                                            ...orderPageState!.filter,
                                                            fromDate: new Date(moment(date).toDate())
                                                        },
                                                        currentPage: 1
                                                    }))
                                                    // setOrderFilters({
                                                    //     ...orderFilters,
                                                    //     fromDate: new Date(moment(date).toDate())
                                                    // })
                                                }}

                                            // maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-2">
                                        <div className="form-group bulk-datepicker">
                                            <label>{t('To_Date')}:</label>
                                            <DatePicker
                                                dateFormat={'dd/MM/yyyy'}
                                                //selected={orderFilters.toDate ? new Date(moment(orderFilters.toDate).toDate()) : null}
                                                selected={(orderPageState?.filter as iOrderFilters).toDate ? 
                                                    new Date(moment((orderPageState?.filter as iOrderFilters).toDate).toDate()):
                                                    null
                                                }
                                                onChange={(date) => {
                                                    console.log('date', date);
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        filterType: eAppFilters.order,
                                                        filter: {
                                                            ...orderPageState!.filter,
                                                            toDate: new Date(moment(date).toDate())
                                                        },
                                                        currentPage: 1
                                                    }))
                                                    // setOrderFilters({
                                                    //     ...orderFilters,
                                                    //     toDate: new Date(moment(date).toDate())
                                                    // })
                                                }}

                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3">
                                        <label>{t('Order_Status')}:</label>
                                        <div className="input-group">
                                            {populateOrderStatusType(
                                                (orderPageState?.filter as iOrderFilters).status!)}
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <label>{t('Buyer_ID')}:</label>
                                        <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                // value={
                                                //     orderFilters.buyerId == -1 ? 
                                                //     '' : 
                                                //     orderFilters.buyerId}
                                                value={
                                                    (orderPageState?.filter as iOrderFilters).buyerId == -1 ? 
                                                    '' : 
                                                    (orderPageState?.filter as iOrderFilters).buyerId}
                                                debounceTimeout={500}
                                                //onChange={event => { setOrderFilters({ ...orderFilters, buyerId: +event.target.value ? +event.target.value : -1 }); }}
                                                onChange={event => { 
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        filterType: eAppFilters.order,
                                                        filter: {
                                                            ...orderPageState!.filter,
                                                            buyerId: +event.target.value ? +event.target.value : -1 
                                                        },
                                                        currentPage: 1
                                                    }))
                                                    //setOrderFilters({ ...orderFilters, buyerId: +event.target.value ? +event.target.value : -1 }); 
                                                }}
                                                placeholder={t('Buyer_ID')!} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <label>{t('Seller_ID')}:</label>
                                        <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                //value={orderFilters.sellerId == -1 ? '' : orderFilters.sellerId}
                                                value={
                                                    (orderPageState?.filter as iOrderFilters).sellerId == -1 ? 
                                                    '' : 
                                                    (orderPageState?.filter as iOrderFilters).sellerId}
                                                debounceTimeout={500}
                                                //onChange={event => { setOrderFilters({ ...orderFilters, sellerId: +event.target.value ? +event.target.value : -1 }); }}
                                                onChange={event => { 
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        filterType: eAppFilters.order,
                                                        filter: {
                                                            ...orderPageState!.filter,
                                                            sellerId: +event.target.value ? +event.target.value : -1 
                                                        },
                                                        currentPage: 1
                                                    }))
                                                    //setOrderFilters({ ...orderFilters, sellerId: +event.target.value ? +event.target.value : -1 }); 
                                                }}
                                                placeholder={t('Seller_ID')!} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-3">
                                        <label>{t('City_Name')}:</label>
                                        <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                //value={orderFilters.city}
                                                value={(orderPageState?.filter as iOrderFilters).city}
                                                debounceTimeout={500}
                                                onChange={event => { 
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        filterType: eAppFilters.order,
                                                        filter: {
                                                            ...orderPageState!.filter,
                                                            city: event.target.value 
                                                        },
                                                        currentPage: 1
                                                    }))
                                                    //setOrderFilters({ ...orderFilters, city: event.target.value }); 
                                                }}
                                                
                                                placeholder={t('City_Name')!} />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-3">
                                        <label></label>
                                        <div className="action-btn">
                                            <a onClick={() => onResetFilter()}
                                                href="javascript:void(0)"
                                                className="bulk-primary-btn bulk-sm add-shoe-model"
                                                data-toggle="modal" data-target="#addReward">
                                                {t('Reset_Filter')}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t('Order_Id')}</th>
                                        <th>{t('Date_Of_Order')}</th>
                                        <th>{t('MarketPlace')}</th>
                                        <th>{t('Thumbnail')}</th>
                                        <th>{t('Shoemodel')}</th>
                                        <th>{t('Buyer_Info')}</th>

                                        {/* <th>{t('Buyer_Address')}</th> */}
                                        <th>{t('Seller_Info')}</th>
                                        <th>{t('Sell_Price')}</th>

                                        <th>{t('Order_Status')}</th>
                                        <th>{t('Order_Details')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderOrders()}
                                </tbody>
                            </table>
                        </div>
                        {totalOrderModelResults && totalOrderModelPages ?
                            <CustomPagination
                                totalResults={totalOrderModelResults}
                                totalPages={totalOrderModelPages}
                                p_iPageState={orderPageState!}
                                onPageChanged={onPageChanged}
                            />
                            : null
                        }

                        {/* <ModalDialog toggle={() => onEditOrderClick()}
                            executeButtonTitle={() => "Save"}
                            isOpen={isOpenEdit}
                            execute={onExecuteEditOrder}
                            enableExecute={enableExecuteOrder()}
                            modalTitle="View/Edit order"
                        >

                            {orderModelDetails && orderModelDetails.id !== -1 &&
                                <div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Order ID</div></div>
                                        <div className="col-7">{orderModelDetails.id}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Buyer User ID</div></div>
                                        <div className="col-7">{orderModelDetails.address.userId}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Buyer Name</div></div>
                                        <div className="col-7">{orderModelDetails.buyer.firstName} {orderModelDetails.buyer.lastName} {orderModelDetails.buyer.phone ? 'Ph.' + orderModelDetails.buyer.phone : null}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Buyer Address</div></div>
                                        <div className="col-7">
                                            <div><span>{orderModelDetails.address.streetNo}</span>, <span>{orderModelDetails.address.village}</span>,</div>
                                            <div><span>{orderModelDetails.address.city}</span>, <span>{orderModelDetails.address.state}</span>,</div>
                                            <div><span>{orderModelDetails.address.country}</span> - <span>{orderModelDetails.address.postalCode}</span>.</div>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Seller User ID</div></div>
                                        <div className="col-7">{orderModelDetails.seller.id}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Seller Name</div></div>
                                        <div className="col-7">{orderModelDetails.seller.firstName} {orderModelDetails.seller.lastName}  {orderModelDetails.seller.phone ? 'Ph.' + orderModelDetails.seller.phone : null}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Order Created At</div></div>
                                        <div className="col-7">{orderModelDetails.createdAt}</div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Order Status</div></div>
                                        <div className="col-7">{orderModelDetails.status}</div>
                                    </div>
                                    <div className="mb-2">
                                        <label>Order Items:</label>
                                        <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Shoe Model</th>
                                                    <th>Quantity</th>
                                                    <th>Avg. Price</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderModelDetails && orderModelDetails.orderItems.length &&

                                                    orderModelDetails.orderItems.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.id}</td>
                                                            <td>{item.shoeModel.brandName} ({item.shoeModel.modelName} , {item.shoeModel.modelCode}) </td>
                                                            <td>{item.totalQuantity}</td>
                                                            <td>€{item.averagePricePerPair}</td>
                                                        </tr>
                                                    )}

                                            </tbody>
                                        </table>
                                    </div>
                                    {labelDetail.length > 0 && <div className="mb-2">
                                        <label>Label Type:</label>
                                        <table className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>Label type</th>
                                                    <th>Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {labelDetail.length > 0 &&
                                                    labelDetail.map((item) =>
                                                        <tr key={item.addressId}>
                                                            <td>{item.labelType}</td>
                                                            <td>{item.createddate}</td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>}
                                    <div className="row mb-2">
                                        <div className="col-5"><div className="colon-text">Total Price</div></div>
                                        <div className="col-7"><b>€{orderModelDetails.totalPrice}</b></div>
                                    </div>
                                    <div className="row mb-2 align-items-center">
                                        <div className="col-5"><div className="colon-text">Change Order status</div></div>
                                        <div className="col-7">
                                            {populateOrderStatusType(orderModelDetails.status!)}

                                        </div>
                                    </div>
                                </div>

                            }

                        </ModalDialog> */}
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div>

    )
};

export default OrdersPage;