import { createSlice } from "@reduxjs/toolkit";

const initialState: any = [];

const rewardSlice = createSlice({
    name: "rewardSlice",
    initialState,
    reducers: {
      storeRewards: (state, action) => {
        return [
          ...action.payload
        ];
      },
    }
})

export const rewardSliceActions = rewardSlice.actions;
export default rewardSlice.reducer;