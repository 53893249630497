export interface iCustomAxiosError {
  errorCode: number;
  errorMessage: {
    name: string;
    value: string;
  };
}

export interface iUserType {
  id: number;
  name: string;
  description?: string;
  isDeleted?: bigint;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  status?: number;
}
export interface iSignUpMedium {
  id: number;
  socialMediaName: string;
  // 1,'admin')
  // 2,'web')
  // 3,'mobile')
  // 4,'facebook')
  // 5,'twitter')
  // 6,'google')
}
export interface iNotificationType {
  id: number;
  type_name: string;
  createdAt?: Date;
  updatedAt?: Date;
}
export interface iRewardLevel {
  id: number;
  name: string;
}
export enum eGender {
  mail = 1,
  femail,
  neutral,
}
export interface iUserDetails {
  userId: number;
  companyName: string;
  companyVAT: string;
  businessName: string;
  iBan: string;
  uniqueCode: string
  accountName: string;
  phoneNo: string;
  dateOfBirth: Date;
  privacyPolicyConcent: boolean;
  newslettersConcent: boolean;
}
export interface iUser {
  id: number;
  name?: string;
  email: string;
  dateOfBirth?: Date;
  firstName?: string;
  lastName?: string;
  userTypeId?: eUserType;
  isBuyer?: boolean;
  isSeller?: boolean;
  isB2B?: boolean;
  showOverlay?:boolean
  locale?: boolean
  levelsForRewardsId?: number
  totalPoints?: number;
  totalBuy?: number
  totalSale?: number
  details?: iUserDetails | null; 
  refreshToken?: string;
  refreshTokenCreatedAt?: Date;
  
  // -----------------------------------------------
  password?: string;


  gender?: eGender;
  phone_no?: string;
  isEmailVerified?: number;
  signUpMediumId?: eSocialMediaName;
  signUpMediumOauthId?: string;
  loginTime?: Date;
  stripeCustomerId?: string;
  billingDetailsStatus?: number;

  companyName?: string;
  companyVat?: string;

  privacyPolicyConcent?: number;
  newslettersConcent?: number;
  notificationTypeId?: iNotificationType;
  notificationFrequencyPerDay?: number;
  

  is_deleted?: number;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  status?: number;

}
export interface iBrandFilters {
  searchBrand: string
}
export class BrandFilters implements iBrandFilters {
  searchBrand: string = ""
}
export interface iUserFilters {
  name?: string;
  user_type_id?: number;
  is_email_verified?: boolean | undefined;
  is_deleted?: boolean | undefined;
  levels_for_rewards_id?: number;
  signup_medium_id?: number;
  email?: string;
}
export class UserFilters implements iUserFilters {
  name: string = "";
  user_type_id: number =  0;
  is_email_verified:boolean | undefined = undefined;
  is_deleted: boolean | undefined= undefined;
  levels_for_rewards_id:number = 0;
  signup_medium_id: number = 0;
  email: string = "";
}
export interface iShoeModelFilters {
  brandIds: iCategory[];
  brandFilterApplied: boolean;
  brandClearedManually: boolean
  categoryIds: iCategory[];
  categoryFilterApplied: boolean;
  categoryClearedManually: boolean
  //shoeModelIds: number[] ;
  colorIds: iShoeColor[];
  colorFilterApplied: boolean;
  colorClearedManually: boolean
  fromReleaseDate: Date | null;
  toReleaseDate: Date | null;
  releaseDateFilterApplied: boolean;
  releaseDateClearedManually: boolean
  exitPrice: iExitPrice | null;
  exitPriceFilterApplied: boolean;
  fromCreationDate: Date | null;
  toCreationDate: Date | null;
  creationDateFilterApplied: boolean;
  creationDateClearedManually: boolean
  //Filter Applied
  
  clearFilter: boolean
  searchShoeModel: string
}
export class ShoeModelFilters implements iShoeModelFilters {
  brandIds: iCategory[] = [];
  brandFilterApplied: boolean = false;
  brandClearedManually: boolean = false;
  categoryIds: iCategory[] = [];
  categoryFilterApplied: boolean = false;
  categoryClearedManually: boolean = false;
  //shoeModelIds: number[] ;
  colorIds: iShoeColor[] = [];
  colorFilterApplied: boolean = false;
  colorClearedManually: boolean = false;
  fromReleaseDate: Date | null = null;
  toReleaseDate: Date | null = null;
  releaseDateFilterApplied: boolean = false;
  releaseDateClearedManually: boolean = false;
  exitPrice: iExitPrice | null = null;
  exitPriceFilterApplied: boolean = false;
  fromCreationDate: Date | null = null;
  toCreationDate: Date | null = null;
  creationDateFilterApplied: boolean = false;
  creationDateClearedManually: boolean = false;
  //Filter Applied
  
  clearFilter: boolean = false;
  searchShoeModel: string = '';
}
export interface iColorFilters {
  search: string
}
export class ColorFilters implements iColorFilters {
  search: string = '';
}
export interface iCouponFilters {
  marketPlace: eMarketplace;
  userId: iUser | null 
  modelId: iShoeModel | null ;
  categoryId: number;
  activeOnly: boolean;
}
export class CouponFilters implements iCouponFilters {
  marketPlace: eMarketplace = eMarketplace.All;
  userId: iUser | null = null;
  modelId: iShoeModel | null = null;
  categoryId: number = 0;
  activeOnly: boolean = true;
}
export interface iOrderFilters {
  id: number | -1;
  fromDate: Date | null;
  toDate: Date | null;
  status: eOrderStatusType | "-1";
  buyerId: number;
  sellerId: number;
  city: string;
}
export class OrderFilters implements iOrderFilters {
  id: number | -1 = -1;
  fromDate: Date | null = null;
  toDate: Date | null = null;
  status: eOrderStatusType | "-1" = '-1';
  buyerId: number = -1;
  sellerId: number = -1;
  city: string = '';
}
export interface iModerationLotsFilters {
  search: string
  isCurrent: boolean
}
export class ModerationLotsFilters implements iModerationLotsFilters {
  search: string = '';
  isCurrent: boolean = true
}
export interface iModerationRequestsFilters {
  search: string
  isCurrent: boolean
}
export class ModerationRequestsFilters implements iModerationRequestsFilters {
  search: string = '';
  isCurrent: boolean = false
}
export enum eAppFilters {
  user = 1,
  brand,
  shoeModel,
  color,
  coupon,
  order,
  b2b_moderation_lots,
  b2b_moderation_requests,
  faq
}

export type iAppFilterType = iUserFilters | iBrandFilters | iShoeModelFilters | 
                          iColorFilters | iCouponFilters | 
                          iOrderFilters | iModerationLotsFilters
                          | iModerationRequestsFilters | null

//   ============ Shoe Model setup =============
// export interface iBrand {
//     id: number;
//     name: string;s
//     imageFileName: string
// }

// export interface iBrandsCategory {
//     id: number;
//     brandId: iBrand;
//     parentCategoryId?: iBrandsCategory
//     categoryLevel: string;
// }
export interface iCategory {
  id: number;
  image: string;
  bgImage: string;
  parentCategoryId?: number;
  name: string;
  childCategories: iCategory[];
  childCount?: number;
  path?: string;
  idPath?: string;
}
export interface iShoeModel {
  id: number;
  modelCode: string;
  modelName: string;
  defaultImage?: string;
  searchableName?: string
  categoryId: number;
  sizeTypeId: number;
  title?: string;
  description: string;
  releaseDate: Date | null;
  exitPrice: number | string;
  isHidden: boolean;

  images?: iShoeModelPhotographs[];
  colors?: iShoeColor[];
  brandId?: number;
  brandName?: string;
  categoryName?: string;
  color?: string;
}

export interface iShoeModelPhotographs {
  id: number;
  shoeModelsId?: number;
  fileId?: string;
  name: string;
  isDefault: boolean;
  url?: string;
  posn?: number;
}

export interface iShoeColor {
  id: number;
  color: string;
}

export interface iShoeModelColor {
  id: number;
  shoeModelsId: iShoeModel;
  shoe_colorsId: iShoeColor;
}

export interface iShoeModelSize {
  id: number;
  size: number | string;
}

export interface iSizeType {
  id: number;
  brandId: number;
  name: string;
}

export interface iAuthUser {
  email: string;
  password: string;
}
export interface iSetting {
  keyName: string;
  description: string;
  fromValue: string;
  toValue: string;
}

export interface iSearchCriteria {
  key: string;
  value: boolean;
  displayName?: string;
}

export interface iWarehouseb2b {
  id: number;
  batchId: number
  sellerId: number;
  shoeModelId: number;
  averagePricePerPair: number;
  feeForService: number;
  size: number;
  sizes: string;
  sizeId: number;
  totalQuantity: number;
  totalPrice: number;
  shoeModel?: iShoeModel;
}

export interface iShoeModelSummary {
  id: number;
  modelName: string;
  modelCode: string;
  brandId: number;
  brandName: string;
  categoryId: number;
  categoryName: string;
  description: string;
  title: string;
  defaultImage: string;
  color: string;
  exitPrice: number;
  releaseDate: Date;
  isHidden: boolean;
}
export interface iProductModel {
  id?: number;
  quantity: number;
  shoeSizeId: number;
  batchId?: number;
  shoeSize?: iShoeSizeModel;
  title?: string;
}
export interface iShoeSizeModel {
  id: number;
  size: number;
}
export interface iShoeColorModel {
  id: number;
  color?: string;
}
export interface iImageModel {
  id: number;
  name?: string;
  url?: string;
  isDefault: boolean;
  posn: number;
  shoeModelId: number;
  shoeModelLabel?: string;
}
export interface iShowModalSpec {
  id: number;
  categoryId: number;
  brandId: number;
  modelName?: string;
  modelCode?: string;
  description?: string;
  title?: string;
  color?: string;
  exitPrice: number;
  releaseDate?: Date;
  isHidden: boolean;
  colors: iShoeColorModel[];
  images: iImageModel[];
}
export interface iBatchModel {
  id?: number;
  userId: number;
  shoeModelId: number;
  totalPrice: number;
  calcTotalPrise?: number;
  receiveMethod:ePaymentMethods
  receiveReference: number
  totalQuantity?: number;
  averagePricePerPair?: number;
  buyerFees?: number;
  buyerFeesPercent?:number;
  buyerPaymentFees?:number
  buyerPaymentFeesPercent?:number
  buyerShippingFees?: number;
  calcBuyerShippingFees?: number;
  sellerFees?: number;
  sellerFeesPercent?:number
  sellerPaymentFees?:number
  sellerPaymentFeesPercent?:number
  sellerShippingFees?: number;
  calcSellerFees?: number;
  calcSellerShippingFees?: number;
  rewardPoints:boolean
  seller?: iUser
  isHidden?: boolean;
  couponId?: number;
  calcDiscount?: number;
  products: iProductModel[];
  isCurrentUserFav: boolean
  favId?: number 
  shoeModel?: iShowModalSpec;
  images?: iImageModel;
}
export enum eOfferType {
  Offer = 1,
  CounterOffer,
}
export enum eOfferStatus {
  Created = 1,
  Countered,
  Accepted,
  Rejected,
  Cancelled,
  Expired,
  Deleted,
}
export interface iBatchOfferModel {
  id	: number,
  batchId	: number,
  userId	: number,
  price	: number,
  createdAt?	: Date,
  updatedAt?	: Date,
  offerPrice	: number,
  batch? : any, //  	InventoryAccountSummary{...}
  type	: eOfferType,
  status	: eOfferStatus,
  buyerFees	: number,
  buyerShippingFees	: number,
  buyerPaymentFees	: number,
  sellerFees	: number,
  sellerShippingFees	: number,
  sellerPaymentFees	: number,
  buyerTotal	: number,
  sellerTotal	: number,
  isBuyer	: boolean,
  buyerDiscount	: number,
  sellerDiscount	: number,
}
export interface iInventorySummary {
  id: number;
  batchId?: number
  batchType?: string
  products?: iProductModel[]
  sellerId: number;
  shoeModelId: number;
  averagePricePerPair: number;
  feeForService: number;
  size: number;
  sizeId: number;
  sizes?: string
  totalQuantity: number;
  shoeModel: iShoeModelSummary;
  receiveDetails?:string;
  receiveMethod?:ePaymentMethods;
  totalPrice:string;
  buyerFees:number;
  buyerShippingFees:number;
  buyerPaymentFees:number;
  sellerFees:number;
  sellerShippingFees:number;
  sellerPaymentFees:number;
  buyerTotal:number;
  couponCode?: string
  sellerTotal:number;
}
export interface iAddress {
  id: number;
  userId: number;
  address: string;
  streetNo: string;
  village: string;
  addressType: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  isDefault: boolean;
}

export interface iUserInfo {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  uniqueCode: string;
  companyVat: string;
}

export enum eOrderStatusType {
  Sold = 1,
  LabelRequested,
  Shipped,
  InVerification,
  Verified,
  Unverified,
  InPreparation,
  ForDelivery,
  ClosedRefundInProgress,
  ClosedRefunded,
  ClosedDelivered,
  ReturnedLabelRequested,
  Returned,
  Cancelled,
}


export interface iOrderModel {
  id: number;
  status: eOrderStatusType;
  description?: string;
  totalPrice: number;
  createdAt?: string;
  discount?:number
  invoice?: boolean
  paymentToken: string;
  paymentId: number;
  addressId: number;
  address: iAddress;
  billingAddress: iAddress;
  sellerAddress: iAddress
  buyer: iUserInfo;
  seller: iUserInfo;
  type: string;
  paymentMethod: string;
  paymentReference:string;
  salePrice: number,
  buyerTotal: number,
  sellerTotal:number,
  buyerFees: number,
  buyerFeesDiscount: number,
  buyerShipping: number,
  buyerShippingDiscount: number,
  buyerPaymentFees: number,
  buyerPriceDiscount: number,
  buyerStatus?: string,
  sellerFees: number,
  sellerFeesDiscount: number,
  sellerShipping: number,
  sellerShippingDiscount:number,
  sellerPaymentFees: number,
  sellerPriceDiscount: number,
  sellerStatus?: string 
  orderItems: iInventorySummary[];
  sellerPoints: number
  buyerPoints: number
  couponCode: string

}
export interface iOrderHistory {
  orderId: number;
  createdDate?: Date;
  newStatus: eOrderStatusType;
  oldStatus: eOrderStatusType;
  message: string;
}

interface iParcels {
  id?: number
  length: number;
  depth: number;
  height: number;
  weight: number;
  totalPairs: number;
}

export interface iLabel {
  orderId: number;
  addressId: number;
  labelType: string;
  createddate: string;
  parcels: iParcels[];
}


export interface iExitPrice {
  id: number 
  fromValue: number 
  toValue: number
}



export enum eMarketplace {
  B2C = 1,
  B2B = 2,
  All = 3,
}
export enum eBeneficiaryType {
  Buyer = 1,
  Seller = 2,
  All = 3,
}
export enum eCouponType {
  Shipping = 1,
  Discount = 2,
  Fees = 3,
}

export enum eSocialMediaName {
  admin = 1,
  web = 2,
  mobile = 3,
  facebook = 4,
  twitter = 5,
  google = 6,
}

export enum eUserType {
  Administrator = 1,
  LogisticOperator = 2,
  CommunicationOfficersOperator = 3,
  AccountingOfficersOperator = 4,
  Guest = 5,
  User = 6,
}

export interface iCouponUserType {
  userId: number;
  userName?: string;
  usageCount?: number;
}
export interface iCouponShoeModel {
  modelId: number;
  modelName?: string;
}
export interface iCouponCategory {
  categoryId: number;
  name?: string;
}
export interface iCouponRewards {
  rewardLevelId: number;
  name?: string;
}
export interface iCouponModel {
  id?: number;
  title: string;
  code: string;
  maxDiscount?: number;
  couponRewards?: iCouponRewards[];
  beneficiaryType: eBeneficiaryType;
  marketPlace: eMarketplace;
  couponType: eCouponType;
  discountPercent: number;
  validFrom: Date | null;
  validTo: Date | null;
  usageLimit: number;
  active: boolean;
  status: string;
  categories: iCouponCategory[];
  shoeModels: iCouponShoeModel[];
  users: iCouponUserType[];
}

export interface iRewardsLevel {
  id: number;
  name: string;
  active: boolean;
  beneficiaryType?: eBeneficiaryType;
  fromPoints: number;
  toPoints?: number;
  rewardLevel: number;
}

export interface iLegalText {
  key: string;
  content: string;
}
export interface iLegalPdf {
  url: string;
  name: string;
}
export interface iFaq {
  id: number;
  question: string;
  answer: string;
}

export interface iNotification {
  id: number;
  message: string;
  fileId: string;
  publishStatus?: boolean;
  createdAt: Date;
}
export interface iPrize {
  id: number;
  rewardPoints: number;
  name: string;
  description: string;
}
export enum eSanction {
  Economical = 1,
  PenaltyOnRewards = 2,
}

export enum ePenaltyType {
  Manual = 1,
  OrderCancellation = 2,
  OrderUnverified = 3,
}

export interface iPenalty {
  id?: number;
  sanctionType: eSanction | -1;
  penaltyType: ePenaltyType | 2;
  percentageValue: number;
  minimumAmount: number;
  collectionDays: number;
  collectionTime: string;
  rewardLevelsRecede: number;
  motivationText: string;
  userId?: number;
  userName?: string;
  status?: string;
}

export interface iNotificationSetting {
  key: string;
  value: boolean;
}

export interface iNewsLatter {
  id: number;
  name: string;
}

export interface iUserRanking {
  userId: number;
  username: string;
  salesCount: number;
  purchaseCount: number;
  totalSaleAmount: number;
  totalPurchaseAmount: number;
}


export interface IPaymentMethod {
  id: number;
  userId: number;
  cardHolder: null;
  paymentMethodId: string;
  isDefault: boolean;
  brandType: string;
  cardNumber: string;
  expiryYear: number;
  expiryMonth: number;
}

export interface B2bShippingFees {
  paymentFees: number;
  platformFees: number;
  shippingFees: ShippingFee[];
}
export interface ShippingFee {
  id: number;
  beneficiaryType: number;
  noOfUnits: number;
  amount: number;
  amountPercent: number;
}

export class Fees {
  b2BBuyShippingFeePercent: number = 0;
  b2BSellShippingFeePercent: number = 0;

  b2BBuyTransactionFeePercent: number = 0;
  b2BBuyPaymentProcessingFeePercent: number = 0;
  b2BSellTransactionFeePercent: number = 0;
  b2BSellPaymentProcessingFeePercent: number = 0;

  b2CBuyShippingFeeValue: number = 0;
  b2CBuyShippingFeePercent: boolean = false;

  b2CSellShippingFeeValue: number = 0;
  b2CSellShippingFeePercent: boolean = false;

  b2CBuyTransactionFeePercent: number = 0;
  b2CBuyPaymentProcessingFeePercent: number = 0;
  b2CSellTransactionFeePercent: number = 0;
  b2CSellPaymentProcessingFeePercent: number = 0;
}
export type FeesKeys = keyof Fees;
export enum ePaymentMethods {
  PayPal = 1,
  Stripe = 2,
  IBan = 3,
}

export class FAQ {
  id: number = 0;
  question: string = "";
  answer: string = "";
}
export class FAQResponse {
  items: FAQ[] = [];
  totalRows: number = 0;
}
export enum ContentType {
  Privacy = "PrivacyAndPolicy",
  TermsAndConditions = "TermsAndConditions",
  OverlayNotifications = "OverlayNotification",
}