import React, { ReactNode, useEffect, useState } from "react"
import { iUserType } from "../../../shared/models/adminModels"
import { Sidebar } from "../Sidebar"
import { useTranslation } from 'react-i18next';

class cUserType implements iUserType {
    id: number
    name: string
    createdAt: Date
    description?: string | undefined
    updatedAt?: Date | undefined
    status?: number | undefined

    constructor() {
        this.id = 1
        this.name = "Admin"
        this.createdAt = new Date()
        this.description = "Test"
        this.updatedAt = new Date()
        this.status = 1
    }
}

const UserTypesPage = () => {
    const { t } = useTranslation()

    const [userTypes, setUserTypes] = useState<iUserType[]>([])
    useEffect(() => {
        console.log('UserTypes')
        setUserTypes([new cUserType()])
        if (!userTypes) {
            console.log('here')

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const renderUser = (): ReactNode[] => {
        console.log(userTypes?.length)
        const returnNode: ReactNode[] = userTypes?.map((userTypes: iUserType) => (
            <tr key={userTypes.id}>
                <td>{userTypes.name}</td>
                <td>{userTypes.description}</td>
                <td>{userTypes.createdAt.toDateString()}</td>
                <td>{userTypes.updatedAt?.toDateString()}</td>
                <td>{userTypes.status}</td>
                <td><input type="checkbox"></input></td>


            </tr>
        ))
        return returnNode
    }
    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">

                                <h1 className="separator">{t('User_Types')}</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a> </li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('User_Type')}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="alert alert-success alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- success %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}

                                {/* <% if(error && error.length > 0) { %> */}
                                <div className="alert alert-danger alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- error %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}
                                <div className="card">

                                    <div className="card-body">
                                        <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>{t('Name')}</th>
                                                    <th>{t('Description')}</th>
                                                    <th>{t('Date_Created_At')}</th>
                                                    {/* <th>Updated At </th> */}
                                                    <th>{t('Status')}</th>
                                                    <th>{t('Action')}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {renderUser()}
                                            </tbody>




                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
            </div>
        </div>





    )

}

export default UserTypesPage