import axios, { AxiosResponse } from "axios";
import {
    axiosConfig, axiosAdminConfig,
    axiosAdminConfigFileUpload, axiosAdminAuthConfig,
    axiosAdminOrderConfig
} from "../shared/constants/constants";
import { iModelType, iPageType } from '../shared/models/adminInterfaces'
import { customAxiosAdminConfig, customAxiosAdminAuthConfig,customAxiosAdminOrderConfig } from './http.interceptor'
// axios.defaults.withCredentials = true
export interface iCustomAxiosError {
    errorCode: number,
    errorMessage: {
        name: string,
        value: string
    }
}

export class HttpService {
    public async post(url: string, body: any) {
        try {
            return await axios.post(url, body, axiosConfig);
        } catch (error) {
            throw error;
        }
    }

    public async get(url: string, param: any): Promise<any> {
        try {
            return await axios.get(url, axiosConfig);
        } catch (error) {
            throw error;
        }
    }
}

export class HttpAdminService {
    public async post(url: string, body: any) {
        try {
            console.log('Tested .........')
            return await customAxiosAdminConfig.post(url, body, axiosAdminConfig);
        } catch (error) {
            throw error;
        }
    }
    public async update(url: string, body: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminConfig.patch<iModelType>(url, body, axiosAdminConfig);
        } catch (error) {
            throw error;
        }
    }
    public async updatePUT(url: string, body: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminConfig.put<iModelType>(url, body, axiosAdminConfig);
        } catch (error) {
            throw error;
        }
    }
    public async get<iModelType>(url: string, param: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminConfig.get<iModelType>(url, axiosAdminConfig);
        } catch (error) {
            throw error;
        }
    }

    public async delete<iModelType>(url: string, param: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminConfig.delete<iModelType>(url + `${param}`, axiosAdminConfig);
        } catch (error) {
            throw error;
        }
    }
    public async getAll<iModelType>(url: string, pageType: iPageType): Promise<AxiosResponse<iModelType[], any>> {
        try {
            return await customAxiosAdminConfig.get<iModelType[]>(url, axiosAdminConfig);
            // return await resp.data
        } catch (error) {
            throw error;
        }

    }
    public async filterBy<iModelType>(url: string): Promise<AxiosResponse<iModelType[], any>> {
        try {
            return await customAxiosAdminConfig.get<iModelType[]>(url, axiosAdminConfig)
            // return await resp.data
        } catch (error) {
            throw error;
        }

    }

    public async upload(url: string, formData: any) {
        try {
            return await customAxiosAdminConfig.post(url, formData, axiosAdminConfigFileUpload);
        } catch (error) {
            throw error;
        }
    }
}


export class HttpAdminAuthService {
    public async post(url: string, body: any) {
        try {
            return await customAxiosAdminAuthConfig.post(url, body, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }
    public async get<iModelType>(url: string, param: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminAuthConfig.get<iModelType>(url, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }
    public async getAll<iModelType>(url: string, param: any): Promise<any> {
        try {
            return await customAxiosAdminAuthConfig.get<iModelType>(url, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }
    public async updatePUT<iModelType>(url: string, body: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminAuthConfig.put<iModelType>(url, body, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }
    public async delete<iModelType>(url: string, param: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminAuthConfig.delete<iModelType>(url + `${param}`, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }
    public async filterBy<iModelType>(url: string): Promise<AxiosResponse<any, any>> {
        try {
            return await customAxiosAdminAuthConfig.get<iModelType[]>(url, axiosAdminAuthConfig)
            // return await resp.data
        } catch (error) {
            throw error;
        }

    }
}




export class HttpWarehouseb2bService {
    public async post(url: string, body: any) {
        try {
            return await customAxiosAdminAuthConfig.post(url, body, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }

    public async getAll(url: string, param: any): Promise<any> {
        try {
            return await customAxiosAdminAuthConfig.get(url, axiosAdminAuthConfig);
        } catch (error) {
            throw error;
        }
    }
}

export class HttpOrderService {
    public async post(url: string, body: any) {
        try {
            return await customAxiosAdminOrderConfig.post(url, body, axiosAdminOrderConfig);
        } catch (error) {
            throw error;
        }
    }

    public async getAll<iModelType>(url: string, param: any): Promise<any> {
        try {
            return await customAxiosAdminOrderConfig.get<iModelType>(url, axiosAdminOrderConfig);
        } catch (error) {
            throw error;
        }
    }
    public async get<iModelType>(url: string, param: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminOrderConfig.get<iModelType>(url, axiosAdminOrderConfig);
        } catch (error) {
            throw error;
        }
    }
    public async delete<iModelType>(url: string, param: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminOrderConfig.delete<iModelType>(url + `${param}`, axiosAdminOrderConfig);
        } catch (error) {
            throw error;
        }
    }
    public async filterBy<iModelType>(url: string): Promise<AxiosResponse<any, any>> {
        try {
            return await customAxiosAdminOrderConfig.get<iModelType[]>(url, axiosAdminOrderConfig)
            // return await resp.data
        } catch (error) {
            throw error;
        }

    }
    public async updatePUT(url: string, body: any): Promise<AxiosResponse<iModelType, any>> {
        try {
            return await customAxiosAdminOrderConfig.put<iModelType>(url, body, axiosAdminOrderConfig);
        } catch (error) {
            throw error;
        }
    }
}