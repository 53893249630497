import axios, { AxiosResponse } from "axios";
import { axiosConfig } from "../shared/constants/constants";

// import { ReservePackageWithPaymentTokenRequest, ReservePackageWithPaymentCreditRequest } from "shared/models/packagedPriceModel";
import {
  HttpAdminService,
  HttpAdminAuthService,
  HttpOrderService,
} from "./http.service";
import { iListType, iPageType } from "../shared/models/adminInterfaces";

import {
  iAuthUser,
  iOrderHistory,
  iPrize,
  iPenalty,
  iShoeModelPhotographs,
  iUser,
  iCategory,
  iShoeModel,
  iShoeColor,
  iShoeModelSize,
  iSetting,
  iWarehouseb2b,
  iOrderModel,
  iSearchCriteria,
  iCouponModel,
  iLegalText,
  iRewardsLevel,
  iFaq,
  iLabel,
  eOrderStatusType,
  iNotification,
  iNotificationSetting,
  iUserRanking,
  iNewsLatter,
  IPaymentMethod,
  ePenaltyType,
  CouponFilters,
  ShippingFee,
  B2bShippingFees,
  iLegalPdf,
} from "../shared/models/adminModels";
import * as appConst from "../shared/constants/constants";
import moment from "moment";

const httpAdminService: HttpAdminService = new HttpAdminService();
const httpAdminAuthService: HttpAdminAuthService = new HttpAdminAuthService();
const httpOrderService: HttpOrderService = new HttpOrderService();

//const httpWarehouseb2bService: HttpWarehouseb2bService = new HttpWarehouseb2bService();

// export interface AxiosResponse<T = any, D = any>  {
//   data: T;
//   status: number;
//   statusText: string;
//   headers: AxiosResponseHeaders;
//   config: AxiosRequestConfig<D>;
//   request?: any;
// }

export type RewardLevel = "up" | "down";

const responseBody = (response: AxiosResponse) => response.data;

export class HttpUsersService implements iListType<iUser> {
  public async add<iUsers>(values: iUsers): Promise<iUsers> {
    console.log(values);
    const resp = await httpAdminAuthService
      .post("/User", values)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async get<iUsers>(params: any): Promise<AxiosResponse<iUsers, any>> {
    try {
      return await httpAdminAuthService
        .get<iUsers>("/User/" + params, "")
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async banUser<iUsers>(
    id: number
  ): Promise<AxiosResponse<iUsers, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Admin/banuser?userid=` + id, id)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async suspendUser<iUsers>(
    id: number,
    toDate: Date
  ): Promise<AxiosResponse<iUsers, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Admin/suspendUser?userid=` + id + '&toDate=' + moment(toDate).format('MM/DD/YYYY'), '')
      .then(responseBody);
    return resp;
  }

  public async delete<iFaq>(id: number): Promise<any> {
    const resp = await httpAdminAuthService
      .delete<iFaq>("/User/?id=" + id, "")
      .then(responseBody);
    return resp;
  }
  //Promise<AxiosResponse<any, any>>
  public async filterBy<iUser>(pageType: iPageType, params: any): Promise<any> {
    let currentPage = pageType.currentPage ? pageType.currentPage : 1;
    let pageLimit = pageType.pageLimit
      ? pageType.pageLimit
      : appConst.PAGE_LIMIT;
    let pageSuffix = "?page=" + currentPage + "&count=" + pageLimit;
    if (params.name) {
      pageSuffix += "&name=" + params.name;
    }
    if (params.email) {
      pageSuffix += "&email=" + params.email;
    }
    if (params.user_type_id) {
      pageSuffix += "&user_type_id=" + params.user_type_id;
    }
    if (params.levels_for_rewards_id) {
      pageSuffix += "&levels_for_rewards_id=" + params.levels_for_rewards_id;
    }
    if (params.signup_medium_id) {
      pageSuffix += "&signup_medium_id=" + params.signup_medium_id;
    }
    if (params.is_deleted !== undefined) {
      pageSuffix += "&is_deleted=" + params.is_deleted;
    }
    if (params.is_email_verified !== undefined) {
      pageSuffix += "&is_email_verified=" + params.is_email_verified;
    }
    const resp = await httpAdminAuthService
      .filterBy<iUser>(`/User` + pageSuffix)
      .then(responseBody)
      .then((value) => {
        console.log("value", value);
        return value;
      });
    console.log("resp", resp);
    return resp;
  }

  public async getUserPaymentMethod<IPaymentMethod>(
    userId: number
  ): Promise<IPaymentMethod[]> {
    try {
      return await httpAdminAuthService
        .get<IPaymentMethod[]>(`/user/${userId}/PaymentMethod`, "")
        .then((value) => {
          return value.data;
        });
    } catch (error) {
      throw error;
    }
  }

  public async getUserAddresses<iAddress>(userId: number): Promise<iAddress[]> {
    try {
      return await httpAdminAuthService
        .get<iAddress[]>(`/user/${userId}/Address`, "")
        .then((value) => {
          return value.data;
        });
    } catch (error) {
      throw error;
    }
  }

  public async updateUserLevel(
    userId: number,
    reason: string,
    action: RewardLevel
  ): Promise<any> {
    try {
      return await httpAdminAuthService
        .updatePUT(
          `/RewardLevel/user/level/${action}?userId=${userId}&reason=${reason}`,
          ""
        )
        .then((value) => {
          return value.data;
        });
    } catch (error) {
      throw error;
    }
  }
}

export class HttpBrandService implements iListType<iCategory> {
  // public async add<iBrand>(brand: iBrand): Promise<AxiosResponse<iBrand,any>> {
  public async add<iCategory>(brand: iCategory): Promise<iCategory> {
    console.log("HttpBrandService add called");

    const resp = await httpAdminService
      .post("/brand", brand)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async get<iBrand>(params: any): Promise<iBrand> {
    console.log("HttpBrandService get called");

    const resp = await httpAdminService
      .get<iBrand>("/Catalogue/Category/" + params.id, "")
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  // public async getAll<iBrand>(): Promise<AxiosResponse<iBrand[], any>> {
  public async getAll<iBrand>(
    pageType: iPageType
  ): Promise<AxiosResponse<iBrand[], any>> {
    console.log("HttpBrandService get called");
    try {
      return await httpAdminService
        .getAll<iBrand>("/Catalogue/Category/Brands", pageType)
        .then((value) => {
          console.log("value", value);
          return value;
        });
      // console.log(resp)
      // return resp
    } catch (error) {
      throw error;
    }
  }

  public async delete<iBrand>(id: number): Promise<any> {
    console.log("HttpBrandService delete called");
    const resp = await httpAdminService
      .delete<iBrand>(`/Catalogue/Category/`, id)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async update<iBrand>(brand: iBrand): Promise<iBrand> {
    console.log("HttpBrandService update called");
    const resp = await httpAdminService
      .update(`/brand`, brand)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updateMany<iBrand>(user: iBrand[]): Promise<iBrand[]> {
    console.log("HttpBrandService updateMany called");

    return [];
  }
  public async filterBy<iBrand>(
    pageType: iPageType,
    params: any
  ): Promise<AxiosResponse<iBrand[], any>> {
    let pageSuffix =
      "?name=" + params.name + "&page=" + 1 + "&count=" + appConst.PAGE_LIMIT;
    const resp = await httpAdminService
      .filterBy(`/brand` + pageSuffix)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async post(url: string, body: any) {
    try {
      return await axios.post(url, body, axiosConfig);
    } catch (error) {
      throw error;
    }
  }
}

export class HttpCategoryService implements iListType<iCategory> {
  public async getAllBrands<iCategory>(
    pageType: iPageType
  ): Promise<any> {
    console.log("HttpCategoryService getAllBrands called");
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      return await httpAdminService
        .getAll<iCategory>("/Catalogue/Category/Brands" + pageSuffix, pageType)
        .then((value) => {
          console.log("getAllBrands", value);
          return value;
        });
      // console.log(resp)
      // return resp
    } catch (error) {
      throw error;
    }
  }

  public async add<iCategory>(
    p_cat: iCategory
  ): Promise<AxiosResponse<iCategory, any>> {
    console.log("HttpBrandService====================== add called", p_cat);

    const resp = await httpAdminService
      .post("/Catalogue/Category", p_cat)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async get<iCategory>(
    params: any
  ): Promise<AxiosResponse<iCategory, any>> {
    console.log("HttpBrandService get called");
    try {
      return await httpAdminService
        .get<iCategory>("/Catalogue/Category/" + params.id, "")
        .then((value) => {
          console.log("aaaa value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  // public async getAll<iCategory>(
  //   pageType: iPageType
  // ): Promise<AxiosResponse<iCategory[], any>> {
  //   console.log("HttpCategoryService get called");
  //   try {
  //     return await httpAdminService
  //       .getAll<iCategory>("/Catalogue/Category", pageType)
  //       .then((value) => {
  //         console.log("value", value);
  //         return value;
  //       });
  //     // console.log(resp)
  //     // return resp
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  public async delete<iCategory>(id: number): Promise<any> {
    console.log("HttpCategoryService delete called");
    const resp = await httpAdminService
      .delete<iCategory>(`/Catalogue/Category/`, id)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async update<iCategory>(
    id: number,
    category: iCategory
  ): Promise<iCategory> {
    console.log("HttpBrandService update called");
    const resp = await httpAdminService
      .update(`/Catalogue/Category/` + id, category)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updatePUT<iCategory>(
    id: number,
    category: iCategory
  ): Promise<AxiosResponse<iCategory, any>> {
    console.log("HttpBrandService updatePUT called");
    const resp = await httpAdminService
      .updatePUT(`/Catalogue/Category/` + id, category)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updateMany<iCategory>(user: iCategory[]): Promise<iCategory[]> {
    console.log("HttpCategoryService updateMany called");

    return [];
  }
  public async filterBy<iCategory>(
    pageType: iPageType,
    params: any
  ): Promise<any> {
    let pageSuffix = "?page=" + pageType.currentPage + "&count=" + pageType.pageLimit;
    //if(params.parentId) {
    pageSuffix += "&parentId=" + params.parentId
    //}
    if (params.query) {
      pageSuffix += "&query=" + params.query
    }
    console.log('Prefix', pageSuffix)
    const resp = await httpAdminService
      .filterBy(`/Catalogue/Category` + pageSuffix)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async filterByCategoryId<iCategory>(
    pageType: iPageType,
    params: any
  ): Promise<any> {
    let pageSuffix = "?page=" + pageType.currentPage + "&count=" + pageType.pageLimit;
    if (params.query) {
      pageSuffix += "&query=" + params.query
    }
    const resp = await httpAdminService
      .filterBy(`/Catalogue/Category/` + params.id + pageSuffix)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async filterByBrandIds<iCategory>(
    pageType: iPageType,
    params: any
  ): Promise<any> {
    let pageSuffix = "?page=" + pageType.currentPage + "&count=" + pageType.pageLimit;
    if (params.ids && params.ids.length) {
      pageSuffix += "&parentIds=" + (params.ids as number[]).join(',')
    }
    if (params.query) {
      pageSuffix += "&query=" + params.query
    }
    const resp = await httpAdminService
      .filterBy(`/Catalogue/SubCategories` + pageSuffix)
      .then(responseBody);
    console.log(resp);
    return resp;
  }


  public async post(url: string, body: any) {
    try {
      return await axios.post(url, body, axiosConfig);
    } catch (error) {
      throw error;
    }
  }
}

export class HttpShoeModelService implements iListType<iShoeModel> {
  // public async add<iBrand>(brand: iBrand): Promise<AxiosResponse<iBrand,any>> {
  public async add<iShoeModel>(p_shoeModel: iShoeModel): Promise<iShoeModel> {
    console.log(
      "HttpShoeModelService====================== add called",
      p_shoeModel
    );

    const resp = await httpAdminService
      .post("/ShoeModel", p_shoeModel)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async get<iShoeModel>(
    params: any
  ): Promise<AxiosResponse<iShoeModel, any>> {
    console.log("HttpShoeModelService get called");
    try {
      return await httpAdminService
        .get<iShoeModel>("/ShoeModel/" + params.id, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async getShoeModelColors<iShoeColor>(
    params: any
  ): Promise<AxiosResponse<iShoeColor, any>> {
    console.log("HttpShoeModelService get called");
    try {
      return await httpAdminService
        .get<iShoeColor>("/ShoeModel/" + params.id + '/colors', "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async getAll<iShoeModel>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    console.log("HttpShoeModelService get called");
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      return await httpAdminService
        .getAll<iShoeModel>("/ShoeModel" + pageSuffix, pageType)
        .then((value) => {
          console.log("value", value);
          return value;
        });
      // console.log(resp)
      // return resp
    } catch (error) {
      throw error;
    }
  }

  public async delete<iShoeModel>(id: number): Promise<any> {
    console.log("HttpShoeModelService delete called");
    const resp = await httpAdminService
      .delete<iShoeModel>(`/ShoeModel/`, id)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async update<iShoeModel>(
    id: number,
    p_shoeModel: iShoeModel
  ): Promise<iShoeModel> {
    console.log("HttpShoeModelService update called");
    const resp = await httpAdminService
      .update(`/ShoeModel/` + id, p_shoeModel)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updatePUT<iShoeModel>(
    id: number,
    p_shoeModel: iShoeModel
  ): Promise<AxiosResponse<iShoeModel, any>> {
    console.log("HttpShoeModelService updatePUT called");
    const resp = await httpAdminService
      .updatePUT(`/ShoeModel/` + id, p_shoeModel)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updateMany<iShoeModel>(
    user: iShoeModel[]
  ): Promise<iShoeModel[]> {
    console.log("HttpShoeModelService updateMany called");

    return [];
  }
  public async filterBy<iShoeModel>(
    //query: string,
    pageType: iPageType,
    params: any
  ): Promise<any> {
    console.log("Service Called");
    try {
      let pageSuffix = "?page=" + pageType.currentPage + "&count=" + pageType.pageLimit;
      if (params.query) {
        pageSuffix += "&query=" + params.query;
      }
      const resp = await httpAdminService
        .filterBy<iShoeModel>(`/Search/shoemodels` + pageSuffix)
        .then(responseBody)
        .then((value) => {
          //console.log('aaaa', value);
          return value;
        });
      console.log('resp', resp)
      return resp
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async filterBySearchFilters<iShoeModel>(
    //query: string,
    pageType: iPageType,
    params: any
  ): Promise<any> {
    console.log("Service Called");
    try {
      let pageSuffix = "?page=" + pageType.currentPage + "&count=" + pageType.pageLimit;
      // if (params.query) {
      //   pageSuffix += "&query=" + params.query;
      // }
      if (params.filters) {
        pageSuffix += params.filters;
      }
      const resp = await httpAdminService
        .filterBy<iShoeModel>(`/Shoemodel/searchModels` + pageSuffix)
        .then(responseBody)
        .then((value) => {
          //console.log('aaaa', value);
          return value;
        });
      //console.log('resp', resp)
      return resp
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async post(url: string, body: any) {
    try {
      return await axios.post(url, body, axiosConfig);
    } catch (error) {
      throw error;
    }
  }

  public async addImage<iShoeModelImage>(
    id: number,
    p_shoeModelImage: iShoeModelImage
  ): Promise<iShoeModelImage> {
    console.log(
      "HttpShoeModelService====================== add image called",
      p_shoeModelImage
    );

    const resp = await httpAdminService
      .post(`/ShoeModel/` + id + "/image", p_shoeModelImage)
      .then(responseBody);
    console.log("HttpShoeModelService", resp);
    return resp;
  }

  public async getImages<iShoeModelImage>(
    id: number,
    pageType: iPageType
  ): Promise<iShoeModelImage[]> {
    console.log("HttpShoeModelService====================== getImages called");

    const resp = await httpAdminService
      .getAll(`/ShoeModel/` + id + "/image", pageType)
      .then(responseBody);
    console.log("HttpShoeModelService", resp);
    return resp;
  }
  public async deleteImage(shoeModelId: number, imageId: number): Promise<any> {
    console.log(
      "HttpShoeModelService====================== deleteImage called"
    );

    const resp = await httpAdminService
      .delete(`/ShoeModel/${shoeModelId}/image/${imageId}`, "")
      .then(responseBody);
    console.log("HttpShoeModelService", resp);
    return resp;
  }
  public async setDefaultImage(
    shoeModelId: number,
    imageId: number,
    p_ShoeModelPhotographs: iShoeModelPhotographs
  ): Promise<any> {
    console.log(
      "HttpShoeModelService====================== setDefaultImage called"
    );
    const resp = await httpAdminService
      .updatePUT(
        `/ShoeModel/${shoeModelId}/image/${imageId}/setdefault`,
        p_ShoeModelPhotographs
      )
      .then(responseBody);
    console.log("HttpShoeModelService", resp);
    return resp;
  }

  public async setOrderedImages(
    shoeModelId: number,
    p_ShoeModelPhotographs: iShoeModelPhotographs[]
  ): Promise<any> {
    console.log(
      "HttpShoeModelService====================== setOrderedImages called"
    );

    const resp = await httpAdminService
      .updatePUT(
        `/ShoeModel/` + shoeModelId + "/imageorder",
        p_ShoeModelPhotographs
      )
      .then(responseBody);
    console.log("HttpShoeModelService", resp);
    return resp;
  }
}

export class HttpAuthService implements iListType<iAuthUser> {
  public async login<iAuthUser>(p_AuthUser: iAuthUser): Promise<any> {
    //console.log("HttpAuthService====================== add called", p_AuthUser);

    const resp = await httpAdminAuthService
      .post("/Auth/AdminLogin", p_AuthUser)
      .then(responseBody);
    //console.log(resp);
    return resp;
  }
  public async logout<iAuthUser>(userId: number): Promise<any> {
    //console.log("HttpAuthService====================== add called", userId);

    const resp = await httpAdminAuthService
      .updatePUT("/Auth/AdminLogout?userId=" + userId, '')
      .then(responseBody);
    //console.log(resp);
    return resp;
  }
  // public async register<iAuthUser>(id: number):  Promise<AxiosResponse<iAuthUser, any>> {
  //   console.log('HttpAuthService get called')
  //   try {
  //     return await httpAdminAuthService.get<iAuthUser>('/ShoeModel' , '')
  //                                      .then((value) => {
  //                                         console.log('value', value)
  //                                         return value
  //                                      })
  //   } catch(error) {
  //     console.log('error', error)
  //     throw error
  //   }

  // }
}

export class HttpSettingService implements iListType<iSetting> {
  public async getBlobURL(): Promise<any> {
    const resp = await httpAdminAuthService
      .get("/Admin/bloburl", "")
      .then(responseBody);
    //console.log(resp);
    return resp;
  }

  public async getAll<iSetting>(): Promise<AxiosResponse<iSetting[], any>> {
    //console.log("HttpSettingService getAll called");
    try {
      return await httpAdminAuthService
        .getAll("/Admin/Settings", "")
        .then((value) => {
          //console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async post<iSetting>(p_Settings: iSetting[]): Promise<iSetting> {
    console.log(p_Settings);
    const resp = await httpAdminAuthService
      .post("/Admin/Settings", p_Settings)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async getNotificationSetting<iNotificationSetting>(): Promise<
    AxiosResponse<iNotificationSetting[], any>
  > {
    try {
      return await httpAdminAuthService
        .getAll("/Admin/NotificationTypes", "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async updateNotificationSetting(
    setting: iNotificationSetting[]
  ): Promise<AxiosResponse<iNotificationSetting, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Admin/NotificationTypes`, setting)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async getShippingFees<B2BShippingFees>(beneficiaryType: number): Promise<AxiosResponse<B2bShippingFees, any>> {
    try {
      return await httpAdminAuthService
        .getAll(`/Admin/B2BShippingFees?beneficiaryType=${beneficiaryType}`, "")
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async getAllFAQ<iCommonModelType>(
    pageType: iPageType
  ): Promise<AxiosResponse<iCommonModelType, any>> {
    try {
      return await httpAdminAuthService
        .getAll<iCommonModelType>("/Settings/getAllFAQ", pageType)
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async getContent<iCommonModelType>(
    key: string
  ): Promise<AxiosResponse<iCommonModelType, any>> {
    try {
      return await httpAdminAuthService
        .get<iCommonModelType>(`/Settings/getContent?key=${key}`, {})
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
}

export class HttpSearchSettingService implements iListType<iSearchCriteria> {
  public async getAll<iSearchCriteria>(): Promise<
    AxiosResponse<iSearchCriteria[], any>
  > {
    console.log("HttpSettingService getAll called");
    try {
      return await httpAdminAuthService
        .getAll("/Admin/SearchSettings", "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async post<iSearchCriteria>(
    p_iSearchCriteria: iSearchCriteria[]
  ): Promise<iSearchCriteria> {
    console.log(p_iSearchCriteria);
    const resp = await httpAdminAuthService
      .post("/Admin/SearchSettings", p_iSearchCriteria)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
}

export class HttpWarehouseb2bService implements iListType<iWarehouseb2b> {
  public async getAllLots<iWarehouseb2b>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    console.log("HttpWarehouseb2bService getAll called");
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      //return await httpAdminService.getAll<iWarehouseb2b>('/Search/lots/latest'+ pageSuffix, pageType)
      return await httpAdminService
        .getAll<iWarehouseb2b>("/Inventory/Lot" + pageSuffix, pageType)
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async getAllRequests<iWarehouseb2b>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    console.log("HttpWarehouseb2bService getAll called");
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      //return await httpAdminService.getAll<iWarehouseb2b>('/Search/lots/latest'+ pageSuffix, pageType)
      return await httpAdminService
        .getAll<iWarehouseb2b>("/Inventory/Request" + pageSuffix, pageType)
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async get<iBatchModel>(
    params: any
  ): Promise<AxiosResponse<iBatchModel, any>> {
    console.log("HttpOrderService get service called");
    try {
      return await httpAdminService
        .get<iBatchModel>("/Inventory/" + params.id, "")
        .then((value) => {
          console.log("iWarehouseb2b value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async delete<iWarehouseb2b>(id: number): Promise<any> {
    console.log("HttpWarehouseb2bService delete called");
    const resp = await httpAdminService
      .delete<iWarehouseb2b>(`/Inventory/`, id)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async getLots<iWarehouseb2b>(
    pageType: iPageType,
    query: string
  ): Promise<iWarehouseb2b> {
    let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}&query=${query}`;
    const resp = await httpAdminService
      .get(`/Inventory/Lot` + pageSuffix + '&type=lot', {})
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async getRequests<iWarehouseb2b>(
    pageType: iPageType,
    query: string
  ): Promise<iWarehouseb2b> {
    let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}&query=${query}`;
    const resp = await httpAdminService
      .get(`/Inventory/Request` + pageSuffix + '&type=request', {})
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async getForceReloadSettings() {
    try {
      return await httpAdminService
        .get<void>("/Inventory/ForceReloadSettings", "")
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
}

export class HttpOrdersService implements iListType<iOrderModel> {
  public async getAll<iOrderModel>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    console.log("HttpOrderService getAll called", pageType.currentPage);
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      return await httpOrderService
        .getAll<iOrderModel>("/Order" + pageSuffix, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async get<iOrderModel>(
    params: any
  ): Promise<AxiosResponse<iOrderModel, any>> {
    console.log("HttpOrderService get service called");
    try {
      return await httpOrderService
        .get<iOrderModel>("/Order/" + params.orderId, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async filterBy<iOrderModel>(
    pageType: iPageType,
    params: any
  ): Promise<any> {
    let pageSuffix = "?";
    pageSuffix += params.id !== -1 ? "id=" + params.id : "";
    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += params.fromDate
      ? "fromDate=" + moment(params.fromDate as Date).format("MM/DD/YYYY")
      : "";
    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += params.toDate
      ? "toDate=" + moment(params.toDate as Date).format("MM/DD/YYYY")
      : "";
    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += params.status !== "-1" ? "orderStatus=" + params.status : "";
    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += params.buyerId !== -1 ? "buyerId=" + params.buyerId : "";
    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += params.sellerId !== -1 ? "sellerId=" + params.sellerId : "";
    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += params.city ? "city=" + params.city : "";

    let currentPage = pageType.currentPage ? pageType.currentPage : 1;
    let pageLimit = pageType.pageLimit
      ? pageType.pageLimit
      : appConst.PAGE_LIMIT;

    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += currentPage ? "page=" + currentPage : "";

    pageSuffix += !["=", "?", "&"].includes(
      pageSuffix.charAt(pageSuffix.length - 1)
    )
      ? "&"
      : "";
    pageSuffix += pageLimit ? "count=" + pageLimit : "";

    // pageSuffix =  pageSuffix + '&page=' + currentPage + '&count=' + pageLimit
    console.log('pageSuffix', pageSuffix)
    const resp = await httpOrderService
      .filterBy<iOrderModel>(`/Order` + pageSuffix)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updatePUT<iOrderModel>(
    id: number,
    p_iOrderModel: any
  ): Promise<AxiosResponse<iOrderModel, any>> {
    console.log("HttpOrdersService updatePUT called");
    const resp = await httpOrderService
      .updatePUT(
        `/Order/order/` +
        id +
        "/status?status=" +
        eOrderStatusType[p_iOrderModel.status] +
        "&message=" +
        p_iOrderModel.description,
        ""
      )
      .then(responseBody)
      .catch((error) => {
        console.log(error)
        throw error
      });
    console.log(resp);
    return resp;
  }
  public async getBatchOffer<iBatchOfferModel>(
    params: any
  ): Promise<AxiosResponse<iBatchOfferModel[], any>> {
    console.log("HttpOrderService get service called");
    try {
      return await httpAdminService
        .get<iBatchOfferModel[]>("/Inventory/" + params.id + '/BatchOffers', "")
        .then((value) => {
          console.log("Batch offer value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async getForceReloadSettings() {
    try {
      return await httpOrderService
        .get<void>("/Order/ForceReloadSettings", "")
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
}

export class HttpOrderHistoryService implements iListType<iOrderHistory> {
  public async get<iOrderHistory>(
    params: any
  ): Promise<AxiosResponse<iOrderHistory, any>> {
    console.log("HttpOrderHistoryService get service called");
    try {
      return await httpOrderService
        .get<iOrderHistory>("/Order/order/" + params.orderId + "/History", "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
}

export class HttpLabelService implements iListType<iLabel> {
  public async get<iLabel>(params: any): Promise<AxiosResponse<iLabel, any>> {
    try {
      return await httpOrderService
        .get<iLabel>("/Order/" + params.orderId + "/label/shipment", "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
}
export class HttpCouponsService implements iListType<iCouponModel> {
  public async add<iCouponModel>(
    p_iCouponModel: iCouponModel
  ): Promise<iCouponModel> {
    console.log(p_iCouponModel);
    const resp = await httpOrderService
      .post("/Coupon", p_iCouponModel)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async getAll<iCouponModel>(
    pageType: iPageType,
    couponFilters?: CouponFilters
  ): Promise<AxiosResponse<any, any>> {
    try {
      // let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;

      if (couponFilters) {
        if (couponFilters.marketPlace) {
          pageSuffix += `&marketPlace=${couponFilters.marketPlace}`;
        }
        if (couponFilters.userId !== null && couponFilters.userId?.id !== 0) {
          pageSuffix += `&userId=${couponFilters.userId.id}`;
        }
        if (couponFilters.modelId !== null && couponFilters.modelId.id !== 0) {
          pageSuffix += `&modelId=${couponFilters.modelId.id}`;
        }
        if (couponFilters.categoryId && couponFilters.categoryId !== 0) {
          pageSuffix += `&categoryId=${couponFilters.categoryId}`;
        }
        if (couponFilters.activeOnly !== undefined) {
          pageSuffix += `&activeOnly=${couponFilters.activeOnly}`;
        }
      }
      return await httpOrderService
        .getAll<iCouponModel>("/Coupon" + pageSuffix, "")
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async delete<iCouponModel>(id: number): Promise<any> {
    console.log("delete coupon called");
    const resp = await httpOrderService
      .delete<iCouponModel>("/Coupon/", id)
      .then(responseBody);
    return resp;
  }
  public async updateCouponStatus<iCouponModel>(id: number): Promise<any> {
    const resp = await httpOrderService
      .updatePUT(`/Coupon/${id}/status?active=false`, "")
      .then(responseBody);
    return resp;
  }
  // public async get<iCouponModel>(params: any): Promise<AxiosResponse<iCouponModel, any>> {
  //   console.log('HttpOrderService get service called')
  //   try {
  //     return await httpOrderService.get<iCouponModel>('/user/' + params.id + '/Order/' + params.orderId, '').then((value) => {
  //       console.log('value', value)
  //       return value
  //     })
  //   } catch (error) {
  //     console.log('error', error)
  //     throw error
  //   }
  // }
}
export class HttpShoeModelSizeService implements iListType<iShoeModelSize> {
  public async addShoeSize<iShoeModelSize>(
    s_size: iShoeModelSize,
    //brandId: number,
    sizeTypeId: number
  ): Promise<iShoeModelSize> {
    console.log(s_size);
    const resp = await httpAdminService
      .post("/Catalogue/ShoeSize/" + sizeTypeId, s_size)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async get<iShoeModelSize>(
    params: any
  ): Promise<AxiosResponse<iShoeModelSize, any>> {
    console.log("Httpsize service called");
    try {
      return await httpAdminService
        .get<iShoeModelSize>("/Catalogue/ShoeSize" + params.id, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async getAll<iShoeModelSize>(
    pageType: iPageType
  ): Promise<AxiosResponse<iShoeModelSize[], any>> {
    console.log("Service Called");
    try {
      return await httpAdminService
        .getAll<iShoeModelSize>("/Catalogue/ShoeSize", pageType)
        .then((value) => {
          console.log(value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async getAllByBrandAndSizeType<iShoeModelSize>(
    pageType: iPageType,
    //brandId: number,
    sizeTypeId: number
  ): Promise<AxiosResponse<iShoeModelSize[], any>> {
    console.log("Service Called");
    try {
      return await httpAdminService
        .getAll<iShoeModelSize>("/Catalogue/ShoeSize/brand/" + sizeTypeId, pageType)
        .then((value) => {
          console.log(value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async getAllSizeTypes<iSizeType>(
    pageType: iPageType,
    brandId: number
  ): Promise<AxiosResponse<iSizeType[], any>> {
    try {
      return await httpAdminService
        .getAll<iSizeType>("/Catalogue/sizeType/" + brandId, pageType)
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async getSizeTypes<iSizeType>(
  ): Promise<AxiosResponse<iSizeType[], any>> {
    try {
      return await httpAdminService
        .getAll<iSizeType>(`/Catalogue/SizeTypes`, {})
        .then((value) => {
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
  public async post(url: string, body: any) {
    try {
      return await axios.post(url, body, axiosConfig);
    } catch (error) {
      throw error;
    }
  }

  public async updatePUT<iShoeModelSize>(
    id: number,
    size: iShoeModelSize
  ): Promise<AxiosResponse<iShoeModelSize, any>> {
    console.log("Shoesize updatePUT called");
    const resp = await httpAdminService
      .updatePUT(`/Catalogue/ShoeSize/` + id, size)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
}
export class HttpShoeColorService implements iListType<iShoeColor> {
  public async add<iShoeColor>(c_colour: iShoeColor): Promise<iShoeColor> {
    console.log(c_colour);
    const resp = await httpAdminService
      .post("/Catalogue/ShoeColor", c_colour)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async get<iShoeColor>(
    params: any
  ): Promise<AxiosResponse<iShoeColor, any>> {
    console.log("Service Called");
    try {
      return await httpAdminService
        .get<iShoeColor>("/Catalogue/ShoeColor" + params.id, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async getAll<iShoeColor>(
    //query: string,
    pageType: iPageType
  ): Promise<any> {
    console.log("Service Called");
    try {

      const resp = await httpAdminService
        .getAll<iShoeColor>("/Catalogue/ShoeColor", pageType)
        .then(responseBody)
      // .then((value) => {
      //   console.log('aaaa',value);
      //   return value;
      // });
      //console.log('resp', resp)
      return resp
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async filterBy<iShoeColor>(
    //query: string,
    pageType: iPageType,
    params: any
  ): Promise<any> {
    console.log("Service Called");
    try {
      let pageSuffix = "?page=" + pageType.currentPage + "&count=" + pageType.pageLimit;
      if (params.query) {
        pageSuffix += "&query=" + params.query;
      }
      const resp = await httpAdminService
        .filterBy<iShoeColor>("/Catalogue/ShoeColor" + pageSuffix)
        .then(responseBody)
        .then((value) => {
          //console.log('aaaa', value);
          return value;
        });
      //console.log('resp', resp)
      return resp
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }
  public async post(url: string, body: any) {
    try {
      return await axios.post(url, body, axiosConfig);
    } catch (error) {
      throw error;
    }
  }

  public async updatePUT<iShoeColor>(
    id: number,
    color: iShoeColor
  ): Promise<AxiosResponse<iShoeColor, any>> {
    console.log("ShoeColor updatePUT called");
    const resp = await httpAdminService
      .updatePUT(`/Catalogue/ShoeColor/` + id, color)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
}

export class HttpLegalService {
  public async get(key: string): Promise<AxiosResponse<string, any>> {
    try {
      return await httpAdminAuthService
        .getAll("/Settings/getContent?key=" + key, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async updatePUT(
    content: iLegalText
  ): Promise<AxiosResponse<any, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Settings/updateContent`, content)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async updatePUTPdf(
    content: iLegalPdf
  ): Promise<AxiosResponse<any, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Settings/updatepdf`, content)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
}

export class HttpFAQService implements iListType<iFaq> {
  public async add<iFaq>(values: iFaq): Promise<iFaq> {
    const resp = await httpAdminAuthService
      .post("/Settings/addFAQ", values)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async getAll<iFaq>(
    pageType: iPageType
  ): Promise<AxiosResponse<iFaq[], any>> {
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      return await httpAdminAuthService
        .getAll("/Settings/getAllFAQ" + pageSuffix, pageType)
        .then((value) => {
          console.log(value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async updatePUT<iFaq>(
    values: iFaq
  ): Promise<AxiosResponse<iFaq, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Settings/updateFAQ`, values)
      .then(responseBody);
    return resp;
  }

  public async delete<iFaq>(id: number): Promise<any> {
    const resp = await httpAdminAuthService
      .delete<iFaq>("/Settings/deleteFAQ?id=" + id, "")
      .then(responseBody);
    return resp;
  }
}

export class HttpRewardsLevelService implements iListType<iRewardsLevel> {
  public async add<iRewardsLevel>(
    values: iRewardsLevel
  ): Promise<iRewardsLevel> {
    console.log(values);
    const resp = await httpAdminAuthService
      .post("/RewardLevel", values)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async get<iRewardsLevel>(
    params: any
  ): Promise<AxiosResponse<iRewardsLevel, any>> {
    try {
      return await httpAdminService
        .get<iRewardsLevel>("/RewardLevel" + params.id, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async getAll<iRewardsLevel>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    try {
      return await httpAdminAuthService
        .getAll<iRewardsLevel>("/RewardLevel", pageType)
        .then((value) => {
          console.log(value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async updatePUT<iRewardsLevel>(
    values: iRewardsLevel
  ): Promise<AxiosResponse<iRewardsLevel, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/RewardLevel`, values)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async delete<iFaq>(id: number): Promise<any> {
    const resp = await httpAdminAuthService
      .delete<iFaq>("/RewardLevel/?id=" + id, "")
      .then(responseBody);
    return resp;
  }
}
export class HttpPrizeService implements iListType<iPrize> {
  public async add<iPrize>(values: iPrize): Promise<iPrize> {
    console.log(values);
    const resp = await httpAdminAuthService
      .post("/Prize", values)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async get<iPrize>(params: any): Promise<AxiosResponse<iPrize, any>> {
    try {
      return await httpAdminService
        .get<iPrize>("/Prize" + params.id, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async getAll<iPrize>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;

      return await httpAdminAuthService
        .getAll<iPrize>("/Prize" + pageSuffix, pageType)
        .then((value) => {
          console.log(value);
          return value;
        });
    } catch (error) {
      console.log("error", error);
      throw error;
    }
  }

  public async updatePUT<iPrize>(
    values: iPrize
  ): Promise<AxiosResponse<iPrize, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Prize`, values)
      .then(responseBody);
    return resp;
  }
  public async delete<iPrize>(id: number): Promise<any> {
    const resp = await httpAdminAuthService
      .delete<iPrize>("/Prize/?id=" + id, "")
      .then(responseBody);
    return resp;
  }
}

export class HttpPenaltyService implements iListType<iPenalty> {
  public async getAll<iPenalty>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    console.log("HttpPenaltyService getAll called");
    try {
      return await httpAdminAuthService
        .getAll<iPenalty>("/Penalty", "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async get<iPenalty>(
    type: number
  ): Promise<AxiosResponse<iPenalty, any>> {
    try {
      return await httpAdminAuthService
        .getAll<iPenalty>(`Penalty/automatic?type=${type}&page=1&count=10`, "")
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async delete<iPenalty>(id: number): Promise<any> {
    const resp = await httpAdminAuthService
      .delete<iPenalty>("/Penalty?id=" + id, "")
      .then(responseBody);
    return resp;
  }

  public async post<iPenalty>(p_iPenalty: iPenalty): Promise<iPenalty> {
    console.log(p_iPenalty);
    const resp = await httpAdminAuthService
      .post("/Penalty/manual", p_iPenalty)
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async apply<iPenalty>(id: number): Promise<iPenalty> {
    const resp = await httpAdminAuthService
      .post("/Penalty/apply?id=" + id, "")
      .then(responseBody);
    console.log(resp);
    return resp;
  }
  public async updatePUT<iPenalty>(
    values: iPenalty
  ): Promise<AxiosResponse<iPenalty, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Penalty`, values)
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async update<iPenalty>(
    values: iPenalty
  ): Promise<AxiosResponse<iPenalty, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Penalty/automatic`, values)
      .then(responseBody);
    return resp;
  }
}

export class HttpNotificationService implements iListType<iNotification> {
  public async add<iNotification>(
    values: iNotification
  ): Promise<iNotification> {
    console.log(values);
    const resp = await httpAdminAuthService
      .post("/Notification", values)
      .then(responseBody);
    return resp;
  }

  public async getAll<iNotification>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;

      return await httpAdminAuthService
        .getAll<iNotification>("/Notification" + pageSuffix, pageType)
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }

  public async getImages<iNotification>(
    id: number,
    pageType: iPageType
  ): Promise<iNotification[]> {
    const resp = await httpAdminService
      .getAll(`/Notification/` + id + "/image", pageType)
      .then(responseBody);
    return resp;
  }

  public async publish<iNotification>(id: number): Promise<iNotification> {
    const resp = await httpAdminAuthService
      .get("/Notification/publishNotification?id=" + id, "")
      .then(responseBody);
    console.log(resp);
    return resp;
  }

  public async updatePUT<iNotification>(
    values: iNotification
  ): Promise<AxiosResponse<iRewardsLevel, any>> {
    const resp = await httpAdminAuthService
      .updatePUT(`/Notification`, values)
      .then(responseBody);
    return resp;
  }

  public async delete<iNotification>(id: number): Promise<any> {
    const resp = await httpAdminAuthService
      .delete<iNotification>("/Notification/?id=" + id, "")
      .then(responseBody);
    return resp;
  }
}

export class HttpNewsLatterService implements iListType<iNewsLatter> {
  public async publish<iNewsLatter>(values: iNewsLatter): Promise<iNewsLatter> {
    console.log(values);
    const resp = await httpAdminAuthService
      .post("/Notification/publishNewsLetters", values)
      .then(responseBody);
    return resp;
  }

  public async getAll<iNewsLatter>(
    pageType: iPageType
  ): Promise<AxiosResponse<any, any>> {
    try {
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      return await httpAdminAuthService
        .getAll<iNewsLatter>(
          "/Notification/getAllNewsletters" + pageSuffix,
          pageType
        )
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
}

export class HttpUserRankingService implements iListType<iUserRanking> {
  public async getAsParams<iUserRanking>(
    pageType: iPageType,
    type: string
  ): Promise<AxiosResponse<any, any>> {
    try {
      let endpoint = "";
      if (type === "sales") {
        endpoint = "Admin/sale";
      } else if (type === "purchase") {
        endpoint = "Admin/purchase";
      } else if (type == "obtain") {
        endpoint = "Admin/purchaseamount";
      } else if (type == "spent") {
        endpoint = "Admin/saleamount";
      }
      let pageSuffix = `?page=${pageType.currentPage}&count=${pageType.pageLimit}`;
      return await httpAdminAuthService
        .getAll<iUserRanking>(endpoint + pageSuffix, pageType)
        .then((value) => {
          console.log("value", value);
          return value;
        });
    } catch (error) {
      throw error;
    }
  }
}
