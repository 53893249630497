import { Sidebar } from "../Sidebar"
import React, { useEffect, useState } from "react";
import { iCategory, iCustomAxiosError } from '../../../shared/models/adminModels'
import { DebounceInput } from 'react-debounce-input';
import { HttpCategoryService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import { useParams } from 'react-router-dom'
import { CommonUtil } from '../../../shared/utils/commonUtils'
import Category from '../../../shared/components/admin/category'
import useModal from "../../../../src/hooks/general"
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'

const BrandCategoryLevelPage = () => {

    const { t } = useTranslation()

    const [populatedCategory, setPopulatedCategory] = useState<iCategory>()
    const [category, setCategory] = useState<iCategory>()

    //For getting the sub catogray by name.
    const [brandCategory, setBrandCategory] = useState<iCategory>()
    const [searchCategory, setSearchCategory] = useState<string>('')
    // -------------- Pagination --------------------
    const [totalCategoryResults, setTotalCategoryResults] = useState(-1);
    const [totalCategoryPages, setTotalCategoryPages] = useState(-1)
    const [currentCategoryPage, setCurrentCategoryPage] = useState(1)
    // -------------- Pagination End --------------------
    
    const [filteredCategories, setFilteredCategories] = useState<iCategory[]>([])
    
    // const [categorys, setCategorys] = useState<iCategory[]>([])
    const [refreshCatId, setRefreshCatId] = useState<number>(-1)
    //For Modal Dialog
    const { isOpen, toggle } = useModal()

    const { isOpen: isEditOpen, toggle: toggleEdit } = useModal()


    const initializeCatagory = {
        id: -1,
        name: '',
        image: '',
        bgImage: '',
        childCategories: []
    }
    // For Adding Brand
    const [categoryToSave, setCategoryToSave] = useState<iCategory>(initializeCatagory)
    const { id } = useParams()

    console.log('id', id)

    const p_utils = new CommonUtil()
    const httpCategoryService: HttpCategoryService = new HttpCategoryService();

    function populatePathCategory(cat: iCategory): iCategory {
        p_utils.populateCategoryPath(cat)
        return cat
    }

    useEffect(() => {
        if (populatedCategory) {
            setBrandCategory(populatedCategory)
            // setCategory(populatedCategory)
        }
    }, [populatedCategory])

    // const refreshUI = async (idRefresh: number,
    //                          currentPage = 1) => {
    //     //console.log('in refreshUI idRefresh', idRefresh)
    //     if (!id) return
    //     await httpCategoryService
    //             .filterByCategoryId<iCategory>(
    //                 { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT },
    //                 { id: +id, query: ''})
    //         .then((value) => {
    //             console.log('got from api category', value)
    //             setBrandCategory(undefined)
    //             let p_cat = populatePathCategory(value.data)
    //             setPopulatedCategory(p_cat)
    //             setRefreshCatId(idRefresh)
    //         })
    //         .catch((error) => console.log(error))
    // }

    useEffect(() => {
        if (refreshCatId !== -1 && brandCategory) {
            console.log('in useEffect [refreshCatId, brandCategory] ', refreshCatId)
            setCategory(p_utils.getCategoryById(refreshCatId, brandCategory))
        }
    }, [refreshCatId, brandCategory])

    const refreshUI = async (idRefresh: number) => {
         //console.log('in refreshUI idRefresh', idRefresh)
        if (!id) return
        await httpCategoryService.get<iCategory>({ id: +id })
            .then((value) => {
                console.log('got from api category', value)
                setBrandCategory(undefined)
                let p_cat = populatePathCategory(value.data)
                setPopulatedCategory(p_cat)
                setRefreshCatId(idRefresh)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        console.log('BrandPage')
        if (id) refreshUI(+id)
    }, [id])


    const handelSetCurrentCategory = (p_Cat: iCategory) => {
        // console.log('p_Cat.name', p_Cat.name)
        setCategory(p_Cat)
    }
    const handelSetCurrentCategoryByName = (cat_name: string) => {
        // console.log('handelSetCurrentCategoryByName cat_name', cat_name)
        if (brandCategory) {
            console.log('brandCategory.name', brandCategory.name)
            let x: iCategory | undefined = p_utils.getCategoryByName(cat_name, brandCategory)
            console.log('x?.name', x?.name)

            setCategory(x)
        }
    }
    const handelGetCategoryChildCount = (cat_name: string): number | undefined => {
        //console.log('handelGetCategoryChildCount cat_name', cat_name)
        if (brandCategory) {
            //console.log('handelGetCategoryChildCount brandCategory.name', brandCategory.name)
            let x: iCategory | undefined = p_utils.getCategoryByName(cat_name, brandCategory)
            return (x) ? x.childCategories.length : 0
        }
    }

    const handelOnDelete = async (p_iCategory: iCategory) => {
        console.log('handelOnDelete', p_iCategory.id)
        let prev_cat_id: number = category ? category.id : 0
        await httpCategoryService.delete<iCategory>(p_iCategory.id)
            .then((value) => {
                refreshUI(prev_cat_id)
                toast.success(t`Delete_success_msg`)
            })
            .catch((error) => console.log(error))
    }
    
    useEffect(()=>{
        handelSearch(searchCategory)
    }, [searchCategory])

    const handelSearch = async (searchText: string) => {
        //Call api to delete the brand details.
        try {
            if (!brandCategory || !searchText) {
                setFilteredCategories([])
                return
            }
            let p_filteredCategories: iCategory[] = []
            p_utils.filterCategoryByName(searchText, brandCategory, p_filteredCategories)
            console.log('filteredCategories', p_filteredCategories)
            setFilteredCategories(p_filteredCategories)
            // refresh list of brands
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('handelSearch error', e.message)
        }
    }
    const onCategorySearchClick = (p_cat: iCategory) => {
        setCategory(p_cat)
        setSearchCategory('')
    
    }
    const onToggleClick = () => {
        console.log('in onToggleClick')
        setCategoryToSave(initializeCatagory)

        toggle()

        if (category) {
            console.log('category.id', category.id)
            // refreshUI(category.id)
        }

    }
    const cancelAdd = () => {
        console.log('in cancelAdd')
        setCategoryToSave(initializeCatagory)
        toggle()
    }
    const cancelEdit = () => {
        console.log('in cancelEdit')
        setCategoryToSave(initializeCatagory)
        toggleEdit()
    }

    const handelOnEdit = (p_iCategory: iCategory) => {
        setCategoryToSave({ ...p_iCategory })
        onEditToggleClick()
    }
    const onEditToggleClick = () => {
        console.log('in onEditToggleClick')
        toggleEdit()

        if (category) {
            console.log('category.id', category.id)
            //refreshUI(category.id)
        }

    }

    const onEditExecute = async () => {
        try {
            console.log('Current category', category)
            await httpCategoryService.updatePUT<iCategory>(categoryToSave.id, { ...categoryToSave })
            setCategoryToSave(initializeCatagory)
            toast.success(t`Edit_success_msg`)
            onEditToggleClick()
            refreshUI(category?.id ? category?.id : 0)
            // refresh list of brands
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onEditExecute error', e.message)
        }

    }

    const onExecute = async () => {
        try {
            if (brandCategory) {
                let x: iCategory | undefined = p_utils.getCategoryByName(categoryToSave.name, brandCategory)
                if (x) {
                    toast.success(t(`Existing_category`) + ' ' + x.path)
                    return
                }
            }
            let prev_cat_id = category ? category.id : 0
            await httpCategoryService.add<iCategory>({ ...categoryToSave, parentCategoryId: category?.id })
            setCategoryToSave(initializeCatagory)
            toast.success(t`Add_success_msg`)
            onToggleClick()
            refreshUI(prev_cat_id)
            // refresh list of brands
        } catch (error) {
            //TODO
            let e = error as iCustomAxiosError
            //TODO
            console.log('onExecute error', e.errorMessage)
        }
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">

                    <section className="page-content container-fluid">

                        {/* -- Begin Categories List -- */}
                        <div className="admin-content-wrapper">
                            <div className="admin-header">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-6">
                                        <h3>{t('Category_Categories')}</h3>
                                    </div>
                                    <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                        <div className="action-btn">
                                            <div className="input-group position-relative">
                                                {/* <input type="text" className="form-control" placeholder="Search..." /> */}
                                                <DebounceInput
                                                    className="form-control"
                                                    minLength={1}
                                                    debounceTimeout={500}
                                                    onChange={event => setSearchCategory(event.target.value) /*handelSearch(event.target.value)*/}
                                                    placeholder={t('Search')!}
                                                    value={searchCategory} />
                                                {/* <div className="input-group-append"></div> */}

                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                                </div>
                                                {filteredCategories.length ?
                                                    <div className="dropdown-search">
                                                        <ul>
                                                            {filteredCategories.map((value, index) =>
                                                                <li key={index} onClick={() => onCategorySearchClick(value)}>{value.path}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                    : null}
                                            </div>
                                            <a onClick={() => onToggleClick()} href="#" role="button" className="bulk-primary-btn bulk-sm add-category" data-toggle="modal" data-target="#addCategory">{t('Category_Add')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Category p_Category={category!}
                                setCurrentCategory={handelSetCurrentCategory}
                                setCurrentCategoryByName={handelSetCurrentCategoryByName}
                                getCategoryChildCount={handelGetCategoryChildCount}
                                onDelete={handelOnDelete}
                                onEdit={handelOnEdit}
                            />
                            {/* <div className="admin-breadcrumb">
                                <ul>
                                    <li><a href="#">Nike(14)</a></li>
                                    <li><a href="#">Jordan(10)</a></li>
                                    <li><a href="#">Rum(8)</a></li>
                                    <li><a href="#">Max(20)</a></li>
                                    <li>Outdoor(3)</li>

                                </ul>
                            </div>
                            <div className="brand-categories-list">
                                {categorys && categorys.map((category) =>
                                    <div className="brand-categories-box">
                                        <div className="brand-categories-box-in">
                                            <div className="brand-img"></div>
                                            <div>
                                                <a href="javascript:void(0)" className="list-link">{category.name}{`(${category.childCategories?.length})`}</a>
                                            </div>
                                            <div className="box-action-btns">
                                                <a href="javascript:void(0)" className="delete"><span className="icon dripicons-trash"></span></a>
                                                <a href="javascript:void(0)" className="edit"><span className="icon dripicons-pencil"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                            </div> */}
                            <ModalDialog toggle={() => toggle()}
                                cancel={() => cancelAdd()}
                                executeButtonTitle={() => { return t('MD_Add') }}
                                isOpen={isOpen}
                                execute={onExecute}
                                enableExecute={(categoryToSave.name) ? true : false}
                                modalTitle={t('Category_Add')}
                                useButtons={t("MD_Cancel") + '/'  + t("MD_Add")}
                            >
                                {/* The Body of Modal Dialog */}
                                <div className="form-group">
                                    <label>{t('Category_Name')}</label>
                                    <input
                                        type="text"
                                        value={categoryToSave.name}
                                        className="form-control"
                                        onChange={(evt) => setCategoryToSave({ ...categoryToSave, name: evt.target.value })} />
                                </div>

                                {/* <label>Brand Image:</label>
                            <div className="category-img">
                                <div className="uploaded-img">
                                    <img id="output" width="200" />
                                </div>
                                <div className="upload-img">
                                    <input type="file" accept="image/*" />
                                    <div className="img-icon"></div>
                                </div>
                            </div> */}
                            </ModalDialog>

                            <ModalDialog toggle={() => onEditToggleClick()}
                                cancel={() => cancelEdit()}
                                executeButtonTitle={() => { return t('MD_Update') }}
                                isOpen={isEditOpen}
                                execute={onEditExecute}
                                enableExecute={(categoryToSave.name) ? true : false}
                                modalTitle={t('Category_Edit')}
                                useButtons={t("MD_Cancel") + '/'  + t("MD_Update")}
                            >
                                {/* The Body of Modal Dialog */}
                                <div className="form-group">
                                    <label>{t('Category_Name')}</label>
                                    <input type="text" className="form-control"
                                        value={categoryToSave.name}
                                        onChange={(evt) => setCategoryToSave({ ...categoryToSave, name: evt.target.value })} />
                                </div>

                            </ModalDialog>
                        </div>
                        {/* -- End Categories List -- */}
                    </section>
                </div>
            </div>
        </div>




    );
};

export default BrandCategoryLevelPage