import React from "react";
import Spinner from "react-bootstrap/Spinner";
const LoadingSpinner = () => {
  return (
    <div className="d-flex justify-content-center my-3">
      <Spinner animation="border" variant="success" />
    </div>
  );
};

export default LoadingSpinner;
