import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iPrize } from '../../../shared/models/adminModels'
import ModalDialog from "../../../shared/components/admin/modalDialog";
import useModal from "../../../hooks/general"
import { AxiosError } from "axios";
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'
import { HttpPrizeService } from "../../../services/admin.service";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const PrizePage = () => {
    const { t } = useTranslation()
    // -------------- Pagination --------------------
    const [totalPrizeResults, setTotalPrizeResults] = useState(-1);
    const [totalPrizePages, setTotalPrizePages] = useState(-1)
    const [currentPrizePages, setCurrentPrizePage] = useState(1)
    // -------------- Pagination End --------------------

    //For Modal Dialog
    const { isOpen, toggle } = useModal()
    const initialPrizeToSave: iPrize = {
        id: 0,
        name: "",
        description: "",
        rewardPoints: 0
    }
    const [prizeToSave, setPrizeToSave] = useState<iPrize>(initialPrizeToSave)
    const [prizes, setPrizes] = useState<iPrize[]>([])
    const [currentState, setCurrentState] = useState<number>(0) // 0- add, 1-Edit, 2 -Photos

    const httpPrizeService: HttpPrizeService = new HttpPrizeService();

    const refreshPrize = (currentPage = 1) => {
        httpPrizeService.getAll<iPrize>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value: any) => {
                setPrizes(value.data.items)
                setTotalPrizeResults(value.data.totalRows)
                setTotalPrizePages(Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT))
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        refreshPrize();
    }, []);

    const deletePrize = async (prize: iPrize) => {
        if (window.confirm(t`Delete_confirm_msg` ?? "")) {
            // let prev_cat_id: number = category ? category.id : 0
            await httpPrizeService.delete<iPrize>(prize.id)
                .then((value) => {
                    toast.success(t`Delete_success_msg`)
                    refreshPrize()
                })
                .catch((error) => console.log(error))
        }
    }

    const onEditPrize = (prize: iPrize) => {
        setCurrentState(1)  // 0- add, 1-Edit, 2 -Photos
        setPrizeToSave(prize)
        toggle()
    }

    const renderPrize = (): ReactNode[] => {
        if (!prizeToSave) return []

        const returnNode: ReactNode[] = prizes?.map((prize: iPrize) => (
            <tr key={prize.id}>
                <td>{prize.id}</td>
                <td>{prize.name}</td>
                <td>{prize.description}</td>
                <td>{prize.rewardPoints}</td>
                <td>{<>
                    <a
                        onClick={() => onEditPrize(prize)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-original-title="Edit"
                        className="btn btn-bulk small me-1"
                        data-rel="tooltip" >
                        <i className="la la-pencil-square"></i>
                    </a>
                    <a
                        onClick={() => deletePrize(prize)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                        data-original-title="Edit"
                        className="btn btn-bulk small me-1"
                        data-rel="tooltip" >
                        <i className="la la-remove"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const onAddPrize = () => {
        setCurrentState(0)  // 0- add, 1-Edit, 2 -Photos
        toggle();
        setPrizeToSave({ ...initialPrizeToSave })
    }
    const cancelAddEdit = () => {
        console.log('in cancelAddEdit')
        setCurrentState(0)  // 0- add, 1-Edit, 2 -Photos
        setPrizeToSave({ ...initialPrizeToSave })
        toggle()
    }
    const onExecuteAddEdit = async () => {
        try {
            setPrizeToSave({ ...prizeToSave })
            if (currentState == 0) {
                await httpPrizeService.add<iPrize>({ ...prizeToSave })
                toast.success(t`Add_success_msg`)
                refreshPrize()
            } else if (currentState == 1) {    // 0- add, 1-Edit, 2 -Photos
                await httpPrizeService.updatePUT<iPrize>(prizeToSave)
                toast.success(t`Edit_success_msg`)
                refreshPrize()
            }
            toggle();
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onExecuteAddEditPrize error', e.message)
        }
    }

    const onPageChanged = (data: any) => {
        setCurrentPrizePage(data.currentPage);
        refreshPrize(data.currentPage);
    }
    const enableExecution = () => {
        if (prizeToSave.name && prizeToSave.rewardPoints && prizeToSave.description) {
            return true
        }
        return false;
    }
    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>{t('Prize')}</h3>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    <div className="action-btn">
                                        <a onClick={() => onAddPrize()}
                                            href="javascript:void(0)"
                                            className="bulk-primary-btn bulk-sm add-shoe-model"
                                            data-toggle="modal" data-target="#addPrizeModel">
                                            {t('Add_Prize')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shoe-model-grid">
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t('Id')}</th>
                                        <th>{t('Name')}</th>
                                        <th>{t('Description')}</th>
                                        <th>{t('Reward_Point')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {renderPrize()}
                                </tbody>
                            </table>

                        </div>
                        <div className="nav-direction">
                            {
                                totalPrizeResults > appConst.PAGE_LIMIT && totalPrizePages ?
                                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                        <Pagination totalRecords={totalPrizeResults}
                                            totalPages={totalPrizePages}
                                            currentPage={currentPrizePages}
                                            pageLimit={appConst.PAGE_LIMIT}
                                            pageNeighbours={1}
                                            onPageChanged={onPageChanged} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <ModalDialog toggle={() => onAddPrize()}
                            cancel={() => cancelAddEdit()}
                            executeButtonTitle={(): any => currentState == 0 ? t("MD_Add") : t('MD_Update')}
                            isOpen={isOpen}
                            execute={onExecuteAddEdit}
                            enableExecute={enableExecution()}
                            modalTitle={currentState == 0 ? t('Add_Prize') : t('Edit_Prize')}
                            useButtons={t("MD_Cancel") + '/' +  (currentState == 0 ? t("MD_Add") : t('MD_Update'))}
                        >
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className="form-group">
                                        <label>{t('Name')}:</label>
                                        <input type="text" className="form-control"
                                            value={prizeToSave.name}
                                            onChange={(evt) => setPrizeToSave({ ...prizeToSave, name: evt.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-12 mb-2">
                                    <div className="form-group">
                                        <label>{t('Description')}:</label>
                                        <textarea className="form-control"
                                            value={prizeToSave.description}
                                            onChange={(evt) => setPrizeToSave({ ...prizeToSave, description: evt.target.value })}
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="col-12 mb-2">
                                    <div className="form-group">
                                        <label>{t('Reward_Point')}:</label>
                                        <input type="text" className="form-control"
                                            value={prizeToSave.rewardPoints}
                                            onChange={(evt) => {
                                                const val = +evt.target.value;
                                                if (isNaN(+evt.target.value) || val > 20000) {
                                                    return
                                                }
                                                setPrizeToSave({ ...prizeToSave, rewardPoints: val })
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalDialog>
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div>
    )
};

export default PrizePage;