
import { iUser, iUserType, iSignUpMedium, iCategory, iShoeModel, iShoeModelPhotographs, eUserType, eSocialMediaName } from "./adminModels"
export class cUserType implements iUserType {
    id: number
    name: string
    createdAt: Date

    constructor() {
        this.id = 1
        this.name = "Admin"
        this.createdAt = new Date()
    }
}

export class cSignUpMedium implements iSignUpMedium {
    id: number
    socialMediaName: string

    constructor() {
        this.id = 1
        this.socialMediaName = "admin"
    }
}

export class cUser implements iUser {
    id: number
    firstname: string
    lastName: string
    gender: number
    email: string
    userTypeId: eUserType
    phone_no: string
    isEmailVerified: number
    signUpMediumId: eSocialMediaName
    loginTime: Date
    privacyPolicyConcent: number
    createdAt: Date
    status: number

    constructor() {
        this.id = 1
        this.firstname = 'a'
        this.lastName = 'surname'
        this.gender = 1
        this.email = "a@b.com"
        this.userTypeId = 1
        this.phone_no = "123456789"
        this.isEmailVerified = 0
        this.signUpMediumId = 1
        this.loginTime = new Date()
        this.privacyPolicyConcent = 1
        this.createdAt = new Date()
        this.status = 1
    }

}




export class cCategory implements iCategory {
    id: number
    categoryLevel: string
    image: string
    bgImage: string
    childCategories: iCategory[]
    name: string

    constructor() {
        this.id = 1
        this.categoryLevel = 'level 1'
        this.image = "aone.jpg"
        this.bgImage= "xyz.jpg"
        this.childCategories = []
        this.name = "Nike"
    }

}

export class cShoeModel implements iShoeModel {
    id: number
    modelCode: string;
    modelName: string;
    title: string
    categoryId: number
    sizeTypeId: number
    description: string
    releaseDate: Date
    exitPrice: number
    isHidden: boolean
    constructor() {
        this.id = 1
        this.modelCode = "NIKE_001"
        this.title = "a/b/c"
        this.modelName = "Nike model"
        this.categoryId = 7
        this.sizeTypeId = 0
        this.description = "nice shoes"
        this.releaseDate = new Date(Date.now())
        this.exitPrice = 50.99
        this.isHidden = false
    }

}

export class cShoeModelPhotographs implements iShoeModelPhotographs {
    id: number
    shoeModelsId?: number
    fileId: string
    name: string
    isDefault: boolean
    constructor(image_file_name: string,
        is_default: boolean,
        shoe_models_id?: number,
        id?: number) {
        this.id = 1
        this.shoeModelsId = shoe_models_id
        this.fileId = "test"
        this.name = image_file_name
        this.isDefault = is_default
    }
}



