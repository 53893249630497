import React, { Fragment, ReactNode } from "react";
import { iCategory } from '../../../shared/models/adminModels'
export interface iCat {
    p_Category: iCategory,
    setCurrentCategory: (p_category: iCategory) => void
    setCurrentCategoryByName: (cat_name: string) => void
    getCategoryChildCount: (cat_name: string) => number | undefined
    onDelete?: (p_iCategory: iCategory) => void
    onEdit?: (p_iCategory: iCategory) => void
}
const Category = (p_Cat: iCat) => {
    const renderHierarchy = (): ReactNode[] | undefined => {
        let arrCat = p_Cat.p_Category?.path?.split('>')
        let lenOfArr = arrCat?.length ? arrCat?.length : 0
        const returnNode: ReactNode[] | undefined = arrCat?.map((catname, index) =>
        (
            <>
                {(index == lenOfArr - 1) ?
                    <li onClick={() => p_Cat.setCurrentCategoryByName(catname)}>{catname}{`(${p_Cat.getCategoryChildCount(catname)})`}</li> :
                    <li><a onClick={() => p_Cat.setCurrentCategoryByName(catname)}
                        href="#">{catname}{`(${p_Cat.getCategoryChildCount(catname)})`}
                    </a>
                    </li>
                }
            </>
        ))
        return returnNode
    }
    return (
        <>
            <div className="admin-breadcrumb">
                <ul>
                    {renderHierarchy()}
                </ul>
            </div>
            <div className="brand-categories-list">
                {p_Cat.p_Category && p_Cat.p_Category.childCategories?.map((category) =>
                    <div className="brand-categories-box" key={category.id}>
                        <div className="brand-categories-box-in">
                            <div className="brand-img"></div>
                            <div>
                                <a onClick={() => p_Cat.setCurrentCategory(category)} href="javascript:void(0)" className="list-link">{category.name}{`(${category.childCategories?.length})`}</a>
                            </div>
                            <div className={p_Cat.onDelete && p_Cat.onEdit ? "box-action-btns" : "box-action-btns hide"}>
                                <a onClick={() => p_Cat.onDelete ? p_Cat.onDelete(category) : null} href="javascript:void(0)" className="delete"><span className="icon dripicons-trash"></span></a>
                                <a onClick={() => p_Cat.onEdit ? p_Cat.onEdit(category) : null} href="javascript:void(0)" className="edit"><span className="icon dripicons-pencil"></span></a>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </>
    )

}

export default Category