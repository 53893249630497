import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState, useRef } from "react";
import { iCategory, iShoeModel, iShoeColor, iShoeModelPhotographs } from '../../../shared/models/adminModels'
import { CommonUtil } from '../../../shared/utils/commonUtils'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { HttpCategoryService, HttpSettingService, HttpShoeModelService, HttpShoeColorService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import Category from '../../../shared/components/admin/category'
import Multiselect from 'multiselect-react-dropdown';
import { BlobServiceClient } from "@azure/storage-blob";
import DatePicker from 'react-datepicker';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css'
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const ProfilePage = () => {
    const { t } = useTranslation()
    
    // -------------- Pagination --------------------
    const [totalShoeModelResults, setTotalShoeModelResults] = useState(-1);
    const [totalProfiles, setTotalProfiles] = useState(-1)
    const [currentProfile, setCurrentProfile] = useState(1)
    // -------------- Pagination End --------------------

    const [shoeModels, setShoeModels] = useState<iShoeModel[]>([])
    const [allShoeColors, setAllShoeColors] = useState<iShoeColor[]>([])

    const [shoeColors, setShoeColors] = useState<iShoeColor[]>([])
    //For Modal Dialog
    const { isOpen, toggle } = useModal()
    const initialShoeModel: iShoeModel = {
        id: -1,
        categoryId: 0,
        sizeTypeId: 0,
        description: "",
        exitPrice: "",
        isHidden: false,
        modelCode: "",
        modelName: "",
        releaseDate: new Date(moment().toDate())
        // colors: []
        // brandId?: number
        // brandName?: string

    }
    const [shoeModelToSave, setShoeModelToSave] = useState<iShoeModel>(initialShoeModel)
    const [shoeModelPhotographs, setShoeModelPhotographs] = useState<iShoeModelPhotographs[]>([])

    const [currentState, setCurrentState] = useState<number>(0) // 0- add, 1-Edit, 2 -Photos

    const { isOpen: isOpenPhoto, toggle: togglePhoto } = useModal()

    // =============== For Category component ====================
    // For list of Brands, got from Api call
    const [brands, setBrands] = useState<iCategory[]>([])
    const [brandSelected, setBrandSelected] = useState<iCategory>()

    const [populatedCategory, setPopulatedCategory] = useState<iCategory>()
    const [category, setCategory] = useState<iCategory>()

    const [uploadURL, setUploadURL] = useState<string>('')

    //For getting the sub catogray by name.
    const [brandCategory, setBrandCategory] = useState<iCategory>()

    // const [categorys, setCategorys] = useState<iCategory[]>([])
    const [refreshCatId, setRefreshCatId] = useState<number>(-1)
    // =============== END For Category component ====================


    const p_utils = new CommonUtil()
    const httpShoeModelService: HttpShoeModelService = new HttpShoeModelService();
    const httpCategoryService: HttpCategoryService = new HttpCategoryService();
    const httpShoeColorService: HttpShoeColorService = new HttpShoeColorService()
    const httpSettingService: HttpSettingService = new HttpSettingService();

    const refreshShoeModels = (currentPage = 1) => {
        console.log('refreshShoeModels', currentPage)
        httpShoeModelService.getAll<iShoeModel>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value) => {
                console.log('items', value.data.items)
                setShoeModels(value.data.items)
                setTotalShoeModelResults(value.data.totalRows)
                console.log('totalRows', value.data.totalRows)
                console.log('pages', Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT))
                setTotalProfiles(Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT))
            })
            .catch((error) => console.log(error))
    }
    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        refreshShoeModels(data.currentPage);
        setCurrentProfile(data.currentPage);
    }

    const fetchAllShoeColors = (currentPage = 1) => {
        httpShoeColorService.getAll<iShoeColor>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value) => {
                setAllShoeColors(value.data)
                // setTotalShoeModelResults(100)
                // setTotalProfiles(10)
            })
    }

    const fetchShoeModelColors = (shoeModelId: number) => {
        httpShoeModelService.get<iShoeModel>({ id: shoeModelId })
            .then((value) => {
                if (value.data.colors) {
                    console.log('value.data.colors', value.data.colors)
                    setShoeColors(value.data.colors ? value.data.colors : [])
                }
            })
    }
    const getUploadURL = async () => {
        await httpSettingService
          .getBlobURL()
          .then((value) => {
            console.log('getUploadURL', value)
            setUploadURL(value)
          }).catch((error) => {
            console.log('getUploadURL error', error)
          })
      }
    useEffect(() => {
        console.log('Profile')
        refreshShoeModels()
        fetchAllShoeColors()
        getUploadURL()
                     
    }, [])

    const gotoEditShoeModel = (shoeModel: iShoeModel) => {
        if (shoeModel.brandId) {
            onBrandsOptionChange(shoeModel.brandId)
            fetchShoeModelColors(shoeModel.id)
            setShoeModelToSave({ ...shoeModelToSave, ...shoeModel })
            refreshUI(shoeModel.categoryId)
            onEditShoeClick()
        }
    }
    const deleteShoeModel = async (shoeModel: iShoeModel) => {
        console.log('handelOnDelete', shoeModel.id)
        // let prev_cat_id: number = category ? category.id : 0
        await httpShoeModelService.delete<iShoeModel>(shoeModel.id)
            .then((value) => {
                toast.success(t`Delete_success_msg`)
                refreshShoeModels()
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        if (shoeModelToSave.id == -1) return
        console.log('shoeModelToSave id', shoeModelToSave.id)
        console.log('currentState', currentState)
        switch (currentState) {
            case 0:
                break;
            case 1:
                break;
            case 2:
                setShoeModelPhotographs([])
                getShoeModelImages()
                onAddShoeModelPhotoClick()
                break;
        }
    }, [shoeModelToSave, currentState])

    const editShoeModelPhotos = (shoeModel: iShoeModel) => {
        console.log('editShoeModelPhotos', shoeModel)
        setShoeModelToSave({ ...initialShoeModel, ...shoeModel })
        setCurrentState(2)  // 0- add, 1-Edit, 2 -Photos
        console.log('shoeModelToSave', { ...shoeModelToSave, ...shoeModel })
    }

    const onSelectColor = (selectedList: iShoeColor[], selectedItem: iShoeColor) => {
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        // shoeModelToSave.colors = selectedList
        setShoeColors(selectedList)
    }
    const onRemoveColor = (selectedList: iShoeColor[], removedItem: iShoeColor) => {
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        // shoeModelToSave.colors = selectedList
        setShoeColors(selectedList)
    }
    const renderShoeModels = (): ReactNode[] => {
        // console.log('shoeModels?.length',shoeModels?.length)
        // console.log('shoeModels=',shoeModels)
        if (!shoeModels || shoeModels.length == 0) return []

        const returnNode: ReactNode[] = shoeModels?.map((shoeModel: iShoeModel) => (
            <tr key={shoeModel.id}>
                <td>{shoeModel.id}</td>
                <td>{shoeModel.title!.replaceAll("/", ">")}</td>
                {/* <td>{shoeModel.categoryName}</td> */}
                <td>{shoeModel.modelName}</td>
                <td>{shoeModel.modelCode}</td>

                <td>{shoeModel.color}</td>

                <td style={{ whiteSpace: "pre" }}>{moment(shoeModel.releaseDate).format('DD/MM/YYYY')}</td>
                <td>{shoeModel.exitPrice}</td>
                <td>{shoeModel.isHidden ? 'true' : 'false'}</td>
                <td width={100}>{<>
                    <a onClick={() => gotoEditShoeModel(shoeModel)} data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-pencil-square"></i></a>
                    <a onClick={() => editShoeModelPhotos(shoeModel)} data-toggle="tooltip" data-placement="top" title="" data-original-title="Add Images" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-image"></i></a>
                    {/* <a onClick={() => deleteShoeModel(shoeModel)} data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-remove"></i></a> */}
                    </>}
                </td>
            </tr>

        ))
        return returnNode
    }
    const showHideQuickActions = (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        p_utils.showHideQuickActions(evt, document)

    }
    const onAddShoeModelClick = () => {
        console.log('in onAddShoeModelClick')
        setCurrentState(0)  // 0- add, 1-Edit, 2 -Photos
        setShoeColors([])
        console.log('initialShoeModel', { ...initialShoeModel, categoryId: shoeModelToSave.categoryId })
        setShoeModelToSave({ ...initialShoeModel, categoryId: shoeModelToSave.categoryId })
        toggle()

    }
    const cancelAdd = () => {
        console.log('in cancelAdd')
        //setBrandCategory(undefined)
        setBrandSelected(undefined)
        setCategory(undefined)
        setShoeColors([])
        setShoeModelToSave(initialShoeModel)
        toggle()
    }
    const onEditShoeClick = () => {
        console.log('in onEditShoeClick')
        setCurrentState(1)  // 0- add, 1-Edit, 2 -Photos
        toggle()


    }
    const onAddShoeModelPhotoClick = () => {
        console.log('in onAddShoeModelPhotoClick')
        // setCurrentState(2)
        togglePhoto()

    }
    const cancelAddPhoto = () => {
        console.log('in cancelAddPhoto')
        togglePhoto()
    }
    const onToggleClick = () => {
        console.log('in onToggleClick')
        toggle()

        if (category) {
            console.log('category.id', category.id)
            // refreshUI(category.id)
        }

    }

    const deleteImage = (item: iShoeModelPhotographs) => {
        if (item.id) {
            httpShoeModelService.deleteImage(shoeModelToSave.id, item.id).then(async (value) => await getShoeModelImages())

        }
    }
    const enableExecuteShoeModelPhotograph = () => {
        let index = shoeModelPhotographs.findIndex((value) => value.isDefault == true)
        if (shoeModelPhotographs.length > 0 && index !== -1) {
            return true
        }
        return false
    }
    const setDefaultImage = (item: iShoeModelPhotographs) => {
        let smp = shoeModelPhotographs.map((shoeModelPhotograph, index) => {
            if (shoeModelPhotograph.id === item.id) {
                return { ...shoeModelPhotograph, isDefault: true }
            } else {
                return { ...shoeModelPhotograph, isDefault: false }
            }
        })

        setShoeModelPhotographs(smp)
        // item.isDefault = true
    }
    const onExecuteAddShoeModelPhoto = async () => {
        console.log('onExecuteAddShoeModelPhoto', shoeModelPhotographs)
        for (let i = 0; i < shoeModelPhotographs.length; i++) {
            if (shoeModelPhotographs[i].isDefault == true) {
                if (shoeModelPhotographs[i].id !== -1) {
                    console.log('shoeModelToSave.id', shoeModelToSave.id)
                    console.log('shoeModelPhotographs[i].id', shoeModelPhotographs[i].id)
                    console.log('shoeModelPhotographs[i]', shoeModelPhotographs[i])
                    await httpShoeModelService.setDefaultImage(shoeModelToSave.id,
                        shoeModelPhotographs[i].id,
                        shoeModelPhotographs[i])//.then((value) => refreshShoeModels())
                }
                break;
            }
        }
        // TODO: uncomment when api is ready.
        // await httpShoeModelService.setOrderedImages(shoeModelToSave.id,shoeModelPhotographs)
        togglePhoto()

    }
    const onExecuteAddEditShoeModel = async () => {
        try {
            if (category && category.id) {
                if (currentState == 0) { //Add shoe model
                    setShoeModelToSave({ ...shoeModelToSave, categoryId: category.id, colors: shoeColors })
                    await httpShoeModelService.add<iShoeModel>({ ...shoeModelToSave, categoryId: category.id, colors: shoeColors })
                    toast.success(t`Add_success_msg`);
                    refreshShoeModels()
                } else if (currentState == 1) {    // 0- add, 1-Edit, 2 -Photos
                    setShoeModelToSave({ ...shoeModelToSave, categoryId: category.id, colors: shoeColors })
                    await httpShoeModelService.updatePUT<iShoeModel>(shoeModelToSave.id, { ...shoeModelToSave, categoryId: category.id, colors: shoeColors })
                    toast.success(t`Edit_success_msg`);
                    refreshShoeModels()
                }
                onToggleClick()
            }
            // refresh list of brands
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onExecuteAddEditShoeModel error', e.message)
        }
    }
    const onEnableExecuteAddShoeModel = () => {
        console.log('shoeColors.length', shoeColors.length)
        if (
            shoeModelToSave.modelCode &&
            shoeModelToSave.modelCode &&
            brandCategory &&
            category && category.id &&
            shoeModelToSave.releaseDate &&
            shoeModelToSave.exitPrice &&
            shoeColors.length &&
            shoeModelToSave.description
        )
          return true;
        else return false;
      };
    useEffect(() => {
        if (refreshCatId !== -1 && brandCategory) {
            console.log('in useEffect [refreshCatId, brandCategory] ', refreshCatId)
            setCategory(p_utils.getCategoryById(refreshCatId, brandCategory))
        }
    }, [refreshCatId, brandCategory])

    function populatePathCategory(cat: iCategory): iCategory {
        p_utils.populateCategoryPath(cat)
        return cat
    }
    const refreshUI = async (idRefresh: number) => {
        console.log('in refreshUI idRefresh', idRefresh)
        if (!brandSelected) return
        await httpCategoryService.get<iCategory>({ id: +brandSelected.id })
            .then((value) => {
                // if (id && +id == idRefresh) {
                console.log('id=', brandSelected.id)
                console.log('idRefresh=', idRefresh)
                let p_cat = populatePathCategory(value.data)
                console.log('p_cat', p_cat)
                setPopulatedCategory(p_cat)
                setRefreshCatId(idRefresh)

            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        if (populatedCategory) {
            setBrandCategory(populatedCategory)
        }
    }, [populatedCategory])

    // const refreshBrands = (currentPage = 1) => {
    //     console.log('refreshBrands called')
    //     // if (brands) return
    //     const bs = httpCategoryService.getAll<iCategory>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
    //         .then((value) => {
    //             setBrands(value.data)
    //             console.log('data', value.data)
    //             //setBrandSelected(value.data[0])
    //         })
    //         .catch((error) => console.log(error))
    // }
    // useEffect(() => {
    //     refreshBrands()
    // }, [])

    useEffect(() => {
        console.log('in useEffect', brandSelected)
        if (brandSelected) {
            console.log('in useEffect id', brandSelected.id)
            refreshUI(brandSelected.id)
        }
    }, [brandSelected])

    const onBrandsOptionChange = (brand_id: number) => {
        let filteredBrands = brands.filter((value) => value.id == brand_id)
        console.log('onBrandsOptionChange', filteredBrands[0])
        setBrandSelected(filteredBrands[0])
        // TODO: fetch brand categories, all the decendents of this brand_id
    }

    const handelSetCurrentCategory = (p_Cat: iCategory) => {
        // console.log('p_Cat.name', p_Cat.name)
        setCategory(p_Cat)
    }

    const handelSetCurrentCategoryByName = (cat_name: string) => {
        // console.log('handelSetCurrentCategoryByName cat_name', cat_name)
        if (brandCategory) {
            console.log('brandCategory.name', brandCategory.name)
            let x: iCategory | undefined = p_utils.getCategoryByName(cat_name, brandCategory)
            console.log('x?.name', x?.name)

            setCategory(x)
        }
    }
    const handelGetCategoryChildCount = (cat_name: string): number | undefined => {
        //console.log('handelGetCategoryChildCount cat_name', cat_name)
        if (brandCategory) {
            //console.log('handelGetCategoryChildCount brandCategory.name', brandCategory.name)
            let x: iCategory | undefined = p_utils.getCategoryByName(cat_name, brandCategory)
            //console.log('x?.name', x?.name)
            //console.log('x.childCategories.length', x?.childCategories.length)
            return (x) ? x.childCategories.length : 0

        }
    }

    //START _MANJU_ CHANGES
    const [file, setFile] = useState(null);

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
    };

    useEffect(() => {
        if (!file) return
        console.log('useEffect called')
        handleAddImage()
    }, [file])

    const getShoeModelImages = async (currentPage = 1) => {
        await httpShoeModelService.getImages<iShoeModelPhotographs>(shoeModelToSave.id,
            { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value) => {
                console.log('Shoe model imgs', value)
                let sorted
                if (value.length) {
                    sorted = value.sort((a, b) =>
                        a.posn && b.posn ? a.posn - b.posn : a.id - b.id
                    )
                } else {
                    sorted = value
                }
                sorted.forEach((item, index, arr) => arr[index].posn = index + 1)
                setShoeModelPhotographs(sorted)
            })
    }
    const handleAddImage = async () => {
        console.log('handleAddImage called')
        // event.preventDefault();
        uploadImage("shoemodel", file).then(async (fileId) => {
            if (file != null && fileId != "") {
                const imageToSave: iShoeModelPhotographs = {
                    id: -1,
                    // fileId: fileId,
                    url: fileId,
                    name: fileId, //file.name 
                    isDefault: false // or 1 if default
                }
                console.log('shoeModelToSave.id', shoeModelToSave.id)
                let resp = await httpShoeModelService.addImage(shoeModelToSave.id, imageToSave)
                    .then((value) => {
                        console.log('value', value)
                        setFile(null)

                        getShoeModelImages()

                    })

                console.log('handleAddImage', resp)
            }

        });
    };

    async function uploadImage(containerName: string, file: any): Promise<string> {
        console.log('uploadURL', uploadURL)
        var tempArr: Array<string> = file.name.split('.');
        var extension: string = tempArr[tempArr.length - 1];
        let p_Utils = new CommonUtil()
        if (p_Utils.isAttachmentSupported(extension)) {
            const blobServiceClient = new BlobServiceClient(
                uploadURL
                //process.env.REACT_APP_BLOB_STORAGE_URL!
            );
            const blobname = p_Utils.generateGUID() + "." + extension;
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobClient = containerClient.getBlobClient(blobname);
            const blockBlobClient = blobClient.getBlockBlobClient();
            await blockBlobClient.uploadBrowserData(file, {
                blockSize: 4 * 1024 * 1024,
                concurrency: 20,
                onProgress: ev => console.log(ev)
            });
            return blobname;
        }
        return "";
    }
    //END _MANJU_ CHANGES

    const handelSearch = async (searchText: string) => {
        console.log('handelSearch', searchText)
        if (!searchText) {
            refreshShoeModels()
            return
        }
        //Call api to search shoeModels.
        try {
            await httpShoeModelService.filterBy<iShoeModel>({}, { query: searchText })
                .then((value) => {
                    setShoeModels(value.data.items)
                    setTotalShoeModelResults(value.data.totalRows)
                    setTotalProfiles(Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT))
                })
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('handelSearch error', e.message)
        }
    }

    const dragItem = useRef<number>(-1);
    const dragOverItem = useRef<number>(-1);
    const dragStart = (e: React.DragEvent<HTMLDivElement>, position: number) => {
        dragItem.current = position; //shoeModelPhotographs[position] ;
        console.log('dragStart position', position)
        console.log('dragStart', e.target);
    };
    const dragEnter = (e: React.DragEvent<HTMLDivElement>, position: number) => {
        dragOverItem.current = position; //shoeModelPhotographs[position] ;
        console.log('dragEnter position', position)
        console.log('dragEnter', e.target);
    };
    const drop = (e: React.DragEvent<HTMLDivElement>) => {
        console.log('drop', e.target)
        const copyListItems = [...shoeModelPhotographs];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = -1;
        dragOverItem.current = -1;
        console.log('copyListItems', copyListItems)
        copyListItems.forEach((item, index, arr) => {
            arr[index].posn = index + 1
        })
        console.log('copyListItems', copyListItems)
        httpShoeModelService.setOrderedImages(shoeModelToSave.id, copyListItems)
            .then((value) => {
                setShoeModelPhotographs([])
                getShoeModelImages()
            })
        // setShoeModelPhotographs(copyListItems)

    };
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">

                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>{t('Profile_listing')}</h3>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    <div className="action-btn">
                                        <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                debounceTimeout={500}
                                                onChange={event => handelSearch(event.target.value)}
                                                placeholder={"Search..."} />
                                            <div className="input-group-append">
                                                <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                            </div>
                                        </div>
                                        <a onClick={() => onAddShoeModelClick()} href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-shoe-model" data-toggle="modal" data-target="#addShoeModel">{t('Add_Profile')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shoe-model-grid">
                            {/* <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t('Id')}</th>
                                        <th>{t('Brand_Category')}</th>
                                        <th>{t('Category_Name')}</th> 
                                        <th>{t('Model_Name')}</th>
                                        <th>{t('Model_Code')}</th>

                                        <th>{t('Colors')}</th>
                                        <th>{t('Release_date')}</th>
                                        <th>{t('Exit_price_code')}</th>
                                        <th>{t('Is_hidden')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>

                                <tbody>

                                    {renderShoeModels()}

                                </tbody>
                            </table> */}

                        </div>
                        <div className="nav-direction">
                            {/* {
                                totalShoeModelResults > appConst.PAGE_LIMIT && totalProfiles ?
                                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                        <Pagination totalRecords={totalShoeModelResults}
                                            totalPages={totalProfiles}
                                            currentPage={currentProfile}
                                            pageLimit={appConst.PAGE_LIMIT}
                                            pageNeighbours={1}
                                            onPageChanged={onPageChanged} />
                                    </div>
                                    :
                                    null
                            } */}
                        </div>
                        <ModalDialog toggle={() => onAddShoeModelClick()}
                            cancel={() => cancelAdd()}
                            executeButtonTitle={() => currentState == 0 ? t('MD_Add') : t('MD_Update')}
                            isOpen={isOpen}
                            execute={onExecuteAddEditShoeModel}
                            enableExecute={onEnableExecuteAddShoeModel()}
                            modalTitle={currentState == 0 ? t('Add_Profile') : t('Edit_Shoe_Model')}
                            useButtons={t("MD_Cancel") + '/' +  (currentState == 0 ? t("MD_Add") : t('MD_Update'))}
                        >
                            {
                                <div className="row">
                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group">
                                            <label>{t('Name')}{'*'}</label>
                                            <input type="text" className="form-control"
                                                value={shoeModelToSave.modelName}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, modelName: evt.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group">
                                            <label>{t('Brand_Brand_Name')}{'*'}</label>
                                            <input type="text" className="form-control"
                                                value={shoeModelToSave.modelName}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, modelName: evt.target.value })}
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group bulk-datepicker">
                                            <label>{t('Release_date')}{'*'}</label>
                                            {/* <label>{shoeModelToSave.releaseDate.toString()}</label> */}
                                            <DatePicker
                                                dateFormat={'dd/MM/yyyy'}
                                                selected={new Date(moment(shoeModelToSave.releaseDate).toDate())}
                                                onChange={(date) => { console.log('date', date); setShoeModelToSave({ ...shoeModelToSave, releaseDate: date }) }}
                                                // shoeModelToSave.releaseDate ? new Date(format(shoeModelToSave.releaseDate, 'MM-DD-YYYY')) : new Date()
                                                // value={moment(shoeModelToSave.releaseDate).format('YYYY-MM-DD')}
                                                maxDate={new Date(moment().toDate())}
                                                
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group">
                                            <label>{t('Search')}{'*'}</label>
                                            <input type="text" className="form-control"
                                                value={shoeModelToSave.modelName}
                                                onChange={(evt) => setShoeModelToSave({ ...shoeModelToSave, modelName: evt.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                        </ModalDialog>
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div>

    )
};

export default ProfilePage;