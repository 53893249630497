import moment from "moment"
import { BrandFilters, ColorFilters, CouponFilters, ModerationLotsFilters, ModerationRequestsFilters, OrderFilters, ShoeModelFilters, UserFilters, eAppFilters, iAppFilterType, iCategory, iCustomAxiosError } from "../models/adminModels"
import { AxiosError } from "axios"
import { Toast, ToastContent, ToastOptions } from "react-toastify/dist/types"


export class CommonUtil {
    public showHideQuickActions(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>, document: Document) {
        console.log('test', evt.currentTarget.getAttribute('aria-expanded'))
        let dataTarget = evt.currentTarget.getAttribute('data-target-id')
        //let toHideOrShowID = href?.replace('#','')
        if (dataTarget) {
            console.log(dataTarget)
            let elem = document.getElementById(dataTarget)
            if (elem) {
                elem.style.display = elem.style.display === 'block' ? 'none' : 'block'
                //window.scrollTo(0, 0);
            }
        }
    }


    public populateCategoryPath(p: iCategory, retVal: { path: string, idPath: string } = { path: '', idPath: '' }) {
        // console.log('cat categoryLevel', p.name) 
        // console.log('p.childCategories',p.childCategories)
        if (!p.childCategories.length) {

            retVal.path = !retVal.path ? p.name : retVal.path + '>' + p.name
            p.path = retVal.path

            retVal.idPath = !retVal.idPath ? '' + p.id : retVal.idPath + '>' + p.id
            p.idPath = retVal.idPath
            // console.log('path ',path)
            //console.log('retVal ',retVal)
            return retVal
        } else {
            // console.log('here')
            for (let i = 0; i < p.childCategories.length; i++) {
                //console.log('retVal in childrens', retVal)
                p.path = !retVal.path ? p.name : retVal.path + '>' + p.name
                p.idPath = !retVal.idPath ?
                    (!p.parentCategoryId ? p.id + '' : p.parentCategoryId + '>' + p.id)
                    : retVal.idPath + '>' + p.id
                this.populateCategoryPath(p.childCategories[i], { path: p.path, idPath: p.idPath! })
            }
        }
    }



    public getCategoryByName(cat_name: string, p_brandCategory: iCategory): iCategory | undefined {
        //    console.log('p_brandCategory', p_brandCategory.name)
        //    console.log('cat_name',cat_name)
        if (p_brandCategory.name === cat_name) {
            // console.log('here')
            return p_brandCategory
        } else {
            for (let i = 0; i < p_brandCategory.childCategories.length; i++) {
                // console.log('p_brandCategory.childCategories',p_brandCategory.childCategories[i].name)
                let x: iCategory | undefined = this.getCategoryByName(cat_name, p_brandCategory.childCategories[i])
                if (x) return x
            }
            // console.log('ffffffffff')
        }
    }
    public getCategoryById(cat_id: number, p_brandCategory: iCategory): iCategory | undefined {
        // console.log('p_brandCategory', p_brandCategory.name)
        // console.log('cat_id',cat_id)
        if (p_brandCategory.id === cat_id) {
            console.log('here')
            return p_brandCategory
        } else {
            for (let i = 0; i < p_brandCategory.childCategories.length; i++) {
                //  console.log('p_brandCategory.childCategories',p_brandCategory.childCategories[i].name)
                let x: iCategory | undefined = this.getCategoryById(cat_id, p_brandCategory.childCategories[i])
                if (x) return x
            }
            //  console.log('ffffffffff')
            return undefined
        }
    }
    public filterCategoryByName(cat_name: string, p_brandCategory: iCategory, filteredCategory: iCategory[] = []) {
        //    console.log('p_brandCategory', p_brandCategory.name)
        //    console.log('cat_name',cat_name)
        if (p_brandCategory.name.includes(cat_name)) {
            filteredCategory.push(p_brandCategory)
            // console.log('here')
            return filteredCategory
        } else {
            for (let i = 0; i < p_brandCategory.childCategories.length; i++) {
                // console.log('p_brandCategory.childCategories',p_brandCategory.childCategories[i].name)
                this.filterCategoryByName(cat_name, p_brandCategory.childCategories[i], filteredCategory)
            }
            // console.log('ffffffffff')
        }
    }
    public getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    public createEmptyArray<T>(count: number): T[] {
        return Array.from({ length: count });
    }

    public addItemOnArray<Y>(sourceArr: any[] | undefined, destArr: Y[]): Y[] {
        if (sourceArr) {
            for (let i = 0; i < sourceArr.length; i++) {
                destArr[i] = sourceArr[i];
            }
        }
        return destArr;
    }

    public getNameInitialText(name: string | undefined): string {
        if (name) {
            const fullname: string[] = name.split(' ');
            const nameInitialText = fullname[0].charAt(0).toUpperCase() + fullname[fullname.length - 1].charAt(0).toUpperCase();
            return nameInitialText;
        } return 'SG';
    }

    public generateGUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    public getTimeStamp(): string {
        return moment().format('YYYYMMDDhhmmss')
    }
    public isAttachmentSupported(extension: string): boolean {
        var attachmentsupportedFiles: Array<string> = new Array<string>("jpg", "jpeg", "png");
        var extensionFound: boolean = false;
        var extensionRes: string | undefined = attachmentsupportedFiles.find(x => x == extension);
        if (extensionRes != null && extensionRes != undefined && extensionRes.length > 0)
            extensionFound = true;

        return extensionFound;
    }
    public isPDFAttachmentSupported(extension: string): boolean {
        var attachmentsupportedFiles: Array<string> = new Array<string>("pdf");
        var extensionFound: boolean = false;
        var extensionRes: string | undefined = attachmentsupportedFiles.find(x => x == extension);
        if (extensionRes != null && extensionRes != undefined && extensionRes.length > 0)
            extensionFound = true;

        return extensionFound;
    }
    public showError = (e: AxiosError, toas: any) => {
        if (e.response! && e.response!.data) {
            console.log(e.response!.data);
            toas((e.response!.data! as iCustomAxiosError).errorMessage!.value);
        }
    };

    public getInitialFilter = (filterType: eAppFilters): iAppFilterType => {
        switch (filterType) {
            case eAppFilters.user:
                console.log('getInitialFilter', 'user')
                return new UserFilters()
            case eAppFilters.brand:
                console.log('getInitialFilter', 'brand')
                return new BrandFilters()
            case eAppFilters.shoeModel:
                console.log('getInitialFilter', 'shoeModel')
                return new ShoeModelFilters()
            case eAppFilters.color:
                console.log('getInitialFilter', 'color')
                return new ColorFilters()
            case eAppFilters.coupon:
                console.log('getInitialFilter', 'coupon')
                return new CouponFilters()
            case eAppFilters.order:
                console.log('getInitialFilter', 'order')
                return new OrderFilters()
            case eAppFilters.b2b_moderation_lots:
                console.log('getInitialFilter', 'b2b_moderation_lots')
                return new ModerationLotsFilters()
            case eAppFilters.b2b_moderation_requests:
                console.log('getInitialFilter', 'b2b_moderation_requests')
                return new ModerationRequestsFilters()
            case eAppFilters.faq:
                console.log('getInitialFilter', 'faq')
                return null
        }
    }
}