import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iNotification } from '../../../shared/models/adminModels'
import ModalDialog from "../../../shared/components/admin/modalDialog";
import useModal from "../../../hooks/general"
import {  HttpSettingService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import * as appConst from '../../../shared/constants/constants'
import { HttpNotificationService } from "../../../services/admin.service";
import { BlobServiceClient } from "@azure/storage-blob";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CommonUtil } from "../../../shared/utils/commonUtils";

const NotificationPage = () => {
    //For Modal Dialog
    const { isOpen, toggle } = useModal();
    const { t } = useTranslation();
    
    const initialNotificationToSave: iNotification = {

        id: -1,
        message: "",
        fileId: "",
        createdAt: new Date()
    }
    const [notificationToSave, setNotificationToSave] = useState<iNotification>(initialNotificationToSave)
    const [notifications, setNotifications] = useState<iNotification[]>([])
    const [uploadURL, setUploadURL] = useState<string>('')
    const [currentState, setCurrentState] = useState<number>(0) // 0- add, 1-Edit, 2 -Photos
    const [file, setFile] = useState(null);
    const [selectedImage, setSelectedImage] = useState("");

    const httpNotificationService: HttpNotificationService = new HttpNotificationService();
    const httpSettingService: HttpSettingService = new HttpSettingService();

    const refreshnotification = (currentPage = 1) => {
        httpNotificationService.getAll<iNotification>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value: any) => {
                setNotifications(value.data)
            })
            .catch((error) => console.log(error))
    }
    const getUploadURL = async () => {
        await httpSettingService
          .getBlobURL()
          .then((value) => {
            console.log('getUploadURL', value)
            setUploadURL(value)
          }).catch((error) => {
            console.log('getUploadURL error', error)
          })
      }
    useEffect(() => {
        refreshnotification();
        getUploadURL()
    }, []);

    const deleteNotification = async (notification: iNotification) => {
        // let prev_cat_id: number = category ? category.id : 0
        await httpNotificationService.delete<iNotification>(notification.id)
            .then((value) => {
                toast.success(t`Delete_success_msg`)
                refreshnotification()
            })
            .catch((error) => console.log(error))
    }

    const publishNotification = async (p_iPenalty: iNotification) => {
        await httpNotificationService.publish<iNotification>(p_iPenalty.id)
        toast.success(t`Publish_success_msg`)
        refreshnotification()
    }

    const renderNotifications = (): ReactNode[] => {
        if (!notificationToSave) return []

        const returnNode: ReactNode[] = notifications?.map((notification: iNotification) => (
            <tr key={notification.id}>
                <td>{notification.id}</td>
                <td>
                    {notification.fileId ?
                        <img src={notification.fileId} width={100} height={100} />
                        : "-"
                    }
                </td>
                <td>{notification.message}</td>
                <td>{notification.publishStatus ? "-" : <>
                    <a
                        onClick={() => publishNotification(notification)}
                        title="Publish" data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Apply"
                        className="btn btn-bulk small me-1"
                        data-rel="tooltip" >
                        <i className="la la-check"></i>
                    </a>
                    <a
                        onClick={() => deleteNotification(notification)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                        data-original-title="Edit"
                        className="btn btn-bulk small me-1"
                        data-rel="tooltip" >
                        <i className="la la-remove"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const onAddnotificationClick = () => {
        setCurrentState(0)  // 0- add, 1-Edit
        toggle();
        setNotificationToSave({ ...initialNotificationToSave })
    }
    const cancelAdd = () => {
        console.log('in cancelAdd')
        setCurrentState(0)  // 0- add, 1-Edit
        setNotificationToSave({ ...initialNotificationToSave })
        toggle()
    }
    const onExecuteAddEditnotification = async (fileId = "") => {
        try {
            if (currentState == 0) {
                await httpNotificationService.add<iNotification>({ ...notificationToSave, fileId: fileId })
                toast.success(t`Add_success_msg`)
                refreshnotification()
            } else if (currentState == 1) {    // 0- add, 1-Edit
                await httpNotificationService.updatePUT<iNotification>({ ...notificationToSave, fileId: fileId })
                toast.success(t`Edit_success_msg`)
                refreshnotification()
            }
            setFile(null);
            setSelectedImage("");
            toggle();
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onExecuteAddEditnotification error', e.message)
        }
    }



    const handleFileChange = async (event: any) => {
        setFile(event.target.files[0]);
        readFileDataAsBase64(event.target.files[0]).then((base64: any) => {
            setSelectedImage(base64);
        })
    };

    // useEffect(() => {
    //     if (!file) return
    //     console.log('useEffect called')
    //     handleAddImage()
    // }, [file])

    function readFileDataAsBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                resolve(reader.result);
            };
            reader.readAsDataURL(file)
        });
    }

    const handleAddImage = async () => {
        if (selectedImage) {
            // event.preventDefault();
            uploadImage("notification", file).then(async (fileId) => {
                console.log("Notification image =>", fileId);

                if (file != null && fileId != "") {
                    onExecuteAddEditnotification(fileId)
                }
            });
        } else {
            onExecuteAddEditnotification()
        }
    };

    async function uploadImage(containerName: string, file: any): Promise<string> {
        console.log('uploadURL',uploadURL)
        var tempArr: Array<string> = file.name.split('.');
        var extension: string = tempArr[tempArr.length - 1];
        let p_Utils = new CommonUtil()
        if (p_Utils.isAttachmentSupported(extension)) {
            const blobServiceClient = new BlobServiceClient(
                uploadURL
                //process.env.REACT_APP_BLOB_STORAGE_URL!
            );
            const blobname = p_Utils.generateGUID() + "." + extension;
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobClient = containerClient.getBlobClient(blobname);
            const blockBlobClient = blobClient.getBlockBlobClient();
            await blockBlobClient.uploadBrowserData(file, {
                blockSize: 4 * 1024 * 1024,
                concurrency: 20,
                onProgress: ev => console.log(ev)
            });
            return blobname;
        }
        return "";
    }
    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>Notification</h3>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    <div className="action-btn">
                                        <a onClick={() => onAddnotificationClick()}
                                            href="javascript:void(0)"
                                            className="bulk-primary-btn bulk-sm add-shoe-model"
                                            data-toggle="modal" data-target="#addnotificationModel">
                                            Add Notification
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shoe-model-grid">
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Logo</th>
                                        <th>Message</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {renderNotifications()}
                                </tbody>
                            </table>

                        </div>
                        <ModalDialog toggle={() => onAddnotificationClick()}
                            cancel={() => cancelAdd()}
                            executeButtonTitle={() => currentState == 0 ? "Add" : "Update"}
                            isOpen={isOpen}
                            execute={handleAddImage}
                            enableExecute={(notificationToSave.message) ? true : false}
                            modalTitle={currentState == 0 ? "Add Notification" : "Edit Notification"}
                            useButtons={t("MD_Cancel") + '/'  + (currentState == 0 ? t("MD_Add") : t("MD_Update"))}
                        >
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className="form-group">
                                        <label>Message:</label>
                                        <input type="text" className="form-control"
                                            value={notificationToSave.message}
                                            onChange={(evt) =>
                                                setNotificationToSave({ ...notificationToSave, message: evt.target.value })}
                                        />
                                    </div>
                                </div>

                                {selectedImage && < div className="col-12 mb-2 text-center">
                                    <img src={selectedImage} style={{ width: "50%" }} />
                                </div>}
                            </div>

                            <div className="row add-shoe-model-images">
                                <div className="add-image-btn">
                                    <div className="add-img-center">
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(evt) => handleFileChange(evt)}
                                            className="file_upload custom-file-input"
                                            name="file"></input>
                                        <a href="javascript:void(0)" className="add-browse-img-btn">
                                            {selectedImage ? "Change Image" : "Add Image"}
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </ModalDialog>
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div >
    )
};

export default NotificationPage;