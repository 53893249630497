import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iUserType, iSignUpMedium, iUser,  iShoeModel, iShoeModelPhotographs } from '../../../shared/models/adminModels'
import { cCategory, cShoeModel, cShoeModelPhotographs} from "../../../shared/models/tempBulkData";
import { CommonUtil} from '../../../shared/utils/commonUtils'

const ShoeModelPhotosPage = () => {
    
    const navigate = useNavigate();
    const [shoeModels, setShoeModels] = useState<iShoeModel[]>([])
    const [shoeModelPhotographs, setShoeModelPhotographs] = useState<iShoeModelPhotographs[]>([])
    const p_utils = new CommonUtil()
    useEffect(() => {
        console.log('ShoeModelPhotosPage')
        //TODO: Filter the shoe model, from treeview, to get iBrandsCategory.id
        //      use this id to filter the ShoeModels.
        //      API = /admin/filterShoeModel(id)
        setShoeModels([new cShoeModel()])

        //TODO: From the shoe_model_id get the photos
        setShoeModelPhotographs([
            new cShoeModelPhotographs("test1.png",false),
            new cShoeModelPhotographs("test2.png",true)
        ])
        
    }, [])

    const gotoEditShoeModelPhotos = (shoeModel: iShoeModelPhotographs) => {
        navigate('/admin/shoe-model-photos/edit/' + shoeModel.id )
    }
    const renderShoeModelsPhotos =(): ReactNode[] => {
        console.log(shoeModelPhotographs?.length)
        const returnNode: ReactNode[]= shoeModelPhotographs?.map((shoeModelPhoto: iShoeModelPhotographs) => (
                                                        
            <tr key={shoeModelPhoto.id}>
                <td>{shoeModelPhoto.id}</td>
                <td>{shoeModelPhoto.shoeModelsId ? shoeModelPhoto.shoeModelsId : 0}</td>
                <td>{shoeModelPhoto.name}</td> 
                <td>{shoeModelPhoto.isDefault}</td>

                <td>{<>
                        <a onClick={()=> gotoEditShoeModelPhotos(shoeModelPhoto)}  data-toggle="tooltip" data-placement="top" title="" data-original-title="Edit" className="btn btn-info btn-floating btn-actions" data-rel="tooltip" ><i className="la la-pencil-square text-white font-size-22 v-align-text-bottom btn-actions-font"></i></a>
                        {/* <a onClick={()=> deleteUser(user)}   className="btn btn-danger btn-floating btn-actions delete-entry" data-rel="tooltip" title=""><i className="la la-remove text-white font-size-22 v-align-text-bottom btn-actions-font"></i></a> */}
                    </>      
                    }</td>
            </tr>
            
           ))
        return returnNode
    }
    const showHideQuickActions = (evt:React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>{
        p_utils.showHideQuickActions(evt, document)
       
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Manage shoe model photos</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Shoe model photos</li>
                                    </ol>
                                </nav>
                            </div>
                            <ul className="actions top-right">
                                <li className="dropdown">
                                    <a onClick={(evt)=>showHideQuickActions(evt)}  data-target-id="shoe_model_photos_create_links" className="btn btn-fab" data-toggle="dropdown" aria-expanded="false">
                                        <i className="la la-ellipsis-h"></i>
                                    </a>
                                    <div id='shoe_model_photos_create_links' className="dropdown-menu dropdown-icon-menu dropdown-menu-right">
                                        <div className="dropdown-header">
                                            Quick actions
                                        </div>

                                        <a href="/admin/shoe-model-photos/create" className="dropdown-item">
                                            <i className="icon dripicons-user"></i> Create shoe model photos
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <% if(success && success.length > 0) { %> */}
                                <div className="alert alert-success alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- success %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}

                                {/* <% if(error && error.length > 0) { %> */}
                                <div className="alert alert-danger alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- error %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}
                                <div className="card">

                                    <div className="card-body">
                                        <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th>id</th>
                                                    <th><img src={''}  /></th>
                                                    <th>is Default</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>
                                            { renderShoeModelsPhotos()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>




    );
};

export default ShoeModelPhotosPage;