import React, { useState, useEffect } from "react";
//import { HttpSettingService } from "../../../../services/HttpSettingService";
import LoadingSpinner from "./LoadingSpinner";
import { useTranslation } from "react-i18next";
import { ContentType } from "../../../shared/models/adminModels";
import { HttpSettingService } from "../../../services/admin.service";

const Privacy = () => {
  const httpSettingService: HttpSettingService = new HttpSettingService();
  const { t } = useTranslation();
  const [privacy, setPrivacy] = useState<string>("");
  const [url, setURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  useEffect(() => {
      setLoading(true);
      httpSettingService
      .getContent<string>(ContentType.Privacy)
      .then((response: any) => {
        console.log('getContent-Privacy policy', response)
        setURL(response.data)
      }).catch((error) => {
        console.log('getContent error', error)
      })
      
      setLoading(false);
    
  }, []);
  return (
    <div className="accordion" id="accordionExample">
      <h6> {t("Privacy_Policy")}</h6>
      {!loading && !error ? (
        // <div dangerouslySetInnerHTML={{ __html: privacy }} />
        <embed src={url} width="1000" height="1000"
          type="application/pdf" />
      ) : (
        <>{error ? <>{error}</> : <LoadingSpinner />}</>
      )}
    </div>
  );
};

export default Privacy;
