import React, { useState, useEffect } from "react";
//import { HttpSettingService } from "../../../../services/HttpSettingService";
import { useTranslation } from "react-i18next";
//import { ContentType } from "../../../../shared/enums/index.enum";
import LoadingSpinner from "./LoadingSpinner";
import { HttpSettingService } from "../../../services/admin.service";
import { ContentType } from "../../../shared/models/adminModels";

const GeneralConditions = () => {
  const httpSettingService: HttpSettingService = new HttpSettingService();
  const { t } = useTranslation();
  const [genralConditions, setGeneralConditions] = useState<string>("");
  const [url, setURL] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  useEffect(() => {
      setLoading(true);
      httpSettingService
      .getContent<string>(ContentType.TermsAndConditions)
      .then((response: any) => {
        console.log('getContent', response)
        setURL(response.data)
      }).catch((error) => {
        console.log('getContent error', error)
      })
      setLoading(false);
  }, []);

  return (
    <div className="accordion" id="accordionExample">
      <h6>{t("Terms_and_Conditions")}</h6>
      {!loading && !error ? (
        // <div dangerouslySetInnerHTML={{ __html: genralConditions }} />
        <embed src={url} width="1000" height="1000"
          type="application/pdf" />
        // <object data={url} 
        // type='application/pdf' 
        // width='100%' 
        // height='100%'></object>
      ) : (
        <>{error ? <>{error}</> : <LoadingSpinner />}</>
      )}
    </div>
  );
};

export default GeneralConditions;
