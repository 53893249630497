import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iShoeModel, iSetting, iPenalty, iCouponModel, eSanction, ePenaltyType, eMarketplace, eBeneficiaryType } from '../../../shared/models/adminModels'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import {
    HttpShoeModelService,
    HttpSettingService,
    HttpPenaltyService,
    HttpCouponsService,
    HttpOrdersService,
    HttpWarehouseb2bService
} from '../../../services/admin.service'
import * as appConst from '../../../shared/constants/constants'
import useAsyncEffect from '../../../hooks/useAsyncEffect'
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import useModal from "../../../hooks/general";
import moment from "moment";
import { AxiosError } from "axios";

const SettingsPage = () => {
    const { t } = useTranslation()
    //For Modal Dialog
    const { isOpen, toggle } = useModal()

    const httpShoeModelService = new HttpShoeModelService()
    const httpPenaltyService: HttpPenaltyService = new HttpPenaltyService();
    const httpCouponsService: HttpCouponsService = new HttpCouponsService();
    const httpSettingService = new HttpSettingService()
    const httpOrdersService: HttpOrdersService = new HttpOrdersService();
    const httpWarehouseb2bService: HttpWarehouseb2bService = new HttpWarehouseb2bService()

    const [topShoeModels, setTopShoeModels] = useState<iShoeModel[]>([])
    const [bottomShoeModels, setBottomShoeModels] = useState<iShoeModel[]>([])
    const [topShoeModelsSelected, setTopShoeModelsSelected] = useState<iShoeModel[]>([])
    const [bottomShoeModelsSelected, setBottomShoeModelsSelected] = useState<iShoeModel[]>([])
    const [searchTopShoeModel, setSearchTopShoeModel] = useState<string>('')
    const [searchBottomShoeModel, setSearchBottomShoeModel] = useState<string>('')
    const [alreadySelectedTopShoeModel, setAlreadySelectedTopShoeModel] = useState<string>('')
    const [alreadySelectedBottomShoeModel, setAlreadySelectedBottomShoeModel] = useState<string>('')

    //penalty
    const [sellingOrderCancellationPenalty, setSellingOrderCancellationPenalty] = useState<iPenalty[]>([])
    const [unverifiedOrderCancellationPenalty, setUnvarifiedOrderCancellationPenalty] = useState<iPenalty[]>([])
    const initializePenalty: iPenalty = {
        sanctionType: 1,
        penaltyType: 2,
        percentageValue: 0,
        minimumAmount: 0,
        collectionDays: 0,
        collectionTime: '12:00 AM',
        rewardLevelsRecede: 1,
        motivationText: ''
    }
    const [penaltyToSave, setPenaltyToSave] = useState<iPenalty>(initializePenalty)
    const [coupons, setCoupons] = useState<iCouponModel[]>([])

    const [maskShoeModelsEvent, setMaskShoeModelsEvent] = useState<boolean>(false)

    const [totalRowsInTopShoeModels, setTotalRowsInTopShoeModels] = useState<number>(0)
    const [totalTopShoeModelPages, setTotalTopShoeModelPages] = useState(-1)
    const [totalRowsInBottomShoeModels, setTotalRowsInBottomShoeModels] = useState<number>(0)
    const [totalBottomShoeModelPages, setTotalBottomShoeModelPages] = useState(-1)
    const mockSettings: iSetting[] = [
        {
            keyName: "AutoComplete", //--
            toValue: "on",  //on , off
            description: "",
            fromValue: ""
        },
        {
            keyName: "TwoFactorAuth",//--
            toValue: "on", //on , off
            description: "",
            fromValue: ""
        },
        {
            keyName: "OrderThresholdPercent",//--
            fromValue: "",
            toValue: "10",      //custom , default
            description: "",
        },
        {
            keyName: "TopAdBannerShoemodel", //--
            toValue: "2",      //shoe_model_id
            description: "",
            fromValue: ""
        },
        {
            keyName: "BottomAdBannerShoemodel",//--
            toValue: "3",      //shoe_model_id
            description: "",
            fromValue: ""
        },
        {
            keyName: "AppSessionTimeOut", //--
            fromValue: "",
            toValue: "20",      //custom , default
            description: ""
        },
        {
            keyName: "LatestProductDays", //--
            fromValue: "",
            toValue: "5",      //custom , default
            description: "",
        },
        {
            keyName: "RecentlyViewedDays", //--
            fromValue: "",
            toValue: "30",      //custom , default
            description: "",
        },
        // {
        //     keyName: "RequestBlockingHours", //--
        //     description: "",
        //     fromValue: "",
        //     toValue: "48"
        // },
        {
            keyName: "RequestExpiryHours", //--
            description: "",
            fromValue: "",
            toValue: "10"
        },
        {
            keyName: "OfferExpiryHours", //--
            description: "",
            fromValue: "",
            toValue: "20"
        },
        {
            keyName: "HottestProductsDays", //--
            fromValue: "",
            toValue: "20",      //custom , default
            description: "",
        }
        ,
        {
            keyName: "CarteExpiryinDays", //--
            fromValue: "",
            toValue: "12",      //custom , default
            description: "",
        }
        ,
        {
            keyName: "SellerPointAmount", //--
            fromValue: "",
            toValue: "12",      //custom , default
            description: "",
        },
        {
            keyName: "BuyerPointAmount", //--
            fromValue: "",
            toValue: "12",      //custom , default
            description: "",
        },
        {
            keyName: "OrderCancellationCompansationToBuyer", //--
            fromValue: "",
            toValue: "14",      //custom , default
            description: "",
        },
        {
            keyName: "CollectionTiming", //--
            fromValue: "",
            toValue: "12:00 PM",      //custom , default
            description: "",
        },

    ]
    const [settings, setSettings] = useState<iSetting[]>([])

    interface iGI {
        index: number,
        groupName?: string,
        keys: string[]
    }
    const p_groupItems: iGI[] = [
        { index: 0, keys: ['AutoComplete'] },
        { index: 1, keys: ['TwoFactorAuth'] },
        { index: 2, keys: ['Overlay'] },
        {
            index: 3, groupName: t('Header_penalty_setting')!,
            keys: [
                'OrderThresholdPercent',
                "OrderCancellationCompansationToBuyer",
                "CollectionTiming"
            ]
        },
        { index: 5, groupName: t('Header_banner_shoemodel_setting')!, keys: ['TopAdBannerShoemodel', 'BottomAdBannerShoemodel'] },
        { index: 7, groupName: t('Header_days_of_viewing_setting')!, keys: ['LatestProductDays', 'RecentlyViewedDays', 'HottestProductsDays', 'CarteExpiryinDays', 'AppSessionTimeOut'] },
        { index: 12, groupName: t('Header_point_amt_setting')!, keys: ['SellerPointAmount', 'BuyerPointAmount'] },
        { index: 14, groupName: t('Header_Request_Offer_Timeout')!, keys: ['RequestExpiryHours', 'OfferExpiryHours'] },
    ]
    const [groupItems, setGroupItems] = useState<iGI[]>(p_groupItems)
    const [enableOrderThresholdPercent, setEnableOrderThresholdPercent] = useState<boolean>(false)


    const setKeyWithValue = (key: string, val: string) => {
        console.log('keyName:', key)
        let a = settings.map((item) => item.keyName == key ? { ...item, toValue: val } : item)
        setSettings(a)
    }
    
    useEffect(() => {
        console.log('Settings', settings)
    }, [settings])

    const setBottomAdBannerSelected = (val: string) => {
        let set = [...settings]
        for (let i = 0; i < set.length; i++) {
            if (set[i].keyName == 'BottomAdBannerShoemodel') {

                set[i].toValue = val
                console.log('BottomAdBannerShoemodel', set[i].toValue)
                setSettings(set)
                return
            }
        }
    }


    const setOrderCancellationCompansationToBuyer = (val: string, index: number) => {
        let set = settings.map((item, index) =>
            item.keyName == 'OrderCancellationCompansationToBuyer' ?
                { ...item, toValue: val } :
                item
        )
        //set[index].toValue = val
        console.log('setOrderCancellationCompansationToBuyer', set)
        console.log('setOrderCancellationCompansationToBuyer val', val)
        setSettings(set)
        return
    }

    // ============= Multiselect Top banner ShoeModels =======================
    const onSelectTopShoeModels = (selectedList: iShoeModel[], selectedItem: iShoeModel) => {
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        setTopShoeModelsSelected((topSelectedShoeModel) => topSelectedShoeModel.concat(selectedItem))
    }

    const onRemoveTopShoeModels = (selectedList: iShoeModel[], removedItem: iShoeModel) => {
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        setTopShoeModelsSelected((topSelectedShoeModel) =>
            topSelectedShoeModel.filter((item) => item.id !== removedItem.id)
        )
    }
    // ============= Multiselect Top banner ShoeModels end======================

    // ============= Multiselect Bottom banner ShoeModels =======================
    const onSelectBottomShoeModels = (selectedList: iShoeModel[], selectedItem: iShoeModel) => {
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)
        setBottomShoeModelsSelected(selectedList)
    }
    const onRemoveBottomShoeModels = (selectedList: iShoeModel[], removedItem: iShoeModel) => {
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)
        setBottomShoeModelsSelected(selectedList)
    }
    // ============= Multiselect Top banner ShoeModels end======================

    // const populateBottomAdBanner = (toValue: string): ReactNode => {
    //     return <select onChange={(evt) => { setBottomAdBannerSelected(evt.target.value) }} className="form-control" name="TopAdBanner2_id">
    //         <option value='-1'>Select one shoe model</option>
    //         {shoeModels.length && shoeModels.map((shoeModel) =>
    //             <option value={shoeModel.id} selected={+toValue == shoeModel.id}>
    //                 {shoeModel.modelCode}
    //             </option>
    //         )}
    //     </select>
    // }



    const populateOrderCancellationCompansationToBuyer = (toValue: string, index: number): ReactNode => {
        console.log("toval=> ", toValue);

        return <select onChange={(evt) => { setOrderCancellationCompansationToBuyer(evt.target.value, index) }} className="form-control" name="TopAdBanner2_id">
            <option value='-1'>{t`Select_one_coupoun`}</option>
            {coupons.length && coupons.map((coupon) =>
                <option value={coupon.id} selected={+toValue == coupon.id}>
                    {coupon.title}
                </option>
            )}
        </select>
    }


    useEffect(() => {
        loadCoupons()
        loadSettings()
        //loadShoeModel()

    }, [])



    // useAsyncEffect(function* (onCancel: any): Generator<any> {
    //     console.log('in useAsyncEffect')
    //     // const cancelTokenSource = axios.CancelToken.source() //new AbortController()  
    //     // const token1 = cancelTokenSource.token

    //     onCancel(() => {
    //         console.log("cancel while fetch is still executed, use controller for aborting the request.");
    //         //    cancelTokenSource.cancel();
    //     })

    //     try {
    //         setMaskShoeModelsEvent(true)
    //         setShoeModels([])
    //         for (let i = 1; i <= totalShoeModelPages; i++) {

    //             let resp: any = yield httpShoeModelService.getAll(
    //                 { currentPage: i, pageLimit: appConst.PAGE_LIMIT_SHOE_MODELS }
    //             )

    //             setShoeModels(shoeModels => shoeModels.concat(resp.data.items))
    //         }
    //         setMaskShoeModelsEvent(false)
    //     } catch (err) {
    //         //    if (err.name === 'AbortError') {
    //         console.log("Request was canceled via controller.abort")
    //         // we know that an 'AbortError' occurs when the request is
    //         // cancelled this means that the next promise returned by yield
    //         // will be created but not actively used, thus, we return in
    //         // order to avoid the promise being created.
    //         return;
    //         //    }
    //     }

    // }, [totalShoeModelPages])

    const getQueryFilter = (top: boolean) => {
        let filter = ''
        if (top && searchTopShoeModel) {
            filter += '&query=' + searchTopShoeModel
            filter += '&includeSearch=' + true
        }
        if (!top && searchBottomShoeModel) {
            filter += '&query=' + searchBottomShoeModel
            filter += '&includeSearch=' + true
        }
        return filter
    }
    const loadShoeModel = (top: boolean) => {
        if (top && !searchTopShoeModel) {
            console.log('--setting topShoeModelsSelected', topShoeModelsSelected)
            //setTopShoeModels([])
            setTotalRowsInTopShoeModels(0)
            setTotalTopShoeModelPages(0)
            return
        }
        if (!top && !searchBottomShoeModel) {
            console.log('--setting bottomShoeModelsSelected', bottomShoeModelsSelected)
            //setBottomShoeModels(bottomShoeModelsSelected)
            //setBottomsShoeModels([])
            setTotalRowsInBottomShoeModels(0)
            setTotalBottomShoeModelPages(0)
            return
        }
        let search = top ? searchTopShoeModel : searchBottomShoeModel
        console.log("loadShoeModel", search);
        //TODO make the get request for settings
        httpShoeModelService
            .filterBySearchFilters<iShoeModel>({
                currentPage: 1,
                pageLimit: appConst.PAGE_LIMIT_SHOE_MODELS,
            }, { filters: getQueryFilter(top) })
            .then((value) => {
                if (top) {
                    console.log('top', top)
                    setTotalRowsInTopShoeModels(value.totalRows);
                    setTotalTopShoeModelPages(
                        Math.ceil(value.totalRows / appConst.PAGE_LIMIT_SHOE_MODELS)
                    );
                    let transformShoeModel =
                        (value.items as iShoeModel[]).map((value: iShoeModel, index, arr) => {
                            return {
                                ...value,
                                searchableName: value.modelName! + ' - ' + value.modelCode!
                            }
                        })
                    //console.log('topShoeModelsSelected=', topShoeModelsSelected)
                    console.log('value.items=', value.items)
                    setTopShoeModels(transformShoeModel)
                } else {
                    console.log('top', top)
                    setTotalRowsInBottomShoeModels(value.totalRows);
                    setTotalBottomShoeModelPages(
                        Math.ceil(value.totalRows / appConst.PAGE_LIMIT_SHOE_MODELS)
                    );
                    let transformShoeModel =
                        (value.items as iShoeModel[]).map((value: iShoeModel, index, arr) => {
                            return {
                                ...value,
                                searchableName: value.modelName! + ' - ' + value.modelCode!
                            }
                        })
                    setBottomShoeModels(transformShoeModel)
                }

            })
            .catch((error) => console.log(error));
    };



    const loadCoupons = (currentPage = 1) => {
        console.log('loadCoupons')
        //TODO make the get request for settings
        httpCouponsService.getAll<iCouponModel>({ currentPage: currentPage, pageLimit: 100 })
            .then((value) => {
                console.log('coupons', value.data.items)
                const CouponsFilter = value.data.items.filter((coupon: iCouponModel) =>
                    coupon.marketPlace === eMarketplace.All &&
                    coupon.beneficiaryType == eBeneficiaryType.Buyer)
                setCoupons(CouponsFilter)
            })
            .catch((error) => console.log(error))
    }

    const orderByMockData = (gotSettings: iSetting[]) => {
        let tempArr: iSetting[] = []
        for (let i = 0; i < mockSettings.length; i++) {
            let elem = gotSettings.find((item) => item.keyName == mockSettings[i].keyName)
            if (elem) {
                tempArr.push(elem)
            }
        }
        return tempArr
    }

    const onToggleClick = (type: number) => {
        toggle()
    }

    const cancelAddView = () => {
        setPenaltyToSave({ ...initializePenalty })
        toggle()
    }

    const enableAddView = () => {
        switch (penaltyToSave.sanctionType) {
            case eSanction.Economical:
                if ((penaltyToSave.percentageValue || penaltyToSave.minimumAmount) &&
                    penaltyToSave.collectionDays &&
                    moment(penaltyToSave.collectionTime, ['hh:mm A'], true).isValid() &&
                    penaltyToSave.motivationText)
                    return true
                else return false
                break;
            case eSanction.PenaltyOnRewards:
                if (penaltyToSave.rewardLevelsRecede &&
                    penaltyToSave.motivationText) return true
                else return false
                break;
        }
    }



    useAsyncEffect(function* (onCancel: any): Generator<any> {
        console.log('in useAsyncEffect')
        // const cancelTokenSource = axios.CancelToken.source() //new AbortController()  
        // const token1 = cancelTokenSource.token

        onCancel(() => {
            console.log("cancel while fetch is still executed, use controller for aborting the request.");
            //    cancelTokenSource.cancel();
        })

        try {
            if (!alreadySelectedTopShoeModel) return
            setMaskShoeModelsEvent(true)
            setTopShoeModelsSelected([])
            let topSelectedShoeModel: iShoeModel[] = []
            console.log('alreadySelectedTopShoeModel', alreadySelectedTopShoeModel)
            let arr = alreadySelectedTopShoeModel.split(',')
            for (let i = 0; i < arr.length; i++) {

                let resp: any = yield httpShoeModelService.get(
                    { id: arr[i] }
                )
                console.log('resp--', resp)
                topSelectedShoeModel.push(resp.data)
            }
            let transformShoeModel =
                topSelectedShoeModel.map((value: iShoeModel, index, arr) => {
                    return {
                        ...value,
                        searchableName: value.modelName! + ' - ' + value.modelCode!
                    }
                })
            setTopShoeModels(transformShoeModel)
            setTopShoeModelsSelected(transformShoeModel)
            setMaskShoeModelsEvent(false)
        } catch (err) {
            //    if (err.name === 'AbortError') {
            console.log("Request was canceled via controller.abort")
            // we know that an 'AbortError' occurs when the request is
            // cancelled this means that the next promise returned by yield
            // will be created but not actively used, thus, we return in
            // order to avoid the promise being created.
            return;
            //    }
        }

    }, [alreadySelectedTopShoeModel])
    useEffect(() => {
        if (!alreadySelectedBottomShoeModel) return
        console.log('dddddddddddddddd')
        httpShoeModelService.get<iShoeModel>(
            { id: alreadySelectedBottomShoeModel }
        )
            .then((value) => {
                console.log('ffff data', value)

                setBottomShoeModels([{
                    ...value.data,
                    searchableName: value.data.modelName! + ' - ' + value.data.modelCode!
                }])
                setBottomShoeModelsSelected([{
                    ...value.data,
                    searchableName: value.data.modelName! + ' - ' + value.data.modelCode!
                }])
            }).catch((err) => console.log(err))

    }, [alreadySelectedBottomShoeModel])


    useEffect(() => {
        if (!settings) return
        for (let k = 0; k < settings.length; k++) {
            if (settings[k].keyName == 'TopAdBannerShoemodel') {
                setAlreadySelectedTopShoeModel(settings[k]?.toValue)
            }
            if (settings[k].keyName == 'BottomAdBannerShoemodel') {
                console.log('settings[k].keyName', settings[k])
                setAlreadySelectedBottomShoeModel(settings[k]?.toValue)
            }
            if (settings[k].keyName == 'Penalty') {
                let toVal = settings[k]?.toValue
                setEnableOrderThresholdPercent(toVal == 'on')
            }
        }
    }, [settings])

    // useEffect(() => {
    //     if (!settings && !shoeModels.length) return
    //     for (let k = 0; k < settings.length; k++) {
    //         if (settings[k].keyName == 'TopAdBannerShoemodel') {
    //             console.log('settings[k].keyName', settings[k])
    //             let shoeModelIds = settings[k].toValue.split(',')
    //             let selShoeModels: iShoeModel[] = []
    //             for (let i = 0; i < shoeModelIds.length; i++) {
    //                 // selShoeModels = shoeModels.map((item)=> item.id == +shoeModelIds[i] ? item : null)
    //                 let sm = shoeModels.find((item) => item.id == +shoeModelIds[i])
    //                 if (sm) selShoeModels.push(sm)

    //             }
    //             console.log('selShoeModels', selShoeModels)
    //             setShoeModelsSelected(selShoeModels)
    //         }
    //         if (settings[k].keyName == 'Penalty') {
    //             let toVal = settings[k].toValue
    //             setEnableOrderThresholdPercent(toVal == 'on')
    //         }
    //     }
    // }, [settings, shoeModels])

    const loadSettings = async () => {
        console.log('loadSettings')
        //TODO make the get request for settings
        await httpSettingService.getAll<iSetting>()
            .then((value) => {
                console.log('value.data', value.data)
                let orderedArr = orderByMockData(value.data)
                console.log('orderedArr', orderedArr)
                setSettings(orderedArr)
                //setSettings(mockSettings)
            })
    }
    const saveChanges = () => {
        //TODO make the post request for settings
        let smToValue = ''
        for (let i = 0; i < topShoeModelsSelected.length; i++) {
            if (!smToValue) {
                smToValue = smToValue + topShoeModelsSelected[i].id
            } else {
                smToValue = smToValue + ',' + topShoeModelsSelected[i].id
            }
        }
        console.log('smToValue', smToValue)
        console.log('settings', settings)
        let settingsToSave = settings.map((item) =>
            item.keyName == 'TopAdBannerShoemodel' ?
                { ...item, toValue: smToValue } :
                item)
        settingsToSave = settingsToSave.map((item) =>
            item.keyName == 'BottomAdBannerShoemodel' ?
                { ...item, toValue: bottomShoeModelsSelected.map((item) => item.id).join(",") } :
                item)
        httpSettingService.post(settingsToSave)
            .then(async (value) => {
                await httpOrdersService.getForceReloadSettings();
                await httpWarehouseb2bService.getForceReloadSettings();
                toast.success(t`Save_success_msg`)
            })

    }


    const renderAutoComplete = (item: iSetting, index: number): ReactNode => {
        return <div className="row align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Auto_Complete')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsFirst" + index}
                        id={"inlineRadioFirst" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "on"}
                        onChange={(evt) => setKeyWithValue(item.keyName, 'on')} />
                    <label className="form-check-label">{t('on')}</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsSec" + index}
                        id={"inlineRadioSec" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "off"}
                        onChange={(evt) => setKeyWithValue(item.keyName, 'off')} />
                    <label className="form-check-label">{t('off')}</label>
                </div>
            </div>
        </div>
    }
    const renderTwoFactorAuth = (item: iSetting, index: number): ReactNode => {

        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Two_Factor_Authentication')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsThird" + index}
                        id={"inlineRadioThird" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "on"}
                        onChange={(evt) => setKeyWithValue(item.keyName, 'on')} />
                    <label className="form-check-label">{t('on')}</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsForth" + index}
                        id={"inlineRadioForth" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "off"}
                        onChange={(evt) => setKeyWithValue(item.keyName, 'off')} />
                    <label className="form-check-label">{t('off')}</label>
                </div>
            </div>
        </div>
    }
    const renderOverlay = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Overlay')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsFirst" + index}
                        id={"inlineRadioFirst" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "on"}
                        onChange={(evt) => setKeyWithValue(item.keyName, 'on')} />
                    <label className="form-check-label">{t('on')}</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsSec" + index}
                        id={"inlineRadioSec" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "off"}
                        onChange={(evt) => setKeyWithValue(item.keyName, 'off')} />
                    <label className="form-check-label">{t('off')}</label>
                </div>
            </div>
        </div>
    }

    const renderPenalty = (item: iSetting, index: number): ReactNode => {
        return <div className="row align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Penalty')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsFirst" + index}
                        id={"inlineRadioFirst" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "on"}
                        onChange={(evt) => { setEnableOrderThresholdPercent(true); setKeyWithValue(item.keyName, 'on') }} />
                    <label className="form-check-label">{t('on')}</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsSec" + index}
                        id={"inlineRadioSec" + index}
                        value={item?.toValue}
                        checked={item?.toValue === "off"}
                        onChange={(evt) => { setEnableOrderThresholdPercent(false); setKeyWithValue(item.keyName, 'off') }} />
                    <label className="form-check-label">{t('off')}</label>
                </div>
            </div>
        </div>
    }
    const renderOrderThresholdPercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
            style={!enableOrderThresholdPercent ? { pointerEvents: "none", opacity: "0.4" } : {}}>
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Order_threshold_percent')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }
    const renderCartTimeOut = (item: iSetting, index: number): ReactNode => {

        return <div className="row align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Cart_Timeout')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            placeholder="Min/Hour/Day"
                            value={item?.toValue}
                            onChange={(evt) => {
                                if (isNaN(+evt.target.value))
                                    return
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">{t('minutes_30')}</div>
                </div>
            </div>
        </div>
    }

    var myTopDebounceTimer: NodeJS.Timeout
    useEffect(() => {
        if (!searchTopShoeModel) return
        myTopDebounceTimer = setTimeout(() => { loadShoeModel(true) }, 1500)
        return () => {
            clearTimeout(myTopDebounceTimer);
        };
    }, [searchTopShoeModel])
    var myBottomDebounceTimer: NodeJS.Timeout
    useEffect(() => {
        if (!searchBottomShoeModel) return
        myBottomDebounceTimer = setTimeout(() => { loadShoeModel(false) }, 1500)
        return () => {
            clearTimeout(myBottomDebounceTimer);
        };
    }, [searchBottomShoeModel])
    useEffect(() => {
        console.log('***topShoeModels', topShoeModels)
    }, [topShoeModels])
    const renderTopAdBannerShoemodel = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Top_banner_shoe_model')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                {/* <select className="form-control">
                <option>Shoe Model1</option>
                <option>Shoe Model2</option>
            </select> */}
                <Multiselect
                    onSearch={(value) => {
                        setSearchTopShoeModel(value);
                    }}
                    options={topShoeModels} // Options to display in the dropdown
                    selectedValues={topShoeModelsSelected} // Preselected value to persist in dropdown
                    onSelect={onSelectTopShoeModels} // Function will trigger on select event
                    onRemove={onRemoveTopShoeModels} // Function will trigger on remove event
                    displayValue="searchableName" // Property name to display in the dropdown options
                />
            </div>
        </div>
    }
    const renderBottomAdBannerShoemodel = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Bottom_banner_shoe_model')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                {/* {populateBottomAdBanner(item?.toValue)} */}
                <Multiselect
                    selectionLimit={1}
                    //singleSelect={true}
                    onSearch={(value) => {
                        setSearchBottomShoeModel(value);
                    }}
                    options={bottomShoeModels} // Options to display in the dropdown
                    selectedValues={bottomShoeModelsSelected} // Preselected value to persist in dropdown
                    onSelect={onSelectBottomShoeModels} // Function will trigger on select event
                    onRemove={onRemoveBottomShoeModels} // Function will trigger on remove event
                    displayValue="searchableName" // Property name to display in the dropdown options
                />
            </div>
        </div>
    }
    const renderAppSessionTimeOut = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('User_session_timeout')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                var floatValues = /^\d+$/;
                                let val = evt.target.value
                                if (!val.length || (val.match(floatValues) && !isNaN(+evt.target.value)))
                                    setKeyWithValue(item.keyName, val);
                            }} />
                    </div>
                    <div className="col-9">{t('days')}</div>
                </div>
            </div>
        </div>
    }
    const renderWebSessionTimeOut = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Web_session_timeout')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                if (isNaN(+evt.target.value))
                                    return
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">{t('Web_session_timeout')}</div>
                </div>
            </div>
        </div>
    }
    const renderSellShippingFeePercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Sell_Shipping_fee')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }
    const renderSellTransactionFeePercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Sell_Transection_fee')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }
    const renderSellPaymentProcessingFeePercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Sell_Payment_processing_fee')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }

    const renderSellerFeePercent = (item: iSetting, index: number): ReactNode => {
        // console.log('renderSellerFeePercent', 'index', index)
        //console.log('p_groupItems', p_groupItems.find((value, ind, arr) => value.index === index)?.keys)
        let keys = p_groupItems.find((value, ind, arr) => value.index === index)?.keys
        let key_b2bSellFeesOnQuantity = settings.find((value, ind, arr) => value.keyName == keys![0])!
        let key_b2bApplySellFees = settings.find((value, ind, arr) => value.keyName == keys![1])!
        let key_b2bSellShippingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![2])!
        let key_b2bSellTransactionFeePercent = settings.find((value, ind, arr) => value.keyName == keys![3])!
        let key_b2bSellPaymentProcessingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![4])!

        let key_b2cSellFeesOnQuantity = settings.find((value, ind, arr) => value.keyName == keys![5])!
        let key_b2cApplySellersFees = settings.find((value, ind, arr) => value.keyName == keys![6])!
        let key_b2cSellShippingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![7])!
        let key_b2cSellTransactionFeePercent = settings.find((value, ind, arr) => value.keyName == keys![8])!
        let key_b2cSellPaymentProcessingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![9])!
        return (
            // <div className="group-settings"> 
            <>
                <div className="sub-header">
                    <div className="row mt-2 align-items-center">
                        <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                            <h6>Seller Fee (%)</h6>
                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h6>B2B</h6>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h6>B2C</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 col-sm-5 col-md-4 col-lg-3">Seller Shipping Fee</div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="setting-switch-control">
                                    <div className="input-euro-txt">
                                        <div className="euro-txt">&euro; xunit</div>
                                        <input type="text" className="form-control"
                                            value={key_b2bSellFeesOnQuantity.toValue}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val))
                                                    return
                                                if (val <= 100)
                                                    setKeyWithValue(key_b2bSellFeesOnQuantity.keyName, evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="setting-switch">
                                        <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"
                                                    value={key_b2bApplySellFees.toValue}
                                                    checked={key_b2bApplySellFees.toValue === "on"}
                                                    onChange={(evt) => setKeyWithValue(key_b2bApplySellFees.keyName, key_b2bApplySellFees.toValue == "on" ? 'off' : "on")}


                                                />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2bSellShippingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2bSellShippingFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="setting-switch-control">
                                    <div className="input-euro-txt">
                                        <div className="euro-txt">&euro; xunit</div>
                                        <input type="text" className="form-control"
                                            value={key_b2cSellFeesOnQuantity.toValue}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val))
                                                    return
                                                if (val <= 100)
                                                    setKeyWithValue(key_b2cSellFeesOnQuantity.keyName, evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="setting-switch">
                                        <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"
                                                    value={key_b2cApplySellersFees.toValue}
                                                    checked={key_b2cApplySellersFees.toValue === "on"}
                                                    onChange={(evt) => setKeyWithValue(key_b2cApplySellersFees.keyName, key_b2cApplySellersFees.toValue === "on" ? 'off' : 'on')}

                                                />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2cSellShippingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2cSellShippingFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 col-sm-5 col-md-4 col-lg-3">Seller Processing Fee</div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2bSellTransactionFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2bSellTransactionFeePercent.keyName, evt.target.value)
                                        }}

                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2cSellTransactionFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2cSellTransactionFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 col-sm-5 col-md-4 col-lg-3">Seller Payment Processing Fee</div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2bSellPaymentProcessingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2bSellPaymentProcessingFeePercent.keyName, evt.target.value)
                                        }} />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2cSellPaymentProcessingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2cSellPaymentProcessingFeePercent.keyName, evt.target.value)
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    const renderBuyerFeePercent = (item: iSetting, index: number): ReactNode => {
        //console.log('renderBuyerFeePercent', 'index', index)
        //console.log('p_groupItems', p_groupItems.find((value, ind, arr) => value.index === index)?.keys)
        let keys = p_groupItems.find((value, ind, arr) => value.index === index)?.keys
        let key_b2bBuyerFeesOnQuantity = settings.find((value, ind, arr) => value.keyName == keys![0])!
        let key_b2bApplyBuyerFees = settings.find((value, ind, arr) => value.keyName == keys![1])!
        let key_b2bBuyerShippingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![2])!
        let key_b2bBuyerTransactionFeePercent = settings.find((value, ind, arr) => value.keyName == keys![3])!
        let key_b2bBuyerPaymentProcessingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![4])!

        let key_b2cBuyerFeesOnQuantity = settings.find((value, ind, arr) => value.keyName == keys![5])!
        let key_b2cApplyBuyersFees = settings.find((value, ind, arr) => value.keyName == keys![6])!
        let key_b2cBuyerShippingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![7])!
        let key_b2cBuyerTransactionFeePercent = settings.find((value, ind, arr) => value.keyName == keys![8])!
        let key_b2cBuyerPaymentProcessingFeePercent = settings.find((value, ind, arr) => value.keyName == keys![9])!

        return (
            // <div className="group-settings"> 
            <>
                <div className="sub-header">
                    <div className="row mt-2 align-items-center">
                        <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                            <h6>Buyer Fee (%)</h6>
                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h6>B2B</h6>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <h6>B2C</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 col-sm-5 col-md-4 col-lg-3">Buyer Shipping Fee</div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="setting-switch-control">
                                    <div className="input-euro-txt">
                                        <div className="euro-txt">&euro; xunit</div>
                                        <input type="text" className="form-control"
                                            value={key_b2bBuyerFeesOnQuantity.toValue}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val))
                                                    return
                                                if (val <= 100)
                                                    setKeyWithValue(key_b2bBuyerFeesOnQuantity.keyName, evt.target.value)
                                            }} />
                                    </div>
                                    <div className="setting-switch">
                                        <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"
                                                    value={key_b2bApplyBuyerFees.toValue}
                                                    checked={key_b2bApplyBuyerFees.toValue === "on"}
                                                    onChange={(evt) => setKeyWithValue(key_b2bApplyBuyerFees.keyName, key_b2bApplyBuyerFees.toValue === "on" ? 'off' : 'on')}

                                                />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2bBuyerShippingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2bBuyerShippingFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="setting-switch-control">
                                    <div className="input-euro-txt">
                                        <div className="euro-txt">&euro; xunit</div>
                                        <input type="text" className="form-control"
                                            value={key_b2cBuyerFeesOnQuantity.toValue}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val))
                                                    return
                                                if (val <= 100)
                                                    setKeyWithValue(key_b2cBuyerFeesOnQuantity.keyName, evt.target.value)
                                            }}
                                        />
                                    </div>
                                    <div className="setting-switch">
                                        <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"

                                                    value={key_b2cApplyBuyersFees.toValue}
                                                    checked={key_b2cApplyBuyersFees.toValue === "on"}
                                                    onChange={(evt) => setKeyWithValue(key_b2cApplyBuyersFees.keyName, key_b2cApplyBuyersFees.toValue === "on" ? 'off' : 'on')}

                                                />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2cBuyerShippingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2cBuyerShippingFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 col-sm-5 col-md-4 col-lg-3">Buyer Processing Fee</div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2bBuyerTransactionFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2bBuyerTransactionFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2cBuyerTransactionFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2cBuyerTransactionFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-2 align-items-center">
                    <div className="col-12 col-sm-5 col-md-4 col-lg-3">Buyer Payment Processing Fee</div>
                    <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2bBuyerPaymentProcessingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2bBuyerPaymentProcessingFeePercent.keyName, evt.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                                <div className="percentage">
                                    <div className="t-percentage">%</div>
                                    <input type="text" className="form-control"
                                        value={key_b2cBuyerPaymentProcessingFeePercent.toValue}
                                        onChange={(evt) => {
                                            const val = +evt.target.value
                                            if (isNaN(val))
                                                return
                                            if (val <= 100)
                                                setKeyWithValue(key_b2cBuyerPaymentProcessingFeePercent.keyName, evt.target.value)
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        )
    }
    const renderBuyShippingFeePercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Buy_Shipping_fee')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }
    const renderBuyTransactionFeePercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Buy_Transection_fee')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }
    const renderBuyPaymentProcessingFeePercent = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Buy_Payment_processing_fee')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                const val = +evt.target.value
                                if (isNaN(val))
                                    return
                                if (val <= 100)
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">(%)</div>
                </div>
            </div>
        </div>
    }
    const renderLatestProductDays = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Latest_product_days')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                var floatValues = /^\d+$/;
                                let val = evt.target.value
                                if (!val.length || (val.match(floatValues) && !isNaN(+evt.target.value)))
                                    //return
                                    setKeyWithValue(item.keyName, evt.target.value)
                            }} />

                    </div>
                    <div className="col-9">{t('days')}</div>
                </div>
            </div>
        </div>
    }
    const renderRecentlyViewedDays = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Recently_viewed_days')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                var floatValues = /^\d+$/;
                                let val = evt.target.value
                                if (!val.length || (val.match(floatValues) && !isNaN(+evt.target.value)))
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">{t('days')}</div>
                </div>
            </div>
        </div>
    }
    const renderHottestProductsDays = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Hottest_product_days')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                var floatValues = /^\d+$/;
                                let val = evt.target.value
                                if (!val.length || (val.match(floatValues) && !isNaN(+evt.target.value)))
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">{t('days')}</div>
                </div>
            </div>
        </div>
    }
    const renderCarteExpiryinDays = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Cart_expiry_days')} </div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                if (isNaN(+evt.target.value))
                                    return
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">{t('days')}</div>
                </div>
            </div>
        </div>
    }

    const renderSellerPointAmount = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Seller_reward_point_amount')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                if (isNaN(+evt.target.value))
                                    return
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">({t('Amount')})</div>
                </div>
            </div>
        </div>
    }

    const renderBuyerPointAmount = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Buyer_reward_point_amount')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                if (isNaN(+evt.target.value))
                                    return
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">({t('Amount')})</div>
                </div>
            </div>
        </div>
    }

    const renderRequestAndOfferExpiry = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Request_Blocking_Hours')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                if (isNaN(+evt.target.value))
                                    return
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">({t('Hours')})</div>
                </div>
            </div>
        </div>
    }
    // const renderRequestExpiryHours = (item: iSetting, index: number): ReactNode => {
    //     return <div className="row mt-2 align-items-center">
    //         <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Request_Expiry_Hours')}</div>
    //         <div className="col-12 col-sm-5 col-md-5 col-lg-4">
    //             <div className="row align-items-center">
    //                 <div className="col-3 pe-0">
    //                     <input type="text"
    //                         className="form-control"
    //                         value={item.toValue}
    //                         onChange={(evt) => {
    //                             if (isNaN(+evt.target.value))
    //                                 return
    //                             setKeyWithValue(item.keyName, evt.target.value)
    //                         }} />
    //                 </div>
    //                 <div className="col-9">({t('Hours')})</div>
    //             </div>
    //         </div>
    //     </div>
    // }
    // const renderOfferExpiryHours = (item: iSetting, index: number): ReactNode => {
    //     return <div className="row mt-2 align-items-center">
    //         <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Offer_Expiry_Hours')}</div>
    //         <div className="col-12 col-sm-5 col-md-5 col-lg-4">
    //             <div className="row align-items-center">
    //                 <div className="col-3 pe-0">
    //                     <input type="text"
    //                         className="form-control"
    //                         value={item?.toValue}
    //                         onChange={(evt) => {
    //                             if (isNaN(+evt.target.value))
    //                                 return
    //                             setKeyWithValue(item.keyName, evt.target.value)
    //                         }} />
    //                 </div>
    //                 <div className="col-9">({t('Hours')})</div>
    //             </div>
    //         </div>
    //     </div>
    // }
    const renderRequestExpiryHours = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Request_Expiry_Hours')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item.toValue}
                            onChange={(evt) => {
                                var floatValues = /^\d+$/;
                                let val = evt.target.value
                                if (!val.length || (val.match(floatValues) && !isNaN(+evt.target.value)))
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">({t('Hours')})</div>
                </div>
            </div>
        </div>
    }
    const renderOfferExpiryHours = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Offer_Expiry_Hours')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-3 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item.toValue}
                            onChange={(evt) => {
                                var floatValues = /^\d+$/;
                                let val = evt.target.value
                                if (!val.length || (val.match(floatValues) && !isNaN(+evt.target.value)))
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-9">({t('Hours')})</div>
                </div>
            </div>
        </div>
    }
    const renderLotExpirationDays = (item: iSetting, index: number): ReactNode => {
        return <></>
        // return <div className="row mt-2 align-items-center"
        // >
        //     <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Lot_expiration')} </div>
        //     <div className="col-12 col-sm-5 col-md-5 col-lg-4">
        //         <div className="row align-items-center">
        //             <div className="col-3 pe-0">
        //                 <input type="text"
        //                     className="form-control"
        //                     value={item?.toValue}
        //                     onChange={(evt) => {
        //                         if (isNaN(+evt.target.value) || +evt.target.value > 14)
        //                             return
        //                         setKeyWithValue(item.keyName, evt.target.value)
        //                     }} />

        //             </div>
        //             <div className="col-9">{t('Max')} {'14'} { t('days')}</div>
        //         </div>
        //     </div>
        // </div>
    }
    const renderLotForSaleExpirationDays = (item: iSetting, index: number): ReactNode => {
        return <></>
        // return <div className="row mt-2 align-items-center"
        // >
        //     <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Lot_For_Sale_expiration')} </div>
        //     <div className="col-12 col-sm-5 col-md-5 col-lg-4">
        //         <div className="row align-items-center">
        //             <div className="col-3 pe-0">
        //                 <input type="text"
        //                     className="form-control"
        //                     value={item?.toValue}
        //                     onChange={(evt) => {
        //                         if (isNaN(+evt.target.value))
        //                             return
        //                         setKeyWithValue(item.keyName, evt.target.value)
        //                     }} />

        //             </div>
        //             <div className="col-9">{t('days')}</div>
        //         </div>
        //     </div>
        // </div>
    }
    const renderModelForSaleExpirationDays = (item: iSetting, index: number): ReactNode => {
        return <></>
        // return <div className="row mt-2 align-items-center"
        // >
        //     <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Model_For_Sale_expiration')} </div>
        //     <div className="col-12 col-sm-5 col-md-5 col-lg-4">
        //         <div className="row align-items-center">
        //             <div className="col-3 pe-0">
        //                 <input type="text"
        //                     className="form-control"
        //                     value={item?.toValue}
        //                     onChange={(evt) => {
        //                         if (isNaN(+evt.target.value))
        //                             return
        //                         setKeyWithValue(item.keyName, evt.target.value)
        //                     }} />

        //             </div>
        //             <div className="col-9">{t('days')}</div>
        //         </div>
        //     </div>
        // </div>
    }


    const renderCounterOfferExpiration = (item: iSetting, index: number): ReactNode => {
        return <></>
        // return <div className="row mt-2 align-items-center">
        //     <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Counter_Offer_Expiration')}</div>
        //     <div className="col-12 col-sm-5 col-md-5 col-lg-4">
        //         <div className="row align-items-center">
        //             <div className="col-3 pe-0">
        //                 <input type="text"
        //                     className="form-control"
        //                     value={item?.toValue}
        //                     onChange={(evt) => {
        //                         if (isNaN(+evt.target.value))
        //                             return
        //                         setKeyWithValue(item.keyName, evt.target.value)
        //                     }} />
        //             </div>
        //             <div className="col-9">({t('Hours')})</div>
        //         </div>
        //     </div>
        // </div>
    }
    const renderOfferExpiration = (item: iSetting, index: number): ReactNode => {
        return <></>
        // return <div className="row mt-2 align-items-center">
        //     <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Offer_Expiration')}</div>
        //     <div className="col-12 col-sm-5 col-md-5 col-lg-4">
        //         <div className="row align-items-center">
        //             <div className="col-3 pe-0">
        //                 <input type="text"
        //                     className="form-control"
        //                     value={item?.toValue}
        //                     onChange={(evt) => {
        //                         if (isNaN(+evt.target.value))
        //                             return
        //                         setKeyWithValue(item.keyName, evt.target.value)
        //                     }} />
        //             </div>
        //             <div className="col-9">({t('Hours')})</div>
        //         </div>
        //     </div>
        // </div>
    }
    const onEdit = (penalty: iPenalty, type: number) => {
        setPenaltyToSave({ ...penalty, penaltyType: type })
        onToggleClick(type)
    }

    const renderPenaltyTable = (penaltys: iPenalty[], type: number): ReactNode[] => {
        console.log(penaltys?.length)
        const returnNode: ReactNode[] = penaltys?.map((penalty: iPenalty) => (

            <tr key={penalty.id}>
                <td>{penalty.id}</td>
                <td>{eSanction[penalty.sanctionType]}</td>
                <td>{ePenaltyType[penalty.penaltyType]}</td>
                <td>{penalty.percentageValue}</td>
                <td>{penalty.minimumAmount}</td>
                <td>{penalty.collectionDays}</td>
                <td>{penalty.collectionTime}</td>
                <td>{penalty.rewardLevelsRecede}</td>
                <td>{penalty.motivationText}</td>
                <td>{<>
                    <a onClick={() => onEdit(penalty, type)}
                        title="Edit"
                        data-toggle="tooltip"
                        data-placement="top"
                        data-original-title="Edit"
                        className="btn btn-bulk small me-1" data-rel="tooltip" >
                        <i className="la la-edit"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const renderSellerOrderCancellationPenalty = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Seller_order_cancellation_penalty')}</div>
            {sellingOrderCancellationPenalty.length === 0 ? <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="action-btn">
                    <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                        data-toggle="modal"
                        data-target="#addSize"
                        id="id-add-brand"
                        onClick={() => onToggleClick(2)}
                    >{t('Add_Penalty')}</a>
                </div>
                {/* {populateSellerOrderCancellationPenalty(item?.toValue, index)} */}
            </div> :
                <div className="mt-3">
                    <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>{t('Id')}</th>
                                <th>{t('Sanction_Type')}</th>
                                <th>{t('Penalty_Type')}</th>
                                <th>{t('Amount')}</th>
                                <th>{t('Min_Amount_Due')}</th>
                                <th>{t('No_of_Days_for_Collection')}</th>
                                <th>{t('Collection_Timing')}</th>
                                <th>{t('No_of_level_Recede')}</th>
                                <th>{t('Motivation_Text')}</th>
                                <th style={{ width: '35px' }}>{t('Action')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {renderPenaltyTable(sellingOrderCancellationPenalty, 2)}
                        </tbody>
                    </table>
                </div>
            }
        </div>
    }

    const renderSellerOrderUnverifiedPenalty = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Seller_order_unvarified_penalty')}</div>
            {unverifiedOrderCancellationPenalty.length === 0 ? <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="action-btn">
                    <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                        data-toggle="modal"
                        data-target="#addSize"
                        id="id-add-brand"
                        onClick={() => onToggleClick(3)}
                    >{t('Add_Penalty')}</a>
                </div>

                {/* {populateSellerOrderUnverifiedPenalty(item?.toValue, index)} */}
            </div> :
                <div className="mt-3">
                    <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>{t('Id')}</th>
                                <th>{t('Sanction_Type')}</th>
                                <th>{t('Penalty_Type')}</th>
                                <th>{t('Amount')}</th>
                                <th>{t('Min_Amount_Due')}</th>
                                <th>{t('No_of_Days_for_Collection')}</th>
                                <th>{t('Collection_Timing')}</th>
                                <th>{t('No_of_level_Recede')}</th>
                                <th>{t('Motivation_Text')}</th>
                                <th style={{ width: '35px' }}>{t('Action')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {renderPenaltyTable(unverifiedOrderCancellationPenalty, 3)}
                        </tbody>
                    </table>
                </div>}
        </div>
    }

    const renderOrderCancellationCompansationToBuyer = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Order_cancellation_compansation_to_buyer')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                {populateOrderCancellationCompansationToBuyer(item?.toValue, index)}
            </div>
        </div>
    }


    const renderPenaltyCollectionTime = (item: iSetting, index: number): ReactNode => {
        return <div className="row mt-2 align-items-center">
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Collection_Timing_eg')}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="row align-items-center">
                    <div className="col-5 pe-0">
                        <input type="text"
                            className="form-control"
                            value={item?.toValue}
                            onChange={(evt) => {
                                setKeyWithValue(item.keyName, evt.target.value)
                            }} />
                    </div>
                    <div className="col-7">({t('Time')})</div>
                </div>
            </div>
        </div>
    }
    const renderGroupSwitch = (groupItem: iGI, index: number): ReactNode => {
        let p_setting: iSetting | undefined = undefined
        // console.log('groupName', groupItem.groupName, ' index=', index)
        switch (index) {
            case 0:
                // p_setting = settings.find((setting) => setting.keyName == groupItem.keys[0])
                // return renderAutoComplete(p_setting!, index)
                break;
            case 1:
                p_setting = settings.find((setting) => setting.keyName == groupItem.keys[0])
                return renderTwoFactorAuth(p_setting!, index);

            case 2:
                // p_setting = settings.find((setting) => setting.keyName == groupItem.keys[0])
                // return renderOverlay(p_setting!, index)
                break;
            case 3:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {/* {renderPenalty(settings.find(
                            (setting) => setting.keyName == groupItem.keys[0])!, index)} */}
                        {renderOrderThresholdPercent(settings.find(
                            (setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderOrderCancellationCompansationToBuyer(settings.find(
                            (setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
                        {renderPenaltyCollectionTime(settings.find(
                            (setting) => setting.keyName == groupItem.keys[2])!, index + 2)}
                        {/* {renderSellerOrderCancellationPenalty(settings.find(
                            (setting) => setting.keyName == groupItem.keys[4])!, index + 4)}
                        {renderSellerOrderUnverifiedPenalty(settings.find(
                            (setting) => setting.keyName == groupItem.keys[2])!, index + 2)} */}
                    </div>
                )
            // case 5:
            //     return (
            //         <div className="group-settings">
            //             <h6 className="sub-header">{groupItem.groupName}</h6>
            //             {/* {renderCartTimeOut(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)} */}
            //             {renderAppSessionTimeOut(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
            //             {/* {renderWebSessionTimeOut(settings.find((setting) => setting.keyName == groupItem.keys[2])!, index + 2)} */}
            //         </div>
            //     )
            //     break;
            case 5:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {renderTopAdBannerShoemodel(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderBottomAdBannerShoemodel(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
                    </div>
                )
            // case 7:
            //     return (
            //         <div className="group-settings">
            //             {/* <h6 className="sub-header">{groupItem.groupName}</h6> */}
            //             {/* {renderSellShippingFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
            //             {renderSellTransactionFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
            //             {renderSellPaymentProcessingFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[2])!, index + 2)} */}
            //             {renderSellerFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
            //         </div>
            //     )
            //     break;
            // case 17:
            //     return (
            //         <div className="group-settings">
            //             {/* <h6 className="sub-header">{groupItem.groupName}</h6>
            //             {renderBuyShippingFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
            //             {renderBuyTransactionFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
            //             {renderBuyPaymentProcessingFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[2])!, index + 2)} */}
            //             {renderBuyerFeePercent(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
            //         </div>
            //     )
            //     break;

            case 7:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {renderLatestProductDays(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderRecentlyViewedDays(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
                        {renderHottestProductsDays(settings.find((setting) => setting.keyName == groupItem.keys[2])!, index + 2)}
                        {renderAppSessionTimeOut(settings.find((setting) => setting.keyName == groupItem.keys[3])!, index + 3)}
                        {/* {renderCarteExpiryinDays(settings.find((setting) => setting.keyName == groupItem.keys[3])!, index + 3)} */}
                    </div>
                )
                break;
            case 12:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {renderSellerPointAmount(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderBuyerPointAmount(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
                    </div>
                )
                break;
            case 14:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {/* {renderRequestAndOfferExpiry(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)} */}
                        {renderRequestExpiryHours(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderOfferExpiryHours(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}

                    </div>
                )
            case 17:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {renderLotExpirationDays(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderLotForSaleExpirationDays(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
                        {renderModelForSaleExpirationDays(settings.find((setting) => setting.keyName == groupItem.keys[2])!, index + 2)}
                    </div>
                )
            case 20:
                return (
                    <div className="group-settings">
                        <h6 className="sub-header">{groupItem.groupName}</h6>
                        {renderOfferExpiration(settings.find((setting) => setting.keyName == groupItem.keys[0])!, index)}
                        {renderCounterOfferExpiration(settings.find((setting) => setting.keyName == groupItem.keys[1])!, index + 1)}
                    </div>
                )
        }
    }

    // const renderSwitch = (item: iSetting, index: number): ReactNode => {

    //     switch (item.keyName) {
    //         case "AutoComplete":
    //             return renderAutoComplete(item, index)
    //         case "TwoFactorAuth":
    //             return renderTwoFactorAuth(item, index)
    //         case "Overlay":
    //             return renderOverlay(item, index)
    //         case "Penalty":
    //             return renderPenalty(item, index)
    //         case "OrderThresholdPercent":
    //             return renderOrderThresholdPercent(item, index)
    //         case "SellerOrderCancellationPenalty":
    //             return renderSellerOrderCancellationPenalty(item, index)
    //         case "SellerOrderUnverifiedPenalty":
    //             return renderSellerOrderUnverifiedPenalty(item, index)
    //         case "OrderCancellationCompansationToBuyer":
    //             return renderOrderCancellationCompansationToBuyer(item, index)
    //         case "CartTimeOut":
    //             return renderCartTimeOut(item, index)
    //         case "AppSessionTimeOut":
    //             return renderAppSessionTimeOut(item, index)
    //         // case "WebSessionTimeOut":
    //         //     return renderWebSessionTimeOut(item, index)
    //         case "TopAdBannerShoemodel":
    //             return renderTopAdBannerShoemodel(item, index)
    //         case "BottomAdBannerShoemodel":
    //             return renderBottomAdBannerShoemodel(item, index)
    //         case "SellShippingFeePercent":
    //             return renderSellShippingFeePercent(item, index)
    //         case "SellTransactionFeePercent":
    //             return renderSellTransactionFeePercent(item, index)
    //         case "SellPaymentProcessingFeePercent":
    //             return renderSellPaymentProcessingFeePercent(item, index)
    //         case "BuyShippingFeePercent":
    //             return renderBuyShippingFeePercent(item, index)
    //         case "BuyTransactionFeePercent":
    //             return renderBuyTransactionFeePercent(item, index)
    //         case "BuyPaymentProcessingFeePercent":
    //             return renderBuyPaymentProcessingFeePercent(item, index)
    //         case "LatestProductDays":
    //             return renderLatestProductDays(item, index)
    //         case "RecentlyViewedDays":
    //             return renderRecentlyViewedDays(item, index)
    //         case "HottestProductsDays":
    //             return renderHottestProductsDays(item, index)
    //         case "CarteExpiryinDays":
    //             return renderCarteExpiryinDays(item, index)
    //         case "SellerPointAmount":
    //             return renderSellerPointAmount(item, index)
    //         case "BuyerPointAmount":
    //             return renderBuyerPointAmount(item, index)
    //     }
    // }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                <div className="content">
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-12">
                                    <h3>All Settings</h3>
                                </div>
                            </div>
                        </div>

                        <div className="setting-wrapper">
                            {/* {!maskShoeModelsEvent && settings && settings.map((item, index) =>
                                renderSwitch(item, index)
                            )} */}
                            {groupItems && settings && settings.length && groupItems.map((item) =>
                                renderGroupSwitch(item, item.index)
                            )}

                            <div className="mt-4 text-end">
                                <a href="javascript:void(0)"
                                    className="bulk-primary-btn"
                                    onClick={(e) => saveChanges()}
                                >{t('Save_Changes')}
                                </a>
                            </div>



                            {/* <ModalDialog toggle={() => onToggleClick(1)}
                                cancel={() => cancelAddView()}
                                executeButtonTitle={() => t('MD_Add')}
                                isOpen={isOpen}
                                execute={onExecuteAddView}
                                enableExecute={enableAddView() ? true : false}
                                modalTitle={t('Add_Penalty')}
                            >

                                {
                                    <div className="row">
                                        <div className="col-sm-12 mb-2">
                                            <div className="form-group d-flex">
                                                <label className="mr-3">{t('Sanction_Type')}:</label>
                                                <div className="form-check">
                                                    <input type="radio"
                                                        className="form-check-input mx-2"
                                                        id="radio1"
                                                        name="optradio"
                                                        value="1"
                                                        onChange={() => setPenaltyToSave({ ...penaltyToSave, sanctionType: eSanction.Economical })}
                                                        checked={penaltyToSave.sanctionType === 1}
                                                    />
                                                    <label className="form-check-label mr-3" htmlFor="radio1">
                                                        {eSanction[eSanction.Economical]}
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input type="radio"
                                                        className="form-check-input mx-2"
                                                        id="radio2"
                                                        name="optradio"
                                                        value="2"
                                                        onChange={() => setPenaltyToSave({ ...penaltyToSave, sanctionType: eSanction.PenaltyOnRewards })}
                                                        checked={penaltyToSave.sanctionType === 2}
                                                    />
                                                    <label className="form-check-label" htmlFor="radio2">
                                                        {eSanction[eSanction.PenaltyOnRewards]}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <div className="form-group">
                                                <label>{t`Amount`}:</label>
                                                <input type="text" className="form-control"
                                                    value={penaltyToSave.percentageValue}
                                                    onChange={(evt) => {
                                                        const val = +evt.target.value
                                                        if (isNaN(val))
                                                            return
                                                        if (val <= 999)
                                                            setPenaltyToSave({ ...penaltyToSave, percentageValue: +evt.target.value })
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-2 ps-lg-1">
                                            <div className="form-group">
                                                <label>{t('Minimum_Amount')}:</label>
                                                <input type="text" className="form-control"
                                                    value={penaltyToSave.minimumAmount}

                                                    onChange={(evt) => {
                                                        const val = +evt.target.value
                                                        if (isNaN(val) || val > 5000)
                                                            return
                                                        setPenaltyToSave({ ...penaltyToSave, minimumAmount: +evt.target.value })
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <div className="form-group">
                                                <label>{t('No_of_Days_for_Collection')}:</label>
                                                <input type="text" className="form-control"
                                                    value={penaltyToSave.collectionDays}
                                                    onChange={(evt) => {
                                                        const val = +evt.target.value
                                                        if (isNaN(val) || val > 365)
                                                            return
                                                        setPenaltyToSave({ ...penaltyToSave, collectionDays: +evt.target.value })
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-sm-6 mb-2">
                                            <div className="form-group" id='No_of_level_recede'>
                                                <label>{t('No_of_level_Recede')}:</label>
                                                <input type="text" className="form-control"
                                                    value={penaltyToSave.rewardLevelsRecede}
                                                    onChange={(evt) => {
                                                        const val = +evt.target.value
                                                        if (isNaN(val))
                                                            return
                                                        if (val <= 1)
                                                            setPenaltyToSave({ ...penaltyToSave, rewardLevelsRecede: +evt.target.value })
                                                    }
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 mb-2 ps-lg-1">
                                            <div className="form-group">
                                                <label>{t('Motivation_Text')}:</label>
                                                <input type="text" className="form-control"
                                                    value={penaltyToSave.motivationText}
                                                    onChange={(evt) => setPenaltyToSave({ ...penaltyToSave, motivationText: evt.target.value })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </ModalDialog> */}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;