import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { formats, modules } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";

const QuillEditor = ({ onChange, value }) => {
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default QuillEditor;
