import React, { useEffect, useState } from "react";
import { appPageSliceActions, iPageState } from "../../store/appPageSlice";
import { DebounceInput } from "react-debounce-input";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Pagination from './Pagination';
import { object } from "yup";
import { useSelector } from "react-redux";
import { RootState } from "../../store/stateTypes";

interface iCustomPaginationProps {
  totalResults: number;
  totalPages: number;
  p_iPageState: iPageState;
  onPageChanged: (data: any) => void
}
const CustomPagination = (props: iCustomPaginationProps) => {
  const dispatch = useDispatch();
  const pageState: iPageState | undefined =
  useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == props.p_iPageState!.filterType));
  const { t } = useTranslation()
  const [totalResults, setTotalResults] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)

  useEffect(()=>{
    console.log('CustomPagination state change')
    
    setTotalResults(props.totalResults)
    setCurrentPage(pageState!.currentPage)
    setTotalPages(props.totalPages)
  },[pageState])
  //console.log('-----', (totalResults > pageState!.pageLimit) && totalPages)
  return (
    <div className="nav-direction">
      <div className="d-flex flex-row py-4 align-items-center justify-content-between">
        <div>
          <label>{t('Page_Size')}:</label>
          <DebounceInput
            className="form-control page-count"
            minLength={1}
            debounceTimeout={500}
            value={pageState!.pageLimit}
            onChange={event => {
              if (!event.target.value || +event.target.value < 1) return
              dispatch(appPageSliceActions.savePageFilters({
                ...pageState,
                currentPage: 1,
                pageLimit: event.target.value
              }))
            }}
            placeholder={t('Page_Size')!} />
        </div>
        {
          (totalResults > pageState!.pageLimit) && totalPages ?
            <div>
              <Pagination totalRecords={totalResults}
                totalPages={totalPages}
                currentPage={currentPage}
                pageLimit={pageState!.pageLimit}
                pageNeighbours={1}
                onPageChanged={props.onPageChanged}
              />
            </div>
            :
            null
        }
      </div>
    </div>
  );
};

export default CustomPagination;