import React, { useState, useEffect } from "react";
import "./support.scss";
import { useTranslation } from "react-i18next";
import Faq from "./faq";
//import OrderAndReturns from "./components/order-returns";
//import ShippingConditions from "./components/shipping-conditions";
//import AboutUs from "./components/about-us";
//import ContactUs from "./components/contact-us";
import GeneralConditions from "./general-conditions";
import Privacy from "./privacy";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
//import { RootState } from "../../../store/stateTypes";

const sections = [
  {
    id: "faq",
    label: "FAQ",
    component: <Faq id=""/>,
  },
  {
    id: "tc",
    label: "Terms and conditions",
    component: <GeneralConditions />,
  },
  {
    id: "pp",
    label: "Privacy policy",
    component: <Privacy />,
  },
];

const Support = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState<string>("");
  const [faqId, setFaqId] = useState<string>("");
  //const b2c = useSelector((state: RootState) => state.b2c.value);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sectionParam = urlParams.get("section");
    const faqId = urlParams.get("id");
    if (sectionParam) {
      setActiveSection(sectionParam);
    }
    if(faqId) {
      setFaqId(faqId)
    }
  }, []);

  const switchSection = (section: string) => {
    setActiveSection(section);
    const newUrl = `?section=${section}`;
    window.history.pushState({ section }, "", newUrl);
  };

  return (
    <div className="container">
      <div className="faq-section">
        <div className="breadcrumbs">
          <ul>
            {/* <li>
              <Link to={b2c ? "/" : "/b2b"}>Home</Link>{" "}
              <span className="icon-arrow"></span>
            </li> 
            <li>Support</li>
            */}
          </ul>
        </div>
        <div className="user-info">
          <h3>Support</h3>
        </div>
        <div className="faq-tab-section">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {sections.map((section) => (
                    <button
                      key={section.id}
                      className={`nav-link ${
                        activeSection === section.id ? "active" : ""
                      }`}
                      id={`nav-${section.id}-tab`}
                      data-bs-toggle="tab"
                      data-bs-target={`#nav-${section.id}`}
                      type="button"
                      role="tab"
                      aria-controls={`nav-${section.id}`}
                      aria-selected={
                        activeSection === section.id ? "true" : "false"
                      }
                      onClick={() => switchSection(section.id)}
                    >
                      {t(section.label)}
                    </button>
                  ))}
                </div>
              </nav>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="faq-question-list">
                <div className="tab-content" id="nav-tabContent">
                  {sections.map((section) => (
                    <div
                      key={section.id}
                      className={`tab-pane fade ${
                        activeSection === section.id ? "show active" : ""
                      }`}
                      id={`nav-${section.id}`}
                      role="tabpanel"
                      aria-labelledby={`nav-${section.id}-tab`}
                    >
                      {section.id == 'faq' ? <Faq id={faqId}/> : section.component}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
