import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import dashlogo from "../../assets/dash-logo.png";

// import "../../assets/admin/css/vendor/bootstrap.css"

//Bootstrap 5.2.3, included from node_modules path
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../assets/admin/css/icons/line-awesome.min.css"
import "../../assets/admin/css/icons/simple-line-icons.css"
import "../../assets/admin/css/icons/dripicons.min.css"

import "../../assets/admin/css/icons/material-design-iconic-font.min.css"
import "../../assets/admin/css/icons/dripicons.min.css"
import "../../assets/admin/css/icons/dripicons.min.css"
import "../../assets/admin/css/icons/dripicons.min.css"
import "../../assets/admin/css/layouts/vertical/core/main.css"
import "../../assets/admin/css/layouts/vertical/menu-type/default.css"
import "../../assets/admin/css/layouts/vertical/themes/theme-a.css"

import "../../assets/admin/css/pnotify.custom.min.css"
import "../../assets/admin/css/common/custom.css"

import { HttpAuthService } from '../../services/admin.service'
import { error } from "console";
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation()
    // const [loginModel, setloginModel] = useState<AdminLoginModel>({email: '', password: ''});
    // For login only
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')

    // For Sign up
    const [emailIdSignup, setEmailIdSignup] = useState<string>('')
    const [passwordSignup, setPasswordSignup] = useState<string>('')
    const [confirmPasswordSignup, setConfirmPasswordSignup] = useState<string>('')
    const [userNameSignup, setUserNameSignup] = useState('')


    const [cookies, setCookie] = useCookies(['admin_user'])
    const [type, setType] = useState("password")


    let httpAuthService: HttpAuthService = new HttpAuthService()

    const navigate = useNavigate();


    // function setCookiesOnLogin(token: string, user: any, tok_expires_in: number) {
    //     var expires = new Date();
    //     expires.setTime(expires.getTime() + (tok_expires_in * 1000));

    //     process.env.REACT_APP_SERVER_PORT == '443' ?
    //         setCookie('admin_access_token', token, { path: '/', expires, secure: true })
    //         : setCookie('admin_access_token', token, { path: '/', expires })
    //     setCookie('admin_user', JSON.stringify(user), { path: '/', expires })
    //     setCookie('admin_expiry_time', expires.toUTCString(), { path: '/', expires })
    // }
    function setCookiesOnLogin(token: string, user: any, tok_expires_in: Date) {
        // var expires = new Date();
        // expires.setTime(tok_expires_in.getTime() );

        // process.env.REACT_APP_SERVER_PORT == '443' ?
        //     setCookie('admin_access_token', token, { path: '/', expires, secure: true })
        //     : setCookie('admin_access_token', token, { path: '/', expires })
        // setCookie('admin_user', JSON.stringify(user), { path: '/', expires })
        // setCookie('admin_expiry_time', expires.toUTCString(), { path: '/', expires })var expires = new Date();
        var expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        expires.setTime(expires.getTime());  
        setCookie("admin_user", JSON.stringify(user), { path: "/", expires });
    }
    const showPassword = () => {
        if (type === "password") {
            setType("text")
        } else if (type === "text") {
            setType("password")
        }
    }
    const handleLogin = async (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        console.log(' in handleLogin')
        // TODO: API call to login
        evt.preventDefault();
        await httpAuthService.login({ email: email, password: password })
            .then((value) => {
                //console.log('value.jwtToken', value.jwtToken)
                //setCookiesOnLogin('1234', {}, 5000)
                setCookiesOnLogin(value.jwtToken.token, value.user, new Date(value.jwtToken.expires))
                console.log(' after setCookiesOnLogin')
                localStorage.setItem('active-nav-item', '')
                navigate('/admin/brands/list', { replace: true });

            }).catch((error) => {
                console.log('error', error)
            })

    }
    const facebooklogin = () => {

    }
    const googlepluslogin = () => {

    }

    const twitterLogIn = () => {

    }



    const handleSignUp = () => {

    }


    return (
        <div className="sign-in-form-wrapper">
            <div className="container">
                <form className="sign-in-form" id="login-form">


                    <div className="card">
                        <div className="card-body">
                            <div className="m-b-20 mt-3">
                                <a href="#" className="brand text-center d-block">
                                    <img style={{ height: '60px' }} src={dashlogo} alt="Bulk" />
                                </a>
                            </div>
                            <h5 className="sign-in-heading text-center m-b-20">{t('Sign_in_to_your_account')}</h5>
                            <div className="form-group">
                                <label htmlFor="inputEmail" className="sr-only">{t('Email')}</label>
                                <input type="email" name="email" id="inputEmail" className="form-control" placeholder="Email address" required={true}
                                    value={email} onChange={(evt) => setEmail(evt.target.value)}
                                ></input>
                            </div>

                            <div className="form-group">
                                <label htmlFor="inputPassword" className="sr-only">{t('Password')}</label>
                                <input name="password" type="password" id="inputPassword" className="form-control" placeholder="Password" required={true}
                                    value={password} onChange={(evt) => setPassword(evt.target.value)}
                                ></input>
                            </div>


                            <input type="submit" id="login-btn" className="btn sign-in-btn btn-lg btn-block" value="Sign In"
                                onClick={(evt) => handleLogin(evt)}
                            ></input>
                            {/* <% /* <p className="text-muted m-t-25 m-b-0 p-0">Don't have an account yet?<a href="/auth/signup"> Create an account</a></p> %>   */}
                        </div>

                    </div>
                    {/* <% if(success && success.length > 0) { %> */}
                    <div className="alert alert-success alert-dismissible deposit-alert mt-3" role="alert">
                        {/* <%- success %>  */}
                    </div>
                    {/* <% } %> */}

                    {/* <% if(error && error.length > 0) { %> */}
                    <div className="alert alert-danger alert-dismissible deposit-alert mt-3" role="alert">
                        {/* <%- error %> */}
                    </div>
                    {/* <% } %> */}
                </form>
            </div>
            {/* <div id="signup-form" style={{ display: "none" }}>
                <div className="row row-eq-height">
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className="same-height login-box animated fadeInLeft">
                            <div className="login-box-logo">
                                <img src={dashlogo} />
                            </div>
                            <h4>{t("SignUpHeading")}</h4>
                            <div className="input-field left-icon">
                                <span className="icons left icon-user"></span>
                                <input type="text" placeholder={t("namee")}
                                    value={userNameSignup} onChange={(e) => setUserNameSignup(e.target.value)}
                                />
                            </div>

                            <div className="input-field left-icon">
                                <span className="icons left icon-email1"></span>
                                <input type="text" placeholder={t("email")}
                                    value={emailIdSignup} onChange={(e) => setEmailIdSignup(e.target.value)}
                                />
                            </div>

                            <div className="input-field left-icon">
                                <span className="icons left icon-phone1"></span>
                                <div className="phone-number">
                                    <div className="dial-code">

 
                                    </div>
                                </div>
                            </div>

                            <div className="input-field left-icon">
                                <span className="icons left icon-password"></span>
                                <input type={type} placeholder={t("Password")}
                                    value={passwordSignup} onChange={(e) => setPasswordSignup(e.target.value)}
                                />
                            </div>
                            <div className="input-field left-icon">
                                <span className="icons left icon-password"></span>
                                <input type={type} placeholder={t("confirm_password")}
                                    value={confirmPasswordSignup} onChange={(e) => setConfirmPasswordSignup(e.target.value)}
                                />
                            </div>
                            
                            <div className="conditions">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                    <label className="custom-control-label" >{t("by_signing_up")} <a data-toggle="modal" data-target="#termsconditions" href="javascript:void(0)">{t("terms_and_conditions")} </a> {t("and")} <a data-toggle="modal" data-target="#privacyconditions" href="javascript:void(0)">{t("privacy_policy")}</a> {t(".")}</label>

                                </div>
                            </div>
                            <div className="login-btn">
                                <a onClick={() => handleSignUp()} className="blue-bg-btn" href="javascript:void(0)">{t("signup_button")}</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-2 or-login-via animated fadeInDown">
                        <div className="same-height">
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5">
                        <div className="same-height social-link-option animated fadeInRight">
                            
                            <div className="right-btns">
                                <div className="link">
                                    <a href="javascript:void(0)">{t("already_have_an_account")}</a>
                                </div>
                                <div className="sign-up-btn">
                                    <a onClick={() => loginPage()} href="javascript:void(0)" id="login_btn">{t("login")}</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default Login;