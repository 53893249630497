import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar";
import React, { ReactNode, useEffect, useState } from "react";
import {
  iShoeModel,
  iRewardsLevel,
  iCouponRewards,
  iCouponShoeModel,
  iCouponCategory,
  iCategory,
  iCouponModel,
  eCouponType,
  eBeneficiaryType,
  eMarketplace,
  iUser,
  iCouponUserType,
  iRewardLevel,
  CouponFilters,
  eAppFilters,
  iCouponFilters,
} from "../../../shared/models/adminModels";
import { CommonUtil } from "../../../shared/utils/commonUtils";
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general";
import {
  HttpCategoryService,
  HttpRewardsLevelService,
  HttpShoeModelService,
  HttpCouponsService,
  HttpUsersService,
} from "../../../services/admin.service";
import { AxiosError } from "axios";
import Category from "../../../shared/components/admin/category";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import Button from "react-bootstrap/Button";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import * as appConst from "../../../shared/constants/constants";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import Pagination from "../../../shared/components/Pagination";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import { RootState } from "../../../store/stateTypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CustomPagination from "../../../shared/components/CustomPagination";

const CouopnsPage = () => {
  // -------------- Pagination --------------------
  const [totalCouponModelResults, setTotalCouponModelResults] = useState(-1);
  const [totalCouponModelPages, setTotalCouponModelPages] = useState(-1);
  // -------------- Pagination End --------------------

  // =============== For Category component ====================
  // For list of Brands, got from Api call
  const [brands, setBrands] = useState<iCategory[]>([]);
  const [brandSelected, setBrandSelected] = useState<iCategory>();
  const [rewardsSelected, setRewardsSelected] = useState<iRewardsLevel[]>([]);
  const [populatedCategory, setPopulatedCategory] = useState<iCategory>();
  const [category, setCategory] = useState<iCategory>();
  const [categoryName, setCategoryName] = useState<string>("");
  interface iAddedCategory {
    id: number;
    name: string;
  }
  const [addedCategory, setAddedCategory] = useState<iAddedCategory[]>([]);
  const [selToRemove, setSelToRemove] = useState<iAddedCategory>();
  //For getting the sub catogray by name.
  const [brandCategory, setBrandCategory] = useState<iCategory>();

  // const [categorys, setCategorys] = useState<iCategory[]>([])
  const [refreshCatId, setRefreshCatId] = useState<number>(-1);
  // =============== END For Category component ====================

  const httpShoeModelService: HttpShoeModelService = new HttpShoeModelService();
  const httpCouponsService: HttpCouponsService = new HttpCouponsService();
  const httpCategoryService: HttpCategoryService = new HttpCategoryService();
  const httpttpUsersService: HttpUsersService = new HttpUsersService();
  const httpRewarsService: HttpRewardsLevelService =
    new HttpRewardsLevelService();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [couponModels, setCouponModels] = useState<iCouponModel[]>([]);
  // const [couponFilters, setCouponFilters] = useState<CouponFilters>(
  //   new CouponFilters()
  // );
  const initialFilter: iCouponFilters = new CouponFilters()
  const couponPageState: iPageState | undefined =
    useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.coupon));
  const dispatch = useDispatch();

  const [shoeModels, setShoeModels] = useState<iShoeModel[]>([]);
  const [searchShoeModel, setSearchShoeModel] = useState<string>('')
  const [maskShoeModelsEvent, setMaskShoeModelsEvent] =
    useState<boolean>(false);
  const [totalRowsInShoeModels, setTotalRowsInShoeModels] = useState<number>(0);
  const [totalShoeModelPages, setTotalShoeModelPages] = useState(-1);
  const [shoeMolelsSelected, setShoeMolelsSelected] = useState<iShoeModel[]>(
    []
  );
  const [rewardsLevel, setRewardsLevel] = useState<iRewardsLevel[]>([]);
  const [users, setUsers] = useState<iUser[]>([]);
  const [usersSelected, setUsersSelected] = useState<iUser[]>([]);
  const [selectionType, setSelectionType] = useState<string>("brands");
  const p_utils = new CommonUtil();

  const initialCouponModel: iCouponModel = {
    // id: -1,
    code: "",
    title: "",
    maxDiscount: 0,
    couponRewards: [],
    beneficiaryType: eBeneficiaryType.All,
    marketPlace: eMarketplace.All,
    couponType: eCouponType.Shipping,
    discountPercent: 0,
    validFrom: new Date(moment().toDate()),
    validTo: new Date(moment().toDate()),
    usageLimit: 100,
    active: true,
    status: "active",
    categories: [],
    shoeModels: [],
    users: [],
  };
  // console.log('initialCoupanModel', initialCouponModel)
  const [couponModelToSave, setCouponModelToSave] =
    useState<iCouponModel>(initialCouponModel);
  // console.log('couponModelToSave', couponModelToSave)
  const { isOpen, toggle } = useModal();

  // Category functions=============================
  useEffect(() => {
    if (refreshCatId !== -1 && brandCategory) {
      setCategory(p_utils.getCategoryById(refreshCatId, brandCategory));
    }
  }, [refreshCatId, brandCategory]);

  function populatePathCategory(cat: iCategory): iCategory {
    p_utils.populateCategoryPath(cat);
    return cat;
  }
  const refreshUI = async (idRefresh: number) => {
    console.log("in refreshUI idRefresh", idRefresh);
    if (!brandSelected) return;
    await httpCategoryService
      .get<iCategory>({ id: +brandSelected.id })
      .then((value) => {
        // if (id && +id == idRefresh) {
        console.log("id=", brandSelected.id);
        console.log("idRefresh=", idRefresh);
        let p_cat = populatePathCategory(value.data);
        console.log("p_cat", p_cat);
        setPopulatedCategory(p_cat);
        setRefreshCatId(idRefresh);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (populatedCategory) {
      setBrandCategory(populatedCategory);
    }
  }, [populatedCategory]);

  const refreshBrands = (currentPage = 1) => {
    const bs = httpCategoryService
      .filterBy<iCategory>(
        { currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT_ALL_BRANDS },
        { parentId: 0, query: '' })
      .then((value) => {
        setBrands(value.items);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    refreshBrands();
  }, []);

  useEffect(() => {
    if (brandSelected) {
      refreshUI(brandSelected.id);
    }
  }, [brandSelected]);

  const onBrandsOptionChange = (brand_id: number) => {
    let filteredBrands = brands.filter((value) => value.id == brand_id);
    setBrandSelected(filteredBrands[0]);

    setCategoryName(filteredBrands[0].name);
    setCategory(filteredBrands[0]);
    // TODO: fetch brand categories, all the decendents of this brand_id
  };

  const handelSetCurrentCategory = (p_Cat: iCategory) => {
    // console.log('p_Cat.name', p_Cat.name)
    setCategoryName(p_Cat.name);
    setCategory(p_Cat);
  };

  const handelSetCurrentCategoryByName = (cat_name: string) => {
    // console.log('handelSetCurrentCategoryByName cat_name', cat_name)
    if (brandCategory) {
      console.log("brandCategory.name", brandCategory.name);
      let x: iCategory | undefined = p_utils.getCategoryByName(
        cat_name,
        brandCategory
      );
      console.log("x?.name", x?.name);
      setCategoryName(x?.name ? x.name : "");
      setCategory(x);
    }
  };
  const handelGetCategoryChildCount = (
    cat_name: string
  ): number | undefined => {
    //console.log('handelGetCategoryChildCount cat_name', cat_name)
    if (brandCategory) {
      //console.log('handelGetCategoryChildCount brandCategory.name', brandCategory.name)
      let x: iCategory | undefined = p_utils.getCategoryByName(
        cat_name,
        brandCategory
      );
      //console.log('x?.name', x?.name)
      //console.log('x.childCategories.length', x?.childCategories.length)
      return x ? x.childCategories.length : 0;
    }
  };

  useEffect(() => {
    //console.log('perv shoemodels', shoeModels)

  }, [shoeModels])
  const onCategoryClick = (item: iAddedCategory) => {
    setSelToRemove(item);
  };
  //================================================
  useEffect(() => {
    loadRewardLevel();
    //refreshCoupons();
  }, []);

  var myDebounceTimer: NodeJS.Timeout
  useEffect(() => {
    myDebounceTimer = setTimeout(() => { loadShoeModel() }, 1500)
    return () => {
      clearTimeout(myDebounceTimer);
    };
  }, [searchShoeModel])


  const getQueryFilter = () => {
    let filter = ''
    if (searchShoeModel) {
      filter += '&query=' + searchShoeModel
      filter += '&includeSearch=' + true
    }
    return filter
  }
  const loadShoeModel = () => {
    if (!searchShoeModel) {
      setShoeModels([])
      setTotalRowsInShoeModels(0)
      setTotalShoeModelPages(0)
      return
    }
    console.log("loadShoeModel", searchShoeModel);
    //TODO make the get request for settings
    httpShoeModelService
      .filterBySearchFilters<iShoeModel>({
        currentPage: 1,
        pageLimit: appConst.PAGE_LIMIT_SHOE_MODELS,
      }, { filters: getQueryFilter() })
      .then((value) => {
        console.log('vvvvvvvvv', value)
        setTotalRowsInShoeModels(value.totalRows);
        setTotalShoeModelPages(
          Math.ceil(value.totalRows / appConst.PAGE_LIMIT_SHOE_MODELS)
        );
        let transformShoeModel =
          (value.items as iShoeModel[]).map((value: iShoeModel, index, arr) => {
            return {
              ...value,
              searchableName: value.modelName! + ' - ' + value.modelCode!
            }
          })
            .sort((a, b) => a.searchableName.localeCompare(b.searchableName));
        console.log('transformShoeModel', transformShoeModel)
        setShoeModels(transformShoeModel)
      })
      .catch((error) => console.log(error));
  };

  // useAsyncEffect(
  //   function* (onCancel: any): Generator<any> {
  //     console.log("in useAsyncEffect");
  //     // const cancelTokenSource = axios.CancelToken.source() //new AbortController()
  //     // const token1 = cancelTokenSource.token

  //     onCancel(() => {
  //       console.log(
  //         "cancel while fetch is still executed, use controller for aborting the request."
  //       );
  //       //    cancelTokenSource.cancel();
  //     });

  //     try {
  //       if (totalShoeModelPages == 0) return
  //       setMaskShoeModelsEvent(true);
  //       setShoeModels([]);
  //       for (let i = 1; i <= totalShoeModelPages; i++) {
  //         let resp: any = yield httpShoeModelService.filterBySearchFilters<iShoeModel>({
  //           currentPage: i,
  //           pageLimit: appConst.PAGE_LIMIT_SHOE_MODELS,
  //         }, { filters: getQueryFilter() })

  //         setShoeModels((shoeModels) => shoeModels.concat(resp.items));
  //       }
  //       setMaskShoeModelsEvent(false);
  //     } catch (err) {
  //       //    if (err.name === 'AbortError') {
  //       console.log("Request was canceled via controller.abort");
  //       // we know that an 'AbortError' occurs when the request is
  //       // cancelled this means that the next promise returned by yield
  //       // will be created but not actively used, thus, we return in
  //       // order to avoid the promise being created.
  //       return;
  //       //    }
  //     }
  //   },
  //   [totalShoeModelPages]
  // );

  const refreshCoupons = (currentPage = 1) => {
    //TODO make the get request for settings
    setTotalCouponModelResults(0);
    setTotalCouponModelPages(0);
    httpCouponsService
      .getAll<iCouponModel>(
        {
          currentPage: currentPage,
          pageLimit: couponPageState?.pageLimit,
        },
        //couponFilters
        (couponPageState?.filter as iCouponFilters)
      )
      .then((value) => {
        console.log('value.data.items', value.data.items)
        console.log('Current page', currentPage)
        console.log('Page Limit', couponPageState?.pageLimit)
        console.log('totalRows', value.data.totalRows)
        console.log('pages', Math.ceil(value.data.totalRows / couponPageState!.pageLimit))
        setCouponModels(value.data.items);
        setTotalCouponModelResults(value.data.totalRows);
        setTotalCouponModelPages(
          Math.ceil(value.data.totalRows / couponPageState!.pageLimit)
        );
      })
      .catch((error) => console.log(error));
  };
  const loadRewardLevel = (currentPage = 1) => {
    console.log("loadRewardLevel");
    httpRewarsService
      .getAll<iRewardsLevel>({
        currentPage: currentPage,
        pageLimit: appConst.PAGE_LIMIT,
      })
      .then((value) => {
        setRewardsLevel(value.data);
        // setTotalRewardsLevelResults(value.data.totalRows)
        // setTotalRewardsLevelPages(Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT))
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    console.log('--Coupon Filter Changed', couponPageState?.filter)
    refreshCoupons(couponPageState?.currentPage);
  }, [couponPageState?.filter]);

  useEffect(() => {
    console.log('--Page Changed', couponPageState?.currentPage)
    refreshCoupons(couponPageState?.currentPage);
  }, [couponPageState?.currentPage, couponPageState?.pageLimit]);

  const onPageChanged = (data: any) => {
    console.log('onPageChanged', data)
    dispatch(appPageSliceActions.savePageFilters({
      ...couponPageState!,
      currentPage: data.currentPage
    }))
  }
  const deletecouponModel = async (p_iCouponModel: iCouponModel) => {
    await httpCouponsService.delete(p_iCouponModel.id!);
    toast.success(t`Delete_success_msg`);
    refreshCoupons();
  };
  const deactivateCoupon = async (p_iCouponModel: iCouponModel) => {
    await httpCouponsService.updateCouponStatus(p_iCouponModel.id!);
    toast.success(`Deactivated Coupon ${p_iCouponModel.title}`);
    refreshCoupons(couponPageState?.currentPage);
  };
  const renderCoupons = (): ReactNode[] => {
    const returnNode: ReactNode[] = couponModels?.map(
      (couponModel: iCouponModel, index) => (
        <tr key={index}>
          <td>{couponModel.id}</td>
          <td>{couponModel.title}</td>
          <td>{couponModel.code}</td>
          <td>{eCouponType[couponModel.couponType]}</td>
          <td>{couponModel.discountPercent}</td>
          <td>{eBeneficiaryType[couponModel.beneficiaryType]}</td>

          <td>{eMarketplace[couponModel.marketPlace]}</td>
          <td>
            {couponModel.categories.map((item, index) => item.name).join(",")}
          </td>
          <td>
            {
              couponModel.shoeModels.length && couponModel.shoeModels
                .map((item, index) => item.modelName)
                .join(",")}
          </td>
          <td>
            {couponModel.users.map((item, index) => item.userName).join(",")}
          </td>
          <td>{couponModel.usageLimit}</td>
          <td>{moment(couponModel.validFrom).format("DD/MM/YYYY")}</td>
          <td>{moment(couponModel.validTo).format("DD/MM/YYYY")}</td>
          <td>{couponModel.status}</td>
          <td>
            {
              <>
                {couponModel.status === "active" ? (
                  <a
                    onClick={() => deactivateCoupon(couponModel)}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Delete"
                    data-original-title="Edit"
                    className="btn btn-bulk small me-1"
                    data-rel="tooltip"
                  >
                    <i className="la la-remove"></i>
                  </a>
                ) : null}
              </>
            }
          </td>
        </tr>
      )
    );
    return returnNode;
  };
  // ============= Multiselect ShoeModels =======================
  const onSelectShoeModels = (
    selectedList: iShoeModel[],
    selectedItem: iShoeModel
  ) => {
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    // shoeModelToSave.colors = selectedList
    setShoeMolelsSelected(selectedList);
  };
  const onRemoveShoeModels = (
    selectedList: iShoeModel[],
    removedItem: iShoeModel
  ) => {
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    // shoeModelToSave.colors = selectedList
    setShoeMolelsSelected(selectedList);
  };
  // ============= Multiselect ShoeModels end======================
  // ============= Multiselect ShoeModels =======================
  const onSelectUsers = (selectedList: iUser[], selectedItem: iUser) => {
    console.log("selectedList", selectedList);
    console.log("selectedItem", selectedItem);
    // shoeModelToSave.colors = selectedList
    setUsersSelected(selectedList);
  };
  const onRemoveUsers = (selectedList: iUser[], removedItem: iUser) => {
    console.log("selectedList", selectedList);
    console.log("removedItem", removedItem);
    // shoeModelToSave.colors = selectedList
    setUsersSelected(selectedList);
  };
  // ============= Multiselect ShoeModels end======================

  //==========ModalDialog functions===========
  const onAddCouponClick = () => {
    toggle();
  };

  const resetCouponUI = () => {
    setSearchShoeModel('')
    setBrandSelected(undefined);
    setCategory(undefined);
    setAddedCategory([]);
    setUsers([]);
    setUsersSelected([]);
    setCategoryName("");
    setCouponModelToSave(initialCouponModel);
    setUsersSelected([]);
    setRewardsSelected([]);
    setShoeMolelsSelected([]);
  };
  const cancelAdd = () => {
    console.log("in cancelAdd");
    resetCouponUI();
    toggle();
  };

  const onExecuteAddCoupon = async () => {
    let shoeModelIds: iCouponShoeModel[] = [];
    if (shoeMolelsSelected.length) {
      shoeModelIds = shoeMolelsSelected.map((item, index): iCouponShoeModel => {
        return { modelId: item.id };
      });
      console.log("shoeModelIds", shoeModelIds);
    }
    let addCat: iCouponCategory[] = [];
    if (addedCategory.length) {
      addCat = addedCategory.map((item, index): iCouponCategory => {
        return { categoryId: item.id };
      });
      console.log("addCat", addCat);
    }
    let addUser: iCouponUserType[] = [];
    if (usersSelected.length) {
      addUser = usersSelected.map((item, index): iCouponUserType => {
        return { userId: item.id };
      });
    }

    await httpCouponsService.add<iCouponModel>({
      ...couponModelToSave,
      categories: addCat,
      shoeModels: shoeModelIds,
      users: addUser,
    });
    toast.success(t`Add_success_msg`);
    toggle();
    resetCouponUI();
    refreshCoupons();
  };
  const onEnableExecuteCoupon = () => {
    if (
      couponModelToSave.code &&
      couponModelToSave.title &&
      couponModelToSave.couponType &&
      couponModelToSave.discountPercent &&
      couponModelToSave.beneficiaryType &&
      // couponModelToSave.beneficiary &&
      couponModelToSave.marketPlace &&
      ((addedCategory.length || shoeMolelsSelected.length) ||
        (addedCategory.length == 0 && shoeMolelsSelected.length == 0)
      ) &&
      // forUsers.length
      //couponModelToSave.usageLimit &&
      couponModelToSave.validFrom &&
      couponModelToSave.validTo &&
      couponModelToSave.validTo >= couponModelToSave.validFrom
    )
      return true;
    else return false;
  };
  const setBeneficiaryTypeSelected = (val: string) => {
    setCouponModelToSave({ ...couponModelToSave, beneficiaryType: +val });
  };
  const populateBeneficiaryType = (toValueBT: eBeneficiaryType): ReactNode => {
    // console.log("populateBeneficiaryType", toValueBT)
    const keys = Object.keys(eBeneficiaryType).filter((v) => isNaN(Number(v)));
    return (
      <select
        onChange={(evt) => {
          setBeneficiaryTypeSelected(evt.target.value);
        }}
        className="form-control"
        name="BeneficiaryType_id"
      >
        {/* <option value='-1'>Select Beneficiary type</option> */}
        {keys.length &&
          keys.map((key, index) => (
            <option
              key={index}
              value={index + 1}
              selected={+toValueBT == index + 1}
            >
              {key}
            </option>
          ))}
      </select>
    );
  };
  const setRewardLevelSelected = (rewards: iRewardsLevel[]) => {
    setRewardsSelected(rewards);
    let r: iCouponRewards[] = rewards.map((reward) => {
      return {
        rewardLevelId: +reward.id,
      };
    });
    setCouponModelToSave({
      ...couponModelToSave,
      couponRewards: r,
    });
  };


  const setMarketplaceSelected = (val: string) => {
    setCouponModelToSave({ ...couponModelToSave, marketPlace: +val });
  };
  const populateMarketplace = (toValueMP: eMarketplace): ReactNode => {
    // console.log("populateMarketplace", toValueMP)
    const keys = Object.keys(eMarketplace).filter((v) => isNaN(Number(v)));
    return (
      <select
        onChange={(evt) => {
          setMarketplaceSelected(evt.target.value);
        }}
        className="form-control"
        name="Marketplace_id"
      >
        {/* <option value='-1'>Select Marketplace</option> */}
        {keys.length &&
          keys.map((key, index) => (
            <option
              key={index}
              value={index + 1}
              selected={+toValueMP == index + 1}
            >
              {key}
            </option>
          ))}
      </select>
    );
  };
  const setCouponTypeSelected = (val: string) => {
    setCouponModelToSave({ ...couponModelToSave, couponType: +val });
  };
  const populateCouponType = (toValueCT: eCouponType): ReactNode => {
    // console.log("populateCouponType", toValueCT)
    const keys = Object.keys(eCouponType).filter((v) => isNaN(Number(v)));
    return (
      <select
        onChange={(evt) => {
          setCouponTypeSelected(evt.target.value);
        }}
        className="form-control"
        name="Marketplace_id"
      >
        {/* <option value='-1'>Select Coupon type</option> */}
        {keys.length &&
          keys.map((key, index) => (
            ((couponModelToSave.beneficiaryType !== eBeneficiaryType.Seller) &&
              (couponModelToSave.beneficiaryType !== eBeneficiaryType.All)) ?
              <option
                key={index}
                value={index + 1}
                selected={+toValueCT == index + 1}
              >
                {key}
              </option>
              :
              (index + 1 !== eCouponType.Discount) ?
                <option
                  key={index}
                  value={index + 1}
                  selected={+toValueCT == index + 1}
                >
                  {key}
                </option>
                : null
          ))}
      </select>
    );
  };
  const handelSearch = async (searchText: string) => {
    if (!searchText) {
      setUsers([]);
      return;
    }
    //Call api to search shoeModels.
    try {
      let p_Users = await httpttpUsersService
        .filterBy<iUser>({ pageLimit: 100 }, { name: searchText })
        .then((value) => {
          setUsers(value.items);
        });
      // console.log('p_Users', p_Users)
    } catch (error) {
      //TODO
      let e = error as AxiosError;
      //TODO
      console.log("handelSearch error", e.message);
    }
  };
  //==========ModalDialog functions end ===========

  const setSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setShoeMolelsSelected([]);
    setAddedCategory([]);
    setCategory(undefined);
    setBrandSelected(undefined);
    setSelectionType(e.target.value);
  };
  return (
    <div id="app">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content custom-modal-lg">
          {/* == Begin - New Changes ==*/}
          <section className="page-content container-fluid">
            <div className="admin-header">
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-6">
                  <h3>{t("Coupons")}</h3>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                  <div className="action-btn">
                    <a
                      onClick={() => onAddCouponClick()}
                      className="bulk-primary-btn bulk-sm add-shoe-model"
                      data-toggle="modal"
                      data-target="#addShoeModel"
                    >
                      Add Coupon
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-3 col-md-3 col-lg-3">
                <div className="form-group dropdown-wrapper">
                  <label>{t("Marketplace")}:</label>
                  <select
                    className="form-control"
                    value={(couponPageState?.filter as iCouponFilters).marketPlace}
                    onChange={(evt) => {
                      // setCouponFilters({
                      //   ...couponFilters,
                      //   marketPlace: +evt.target.value,
                      // });
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          marketPlace: +evt.target.value,
                        }
                      }))
                    }}
                  >
                    {Object.keys(eMarketplace)
                      .filter((v) => isNaN(Number(v)))
                      .map((item, i) => (
                        <option key={i} value={i + 1}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3">
                <div className="form-group dropdown-wrapper">
                  <label>{t("For_Users")}:</label>

                  <Multiselect
                    onSearch={(value) => {
                      if (value.length >= 1) handelSearch(value);
                    }}
                    options={users} // Options to display in the dropdown
                    onSelect={(items) => {
                      setUsers([]);
                      //setCouponFilters({ ...couponFilters, userId: items[0] });
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          userId: items[0]
                        }
                      }))
                    }} // Function will trigger on select event
                    onRemove={() =>
                      //setCouponFilters({ ...couponFilters, userId: null })
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          userId: null
                        }
                      }))
                    } // Function will trigger on remove event
                    displayValue="email"
                    selectionLimit="1"
                    closeOnSelect={true}
                    hidePlaceholder={true}
                  />
                </div>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3">
                <div className="form-group dropdown-wrapper">
                  <label>{t("Brand_Brands")}:</label>
                  <select
                    onChange={(evt) => {
                      // setCouponFilters({
                      //   ...couponFilters,
                      //   categoryId: +evt.target.value,
                      // });
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          categoryId: +evt.target.value,
                        }
                      }))
                    }}
                    className="form-control in_language_id"
                    name="in_language_id"
                  >
                    <option key={-1} defaultValue="-1">
                      Select one brand
                    </option>
                    {brands.length &&
                      brands.map((brand, index) => (
                        <option
                          key={index}
                          value={brand.id}
                          selected={
                            //couponFilters.categoryId === brand.id
                            (couponPageState?.filter as iCouponFilters).categoryId === brand.id
                          }
                        >
                          {brand.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3">
                <div className="form-group  dropdown-wrapper">
                  <label>{t("Shoe_Models")}:</label>
                  <Multiselect
                    options={shoeModels} // Options to display in the dropdown
                    onSelect={(items) => {
                      //setCouponFilters({ ...couponFilters, modelId: items[0] });
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          modelId: items[0]
                        }
                      }))
                    }} // Function will trigger on select event
                    onRemove={() =>
                      //setCouponFilters({ ...couponFilters, modelId: null })
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          modelId: null
                        }
                      }))
                    } // Function will trigger on remove event
                    displayValue="searchableName"
                    selectionLimit="1"
                    closeOnSelect={true}
                    hidePlaceholder={true}
                    disable={!!(couponPageState?.filter as iCouponFilters).categoryId}
                  />
                </div>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-2">
                <div className="dropdown-wrapper-1">
                  <input
                    type="checkbox"
                    checked={!(couponPageState?.filter as iCouponFilters).activeOnly}
                    onChange={() =>
                      // setCouponFilters({
                      //   ...couponFilters,
                      //   activeOnly: !couponFilters.activeOnly,
                      // })
                      dispatch(appPageSliceActions.savePageFilters({
                        ...couponPageState!,
                        currentPage: 1,
                        filter: {
                          ...couponPageState!.filter,
                          activeOnly: !(couponPageState?.filter as iCouponFilters).activeOnly,
                        }
                      }))
                    }
                  ></input>
                  <label className="ms-2">Show inactive</label>
                </div>
              </div>
              {JSON.stringify(initialFilter) !== JSON.stringify(couponPageState?.filter) &&
                <div className="col-sm-3 col-md-3 col-lg-3">
                  <label></label>
                  <div className="action-btn">
                    <a onClick={() =>
                      //onResetFilter()
                      dispatch(appPageSliceActions.resetPageFilter({ filterType: eAppFilters.coupon }))
                    }
                      href="javascript:void(0)"
                      className="bulk-primary-btn bulk-sm add-shoe-model"
                      data-toggle="modal" data-target="#addReward">
                      {t('Reset_Filter')}
                    </a>
                  </div>
                </div>
              }
            </div>
            <div className="shoe-model-grid">
              <table
                id="dataGrid"
                className="table table-striped table-bordered table-hover"
                width="100%"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>{t("Id")}</th>
                    <th>{t("Title")}</th>
                    <th>{t("Coupon_Code")}</th>
                    <th>{t("Coupons_Types")}</th>
                    <th>{t("Discount")}</th>
                    <th>{t("Beneficiary_Type")}</th>
                    {/* <th>Beneficiaries</th> */}
                    <th>{t("Marketplace")}</th>
                    <th>{t("Category_Categories")}</th>
                    <th>{t("Shoe_Models")}</th>
                    <th>{t("Users")}</th>
                    <th>{t("Maximum_Usage_Limit")}</th>
                    <th>{t("Valid_From")}</th>
                    <th>{t("Valid_To")}</th>
                    <th>{t("Status")}</th>
                    <th>{t("Action")}</th>
                  </tr>
                </thead>

                <tbody>{renderCoupons()}</tbody>
              </table>
            </div>
            {totalCouponModelResults && totalCouponModelPages ?
              <CustomPagination
                totalResults={totalCouponModelResults}
                totalPages={totalCouponModelPages}
                p_iPageState={couponPageState!}
                onPageChanged={onPageChanged}
              />
              : null
            }
            {/* <div className="nav-direction">
              {totalCouponModelResults > appConst.PAGE_LIMIT &&
                totalCouponModelPages ? (
                <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                  <Pagination
                    totalRecords={totalCouponModelResults}
                    totalPages={totalCouponModelPages}
                    currentPage={currentCouponModelPage}
                    pageLimit={appConst.PAGE_LIMIT}
                    pageNeighbours={1}
                    onPageChanged={onPageChanged}
                  />
                </div>
              ) : null}
            </div> */}
          </section>

          {/* == End- New Changes ==*/}
          {/* -- Start Modal -- */}
          <ModalDialog
            toggle={() => onAddCouponClick()}
            cancel={() => cancelAdd()}
            executeButtonTitle={() => {
              return t("MD_Add");
            }}
            isOpen={isOpen}
            execute={onExecuteAddCoupon}
            enableExecute={onEnableExecuteCoupon()}
            modalTitle={t("Coupon_Add")}
            useButtons={t("MD_Cancel") + '/' + t("MD_Add")}
          >
            {
              <div className="row">
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>{t("Coupon_Code_Star")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={couponModelToSave.code}
                      onChange={(evt) =>
                        setCouponModelToSave({
                          ...couponModelToSave,
                          code: evt.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>*{t("Title")}:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={couponModelToSave.title}
                      onChange={(evt) =>
                        setCouponModelToSave({
                          ...couponModelToSave,
                          title: evt.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>{t("Coupon_Type")}</label>
                    {populateCouponType(couponModelToSave?.couponType)}
                    {/* <select className="form-control">
                                            <option>Shipping</option>
                                            <option>Price</option>
                                        </select> */}
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>*{t("Discount")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={couponModelToSave.discountPercent}
                      onChange={(evt) => {
                        const val = +evt.target.value;
                        if (isNaN(val) || val > 100) return;
                        setCouponModelToSave({
                          ...couponModelToSave,
                          discountPercent: +evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>*{t("Max_Discount")}</label>
                    <input
                      type="text"
                      className="form-control"
                      value={couponModelToSave.maxDiscount}
                      onChange={(evt) => {
                        const val = +evt.target.value;
                        if (isNaN(val) || val > 5000) return;
                        setCouponModelToSave({
                          ...couponModelToSave,
                          maxDiscount: +evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>*{t("Beneficiary_Type")}</label>
                    {populateBeneficiaryType(
                      couponModelToSave?.beneficiaryType
                    )}
                    {/* <select className="form-control">
                                            <option>Buyer</option>
                                            <option>Seller</option>
                                            <option>Both</option>
                                        </select> */}
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-2">
                  <div className="form-group">
                    <label>*{t("Marketplace")}:</label>
                    {populateMarketplace(couponModelToSave?.marketPlace)}
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="form-group mb-2">
                    <label>Selection Type:</label>
                    <select
                      onChange={setSelection}
                      className="form-control in_language_id"
                      name="in_language_id"
                    >
                      <option value="brands">Brands/Categories</option>
                      <option value="shoeModels">Shoe Models</option>
                    </select>
                  </div>
                </div>
                {selectionType === "brands" ? (
                  <>
                    <div className="col-sm-12 col-md-6 mb-2">
                      <div className="form-group mb-2">
                        <label>{t("Brand_Brands")}:</label>
                        <select
                          onChange={(evt) => {
                            onBrandsOptionChange(+evt.target.value);
                          }}
                          className="form-control in_language_id"
                          name="in_language_id"
                        >
                          <option key={-1} defaultValue="-1">
                            Select brand
                          </option>
                          {brands.length &&
                            brands.map((brand, index) => (
                              <option
                                key={index}
                                value={brand.id}
                                selected={
                                  brandSelected?.id == brand.id ? true : false
                                }
                              >
                                {brand.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-2">
                      <div className="form-group modal-category-level">
                        <label>{t("Category_Level")}:</label>
                        <Category
                          p_Category={category!}
                          setCurrentCategory={handelSetCurrentCategory}
                          setCurrentCategoryByName={
                            handelSetCurrentCategoryByName
                          }
                          getCategoryChildCount={handelGetCategoryChildCount}
                        />
                        <div className="add-catagories-btn">
                          <Button
                            className="btn btn-primary"
                            variant="secondary"
                            disabled={
                              !brandSelected || !category || !categoryName
                            }
                            onClick={() => {
                              let id = addedCategory.findIndex(
                                (c) => c.id === category?.id
                              );
                              if (id === -1) {
                                setAddedCategory([
                                  ...addedCategory,
                                  {
                                    id: category?.id ? category?.id : 0,
                                    name: categoryName,
                                  },
                                ]);
                              }
                            }}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mb-2">
                      <div className="selected-catagories">
                        <div className="form-group">
                          <div className="row align-items-center">
                            <div className="col-9">
                              <label>{t("Selected_Categories")}:</label>
                            </div>
                            <div className="col-3 text-end">
                              <Button
                                className="btn btn-secondary"
                                variant="primary"
                                disabled={selToRemove ? false : true}
                                onClick={() => {
                                  let i = addedCategory.findIndex(
                                    (value) => value.id == selToRemove?.id
                                  );
                                  console.log("i", i);
                                  let arr = [...addedCategory];
                                  arr.splice(i, 1);
                                  setAddedCategory(arr);
                                }}
                              >
                                {t("Remove")}
                              </Button>
                            </div>
                          </div>
                          <ul>
                            {addedCategory.map((item, index) => (
                              <li>
                                <div
                                  className="selected-box"
                                  key={index}
                                  style={
                                    selToRemove?.id == item.id
                                      ? { borderColor: "#3D261C" }
                                      : {}
                                  }
                                >
                                  <div onClick={() => onCategoryClick(item)}>
                                    {item.name}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-sm-12 col-md-12 mb-2">
                      <div className="form-group">
                        <label>{t("Shoe_Models")}:</label>

                        <Multiselect
                          onSearch={(value) => {
                            setSearchShoeModel(value);
                          }}
                          options={shoeModels} // Options to display in the dropdown
                          selectedValues={shoeMolelsSelected} // Preselected value to persist in dropdown
                          onSelect={onSelectShoeModels} // Function will trigger on select event
                          onRemove={onRemoveShoeModels} // Function will trigger on remove event
                          displayValue="searchableName" // Property name to display in the dropdown options
                          disable={selectionType === "brands"}
                        />

                        {/* <select className="form-control">
                                            <option>Puma</option>
                                            <option>Nike</option>
                                        </select> */}
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-12 col-md-12 mb-2">
                  <div className="form-group">
                    <label>{t("Reward_level")}</label>
                    <Multiselect
                      options={rewardsLevel} // Options to display in the dropdown
                      selectedValues={rewardsSelected} // Preselected value to persist in dropdown
                      onSelect={setRewardLevelSelected} // Function will trigger on select event
                      onRemove={setRewardLevelSelected} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      disable={usersSelected.length > 0}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 mb-2">
                  <div className="form-group">
                    <label>{t("For_Users")}:</label>

                    <Multiselect
                      onSearch={(value) => {
                        if (value.length >= 1) handelSearch(value);
                      }}
                      options={users} // Options to display in the dropdown
                      selectedValues={usersSelected} // Preselected value to persist in dropdown
                      onSelect={onSelectUsers} // Function will trigger on select event
                      onRemove={onRemoveUsers} // Function will trigger on remove event
                      displayValue="email" // Property name to display in the dropdown options
                      disable={rewardsSelected.length > 0}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-md-4 mb-2">
                  <div className="form-group">
                    <label>{t("Maximum_Usage_Limit")}:</label>
                    <input
                      type="text"
                      className="form-control"
                      value={couponModelToSave.usageLimit}
                      onChange={(evt) => {
                        const val = +evt.target.value;
                        if (isNaN(val) || val > 100) return;
                        setCouponModelToSave({
                          ...couponModelToSave,
                          usageLimit: +evt.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 mb-2">
                  <div className="form-group bulk-datepicker">
                    <label>*{t("Valid_From")}:</label>
                    <DatePicker
                      dateFormat={'dd/MM/yyyy'}
                      selected={
                        new Date(
                          moment(couponModelToSave.validFrom).toDate()
                        )
                      }
                      onChange={(date) =>
                        setCouponModelToSave({
                          ...couponModelToSave,
                          validFrom: date,
                        })
                      }
                      //value={moment(couponModelToSave.validFrom).format('DD/MM/YYYY')}
                      minDate={new Date()}

                    />
                    {/* <input type="text" className="form-control" /> */}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 mb-2">
                  <div className="form-group bulk-datepicker">
                    <label>*{t("Valid_To")}:</label>
                    <DatePicker
                      dateFormat={'dd/MM/yyyy'}
                      selected={
                        new Date(
                          moment(couponModelToSave.validTo).toDate()
                        )
                      }
                      onChange={(date) => {
                        console.log("date", date);
                        setCouponModelToSave({
                          ...couponModelToSave,
                          validTo: date,
                        });
                      }}
                      minDate={new Date()}
                    //value={moment(couponModelToSave.validTo).format('DD/MM/YYYY')}
                    // maxDate={new Date()}
                    />
                    {/* <input type="text" className="form-control" /> */}
                  </div>
                </div>
              </div>
            }
          </ModalDialog>

          {/* -- End Modal -- */}
        </div>
      </div>
    </div>
  );
};

export default CouopnsPage;
