import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { iNewsLatter } from '../../../shared/models/adminModels'
import { AxiosError } from "axios";
import * as appConst from '../../../shared/constants/constants'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { HttpNewsLatterService } from "../../../services/admin.service";

const NewsLatterPage = () => {
    //For Modal Dialog
    const { t } = useTranslation();
    const [newsLatters, setNewsLatters] = useState<iNewsLatter[]>([])

    const httpNewsLatterservice: HttpNewsLatterService = new HttpNewsLatterService();

    const refreshNewsLatter = (currentPage = 1) => {
        httpNewsLatterservice.getAll<iNewsLatter>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value: any) => {
                setNewsLatters(value.data)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        refreshNewsLatter();
    }, []);

    const rendernewsLatters = (): ReactNode[] => {
        const returnNode: ReactNode[] = newsLatters?.map((newsLatter: iNewsLatter) => (
            <tr key={newsLatter.id}>
                <td width={250}>{newsLatter.id}</td>
                <td>{newsLatter.name}</td>
                <td width={50}>
                    <a
                        onClick={() => publishNewsLatter(newsLatter)}
                        title={t`Publish` ?? ""} data-toggle="tooltip"
                        data-placement="top"
                        data-original-title={t`Publish`}
                        className="btn btn-bulk small me-1"
                        data-rel="tooltip" >
                        <i className="la la-check"></i>
                    </a>
                </td>
            </tr>

        ))
        return returnNode
    }

    const publishNewsLatter = async (newsLatter: iNewsLatter) => {
        try {
            await httpNewsLatterservice.publish<iNewsLatter>({ ...newsLatter })
            toast.success(t`Publish_success_msg`)
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onExecuteAddEditNewsLatter error', e.message)
        }
    }

    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>{t`News_latter`}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="shoe-model-grid">
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t`id`}</th>
                                        <th>{t`Name`}</th>
                                        <th>{t`Action`}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {rendernewsLatters()}
                                </tbody>
                            </table>
                        </div>

                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div >
    )
};

export default NewsLatterPage;