
import axios, { AxiosError } from "axios";
import { axiosAdminConfig,axiosAdminAuthConfig,axiosAdminOrderConfig } from "../shared/constants/constants";
import { CommonUtil } from '../shared/utils/commonUtils'
// axios.defaults.withCredentials = true

export const customAxiosAdminConfig = axios.create(axiosAdminConfig);
export const customAxiosAdminAuthConfig = axios.create(axiosAdminAuthConfig);
export const customAxiosAdminOrderConfig = axios.create(axiosAdminOrderConfig);

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    console.error(`[response error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
}
customAxiosAdminConfig.interceptors.request.use(
    function (config: any) {
        if (config.headers) {
            let p_Utils = new CommonUtil()
            let token = p_Utils.getCookie('admin_access_token')
            if (token)
                config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, onResponseError
);

customAxiosAdminAuthConfig.interceptors.request.use(
    function (config: any) {
        if (config.headers) {
            let p_Utils = new CommonUtil()
            let token = p_Utils.getCookie('admin_access_token')
            if (token)
                config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, onResponseError
);

customAxiosAdminOrderConfig.interceptors.request.use(
    function (config: any) {
        if (config.headers) {
            let p_Utils = new CommonUtil()
            let token = p_Utils.getCookie('admin_access_token')
            if (token)
                config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, onResponseError
);