import React, { useState } from "react";
import { HttpAdminService } from '../../../services/http.service'

export interface iFileUpload {
    optionNo?: number
    apiUrlRelativePath: string
    existingFileName: string
    uploadedRelativePath: string
    onSuccess: (file_name: string) => void
    onFailure: (error_string: string) => void
}

const FileUpload = ({ optionNo, apiUrlRelativePath, existingFileName, uploadedRelativePath, onSuccess, onFailure }: iFileUpload) => {
    const [currentFile, setCurrentFile] = useState<File>();
    const httpAdminService: HttpAdminService = new HttpAdminService();
    optionNo = !optionNo ? 0 : optionNo
    const uploadImage = () => {
        const upload_image: HTMLElement | null = document.getElementById('uploaded_image' + optionNo)
        var fd: any = new FormData();
        fd.append('file', currentFile);
        httpAdminService.upload(apiUrlRelativePath, fd)
            .then((response) => {
                console.log(response)
                if (upload_image) {
                    upload_image.setAttribute('src', process.env.AZURE_URL_FOR_IMAGES + uploadedRelativePath + '/' + response.data.file_name)
                    upload_image.style.display = 'block'
                    onSuccess(response.data.file_name)
                }
            })
            .catch((response) => {
                console.log(response)
                onFailure(response)
            })
    }
    const setFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(' in setFileName')
        const filename = event.target.files && event.target.files.length ? event.target.files[0].name : '';
        const elem: HTMLElement | null = document.getElementById('image_label_id' + optionNo) //for file name to display in label
        const elemUploadButton: HTMLElement | null = document.getElementById('upload_file_btn' + optionNo)
        const div_upload_image: HTMLElement | null = document.getElementById('div_uploaded_image_ms' + optionNo)

        if (filename && event.target.files && elem && div_upload_image && elemUploadButton) {
            elem.innerHTML = filename
            setCurrentFile(event.target.files[0])
            div_upload_image.style.display = 'block'
        }
    }
    return (
        <>
            {/* <div className="form-row"> */}
            <div className="form-group col-md-6">
                <label>Image</label><span className="required">*</span>
                <input type="hidden" name="image" value={existingFileName ? process.env.AZURE_URL_FOR_IMAGES + uploadedRelativePath + '/' + existingFileName : ""}></input>
                <div className="custom-file">
                    <input type="file" onChange={(evt) => setFileName(evt)} className="file_upload custom-file-input" name="file"></input>
                    <label id={'image_label_id' + optionNo} className="custom-file-label" htmlFor="file">{existingFileName ? existingFileName : 'Choose Image...'}</label>

                </div>

            </div>
            {/* </div> */}
            {/* <div className="form-row" id={"div_uploaded_image_ms" + optionNo} style={ existingFileName ? { display: 'block' } : { display: 'none' }}> */}
            <div className="form-group col-md-6"
                id={"div_uploaded_image_ms" + optionNo} style={existingFileName ? { display: 'block' } : { display: 'none' }}>
                <button className="btn btn-primary"
                    id={"upload_file_btn" + optionNo}
                    onClick={() => uploadImage()}
                    style={currentFile ? { display: 'block' } : { display: 'none' }}
                >Upload</button>
                <img src={existingFileName ? process.env.AZURE_URL_FOR_IMAGES + uploadedRelativePath + '/' + existingFileName : ""}
                    id={"uploaded_image" + optionNo}
                    style={existingFileName ? { display: 'block' } : { display: 'none' }}
                    alt="file upload"
                >
                </img>
            </div>
            {/* </div> */}
        </>
    )

}

export default FileUpload