import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { ePenaltyType, eSanction, iPenalty, iUser } from '../../../shared/models/adminModels'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { HttpPenaltyService, HttpUsersService } from '../../../services/admin.service'
import 'react-datepicker/dist/react-datepicker.css'
import * as appConst from '../../../shared/constants/constants'
import Pagination from '../../../shared/components/Pagination';
import moment from 'moment';
import { AxiosError } from "axios";
import Multiselect from 'multiselect-react-dropdown';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const PenaltyPage = () => {

    // -------------- Pagination --------------------
    const [totalPenaltyResults, setTotalPenaltyResults] = useState(-1);
    const [totalPenaltyPages, setTotalPenaltyPages] = useState(-1)
    const [currentPenaltyPage, setCurrentPenaltyPage] = useState(1)
    // -------------- Pagination End --------------------
    const { t } = useTranslation()

    const initializePenalty: iPenalty = {
        // id: -1,
        sanctionType: 1,
        penaltyType: 2, //Automatic-1 , Manual-2
        percentageValue: 0,
        minimumAmount: 0,
        collectionDays: 0,
        collectionTime: '12:00 AM',
        rewardLevelsRecede: 1,
        motivationText: '',
        userId: 0
    }
    const [penaltys, setPenaltys] = useState<iPenalty[]>([])
    const [penaltyToSave, setPenaltyToSave] = useState<iPenalty>(initializePenalty)
    const [currentState, setCurrentState] = useState<number>(0) // 0- add, 1-Edit, 2- View

    const httpPenaltyService: HttpPenaltyService = new HttpPenaltyService();
    const httpUsersService: HttpUsersService = new HttpUsersService();

    const [users, setUsers] = useState<iUser[]>([])
    const [usersSelected, setUsersSelected] = useState<iUser[]>([])


    class initialOrderCancellationPenalty implements iPenalty {
        //    id: number = 0
        sanctionType: eSanction | -1 = eSanction.Economical
        penaltyType: ePenaltyType = ePenaltyType.OrderCancellation
        percentageValue: number = 0
        minimumAmount: number = 0
        collectionDays: number = 0
        collectionTime: string = ''
        rewardLevelsRecede: number = 0
        motivationText: string = ''
        userId?: number = -1
        userName?: string = ''
        status?: string = ''
    }
    class initialUnVerifiedOrderCancellationPenalty implements iPenalty {
        //      id: number = 0
        sanctionType: eSanction | -1 = eSanction.Economical
        penaltyType: ePenaltyType = ePenaltyType.OrderUnverified
        percentageValue: number = 0
        minimumAmount: number = 0
        collectionDays: number = 0
        collectionTime: string = ''
        rewardLevelsRecede: number = 0
        motivationText: string = ''
        userId?: number = -1
        userName?: string = ''
        status?: string = ''
    }

    class initialManualPenalty implements iPenalty {
        //    id: number = 0
        sanctionType: eSanction | -1 = eSanction.Economical
        penaltyType: ePenaltyType = ePenaltyType.Manual
        percentageValue: number = 0
        minimumAmount: number = 0
        collectionDays: number = 0
        collectionTime: string = ''
        rewardLevelsRecede: number = 0
        motivationText: string = ''
        userId?: number = -1
        userName?: string = ''
        status?: string = ''
    }

    //penalty
    const [sellingOrderCancellationPenalty, setSellingOrderCancellationPenalty] = useState<iPenalty>()
    const [unverifiedOrderCancellationPenalty, setUnvarifiedOrderCancellationPenalty] = useState<iPenalty>()
    const [manualView, setManualView] = useState(false)
    //For Modal Dialog
    const { isOpen: isAddPenaltyOpen, toggle: toggleAddPanelty } = useModal()
    //For Modal Dialog
    const { isOpen, toggle } = useModal()

    useEffect(() => {
        loadPenalty()
        loadSellingOrderCancellationPenalty()
        loadUnvarifiedOrderCancellationPenalty()
    }, [])

    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        loadPenalty(data.currentPage);
        setCurrentPenaltyPage(data.currentPage);
    }
    const loadPenalty = (currentPage = 1) => {
        httpPenaltyService.getAll<iPenalty>({ currentPage: currentPage, pageLimit: appConst.PAGE_LIMIT })
            .then((value) => {
                console.log('value', value.data)
                setPenaltys(value.data.items)
                setTotalPenaltyResults(value.data.totalRows)
                setTotalPenaltyPages(Math.ceil(value.data.totalRows / appConst.PAGE_LIMIT))
            })
    }

    const loadSellingOrderCancellationPenalty = () => {
        httpPenaltyService.get<iPenalty>(ePenaltyType.OrderCancellation)
            .then((value) => {
                if (value.data) setSellingOrderCancellationPenalty(value.data)
            })
    }
    const loadUnvarifiedOrderCancellationPenalty = () => {
        httpPenaltyService.get<iPenalty>(ePenaltyType.OrderUnverified)
            .then((value) => {
                if (value.data) setUnvarifiedOrderCancellationPenalty(value.data);
            })
    }

    const viewPenalty = async (p_iPenalty: iPenalty) => {
        setPenaltyToSave({ ...p_iPenalty })
        console.log('kkkkkkkk')
        await httpUsersService.get<iUser>(p_iPenalty.userId)
            .then((value) => {
                // console.log('value', value)
                console.log('value user', value.data)
                setUsers([{ ...value.data }])
                setUsersSelected([{ ...value.data }])
            })
        setCurrentState(ePenaltyType.Manual)
        setManualView(true)
        toggle()
    }
    const deletePenalty = async (p_iPenalty: iPenalty) => {
        await httpPenaltyService.delete<iPenalty>(p_iPenalty.id!)
        toast.success(t`Delete_success_msg`);
        loadPenalty()
    }
    const applyPenalty = async (p_iPenalty: iPenalty) => {
        await httpPenaltyService.apply<iPenalty>(p_iPenalty.id!)
        toast.success(t`Apply_success_msg`);
        loadPenalty()
    }
    const renderPenalty = (): ReactNode[] => {
        console.log(penaltys?.length)
        const returnNode: ReactNode[] = penaltys?.map((penalty: iPenalty) => (

            <tr key={penalty.id}>
                <td>{penalty.id}</td>
                <td>{eSanction[penalty.sanctionType]}</td>
                <td>{ePenaltyType[penalty.penaltyType]}</td>
                <td>{penalty.percentageValue}</td>
                <td>{penalty.minimumAmount}</td>
                <td>{penalty.collectionDays}</td>
                <td>{penalty.collectionTime}</td>
                <td>{penalty.rewardLevelsRecede}</td>
                <td>{penalty.motivationText}</td>

                <td>{penalty.userName}</td>
                <td>{<>
                    <a onClick={() => viewPenalty(penalty)} title="View" data-toggle="tooltip" data-placement="top" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-eye"></i></a>
                    {penalty.status === 'applied' ? null :
                        <>
                            <a onClick={() => deletePenalty(penalty)} title="Delete" data-toggle="tooltip" data-placement="top" data-original-title="Edit" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-remove"></i></a>
                            <a onClick={() => applyPenalty(penalty)} title="Apply" data-toggle="tooltip" data-placement="top" data-original-title="Apply" className="btn btn-bulk small me-1" data-rel="tooltip" ><i className="la la-check"></i></a>
                        </>}
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const renderSellerOrderCancellationPenalty = (): ReactNode => {
        return (<section className="page-content container-fluid">
            {/* <div className="row mt-2 align-items-center"> */}
            {/* <div className="col-12 col-sm-7 col-md-7 col-lg-8">{t('Order_cancellation_penalty')}</div> */}
            <div className="admin-header">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-6">
                        <h3>{t('Order_cancellation_penalty')}</h3>
                    </div>

                    {!sellingOrderCancellationPenalty &&
                        <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                            <div className="action-btn">
                                <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                                    data-toggle="modal"
                                    data-target="#addSize"
                                    id="id-add-brand"
                                    onClick={() => onToggleClick(ePenaltyType.OrderCancellation)}
                                >{t('Add_Penalty')}</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {sellingOrderCancellationPenalty &&
                <div className="mt-3">
                    <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>{t('Id')}</th>
                                <th>{t('Sanction_Type')}</th>
                                <th>{t('Penalty_Type')}</th>
                                <th>{t('Amount')}</th>
                                <th>{t('Min_Amount_Due')}</th>
                                <th>{t('No_of_Days_for_Collection')}</th>
                                <th>{t('Collection_Timing')}</th>
                                <th>{t('No_of_level_Recede')}</th>
                                <th>{t('Motivation_Text')}</th>
                                <th style={{ width: '35px' }}>{t('Action')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {renderPenaltyTable(sellingOrderCancellationPenalty, ePenaltyType.OrderCancellation)}
                        </tbody>
                    </table>
                </div>
            }
        </section >)
    }
    //================ Manual Penaltys ========================= 

    //================ Automatic Penalty =======================
    const onToggleClick = (type: ePenaltyType) => {
        if (type == ePenaltyType.OrderCancellation) {
            setPenaltyToSave(new initialOrderCancellationPenalty)
        } else if (type == ePenaltyType.OrderUnverified) {
            setPenaltyToSave(new initialUnVerifiedOrderCancellationPenalty)
        } else { //Manual
            setPenaltyToSave(new initialManualPenalty)
        }
        setManualView(false)
        setCurrentState(type)
        setUsersSelected([])
        toggle()
    }
    const onEdit = (type: ePenaltyType) => {
        if (type == ePenaltyType.OrderCancellation) {
            setPenaltyToSave({ ...sellingOrderCancellationPenalty! })
        } else {
            setPenaltyToSave({ ...unverifiedOrderCancellationPenalty! })
        }
        setManualView(false)
        setCurrentState(type)
        toggle()
    }

    const onDelete = (type: ePenaltyType) => {
        httpPenaltyService.delete<iPenalty>(type)
            .then((value) => {
                if (type == ePenaltyType.OrderCancellation) {
                    setSellingOrderCancellationPenalty(undefined)
                } else {
                    setUnvarifiedOrderCancellationPenalty(undefined)
                }
            })
    }

    const cancelAddView = () => {
        setUsersSelected([])
        setManualView(false)
        toggle()
    }

    const enableAddView = () => {
        switch (penaltyToSave.sanctionType) {
            case eSanction.Economical:
                if ((penaltyToSave.percentageValue || penaltyToSave.minimumAmount) &&
                    penaltyToSave.collectionDays &&
                    //moment(penaltyToSave.collectionTime, ['hh:mm A'], true).isValid() &&
                    penaltyToSave.motivationText)
                    return true
                else return false
                break;
            case eSanction.PenaltyOnRewards:
                if (penaltyToSave.rewardLevelsRecede &&
                    penaltyToSave.motivationText) return true
                else return false
                break;
        }
    }


    const onExecutePanelty = async () => {
        try {
            if (currentState == ePenaltyType.Manual) {
                usersSelected && usersSelected[0] ?
                    await httpPenaltyService.post<iPenalty>({
                        ...penaltyToSave,
                        userId: usersSelected[0].id
                    })
                    :
                    await httpPenaltyService.post<iPenalty>({ ...penaltyToSave })
                loadPenalty()
            } else {
                console.log('execute called')
                await httpPenaltyService.update<iPenalty>({ ...penaltyToSave })
                toast.success(t`Add_success_msg`)
                if (penaltyToSave.penaltyType === ePenaltyType.OrderCancellation) {
                    loadSellingOrderCancellationPenalty();
                } else {
                    loadUnvarifiedOrderCancellationPenalty();
                }
            }
            setUsers([])
            setUsersSelected([])
            toggle()
        } catch (error) {
            let e = error as AxiosError
            console.log('error', e.message)
        }
    }

    const renderPenaltyTable = (penalty: iPenalty, type: number): ReactNode => {
        console.log(penalty)

        return <tr key={penalty.id}>
            <td>{penalty.id}</td>
            <td>{eSanction[penalty.sanctionType]}</td>
            <td>{ePenaltyType[penalty.penaltyType]}</td>
            <td>{penalty.percentageValue}</td>
            <td>{penalty.minimumAmount}</td>
            <td>{penalty.collectionDays}</td>
            <td>{penalty.collectionTime}</td>
            <td>{penalty.rewardLevelsRecede}</td>
            <td>{penalty.motivationText}</td>
            <td width={100}>{<>
                <a onClick={() => onEdit(penalty.penaltyType)}
                    title="Edit"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Edit"
                    className="btn btn-bulk small me-1" data-rel="tooltip" >
                    <i className="la la-edit"></i>
                </a>
                <a onClick={() => onDelete(penalty.penaltyType)}
                    title="Delete"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Edit"
                    className="btn btn-bulk small me-1" data-rel="tooltip" >
                    <i className="la la-remove"></i>
                </a>
            </>
            }</td>
        </tr>
    }

    const renderSellerOrderUnverifiedPenalty = (): ReactNode => {
        return (<section className="page-content container-fluid">
            <div className="admin-header">
                <div className="row">
                    <div className="col-sm-4 col-md-4 col-lg-6">
                        <h3>{t('Order_unvarified_penalty')}</h3>
                    </div>
                    {!unverifiedOrderCancellationPenalty &&
                        <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                            <div className="action-btn">
                                <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                                    data-toggle="modal"
                                    data-target="#addSize"
                                    id="id-add-brand"
                                    onClick={() => onToggleClick(ePenaltyType.OrderUnverified)}
                                >{t('Add_Penalty')}</a>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {unverifiedOrderCancellationPenalty &&
                <div className="mt-3">
                    <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                        <thead>
                            <tr>
                                <th>{t('Id')}</th>
                                <th>{t('Sanction_Type')}</th>
                                <th>{t('Penalty_Type')}</th>
                                <th>{t('Amount')}</th>
                                <th>{t('Min_Amount_Due')}</th>
                                <th>{t('No_of_Days_for_Collection')}</th>
                                <th>{t('Collection_Timing')}</th>
                                <th>{t('No_of_level_Recede')}</th>
                                <th>{t('Motivation_Text')}</th>
                                <th style={{ width: '35px' }}>{t('Action')}</th>
                            </tr>
                        </thead>

                        <tbody>
                            {renderPenaltyTable(unverifiedOrderCancellationPenalty, ePenaltyType.OrderUnverified)}
                        </tbody>
                    </table>
                </div>}
        </section>)
    }

    // ============= Multiselect ShoeModels =======================
    const onSelectUsers = (selectedList: iUser[], selectedItem: iUser) => {
        console.log('selectedList', selectedList)
        console.log('selectedItem', selectedItem)

        setUsersSelected(selectedList)
    }
    const onRemoveUsers = (selectedList: iUser[], removedItem: iUser) => {
        console.log('selectedList', selectedList)
        console.log('removedItem', removedItem)

        setUsersSelected(selectedList)
    }
    // ============= Multiselect ShoeModels end======================
    const handelSearch = async (searchText: string) => {
        console.log('handelSearch', searchText)
        if (!searchText) {
            setUsers([])
            return
        }
        //Call api to search shoeModels.
        try {

            let p_Users = await httpUsersService.filterBy<iUser>({ pageLimit: 100 }, { name: searchText })
                .then((value) => {
                    // console.log('value', value)
                    console.log('value', value)
                    setUsers(value.items)
                })
            // console.log('p_Users', p_Users)

        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('handelSearch error', e.message)
        }
    }
    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                <div className="content">
                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>{t('Manual_Penalty')}</h3>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    <div className="action-btn">
                                        <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                                            data-toggle="modal"
                                            data-target="#addSize"
                                            id="id-add-brand"
                                            onClick={() => onToggleClick(ePenaltyType.Manual)}
                                        >{t('Add_Penalty')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t('Id')}</th>
                                        <th>{t('Sanction_Type')}</th>
                                        <th>{t('Penalty_Type')}</th>
                                        <th>{t('Amount')}</th>
                                        <th>{t('Min_Amount_Due')}</th>
                                        <th>{t('No_of_Days_for_Collection')}</th>
                                        <th>{t('Collection_Timing')}</th>
                                        <th>{t('No_of_level_Recede')}</th>
                                        <th>{t('Motivation_Text')}</th>
                                        <th>{t('User_Name')}</th>
                                        <th style={{ width: '125px' }}>{t('Action')}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {renderPenalty()}
                                </tbody>
                            </table>
                        </div>
                        <div className="nav-direction">
                            {
                                totalPenaltyResults > appConst.PAGE_LIMIT && totalPenaltyPages ?
                                    <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                        <Pagination totalRecords={totalPenaltyResults}
                                            totalPages={totalPenaltyPages}
                                            currentPage={currentPenaltyPage}
                                            pageLimit={appConst.PAGE_LIMIT}
                                            pageNeighbours={1}
                                            onPageChanged={onPageChanged} />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </section>
                    {renderSellerOrderCancellationPenalty()}
                    {renderSellerOrderUnverifiedPenalty()}
                    <ModalDialog toggle={() => toggle()}
                        cancel={() => cancelAddView()}
                        executeButtonTitle={() => currentState == ePenaltyType.Manual ? t('Add') : t('Ok')}
                        isOpen={isOpen}
                        execute={onExecutePanelty}
                        enableExecute={enableAddView() ? true : false}
                        modalTitle={currentState == ePenaltyType.Manual ? t('Penalty') :
                            (currentState == ePenaltyType.OrderCancellation ? t('Order_cancellation_penalty') : t('Order_unvarified_penalty'))}
                        useButtons={t("MD_Cancel") + '/'  + (currentState == ePenaltyType.Manual ? t('Add') : t('Ok'))}
                    >

                        {
                            <div className="row">
                                <div className="col-sm-12 mb-2">
                                    <div className="form-group d-flex"
                                        style={manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <label className="mr-3">{t('Sanction_Type')}:</label>
                                        <div className="form-check">
                                            <input type="radio"
                                                className="form-check-input mx-2"
                                                id="radio1"
                                                name="optradio"
                                                value="1"
                                                onChange={() => setPenaltyToSave({ ...penaltyToSave, sanctionType: eSanction.Economical })}
                                                checked={penaltyToSave.sanctionType === 1}
                                            />
                                            <label className="form-check-label mr-3" htmlFor="radio1">
                                                {eSanction[eSanction.Economical]}
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input type="radio"
                                                className="form-check-input mx-2"
                                                id="radio2"
                                                name="optradio"
                                                value="2"
                                                onChange={() => setPenaltyToSave({ ...penaltyToSave, sanctionType: eSanction.PenaltyOnRewards })}
                                                checked={penaltyToSave.sanctionType === 2}
                                            />
                                            <label className="form-check-label" htmlFor="radio2">
                                                {eSanction[eSanction.PenaltyOnRewards]}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <div className="form-group"
                                        style={penaltyToSave.sanctionType == eSanction.PenaltyOnRewards ||
                                            manualView
                                            ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <label>{t`Amount`}:</label>
                                        <input type="text" className="form-control"
                                            value={penaltyToSave.percentageValue}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val))
                                                    return
                                                if (val <= 999)
                                                    setPenaltyToSave({ ...penaltyToSave, percentageValue: +evt.target.value })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-2 ps-lg-1">
                                    <div className="form-group"
                                        style={penaltyToSave.sanctionType == eSanction.PenaltyOnRewards ||
                                            manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <label>{t('Minimum_Amount')}:</label>
                                        <input type="text" className="form-control"
                                            value={penaltyToSave.minimumAmount}

                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val) || val > 5000)
                                                    return
                                                setPenaltyToSave({ ...penaltyToSave, minimumAmount: +evt.target.value })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <div className="form-group"
                                        style={penaltyToSave.sanctionType == eSanction.PenaltyOnRewards ||
                                            manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <label>{t('No_of_Days_for_Collection')}:</label>
                                        <input type="text" className="form-control"
                                            value={penaltyToSave.collectionDays}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val) || val > 365)
                                                    return
                                                setPenaltyToSave({ ...penaltyToSave, collectionDays: +evt.target.value })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-sm-6 mb-2 ps-lg-1">
                                        <div className="form-group"
                                            style={penaltyToSave.sanctionType == eSanction.PenaltyOnRewards ||
                                                manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            <label>{t('Collection_Timing_eg')}:</label>
                                            <input type="text" className="form-control"
                                                value={penaltyToSave.collectionTime}
                                                onChange={(evt) => setPenaltyToSave({ ...penaltyToSave, collectionTime: evt.target.value })}
                                            />
                                        </div>
                                    </div> */}
                                <div className="col-sm-6 mb-2">
                                    <div className="form-group"
                                        id='No_of_level_recede'
                                        style={penaltyToSave.sanctionType == eSanction.Economical ||
                                            manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <label>{t('No_of_level_Recede')}:</label>
                                        <input type="text" className="form-control"
                                            value={penaltyToSave.rewardLevelsRecede}
                                            onChange={(evt) => {
                                                const val = +evt.target.value
                                                if (isNaN(val))
                                                    return
                                                if (val <= 1)
                                                    setPenaltyToSave({ ...penaltyToSave, rewardLevelsRecede: +evt.target.value })
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-2">
                                    <div className="form-group"
                                        style={manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                        <label>{t('Motivation_Text')}:</label>
                                        <input type="text" className="form-control"
                                            value={penaltyToSave.motivationText}
                                            onChange={(evt) => setPenaltyToSave({ ...penaltyToSave, motivationText: evt.target.value })}
                                        />
                                    </div>
                                </div>
                                {currentState == ePenaltyType.Manual &&
                                    <div className="col-sm-6 mb-2">
                                        <div className="form-group"
                                            style={manualView ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                                            <label>{t('For_Users')}:</label>
                                            <Multiselect
                                                // disablePreSelectedValues={true}
                                                // showCheckbox={true}
                                                // singleSelect={true}
                                                selectionLimit={1}
                                                closeOnSelect={false}
                                                keepSearchTerm={true}
                                                onSearch={(value) => { if (value.length >= 1) handelSearch(value) }}
                                                options={users} // Options to display in the dropdown
                                                selectedValues={usersSelected} // Preselected value to persist in dropdown
                                                onSelect={onSelectUsers} // Function will trigger on select event
                                                onRemove={onRemoveUsers} // Function will trigger on remove event
                                                displayValue="email" // Property name to display in the dropdown options
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </ModalDialog>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div>

    )
};

export default PenaltyPage;