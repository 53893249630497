

// Using v6 of React-router-dom we handle the protected route in this format
// Setting up the Auth protection component

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
// import { useAppSelector } from "../../state/hooks";
import { useCookies } from "react-cookie";

// const ProtectedRoute: React.FC<{ children: React.ReactElement  }> = ({ children, ...rest }) => {
//     const [cookies, setCookie] = useCookies(['admin_access_token', 'admin_user', 'admin_global_language', 'admin_login_language', 'admin_expiry_time'])
//     // const { user } = <Your-State-Provider>// Redux/Context or even in-memory user
//     const location = useLocation();
//     return !cookies['admin_access_token'] ? (
//         <Navigate to={"auth/login"} state={{ from: location }} replace />
//     ) : (
//         children
//     );
// };

// export default ProtectedRoute;

const AdminPrivateRoute = () => {
    const [cookies] = useCookies(['admin_user'])

    return (
        !cookies['admin_user'] ? <Navigate to='auth/login' /> : <Outlet />
    )
}

export default AdminPrivateRoute;