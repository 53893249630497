import React, { useState, useEffect } from "react";
import { Sidebar } from "../Sidebar";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { HttpOrdersService, HttpSettingService, HttpWarehouseb2bService } from "../../../services/admin.service";
import {
  Fees,
  FeesKeys,
  ShippingFee,
  eBeneficiaryType,
  iSetting,
} from "../../../shared/models/adminModels";
import { toast } from "react-toastify";
import { feesFormValidationSchema } from "../../../shared/utils/validationSchemas";

const FeesPage = () => {
  const { t } = useTranslation();
  const httpSettingService: HttpSettingService = new HttpSettingService(); 
  const httpOrdersService: HttpOrdersService = new HttpOrdersService();
  const httpWarehouseb2bService: HttpWarehouseb2bService = new HttpWarehouseb2bService()
  const { values, handleChange, handleSubmit, setFieldValue, errors } =
    useFormik<Fees>({
      initialValues: new Fees(),
      validationSchema: feesFormValidationSchema,
      onSubmit,
    });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [buyerShippingFees, setBuyerShippingFees] = useState<ShippingFee[]>([]);
  const [sellerShippingFees, setSellerShippingFees] = useState<ShippingFee[]>(
    []
  );

  function onSubmit() {
    const resultArray: iSetting[] = Object.keys(values).map((key) => {
      const feesKey: FeesKeys = key as FeesKeys;
      const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);

      return {
        keyName: capitalizedKey,
        description: "",
        fromValue: values[feesKey].toString(),
        toValue: values[feesKey].toString(),
      };
    });
    saveSettings(resultArray);
  }
  const loadSettings = () => {
    setLoading(true);
    httpSettingService
      .getAll<iSetting>()
      .then((value) => {
        value.data.forEach((item) => {
          let key =
            item.keyName.charAt(0).toLowerCase() + item.keyName.slice(1);
          if (key in new Fees()) {
            setFieldValue(key, item.toValue);
          }
        });
      })
      .catch((error) => {
        setError(error.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const saveSettings = (settingsToSave: iSetting[]) => {
    httpSettingService
      .post(settingsToSave)
      .then(() => {toast.success(t`Save_success_msg`)})
      .catch((error) => {
        setError(error.response.data.message);
      });
    httpOrdersService.getForceReloadSettings();
    httpWarehouseb2bService.getForceReloadSettings();  
  };

  const getFees = (beneficiaryType: number) => {
    setLoading(true);
    httpSettingService
      .getShippingFees(beneficiaryType)
      .then((value) => {
        if (beneficiaryType === eBeneficiaryType.Buyer) {
          setBuyerShippingFees(value.data.shippingFees);
        } else {
          setSellerShippingFees(value.data.shippingFees);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadSettings();
    getFees(eBeneficiaryType.Buyer);
    getFees(eBeneficiaryType.Seller);
  }, []);

  return (
    <div id="app">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">
          <section className="page-content container-fluid">
            <div className="admin-header">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <h3>{t("Fees")}</h3>
                </div>
              </div>
            </div>
            {!loading && !error ? (
              <form className="fees-wrapper" onSubmit={handleSubmit}>
                <div className="fees-main-box">
                  <div className="main-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                        <h6 className="first">B2B</h6>
                      </div>
                      <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <h6>Buyer</h6>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <h6>Seller</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-settings mt-2 mb-0">
                    <div className="b2b-sfee-box">
                      <div className="sub-header">Shipping Fee</div>

                      <div className="row mt-2">
                        <div className="col-3 col-sm-5 col-md-4 col-lg-3">
                          <div className="sb-header">Up to</div>
                          {sellerShippingFees.map((item) => (
                            <React.Fragment key={item.id}>
                              <div className="info-txt">
                                {item.noOfUnits === -1
                                  ? "For any other amount"
                                  : item.noOfUnits}
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                        <div className="col-9 col-sm-8 col-md-8 col-lg-9">
                          <div className="row">
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ps-0">
                              <div className="sb-header">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="b2BBuyShippingFeePercent"
                                        name="b2BBuyShippingFeePercent"
                                        value="false"
                                        checked={
                                          values.b2BBuyShippingFeePercent.toString() ===
                                          "false"
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            "b2BBuyShippingFeePercent",
                                            "false"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label mr-3"
                                        htmlFor=" b2BBuyShippingFeePercent"
                                      >
                                        Fee x unit
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="radio1"
                                        name="radio1"
                                        checked={
                                          values.b2BBuyShippingFeePercent.toString() ===
                                          "true"
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            "b2BBuyShippingFeePercent",
                                            "true"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label mr-3"
                                        htmlFor="radio1"
                                      >
                                        % Fee
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {buyerShippingFees.map((fee) => (
                                <div className="row" key={fee.id}>
                                  <div className="col-6">
                                    <div className="info-txt">
                                      {fee.amount} &euro;
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="info-txt">
                                      {fee.amountPercent}%
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ps-0">
                              <div className="sb-header">
                                <div className="row">
                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="b2BSellShippingFeePercent"
                                        name="b2BSellShippingFeePercent"
                                        value="false"
                                        checked={
                                          values.b2BSellShippingFeePercent.toString() ===
                                          "false"
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            "b2BSellShippingFeePercent",
                                            "false"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label mr-3"
                                        htmlFor="radio2"
                                      >
                                        Fee x unit
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="form-check">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id="radio2"
                                        name="radio2"
                                        value="true"
                                        checked={
                                          values.b2BSellShippingFeePercent.toString() ===
                                          "true"
                                        }
                                        onChange={() => {
                                          setFieldValue(
                                            "b2BSellShippingFeePercent",
                                            "true"
                                          );
                                        }}
                                      />
                                      <label
                                        className="form-check-label mr-3"
                                        htmlFor="radio2"
                                      >
                                        % Fee
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {sellerShippingFees.map((fee) => (
                                <div className="row" key={fee.id}>
                                  <div className="col-6">
                                    <div className="info-txt">
                                      {fee.amount} &euro;
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="info-txt">
                                      {fee.amountPercent}%
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row mt-2 align-items-center">
                        <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                          Processing Fee
                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                          <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3">
                              <div className="percentage">
                                <div className="t-percentage">%</div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="b2BBuyTransactionFeePercent"
                                  value={
                                    !!values.b2BBuyTransactionFeePercent
                                      ? values.b2BBuyTransactionFeePercent
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.b2BBuyTransactionFeePercent && (
                                  <div className="validation-txt text-danger">
                                    {errors.b2BBuyTransactionFeePercent}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                              <div className="percentage">
                                <div className="t-percentage">%</div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="b2BSellTransactionFeePercent"
                                  value={
                                    !!values.b2BSellTransactionFeePercent
                                      ? values.b2BSellTransactionFeePercent
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.b2BSellTransactionFeePercent && (
                                  <div className="validation-txt text-danger">
                                    {errors.b2BSellTransactionFeePercent}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2 align-items-center">
                        <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                          Payment Processing Fee
                        </div>
                        <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                          <div className="row">
                            <div className="col-sm-3 col-md-3 col-lg-3">
                              <div className="percentage">
                                <div className="t-percentage">%</div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="b2BBuyPaymentProcessingFeePercent"
                                  value={
                                    !!values.b2BBuyPaymentProcessingFeePercent
                                      ? values.b2BBuyPaymentProcessingFeePercent
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.b2BBuyPaymentProcessingFeePercent && (
                                  <div className="validation-txt text-danger">
                                    {errors.b2BBuyPaymentProcessingFeePercent}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            <div className="col-sm-3 col-md-3 col-lg-3">
                              <div className="percentage">
                                <div className="t-percentage">%</div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="b2BSellPaymentProcessingFeePercent"
                                  value={
                                    !!values.b2BSellPaymentProcessingFeePercent
                                      ? values.b2BSellPaymentProcessingFeePercent
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                                {errors.b2BSellPaymentProcessingFeePercent && (
                                  <div className="validation-txt text-danger">
                                    {errors.b2BSellPaymentProcessingFeePercent}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fees-main-box">
                  <div className="main-header">
                    <div className="row align-items-center">
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                        <h6 className="first">B2C</h6>
                      </div>
                      <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <h6>Buyer</h6>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <h6>Seller</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-settings mt-2 mb-0">
                    <div className="row mt-2 align-items-center">
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                        Shipping Fee
                      </div>
                      <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="setting-switch-control">
                              <div className="input-euro-txt">
                                <div className="euro-txt">&euro; xunit</div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="b2CBuyShippingFeeValue"
                                  value={
                                    values.b2CBuyShippingFeePercent.toString() ===
                                    "true"
                                      ? ""
                                      : !!values.b2CBuyShippingFeeValue
                                      ? values.b2CBuyShippingFeeValue
                                      : ""
                                  }
                                  disabled={
                                    values.b2CBuyShippingFeePercent.toString() ===
                                    "true"
                                  }
                                  onChange={handleChange}
                                />
                                {errors.b2CBuyShippingFeeValue &&
                                  values.b2CBuyShippingFeePercent.toString() ===
                                    "false" && (
                                    <div className="validation-txt text-danger">
                                      {errors.b2CBuyShippingFeeValue}
                                    </div>
                                  )}
                              </div>
                              <div className="setting-switch">
                                <div className="switch-control">
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      checked={
                                        values.b2CBuyShippingFeePercent.toString() ===
                                        "true"
                                          ? true
                                          : false
                                      }
                                      name="b2CBuyShippingFeePercent"
                                      onChange={() => {
                                        setFieldValue(
                                          "b2CBuyShippingFeeValue",
                                          ""
                                        );
                                        setFieldValue(
                                          "b2CBuyShippingFeePercent",
                                          values.b2CBuyShippingFeePercent.toString() ===
                                            "true"
                                            ? "false"
                                            : "true"
                                        );
                                      }}
                                      type="checkbox"
                                      role="switch"
                                    />
                                    <label className="form-check-label"></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="percentage">
                              <div className="t-percentage">%</div>
                              <input
                                type="text"
                                className="form-control"
                                id="b2CBuyShippingFeeValue"
                                value={
                                  values.b2CBuyShippingFeePercent.toString() ===
                                  "false"
                                    ? ""
                                    : !!values.b2CBuyShippingFeeValue
                                    ? values.b2CBuyShippingFeeValue
                                    : ""
                                }
                                disabled={
                                  values.b2CBuyShippingFeePercent.toString() ===
                                  "false"
                                }
                                onChange={handleChange}
                              />
                              {errors.b2CBuyShippingFeeValue &&
                                values.b2CBuyShippingFeePercent.toString() ===
                                  "true" && (
                                  <div className="validation-txt text-danger">
                                    {errors.b2CBuyShippingFeeValue}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="setting-switch-control">
                              <div className="input-euro-txt">
                                <div className="euro-txt">&euro; xunit</div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="b2CSellShippingFeeValue"
                                  value={
                                    values.b2CSellShippingFeePercent.toString() ===
                                    "true"
                                      ? ""
                                      : !!values.b2CSellShippingFeeValue
                                      ? values.b2CSellShippingFeeValue
                                      : ""
                                  }
                                  disabled={
                                    values.b2CSellShippingFeePercent.toString() ===
                                    "true"
                                  }
                                  onChange={handleChange}
                                />
                                {errors.b2CSellShippingFeeValue &&
                                  values.b2CSellShippingFeePercent.toString() ===
                                    "false" && (
                                    <div className="validation-txt text-danger">
                                      {errors.b2CSellShippingFeeValue}
                                    </div>
                                  )}
                              </div>
                              <div className="setting-switch">
                                <div className="switch-control">
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      checked={
                                        values.b2CSellShippingFeePercent.toString() ===
                                        "true"
                                          ? true
                                          : false
                                      }
                                      name="b2CSellShippingFeePercent"
                                      onChange={() => {
                                        setFieldValue(
                                          "b2CSellShippingFeeValue",
                                          ""
                                        );
                                        setFieldValue(
                                          "b2CSellShippingFeePercent",
                                          values.b2CSellShippingFeePercent.toString() ===
                                            "true"
                                            ? "false"
                                            : "true"
                                        );
                                      }}
                                      type="checkbox"
                                      role="switch"
                                    />
                                    <label className="form-check-label"></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="percentage">
                              <div className="t-percentage">%</div>
                              <input
                                type="text"
                                className="form-control"
                                id="b2CSellShippingFeeValue"
                                value={
                                  values.b2CSellShippingFeePercent.toString() ===
                                  "false"
                                    ? ""
                                    : !!values.b2CSellShippingFeeValue
                                    ? values.b2CSellShippingFeeValue
                                    : ""
                                }
                                disabled={
                                  values.b2CSellShippingFeePercent.toString() ===
                                  "false"
                                }
                                onChange={handleChange}
                              />
                              {errors.b2CSellShippingFeeValue &&
                                values.b2CSellShippingFeePercent.toString() ===
                                  "true" && (
                                  <div className="validation-txt text-danger">
                                    {errors.b2CSellShippingFeeValue}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 align-items-center">
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                        Processing Fee
                      </div>
                      <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                          <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="percentage">
                              <div className="t-percentage">%</div>
                              <input
                                type="text"
                                className="form-control"
                                id="b2CBuyTransactionFeePercent"
                                value={
                                  !!values.b2CBuyTransactionFeePercent
                                    ? values.b2CBuyTransactionFeePercent
                                    : ""
                                }
                                onChange={handleChange}
                              />
                              {errors.b2CBuyTransactionFeePercent && (
                                <div className="validation-txt text-danger">
                                  {errors.b2CBuyTransactionFeePercent}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="percentage">
                              <div className="t-percentage">%</div>
                              <input
                                type="text"
                                className="form-control"
                                id="b2CSellTransactionFeePercent"
                                value={
                                  !!values.b2CSellTransactionFeePercent
                                    ? values.b2CSellTransactionFeePercent
                                    : ""
                                }
                                onChange={handleChange}
                              />
                              {errors.b2CSellTransactionFeePercent && (
                                <div className="validation-txt text-danger">
                                  {errors.b2CSellTransactionFeePercent}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 align-items-center">
                      <div className="col-12 col-sm-5 col-md-4 col-lg-3">
                        Payment Processing Fee
                      </div>
                      <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                        <div className="row">
                          <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="percentage">
                              <div className="t-percentage">%</div>
                              <input
                                type="text"
                                className="form-control"
                                id="b2CBuyPaymentProcessingFeePercent"
                                value={
                                  !!values.b2CBuyPaymentProcessingFeePercent
                                    ? values.b2CBuyPaymentProcessingFeePercent
                                    : ""
                                }
                                onChange={handleChange}
                              />
                              {errors.b2CBuyPaymentProcessingFeePercent && (
                                <div className="validation-txt text-danger">
                                  {errors.b2CBuyPaymentProcessingFeePercent}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-3 col-lg-3"></div>
                          <div className="col-sm-3 col-md-3 col-lg-3">
                            <div className="percentage">
                              <div className="t-percentage">%</div>
                              <input
                                type="text"
                                className="form-control"
                                id="b2CSellPaymentProcessingFeePercent"
                                value={
                                  !!values.b2CSellPaymentProcessingFeePercent
                                    ? values.b2CSellPaymentProcessingFeePercent
                                    : ""
                                }
                                onChange={handleChange}
                              />
                              {errors.b2CSellPaymentProcessingFeePercent && (
                                <div className="validation-txt text-danger">
                                  {errors.b2CSellPaymentProcessingFeePercent}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-end">
                  <button type="submit" className="bulk-primary-btn">
                    {t("Save_Changes")}
                  </button>
                </div>
              </form>
            ) : (
              <>{loading ? <div>Loading...</div> : <>{error}</>}</>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default FeesPage;
