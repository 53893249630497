import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

interface iModalDialog {
    isOpen: boolean
    toggle: () => void
    cancel: () => void
    modalTitle: string
    useButtons: string
    children: React.ReactNode

    execute: () => void
    enableExecute: boolean
    executeButtonTitle: () => string

    hideFooter?: boolean
}

function ModalDialog(p_iModalDialog: iModalDialog) {
    const { t } = useTranslation()
    return (
        <div
            className={p_iModalDialog.isOpen ? "modal show" : "modal"}
            style={p_iModalDialog.isOpen ? { display: 'block', position: 'fixed' } : { display: 'none' }}
        >
            <Modal.Dialog>
                <Modal.Header className="close" >
                    {/* <Modal.Title>{p_iModalDialog.modalTitle}</Modal.Title> */}
                    <h5 className="modal-title" id="exampleModalLabel">{p_iModalDialog.modalTitle}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                        onClick={() => p_iModalDialog.cancel()}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>

                <Modal.Body className="modal-body add-category-modal">
                    {/* <div className="form-group">
                        <label>Brand Name:</label>
                        <input type="text" className="form-control" />
                    </div>
                    <label>Brand Image:</label>
                    <div className="category-img">
                        <div className="uploaded-img"><img id="output" width="200" /></div>
                        <div className="upload-img">
                            <input type="file" accept="image/*" />
                            <div className="img-icon"></div>
                        </div>
                    </div> */}
                    {p_iModalDialog.children}

                </Modal.Body>

                {
                    p_iModalDialog.hideFooter ?
                        <></> :
                        <Modal.Footer className="modal-footer">
                            {p_iModalDialog.useButtons.split("/").includes(t("MD_Cancel")) ?
                                <Button className="btn btn-secondary" variant="secondary" onClick={() => p_iModalDialog.cancel()}>{t("MD_Cancel")}</Button>
                                : null}
                            {p_iModalDialog.useButtons.split("/").includes(t("Ok")) ?
                            <Button className="btn btn-primary" variant="primary"
                                disabled={p_iModalDialog.enableExecute ? false : true}
                                onClick={() => p_iModalDialog.execute()}>{t("Ok")}</Button>
                                : null}
                            {p_iModalDialog.useButtons.split("/").includes(t("Save")) ?
                            <Button className="btn btn-primary" variant="primary"
                                disabled={p_iModalDialog.enableExecute ? false : true}
                                onClick={() => p_iModalDialog.execute()}>{t("Save")}</Button>
                                : null}
                            {p_iModalDialog.useButtons.split("/").includes(t("MD_Add")) ?
                            <Button className="btn btn-primary" variant="primary"
                                disabled={p_iModalDialog.enableExecute ? false : true}
                                onClick={() => p_iModalDialog.execute()}>{t("MD_Add")}</Button>
                                : null}
                            {p_iModalDialog.useButtons.split("/").includes(t("MD_Update")) ?
                            <Button className="btn btn-primary" variant="primary"
                                disabled={p_iModalDialog.enableExecute ? false : true}
                                onClick={() => p_iModalDialog.execute()}>{t("MD_Update")}</Button>
                                : null}
                            {p_iModalDialog.useButtons.split("/").includes(t("MD_Suspend")) ?
                            <Button className="btn btn-primary" variant="primary"
                                disabled={p_iModalDialog.enableExecute ? false : true}
                                onClick={() => p_iModalDialog.execute()}>{t("MD_Suspend")}</Button>
                                : null}
                        </Modal.Footer>
                }
            </Modal.Dialog>
        </div>
    );
}

export default ModalDialog;