import { Sidebar } from "../Sidebar"
import { ReactNode, useEffect, useState } from "react";
import { iNotificationSetting } from '../../../shared/models/adminModels'
import {
    HttpSettingService
} from '../../../services/admin.service'
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const NotificationSettingsPage = () => {
    const httpSettingService = new HttpSettingService();
    const { t } = useTranslation();
    // use Mock setting for adding new keys for first time only
    const mockSettings: iNotificationSetting[] = [
        {
            key: "Admin",
            value: true
        },
        {
            key: "BatchRequestAccepted",
            value: true
        },
        {
            key: "CounterOfferRequested",
            value: true
        },
        {
            key: "CounterRequestAccepted",
            value: true
        },
        {
            key: "FavProductPurchased",
            value: true
        },
        {
            key: "OfferRequestAccepted",
            value: true
        },
        {
            key: "OfferRequested",
            value: true
        },
        {
            key: "OrderUpdated",
            value: true
        },
    ]
    const orderByMockData = (gotSettings: iNotificationSetting[]) => {
        let tempArr: iNotificationSetting[] = []
        for (let i = 0; i < mockSettings.length; i++) {
            let elem = gotSettings.find((item) => item.key == mockSettings[i].key)
            if (elem) {
                tempArr.push(elem)
            }
        }
        return tempArr
    }
    const [settings, setSettings] = useState<iNotificationSetting[]>([])

    const setKeyWithValue = (key: string, val: boolean) => {
        console.log('keyName:', key, "Val => ", val)
        let a = settings.map((item) => item.key == key ? { ...item, value: val } : item)
        setSettings(a)
    }

    useEffect(() => {
        loadSettings()
    }, [])

    const loadSettings = () => {
        console.log('loadSettings')
        //TODO make the get request for settings
        httpSettingService.getNotificationSetting<iNotificationSetting>()
            .then((value) => {
                console.log('value.data', value.data)
                if(value.data.length) {
                    setSettings(orderByMockData(value.data))
                } else {
                    setSettings(mockSettings)
                }
                // let orderedArr = orderByMockData(value.data)
                // console.log('orderedArr', orderedArr)
                // setSettings(value.data)
                // setSettings(mockSettings)
            })
    }
    const saveChanges = () => {
        //TODO make the post request for settings
        console.log('settings', settings)
        httpSettingService.updateNotificationSetting(settings)
            .then((value) => toast.success(t`Save_success_msg`))
    }

    const renderBooleanComponent = (item: iNotificationSetting, index: number, label: string): ReactNode => {
        return <div className="row align-items-center mb-3"
        >
            <div className="col-12 col-sm-7 col-md-7 col-lg-8">{label}</div>
            <div className="col-12 col-sm-5 col-md-5 col-lg-4">
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsFirst" + index}
                        id={"inlineRadioFirst" + index}
                        value={item.value ? "on" : "off"}
                        checked={item.value}
                        onChange={(evt) => setKeyWithValue(item.key, true)} />
                    <label className="form-check-label">On</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input"
                        type="radio"
                        name={"inlineRadioOptionsSec" + index}
                        id={"inlineRadioSec" + index}
                        value={!item.value ? "on" : "off"}
                        checked={!item.value}
                        onChange={(evt) => setKeyWithValue(item.key, false)} />
                    <label className="form-check-label">Off</label>
                </div>
            </div>
        </div>
    }

    const renderSwitch = (item: iNotificationSetting, index: number): ReactNode => {
        switch (item.key) {
            case "FavProductPurchased":
                return renderBooleanComponent(item, index, "Fav Product Purchased")
            case "BatchRequestAccepted":
                return renderBooleanComponent(item, index, "Batch Request Accepted")
            case "OfferRequested":
                return renderBooleanComponent(item, index, "Offer Requested")
            case "OfferRequestAccepted":
                return renderBooleanComponent(item, index, "Offer Request Accepted")
            case "CounterOfferRequested":
                return renderBooleanComponent(item, index, "Counter Offer Requested")
            case "CounterRequestAccepted":
                return renderBooleanComponent(item, index, "Counter Request Accepted")
            case "OrderUpdated":
                return renderBooleanComponent(item, index, "Order Updated")
            case "Admin":
                return renderBooleanComponent(item, index, "Admin")
        }
    }
    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                <div className="content">
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-12">
                                    <h3>Notification Settings</h3>
                                </div>
                            </div>
                        </div>

                        <div className="setting-wrapper">
                            {settings && settings.map((item, index) =>
                                renderSwitch(item, index)
                            )}
                            <div className="mt-4 text-end">
                                <a href="javascript:void(0)"
                                    className="bulk-primary-btn"
                                    onClick={saveChanges}>Save Changes</a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};




export default NotificationSettingsPage;