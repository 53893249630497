import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { UserFilters, eAppFilters, eSocialMediaName, eUserType, iRewardsLevel, iUser, iUserFilters } from '../../../shared/models/adminModels'
import { HttpRewardsLevelService, HttpUsersService } from "../../../services/admin.service";
import * as appConst from '../../../shared/constants/constants'
import Pagination from "../../../shared/components/Pagination";
import { useTranslation } from 'react-i18next';
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { rewardSliceActions } from "../../../store/rewardSlice";
import moment from "moment";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import useModal from "../../../hooks/general";
import DatePicker from "react-datepicker";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import { RootState } from "../../../store/stateTypes";
import { useSelector } from "react-redux";
import CustomPagination from "../../../shared/components/CustomPagination";

const UsersPage = () => {
    const { t } = useTranslation()
    // -------------- Pagination --------------------
    const [totalUsersResults, setTotalUsersResults] = useState(-1);
    const [totalUsersPages, setTotalUsersPages] = useState(-1)
    // -------------- Pagination End --------------------

    const initialFilter: iUserFilters = new UserFilters()
    // const [userFilter, setUserFilter] = useState<iUserFilters>(initialFilter)
    const userPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.user));
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('userPageState Page Filters', userPageState)
    }, [userPageState])

    const navigate = useNavigate();
    const [users, setUsers] = useState<iUser[]>([])
    const [rewardsLevel, setRewardsLevel] = useState<iRewardsLevel[]>([])

    //const [resetFilter, setResetFilter] = useState<boolean>(false);
    const httpUsersService: HttpUsersService = new HttpUsersService();
    const httpRewarsService: HttpRewardsLevelService = new HttpRewardsLevelService();
    //For Modal Dialog
    const { isOpen, toggle } = useModal()

    // useEffect(() => {
    //     refreshUsersList();
    //     if (JSON.stringify(initialFilter) !== JSON.stringify(userPageState?.filter)) {
    //         setResetFilter(true)
    //     } else {
    //         setResetFilter(false)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [userPageState?.filter])

    const refreshUsersList = (currentPage = 1, reset = false) => {
        console.log('refreshUsersList', currentPage)
        let filter = JSON.stringify(initialFilter) !== JSON.stringify(userPageState?.filter) ?
            { ...userPageState?.filter } :
            {};
        setTotalUsersResults(0)
        setTotalUsersPages(0)
        httpUsersService.filterBy({
            pageLimit: userPageState!.pageLimit,
            currentPage: currentPage
        }, filter)
            .then((value: any) => {
                console.log('totalRows', value.totalRows)
                console.log('pages', Math.ceil(value.totalRows / userPageState!.pageLimit))
                setUsers(value.items)
                setTotalUsersResults(value.totalRows)
                setTotalUsersPages(Math.ceil(value.totalRows / userPageState!.pageLimit))
                //setCurrentUsersPage(currentPage)
            })
            .catch((error) => console.log(error))
    }
    useEffect(() => {
        console.log('--Toggle Refresh', userPageState?.currentPage)
        refreshUsersList(userPageState!.currentPage);
    }, [userPageState?.toggleRefresh])

    const refreshRewardsLevel = (currentPage = 1) => {
        httpRewarsService.getAll<iRewardsLevel>({ currentPage: currentPage, pageLimit: 200 })
            .then((value: any) => {
                setRewardsLevel(value.data);
                dispatch(rewardSliceActions.storeRewards(value.data));
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        refreshRewardsLevel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const onViewUserDetailClicked = (user: iUser) => {
        navigate('/admin/users/detail/' + user.id)
    }

    const renderUser = (): ReactNode[] => {
        console.log(users?.length)
        const returnNode: ReactNode[] = users?.map((user: iUser) => (

            <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user?.userTypeId && eUserType[user.userTypeId]} </td>
                <td width={130}>{<>
                    <a onClick={() => onViewUserDetailClicked(user)}
                        data-toggle="tooltip" data-placement="top" title="View"
                        className="btn btn-bulk icon small me-1" data-rel="tooltip" ><i className="la la-eye"></i>
                    </a>
                    {user.userTypeId !== eUserType.Administrator &&
                        <a onClick={() => onToggleClick(user)}
                            className="btn btn-bulk-danger icon small me-1"
                            data-rel="tooltip" title="Suspend"><i className="la la-remove"></i>
                        </a>
                    }
                    <a onClick={() => onBan(user.id)}
                        className="btn btn-bulk-danger icon small"
                        data-rel="tooltip" title="Ban"><i className="la la-ban"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const onSuspend = async (user: iUser, toDate: Date) => {
        await httpUsersService.suspendUser(user.id, toDate);
        //await httpUsersService.suspendUser(id, moment(toDate).format('MM/DD/YYYY'));
        toast.success(t`Suspend_success_msg`)
        dispatch(appPageSliceActions.savePageFilters({
            ...userPageState!,
            toggleRefresh: !userPageState?.toggleRefresh
        }))
        //refreshUsersList();
    }

    const onBan = async (id: number) => {
        await httpUsersService.banUser(id);
        toast.success(t`Ban_success_msg`)
        dispatch(appPageSliceActions.savePageFilters({
            ...userPageState!,
            toggleRefresh: !userPageState?.toggleRefresh
        }))
        //refreshUsersList();
    }
    
    useEffect(() => {
        refreshUsersList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPageState?.filter])

    useEffect(() => {
        console.log('--Page Changed', userPageState?.currentPage)
        refreshUsersList(userPageState?.currentPage);
    }, [userPageState?.currentPage, userPageState?.pageLimit])

    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...userPageState!,
            currentPage: data.currentPage
        }))
    }

    const populateUserType = (): ReactNode => {
        const keys = Object.keys(eUserType).filter((v) => isNaN(Number(v)));
        return <select value={(userPageState?.filter as iUserFilters).user_type_id}
            onChange={(evt) => {
                dispatch(appPageSliceActions.savePageFilters({
                    ...userPageState!,
                    currentPage: 1,
                    filter: {
                        ...userPageState!.filter,
                        user_type_id: +evt.target.value
                    }
                }))
                //setUserFilter({ ...userFilter, user_type_id: +evt.target.value }) 
            }} className="form-control" name="BeneficiaryType_id">
            <option value="0">{t('Select_User_type')}</option>
            {keys.length && keys.map((key, index) =>
                <option value={index + 1}>
                    {key}
                </option>)}
        </select>
    }

    const populateSocialMedium = (): ReactNode => {
        const keys = Object.keys(eSocialMediaName).filter((v) => isNaN(Number(v)));
        return <select value={(userPageState?.filter as iUserFilters).signup_medium_id}
            onChange={(evt) => {
                dispatch(appPageSliceActions.savePageFilters({
                    ...userPageState!,
                    currentPage: 1,
                    filter: {
                        ...userPageState!.filter,
                        signup_medium_id: +evt.target.value
                    }
                }))
                //setUserFilter({ ...userFilter, signup_medium_id: +evt.target.value })
            }}
            className="form-control" name="BeneficiaryType_id">
            <option value='0'>{t('Select_Social_medium')}</option>
            {keys.length && keys.map((key, index) =>
                <option value={index + 1} >
                    {key}
                </option>)}
        </select>
    }

    const onResetFilter = () => {
        //setUserFilter(initialFilter);
        //setResetFilter(false);
        dispatch(appPageSliceActions.resetPageFilter({ filterType: eAppFilters.user }))
    }

    //============ Modal Dialog ====================
    const [suspendTillDate, setSuspendTillDate] = useState<Date>(new Date())
    const [userToSuspend, setUserToSuspend] = useState<iUser>()
    const onToggleClick = (user: iUser) => {
        console.log('in onToggleClick')
        setUserToSuspend(user)
        toggle()
    }
    const cancelClick = () => {
        console.log('in cancelClick')
        toggle()
    }
    const onExecute = async () => {
        console.log('in onExecute')
        await onSuspend(userToSuspend!, suspendTillDate)
        toggle()
    }
    const enableExecute = () => {
        console.log('in enableExecute')
        return true
    }

    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1>{t('Manage_users')}</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item active" aria-current="page">{t('Users')}</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid pt-0">

                        <div className="row">
                            <div className="order-filter mb-3">
                                <div className="row">

                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('Name')}:</label>
                                        <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                debounceTimeout={500}
                                                onChange={event => {
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        ...userPageState!,
                                                        currentPage: 1,
                                                        filter: {
                                                            ...userPageState!.filter,
                                                            name: event.target.value
                                                        }
                                                    }))
                                                    //setUserFilter({ ...userFilter, name: event.target.value }); 
                                                }}
                                                placeholder={t('Name')!}
                                                value={(userPageState?.filter as iUserFilters).name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('Email')}:</label>
                                        <div className="input-group">
                                            <DebounceInput
                                                className="form-control"
                                                minLength={1}
                                                debounceTimeout={500}
                                                onChange={event => {
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        ...userPageState!,
                                                        currentPage: 1,
                                                        filter: {
                                                            ...userPageState!.filter,
                                                            email: event.target.value
                                                        }
                                                    }))
                                                    //setUserFilter({ ...userFilter, email: event.target.value }); 
                                                }}
                                                placeholder={t('Email')!}
                                                value={(userPageState?.filter as iUserFilters).email}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('User_Type')}:</label>
                                        <div className="input-group">
                                            {populateUserType()}
                                        </div>
                                    </div>

                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('Email_Varified')}:</label>
                                        <select value={(userPageState?.filter as iUserFilters).is_email_verified === undefined ?
                                            "undefined" : (userPageState?.filter as iUserFilters).is_email_verified ? 1 : 0}
                                            onChange={(evt) => {
                                                if (evt.target.value === "undefined") {
                                                    // setUserFilter({
                                                    //     ...userFilter,
                                                    //     is_email_verified: undefined
                                                    // });
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        ...userPageState!,
                                                        currentPage: 1,
                                                        filter: {
                                                            ...userPageState!.filter,
                                                            is_email_verified: undefined
                                                        }
                                                    }))
                                                    return
                                                }
                                                // setUserFilter({
                                                //     ...userFilter,
                                                //     is_email_verified: +evt.target.value === 0 ? false : true
                                                // })
                                                dispatch(appPageSliceActions.savePageFilters({
                                                    ...userPageState!,
                                                    currentPage: 1,
                                                    filter: {
                                                        ...userPageState!.filter,
                                                        is_email_verified: +evt.target.value === 0 ? false : true
                                                    }
                                                }))
                                            }} className="form-control in_language_id" name="in_language_id">
                                            <option value="undefined">--</option>
                                            {appConst.DROPDOWN_VALUE_FOR_BOOLEAN.map((type) =>
                                                <option value={type.value}>
                                                    {type.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('Deleted_User')}:</label>
                                        <select value={(userPageState?.filter as iUserFilters).is_deleted === undefined ?
                                            "undefined" : (userPageState?.filter as iUserFilters).is_deleted ? 1 : 0} onChange={(evt) => {
                                                if (evt.target.value === "undefined") {
                                                    // setUserFilter({
                                                    //     ...userFilter,
                                                    //     is_deleted: undefined
                                                    // });
                                                    dispatch(appPageSliceActions.savePageFilters({
                                                        ...userPageState!,
                                                        currentPage: 1,
                                                        filter: {
                                                            ...userPageState!.filter,
                                                            is_deleted: undefined
                                                        }
                                                    }))
                                                    return
                                                }
                                                // setUserFilter({
                                                //     ...userFilter,
                                                //     is_deleted: +evt.target.value === 0 ? false : true
                                                // })
                                                dispatch(appPageSliceActions.savePageFilters({
                                                    ...userPageState!,
                                                    currentPage: 1,
                                                    filter: {
                                                        ...userPageState!.filter,
                                                        is_deleted: +evt.target.value === 0 ? false : true
                                                    }
                                                }))
                                            }
                                            } className="form-control in_language_id" name="in_language_id">
                                            <option value="undefined">--</option>
                                            {appConst.DROPDOWN_VALUE_FOR_BOOLEAN.map((type) =>
                                                <option value={type.value}>
                                                    {type.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('Signup_Medium')}:</label>
                                        {populateSocialMedium()}
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 mb-2">
                                        <label>{t('Reward_Level_Type')}</label>
                                        <select onChange={(evt) =>
                                            //setUserFilter({ ...userFilter, levels_for_rewards_id: +evt.target.value })
                                            dispatch(appPageSliceActions.savePageFilters({
                                                ...userPageState!,
                                                currentPage: 1,
                                                filter: {
                                                    ...userPageState!.filter,
                                                    levels_for_rewards_id: +evt.target.value
                                                }
                                            }))
                                        }
                                            className="form-control in_language_id" name="in_language_id">
                                            <option value="0">{t('All_Reward_Levels')}</option>
                                            {rewardsLevel.map((type) =>
                                                <option value={type.id}>
                                                    {type.name}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                    {JSON.stringify(initialFilter) !== JSON.stringify(userPageState?.filter) &&
                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                            <label></label>
                                            <div className="action-btn">
                                                <a onClick={() => onResetFilter()}
                                                    href="javascript:void(0)"
                                                    className="bulk-primary-btn bulk-sm add-shoe-model"
                                                    data-toggle="modal" data-target="#addReward">
                                                    {t('Reset_Filter')}
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-12">
                                <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>{t('Id')}</th>
                                            <th>{t('Name')}</th>
                                            <th>{t('Email')}</th>
                                            <th>{t('User_Role')}</th>
                                            <th>{t('Action')}</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {renderUser()}
                                    </tbody>
                                </table>
                            </div>
                            {totalUsersResults && totalUsersPages ?
                                <CustomPagination
                                    totalResults={totalUsersResults}
                                    totalPages={totalUsersPages}
                                    p_iPageState={userPageState!}
                                    onPageChanged={onPageChanged}
                                />
                                : null
                            }
                        </div>
                        {/* Modal For filter Ended */}
                    </section>
                </div>
                <ModalDialog toggle={toggle}
                    cancel={() => cancelClick()}
                    executeButtonTitle={() => { return t('MD_Suspend') }}
                    isOpen={isOpen}
                    execute={onExecute}
                    enableExecute={enableExecute()}
                    modalTitle={t('MD_Suspend_user')}
                    useButtons={t("MD_Cancel") + '/' +  t('MD_Suspend')}
                >
                    {/* The Body of Modal Dialog */}
                    <label>{t('MD_Suspend')}: {userToSuspend ? userToSuspend!.email : ''}</label><br></br>

                    <label>{t('Till_Date')}:</label><br></br>
                    <DatePicker
                        dateFormat={'dd/MM/yyyy'}
                        selected={new Date(moment(suspendTillDate).toDate())}
                        onChange={(date) => {
                            console.log('date', date);
                            setSuspendTillDate(new Date(moment(date).toDate()))
                        }}

                        minDate={new Date()}
                    // maxDate={new Date()}
                    />
                </ModalDialog>
            </div>
        </div >




    );
};

export default UsersPage;