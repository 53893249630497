import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { iUserType, iSignUpMedium, iUser,  iShoeModel, iCategory, iShoeModelPhotographs } from '../../../shared/models/adminModels'
import { useParams } from 'react-router-dom'
import {  cUser, cCategory, cShoeModel, cShoeModelPhotographs } from '../../../shared/models/tempBulkData'
import { HttpAdminService } from '../../../services/http.service'
import FileUpload, { iFileUpload } from '../../../shared/components/admin/fileUpload'
import OptionFileUpload, { iOptionFileUpload } from "../../../shared/components/admin/optionFileUpload"
import DatePicker from 'react-date-picker';



const AddShoeModel = () => {
    //set from API's
    const [brandsCategories, setBrandCategories] = useState<iCategory[]>([new cCategory()])
    const [brands, setBrands] = useState<iCategory[]>([])

    const [brandSelected, setBrandSelected] = useState<iCategory>()

    //Note : this is our shoeModel to add, on submit.
    const [shoeModel, setShoeModel] = useState<iShoeModel>(new cShoeModel())

    // Fetch from API
    const [shoeModelPhotographs, setShoeModelPhotographs] =
        useState<iShoeModelPhotographs[]>([
            new cShoeModelPhotographs("test1.png", false)
        ])

    const navigate = useNavigate();
    useEffect(() => {
        setBrands([new cCategory()])

    }, [])

    useEffect(() => {
        // setBrand(new cBrand())
        function enableSubmitButton() {
            const input = document.getElementById('saveBtn') as HTMLInputElement | null;
            if (shoeModel.categoryId && shoeModel.modelCode && shoeModel.modelName) {
                input?.removeAttribute('disabled')
            } else {
                input?.setAttribute('disabled', '')
            }
        }
        enableSubmitButton()
    }, [shoeModel])

    const isValid = (): boolean => {
        let bValid = false


        return bValid
    }

    const onSubmit = (evt: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        evt.preventDefault()

        console.log('onSubmit called')
        navigate("/admin/shoe-models/list")
    }

    const onBrandsOptionChange = (brand_id: number) => {
        let filteredBrands = brands.filter((value) => value.id == brand_id)
        setBrandSelected(filteredBrands[0])
        // TODO: fetch brand categories, all the decendents of this brand_id
    }
    const changeReleaseDate = (date: Date) => {
        setShoeModel({ ...shoeModel, releaseDate: date })
    }
    const shoeModelDefaultPhotoSelected = (optionNo: number): boolean => {
        console.log('optionNo selected', optionNo)

        let copy: iShoeModelPhotographs[] = shoeModelPhotographs.map((value, index) => { return index == optionNo ? { ...value, is_default: 1 } : { ...value } })
        setShoeModelPhotographs(copy)
        // shoeModelPhotographs[optionNo].is_default = 1
        return true
    }
    const handelAddPhotograph = (evt: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
        evt.preventDefault()
        console.log('handelAddPhotograph called')
        //setShoeModelPhotographs([...shoeModelPhotographs, new cShoeModelPhotographs('addPhoto.pnd', 0)])
    }

    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div classNameName="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    <header className="page-header">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h1 className="separator">Manage shoe model</h1>
                                <nav className="breadcrumb-wrapper" aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/admin/dashboard"><i className="icon dripicons-home"></i></a></li>
                                        <li className="breadcrumb-item"><a href="/admin/shoe-models/list">Manage shoe models</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Add new shoe model</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </header>
                    <section className="page-content container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <% if(success && success.length > 0) { %> */}
                                <div className="alert alert-success alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- success %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}

                                {/* <% if(error && error.length > 0) { %> */}
                                <div className="alert alert-danger alert-dismissible deposit-alert" role="alert">
                                    <div className="container">
                                        {/* <%- error %>  */}
                                    </div>
                                </div>
                                {/* <% } %> */}
                                <form className="sign-up-form" action="#" method="POST" id="signup-edit-form">
                                    <div className="card">
                                        <h5 className="card-header">Add Shoe Model</h5>
                                        <div className="card-body">
                                            <p> Please fill all the required details of new brand. </p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label htmlFor="">Brands</label><span className="required">*</span>
                                                        <select onChange={(evt) => { onBrandsOptionChange(+evt.target.value) }} className="form-control in_language_id" name="in_language_id">
                                                            <option value="">Select one brand</option>
                                                            {brands.length && brands.map((brand) =>
                                                                <option value={brand.id}>
                                                                    {brand.name}
                                                                </option>
                                                            )

                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="">Categories</label><span className="required">*</span>
                                                        <select id="inputState" className="form-control" name="parent_cat" required>
                                                            <option value="" >Select category</option>
                                                            {/* <option value="0" >No Parent</option> */}
                                                            {brandsCategories && brandsCategories.map((brandsCategory, index) =>
                                                                <option value={brandsCategory.id}>
                                                                    {brandsCategory.name}
                                                                </option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-3">
                                                        <label >Model code</label>
                                                        <input type="text" className="form-control" id="" name="model_code"
                                                            onChange={(evt) => setShoeModel({ ...shoeModel, modelCode: evt.target.value })}
                                                            value={shoeModel.modelCode}></input>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Model name</label>
                                                        <input type="text" className="form-control" id="" name="model_name"
                                                            onChange={(evt) => setShoeModel({ ...shoeModel, modelName: evt.target.value })}
                                                            value={shoeModel.modelName}></input>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="form-group add-shoe-modal-datepicker mb-3">
                                                        <label htmlFor="">Release date</label>
                                                        {/* <DatePicker
                                                            onChange={changeReleaseDate}
                                                            value={shoeModel.releaseDate}
                                                            maxDate={new Date()}

                                                        /> */}
                                                        {/* <label htmlFor="">Release date</label>

                                                    <div className="input-group date helper-datepicker">
                                                        <input type='text' 
                                                                name="from_date" className="form-control" placeholder="mm/dd/yyyy"
                                                                onChange={(evt) => setShoeModel({ ...shoeModel, release_date: evt.target.value })}
                                                                value={shoeModel.release_date} />
                                                        <span className="input-group-addon action">
                                                            <i className="icon dripicons-calendar"></i>
                                                        </span>
                                                    </div> */}
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Exit prise</label>
                                                        <input type="text" className="form-control" id="" name="model_name"
                                                            onChange={(evt) => setShoeModel({ ...shoeModel, exitPrice: +evt.target.value })}
                                                            value={shoeModel.exitPrice}></input>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group mb-3">
                                                        {/* <input type="checkbox" id="" name="model_name"
                                                            onChange={(evt) => setShoeModel({ ...shoeModel, isHidden: +evt.target.value })}
                                                            value={shoeModel.isHidden}></input> */}
                                                        <label className="ms-2">Is hidden</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="form-group photographs">
                                                        <div className="heading">
                                                            <label >Photographs</label><div className="add-image" onClick={(evt) => handelAddPhotograph(evt)}><span className="icon dripicons-plus"></span></div>
                                                        </div>
                                                        <div className="image-upload-main">
                                                            {shoeModelPhotographs.length && shoeModelPhotographs.map((smp, index) =>
                                                                <OptionFileUpload key={index}
                                                                    optionNo={index}
                                                                    setOptionSelected={shoeModelDefaultPhotoSelected}
                                                                    selectedOptionNo={smp.isDefault? 1:0}
                                                                    apiUrlRelativePath="/uploadShoeModelImage"
                                                                    existingFileName={smp.name}
                                                                    uploadedRelativePath={'/shoemodel'}
                                                                />
                                                            )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="card-footer bg-light text-right">
                                            <div className="form-actions">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <input type="submit" onClick={(evt) => onSubmit(evt)} id="saveBtn" className="btn btn-primary btn-rounded"></input>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>




                                    </div>
                                </form>
                            </div>
                        </div>
                    </section >
                </div>

            </div>
        </div >


    )
}

export default AddShoeModel