import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { eAppFilters, iFaq } from '../../../shared/models/adminModels'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { AxiosError } from "axios";
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'
import { HttpFAQService } from "../../../services/admin.service";
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import CustomPagination from "../../../shared/components/CustomPagination";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import { RootState } from "../../../store/stateTypes";

const FAQPage = () => {
    const { t } = useTranslation()
    // -------------- Pagination --------------------
    const [totalFaqResults, setTotalFaqResults] = useState(0);
    const [totalFaqPages, setTotalFaqPages] = useState(0)
    // -------------- Pagination End --------------------
    
    const faqPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.faq));
    const dispatch = useDispatch();

    //For Modal Dialog
    const { isOpen, toggle } = useModal()
    const initialFaqToSave: iFaq = {
        id: -1,
        question: "",
        answer: ""
    }
    const [faqToSave, setFaqToSave] = useState<iFaq>(initialFaqToSave)
    const [faqs, setFaqs] = useState<iFaq[]>([])
    const [currentState, setCurrentState] = useState<number>(0) // 0- add, 1-Edit, 2 -Photos

    const httpFaqService: HttpFAQService = new HttpFAQService();

    const refreshFaq = (currentPage = 1) => {
        setTotalFaqResults(0)
        setTotalFaqPages(0)
        httpFaqService.getAll<iFaq>({ currentPage: currentPage, 
                                        pageLimit: faqPageState!.pageLimit })
            .then((value: any) => {
                setFaqs(value.data.items)
                setTotalFaqResults(value.data.totalRows)
                setTotalFaqPages(Math.ceil(value.data.totalRows / faqPageState!.pageLimit))
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        console.log('--Toggle Refresh', faqPageState?.currentPage)
        refreshFaq(faqPageState!.currentPage);
    }, [faqPageState?.toggleRefresh])

    useEffect(() => {
        console.log('--Page Changed', faqPageState?.currentPage)
        refreshFaq(faqPageState!.currentPage);
    }, [faqPageState?.currentPage, faqPageState?.pageLimit])

    const onPageChanged = (data: any) => {        
        console.log('onPageChanged', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...faqPageState!,
            currentPage: data.currentPage
        }))
    }

    const deleteFaq = async (faq: iFaq) => {
        if (window.confirm(t`Delete_confirm_msg` ?? "")) {
            await httpFaqService.delete<iFaq>(faq.id)
                .then((value) => {
                    toast.success(t`Delete_success_msg`)
                    refreshFaq()
                })
                .catch((error) => console.log(error))
        }
    }

    const onEditFaqClick = (faq: iFaq) => {
        setCurrentState(1)  // 0- add, 1-Edit, 2 -Photos
        setFaqToSave(faq)
        toggle()
    }

    const renderFaq = (): ReactNode[] => {
        if (!faqToSave) return []

        const returnNode: ReactNode[] = faqs?.map((faq: iFaq) => (
            <tr key={faq.id}>
                <td>{faq.id}</td>
                <td>{faq.question}</td>
                <td>{faq.answer}</td>
                <td width={130}>{<>
                    <a
                        onClick={() => onEditFaqClick(faq)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-original-title="Edit"
                        className="btn btn-bulk small me-1"
                        data-rel="tooltip" >
                        <i className="la la-pencil-square"></i>
                    </a>
                    <a
                        onClick={() => deleteFaq(faq)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                        data-original-title="Edit"
                        className="btn btn-bulk small"
                        data-rel="tooltip" >
                        <i className="la la-remove"></i>
                    </a>
                </>
                }</td>
            </tr>

        ))
        return returnNode
    }

    const onAddFaqClick = () => {
        setCurrentState(0)  // 0- add, 1-Edit, 2 -Photos
        toggle();
        setFaqToSave({ ...initialFaqToSave })
    }
    const cancelAdd = () => {
        console.log('in cancelAdd')
        setFaqToSave({ ...initialFaqToSave })
        toggle()
    }
    const onExecuteAddEditFaq = async () => {
        try {
            setFaqToSave({ ...faqToSave })
            if (currentState == 0) {
                await httpFaqService.add<iFaq>({ ...faqToSave })
                toast.success(t`Add_success_msg`)
                dispatch(appPageSliceActions.savePageFilters({
                    ...faqPageState!,
                    currentPage: 1,
                    toggleRefresh: !faqPageState?.toggleRefresh
                }))
            } else if (currentState == 1) {    // 0- add, 1-Edit, 2 -Photos
                await httpFaqService.updatePUT<iFaq>(faqToSave)
                toast.success(t`Edit_success_msg`)
                dispatch(appPageSliceActions.savePageFilters({
                    ...faqPageState!,
                    toggleRefresh: !faqPageState?.toggleRefresh
                }))
                //refreshFaq(faqPageState!.currentPage)
            }
            toggle();
        } catch (error) {
            //TODO
            let e = error as AxiosError
            //TODO
            console.log('onExecuteAddEditFAQ error', e.message)
        }
    }



    return (
        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">
                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-sm-4 col-md-4 col-lg-6">
                                    <h3>FAQ</h3>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                    <div className="action-btn">
                                        <a onClick={() => onAddFaqClick()}
                                            href="javascript:void(0)"
                                            className="bulk-primary-btn bulk-sm add-shoe-model"
                                            data-toggle="modal" data-target="#addFaqModel">
                                            Add FAQ
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shoe-model-grid">
                            <table id="dataGrid" className="table table-striped table-bordered table-hover" width="100%" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>{t('Id')}</th>
                                        <th>{t('Question')}</th>
                                        <th>{t('Answer')}</th>
                                        <th>{t('Action')}</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {renderFaq()}
                                </tbody>
                            </table>

                        </div>
                        {totalFaqResults && totalFaqPages ?
                            <CustomPagination
                                totalResults={totalFaqResults}
                                totalPages={totalFaqPages}
                                p_iPageState={faqPageState!}
                                onPageChanged={onPageChanged}
                            />
                            : null
                        }

                        <ModalDialog toggle={() => onAddFaqClick()}
                            executeButtonTitle={() => currentState == 0 ? "Add" : "Update"}
                            cancel={() => cancelAdd()}
                            isOpen={isOpen}
                            execute={onExecuteAddEditFaq}
                            enableExecute={(faqToSave.question) ? true : false}
                            modalTitle={currentState == 0 ? "Add FAQ" : "Edit FAQ"}
                            useButtons={t("MD_Cancel") + '/'  + (currentState == 0 ? t("MD_Add") : t("MD_Update"))}
                        >
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <div className="form-group">
                                        <label>{t('Question')}:</label>
                                        <input type="text" className="form-control"
                                            value={faqToSave.question}
                                            onChange={(evt) => setFaqToSave({ ...faqToSave, question: evt.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 mb-2">
                                    <div className="form-group">
                                        <label>{t('Answer')}:</label>
                                        <textarea className="form-control"
                                            value={faqToSave.answer}
                                            onChange={(evt) => setFaqToSave({ ...faqToSave, answer: evt.target.value })}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                        </ModalDialog>
                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div>
    )
};

export default FAQPage;