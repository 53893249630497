import { Sidebar } from "../Sidebar";
import React, { useEffect, useState } from "react";
import { ContentType, iLegalPdf, iLegalText } from "../../../shared/models/adminModels";
import ModalDialog from "../../../shared/components/admin/modalDialog";
import useModal from "../../../hooks/general";
import { AxiosError } from "axios";
import QuillEditor from "../../../shared/components/QuillEditor";
import { HttpLegalService, HttpSettingService } from "../../../services/admin.service";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CommonUtil } from "../../../shared/utils/commonUtils";
import { BlobServiceClient } from "@azure/storage-blob";

const TermsAndConditionPage = () => {
  const { t } = useTranslation();
  //For Modal Dialog
  const { isOpen, toggle } = useModal();
  const [url, setURL] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [termsAndCondition, setTermsAndCondition] = useState<iLegalText>();
  const [dataEdited, setDataEdited] = useState<boolean>(false);
  const httpLegalService: HttpLegalService = new HttpLegalService();
  const httpSettingService: HttpSettingService = new HttpSettingService();
  const [uploadURL, setUploadURL] = useState<string>('')
  const [refreshPDF, setRefreshPDF] = useState<boolean>(false)
  let p_Utils = new CommonUtil()

  const getUploadURL = async () => {
    await httpSettingService
      .getBlobURL()
      .then((value) => {
        console.log('getUploadURL', value)
        setUploadURL(value)
      }).catch((error) => {
        console.log('getUploadURL error', error)
      })
  }
  const getContent = async() => {
    setRefreshPDF(false)
    //console.log('---getContent')
    await httpSettingService
      .getContent<string>(ContentType.TermsAndConditions)
      .then((response: any) => {
        console.log('getContent', response)
        setURL(response.data)
        setRefreshPDF(true)
      }).catch((error) => {
        console.log('getContent error', error)
      })
  }

  useEffect(()=>{
    console.log('refreshPDF', refreshPDF)
  },[refreshPDF])

  useEffect(() => {
    getUploadURL()
    getContent()
  }, []);


  //pdf upload changes
  const [file, setFile] = useState(null);

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  useEffect(() => {
    if (!file) return
    console.log('useEffect called')
    handleAddImage()
  }, [file])
  const handleAddImage = async () => {
    console.log('handleAddImage called')
    // event.preventDefault();
    uploadImage("legaldocs", file).then(async (fileId) => {
      if (file != null && fileId != "") {
        console.log('termsandcondition fileId', fileId)
        await httpLegalService
          .updatePUT({key:ContentType.TermsAndConditions,  content: fileId })
          .then((response)=>{
            getContent()
          })
          .catch((error)=> {
            p_Utils.showError(error, toast)
          })
          .finally(()=>{

          })
      }
    });
  };
  async function uploadImage(containerName: string, file: any): Promise<string> {
    console.log('uploadURL', uploadURL)
    var tempArr: Array<string> = file.name.split('.');
    var extension: string = tempArr[tempArr.length - 1];
    
    if (p_Utils.isPDFAttachmentSupported(extension)) {
      const blobServiceClient = new BlobServiceClient(
        uploadURL
        //process.env.REACT_APP_BLOB_STORAGE_URL!
      );

      let fileName = 'termsandconditions' + '-' + p_Utils.getTimeStamp()
      const blobname = fileName + "." + extension;
      console.log('blobname', blobname)
      const containerClient = blobServiceClient.getContainerClient(containerName);
      const blobClient = containerClient.getBlobClient(blobname);
      const blockBlobClient = blobClient.getBlockBlobClient();
      await blockBlobClient.uploadBrowserData(file, {
        blockSize: 4 * 1024 * 1024,
        concurrency: 20,
        onProgress: ev => console.log(ev),
        blobHTTPHeaders: { blobContentType: "application/pdf" },
      });
      return blobname;
    }
    return "";
  }
  return (
    <div id="app">
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">
          {/* == Begin - New Changes ==*/}
          <section className="page-content container-fluid custom-modal-lg">
            <div className="admin-header">
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-6">
                  <h3>{t("Terms_and_Conditions")}</h3>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                  <div className="action-btn">
                    <input type="file"
                      accept=".pdf"
                      onChange={(evt) => handleFileChange(evt)}
                      className="file_upload custom-file-input"
                      name="file"></input>
                    {/* <a href="javascript:void(0)" className="add-browse-img-btn">{t("Edit_Terms_Conditions")}</a> */}
                  </div>
                </div>
              </div>
            </div>
            
            <embed
              src={refreshPDF ? url : ''}
              type="application/pdf"
              height="1000"
              width="1000"
            ></embed>
          
          </section>
          {/* == End- New Changes ==*/}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionPage;
