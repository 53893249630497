import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { eAppFilters, iColorFilters, iShoeColor } from '../../../shared/models/adminModels'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { HttpShoeColorService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import Pagination from '../../../shared/components/Pagination';
import * as appConst from '../../../shared/constants/constants'

import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { DebounceInput } from "react-debounce-input";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/stateTypes";
import { useDispatch } from "react-redux";
import CustomPagination from "../../../shared/components/CustomPagination";

const ColorsPage = () => {
    // -------------- Pagination --------------------
    const [totalColorsResults, setTotalColorsResults] = useState(-1);
    const [totalColorsPages, setTotalColorsPages] = useState(-1)
    const [currentColorsPage, setCurrentColorsPage] = useState(1)
    // -------------- Pagination End --------------------
    const { t } = useTranslation()

    const HttpColorService: HttpShoeColorService = new HttpShoeColorService();

    const [colors, setColors] = useState<iShoeColor[]>([])
    //const [searchColor, setSearchColor] = useState<string>('')
    const colorPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.color));
    const dispatch = useDispatch();

    const initializeColors =
    {
        id: -1,
        color: ''
    }
    const { isOpen, toggle } = useModal()
    const { isOpen: isEditOpen, toggle: toggleEdit } = useModal()
    const [colorToSave, setColorToSave] = useState<iShoeColor>(initializeColors)
    const getColors = (currentPage = 1) => {
        setTotalColorsResults(0)
        setTotalColorsPages(0)
        const c = HttpColorService.filterBy<iShoeColor>({ currentPage: currentPage, pageLimit: colorPageState?.pageLimit },
            { query: (colorPageState?.filter as iColorFilters).search })
            .then((value) => {
                console.log('totalRows', value.totalRows)
                console.log('pages', Math.ceil(value.totalRows / colorPageState!.pageLimit))
                setColors(value.items)
                setTotalColorsResults(value.totalRows)
                setTotalColorsPages(Math.ceil(value.totalRows / colorPageState!.pageLimit))
                setCurrentColorsPage(currentPage)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        console.log('--Toggle Refresh', colorPageState?.currentPage)
        getColors(colorPageState!.currentPage);
    }, [colorPageState?.toggleRefresh])

    useEffect(() => {
        getColors()
    }, [(colorPageState?.filter as iColorFilters).search])

    useEffect(() => {
        console.log('--Page Changed', colorPageState?.currentPage)
        getColors(colorPageState?.currentPage);
    }, [colorPageState?.currentPage, colorPageState?.pageLimit ])

    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...colorPageState!,
            currentPage: data.currentPage
        }))
    }
    const renderColor = (): ReactNode[] => {
        console.log(colors.length)
        const returnNode: ReactNode[] = colors?.map((colors: iShoeColor) => (
            <div className="brand-categories-box" key={colors.id}>
                <div className="brand-categories-box-in colors-sizes">
                    <div>
                        <a href="javascript:void(0)" className="list-link">{colors.color}</a>
                    </div>
                    <div className="box-action-btns">
                        {/* <a href="javascript:void(0)" className="delete"><span className="icon dripicons-trash"></span></a> */}
                        <a href="javascript:void(0)" title="Edit" className="edit" onClick={() => onToggleEditClick(colors)}><span className="icon dripicons-pencil"></span></a>
                    </div>
                </div>
            </div>
        ))
        return returnNode
    }

    const onToggleClick = () => {
        console.log('in Toggle Click')
        toggle()
        if (colors) {
            console.log('present', colors.length)
        }
    }
    const cancelAdd = () => {
        console.log('in cancelAdd')
        setColorToSave(initializeColors)
        toggle()
    }
    const cancelEdit = () => {
        console.log('in cancelEdit')
        setColorToSave(initializeColors)
        toggleEdit()
    }
    const onToggleEditClick = (colors: iShoeColor) => {
        console.log("Toggle Edit Clicked")
        setColorToSave(colors)
        toggleEdit()
    }

    const onExecuteAdd = async () => {
        try {
            console.log('execute called')
            console.log('colors?.id=', colors?.length)
            await HttpColorService.add<iShoeColor>({ ...colorToSave })
            toast.success(t`Add_success_msg`)
            setColorToSave(initializeColors)
            //getColors()
            dispatch(appPageSliceActions.savePageFilters({
                ...colorPageState!,
                currentPage: 1,
                toggleRefresh: !colorPageState?.toggleRefresh
            }))
            toggle()
        } catch (error) {
            let e = error as AxiosError
            console.log('error', e.message)
        }
    }

    const onExecuteEdit = async () => {
        try {
            await HttpColorService.updatePUT<iShoeColor>(colorToSave.id, colorToSave)
            toast.success(t`Edit_success_msg`)
            setColorToSave(initializeColors)
            //getColors(currentColorsPage)
            dispatch(appPageSliceActions.savePageFilters({
                ...colorPageState!,
                toggleRefresh: !colorPageState?.toggleRefresh
            }))
            toggleEdit()
        } catch (error) {
            let e = error as AxiosError
            console.log('error', e.message)
        }
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">

                    {/* -- Begin Colors -- */}
                    <section className="page-content container-fluid">
                        <div className="admin-content-wrapper">
                            <div className="admin-header">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-6">
                                        <h3>{t('Color_Shoe')}</h3>
                                    </div>
                                    <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                        <div className="action-btn">
                                            <div className="input-group position-relative">
                                                {/* <input type="text" className="form-control" placeholder="Search..." /> */}
                                                <DebounceInput
                                                    className="form-control"
                                                    minLength={1}
                                                    debounceTimeout={500}
                                                    onChange={event =>
                                                        //setSearchColor(event.target.value) 
                                                        dispatch(appPageSliceActions.savePageFilters({
                                                            ...colorPageState!,
                                                            currentPage: 1,
                                                            filter: {
                                                                ...colorPageState!.filter,
                                                                search: event.target.value
                                                            }
                                                        }))
                                                    }
                                                    placeholder={t('Search')!}
                                                    value={(colorPageState?.filter as iColorFilters).search} />
                                                {/* <div className="input-group-append"></div> */}

                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                                </div>
                                                {/* {filteredCategories.length ?
                                                    <div className="dropdown-search">
                                                        <ul>
                                                            {filteredCategories.map((value, index) =>
                                                                <li key={index} onClick={() => onCategorySearchClick(value)}>{value.path}</li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                    : null} */}
                                            </div>
                                            <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                                                data-toggle="modal"
                                                data-target="#addColors"
                                                id="id-add-brand"
                                                onClick={() => onToggleClick()}
                                            >{t('Color_Add')}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-breadcrumb">
                                <div className="mb-1">{t('Color_Colors')}</div>
                            </div>
                            <div className="brand-categories-list">


                                {renderColor()}
                            </div>
                            {totalColorsResults && totalColorsPages ?
                                <CustomPagination
                                    totalResults={totalColorsResults}
                                    totalPages={totalColorsPages}
                                    p_iPageState={colorPageState!}
                                    onPageChanged={onPageChanged}
                                />
                                : null
                            }
                        </div>
                        <ModalDialog toggle={() => onToggleClick()}
                            cancel={() => cancelAdd()}
                            executeButtonTitle={() => { return t('MD_Add') }}
                            isOpen={isOpen}
                            execute={onExecuteAdd}

                            enableExecute={(colorToSave.color) ? true : false}
                            modalTitle={t('Color_Add')}
                            useButtons={t("MD_Cancel") + '/'  + t("MD_Add")}
                        >

                            {
                                <div className="form-group">
                                    <label>{t('Color_Name')}</label>
                                    <input type="text" className="form-control"
                                        value={colorToSave.color}
                                        onChange={(evt) => setColorToSave({ ...colorToSave, color: evt.target.value })}
                                    />
                                </div>
                            }
                        </ModalDialog>
                        <ModalDialog
                            cancel={() => cancelEdit()}
                            toggle={() => toggleEdit()}
                            executeButtonTitle={() => { return t('MD_Update') }}
                            isOpen={isEditOpen

                            }
                            execute={onExecuteEdit}
                            enableExecute={(colorToSave.color) ? true : false}
                            modalTitle={t('Color_Edit')}
                            useButtons={t("MD_Cancel") + '/'  + t("MD_Update")}
                        >{
                                <div className="form-group">
                                    <label>{t('Color_Name')}</label>
                                    <input type="text" className="form-control"
                                        value={colorToSave.color}
                                        onChange={(evt) => setColorToSave({ ...colorToSave, color: evt.target.value })}
                                    />
                                </div>
                            }

                        </ModalDialog>
                    </section>
                    {/* -- End Colors -- */}
                </div>
            </div>
        </div >




    );
};

export default ColorsPage;