import { Sidebar } from "../Sidebar"
import React, { useEffect, useState } from "react";
import { iSearchCriteria } from '../../../shared/models/adminModels'
import { HttpSearchSettingService } from '../../../services/admin.service'
import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

const SearchByPage = () => {
    const { t } = useTranslation()
    const httpSearchSettingService: HttpSearchSettingService = new HttpSearchSettingService();

    const mockSearchCriteria: iSearchCriteria[] = [
        { key: 'IncludeModelCode', value: false, displayName: t('Model_Code')! },
        { key: 'IncludeModelName', value: false, displayName: t('Model_Name')! },
        { key: 'IncludeBrandName', value: false, displayName: t('Brand_Brand_Name')! },
        { key: 'IncludeCategoryName', value: false, displayName: t('Category_Name')! },
        { key: 'IncludeTitle', value: false, displayName: t('Title')! },
        { key: 'IncludeDescription', value: false, displayName: t('Description')! },
        { key: 'IncludeColor', value: false, displayName: t('Color')! }
    ]
    const [searchCriteria, setSearchCriteria] = useState<iSearchCriteria[]>([])


    useEffect(() => {
        loadSearchCriteria()
    }, [])

    const orderByMockData = (gotSettings: iSearchCriteria[]) => {
        let tempArr: iSearchCriteria[] = []
        for (let i = 0; i < mockSearchCriteria.length; i++) {
            let elem = gotSettings.find((item) => item.key == mockSearchCriteria[i].key)
            if (elem) tempArr.push({ ...elem, displayName: mockSearchCriteria[i].displayName })
        }
        return tempArr
    }

    const loadSearchCriteria = () => {
        //TODO
        httpSearchSettingService.getAll<iSearchCriteria>()
            .then((value) => {
                console.log('value.data', value.data)
                let orderedArr = orderByMockData(value.data)
                console.log('orderedArr', orderedArr)
                setSearchCriteria(orderedArr)
            })
    }


    const saveChanges = async () => {
        //TODO make the post request for settings
        let param = searchCriteria.map((item) => ({ 'key': item.key, 'value': item.value }))
        console.log('searchCriteria saving', param)
        await httpSearchSettingService.post(param)
            .then((value) => console.log('value', value))
        toast.success(t`Save_success_msg`)
    }

    useEffect(() => {
        console.log('searchCriteria', searchCriteria)
    }, [searchCriteria])

    const setKeyValue = (itemChanged: iSearchCriteria, changedValue: boolean) => {
        let key = itemChanged.key
        console.log('changedValue', changedValue)
        let x = searchCriteria.map((item) => item.key == itemChanged.key ? { ...itemChanged, value: changedValue } : item)
        setSearchCriteria(x)
        // setSearchCriteria([{key: key, value: value == 'on'? 1 : 0 ,displayName: item.displayName},...searchCriteria])
    }
    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">

                <div className="content">

                    {/* == Begin - New Changes ==*/}
                    <section className="page-content container-fluid">
                        <div className="admin-header">
                            <div className="row">
                                <div className="col-12">
                                    <h3>{t('Search_criteria')}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="searchby-wrapper">
                            <div className="row">
                                {searchCriteria && searchCriteria.map((item, index) =>
                                    <div className="col-sm-4">
                                        <div className="searchby-box">
                                            <div className="searchby-txt">
                                                <div>{item.displayName}</div>
                                                <div className="switch-control">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" role="switch"
                                                            id={item.key}
                                                            onChange={(evt) => setKeyValue(item, evt.currentTarget.checked)}
                                                            checked={item.value ? true : false}
                                                        />
                                                        <label className="form-check-label"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {/* <div className="col-sm-4">
                                    <div className="searchby-box">
                                        <div className="searchby-txt">
                                            <div>Model Name</div>
                                            <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="searchby-box">
                                        <div className="searchby-txt">
                                            <div>Brand Name</div>
                                            <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="searchby-box">
                                        <div className="searchby-txt">
                                            <div>Category Name</div>
                                            <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="searchby-box">
                                        <div className="searchby-txt">
                                            <div>Title</div>
                                            <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="searchby-box">
                                        <div className="searchby-txt">
                                            <div>Description</div>
                                            <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="searchby-box">
                                        <div className="searchby-txt">
                                            <div>Color</div>
                                            <div className="switch-control">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </div>
                                        </div>                                        
                                    </div>
                                </div> */}
                            </div>
                            <div className="mt-4 text-end">
                                <a onClick={saveChanges} href="javascript:void(0)" className="bulk-primary-btn">{t('Save_Changes')}</a>
                            </div>
                        </div>

                    </section>
                    {/* == End- New Changes ==*/}
                </div>
            </div>
        </div>

    )
};

export default SearchByPage;