import { useNavigate } from "react-router-dom";
import { Sidebar } from "../Sidebar"
import React, { ReactNode, useEffect, useState } from "react";
import { eAppFilters, iBrandFilters, iCategory } from '../../../shared/models/adminModels'
import ModalDialog from "../../../../src/shared/components/admin/modalDialog";
import useModal from "../../../../src/hooks/general"
import { HttpCategoryService, HttpSettingService } from '../../../services/admin.service'
import { AxiosError } from "axios";
import { BlobServiceClient } from "@azure/storage-blob";
import * as appConst from '../../../shared/constants/constants'
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";
import { error } from "console";
import { CommonUtil } from "../../../shared/utils/commonUtils";
import Pagination from '../../../shared/components/Pagination';
import { DebounceInput } from "react-debounce-input";
import { RootState } from "../../../store/stateTypes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appPageSliceActions, iPageState } from "../../../store/appPageSlice";
import CustomPagination from "../../../shared/components/CustomPagination";

const BrandPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const httpCategoryService: HttpCategoryService = new HttpCategoryService();
    const httpSettingService: HttpSettingService = new HttpSettingService();
    // For list of Brands, got from Api call
    const [brands, setBrands] = useState<iCategory[]>([])

    // -------------- Pagination --------------------
    const [totalBrandResults, setTotalBrandResults] = useState(-1);
    const [totalBrandPages, setTotalBrandPages] = useState(-1)
    // -------------- Pagination End --------------------

    const brandPageState: iPageState | undefined =
        useSelector((state: RootState) => state.appPageSlice.find((value, index) => value.filterType == eAppFilters.brand));
    const dispatch = useDispatch();


    const [uploadURL, setUploadURL] = useState<string>('')
    //For Modal Dialog
    const { isOpen, toggle } = useModal()

    const { isOpen: isOpenEdit, toggle: toggleEdit } = useModal()

    // For Adding Brand
    const initialBrandToSave = {
        id: -1,
        name: '',
        image: '',
        bgImage: '',
        parentCategoryId: 0,
        childCategories: []

    }
    const [brandToSave, setBrandToSave] = useState<iCategory>(initialBrandToSave)

    const refreshBrands = (currentPage: number = 1) => {
        setTotalBrandResults(0)
        setTotalBrandPages(0)
        const bs = httpCategoryService
            .filterBy<iCategory>(
                { currentPage: currentPage, pageLimit: brandPageState!.pageLimit },
                { parentId: 0, query: (brandPageState?.filter as iBrandFilters).searchBrand })
            .then((value) => {
                console.log('brands--', value)
                setBrands(value.items)
                setTotalBrandResults(value.totalRows)
                setTotalBrandPages(Math.ceil(value.totalRows / brandPageState!.pageLimit))
                //setCurrentBrandPage(currentPage)
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        console.log('--Toggle Refresh', brandPageState?.currentPage)
        refreshBrands(brandPageState!.currentPage);
    }, [brandPageState?.toggleRefresh])

    useEffect(() => {
        console.log('--searchBrands')
        refreshBrands()
    }, [(brandPageState?.filter as iBrandFilters).searchBrand])

    useEffect(() => {
        console.log('currentPage', brandPageState?.currentPage)
    }, [brandPageState?.currentPage])

    useEffect(() => {
        console.log('--Page Changed', brandPageState?.currentPage)
        refreshBrands(brandPageState!.currentPage)
    }, [brandPageState?.currentPage, brandPageState?.pageLimit])

    const onPageChanged = (data: any) => {
        console.log('onPageChanged', data)
        dispatch(appPageSliceActions.savePageFilters({
            ...brandPageState!,
            currentPage: data.currentPage
        }))
    }
    const getUploadURL = async () => {
        await httpSettingService
            .getBlobURL()
            .then((value) => {
                console.log('getUploadURL', value)
                setUploadURL(value)
            }).catch((error) => {
                console.log('getUploadURL error', error)
            })
    }
    useEffect(() => {
        //refreshBrands()
        getUploadURL()

    }, [])

    const handelNameClick = async (id: number) => {
        navigate("/admin/brands-category-level/list/" + id)
    }
    const handelDelete = async (id: number) => {
        //Call api to delete the brand details.
        try {
            await httpCategoryService.delete<iCategory>(id)
            toast.success(t`Delete_success_msg`)
            dispatch(appPageSliceActions.savePageFilters({
                ...brandPageState!,
                currentPage: 1,
                toggleRefresh: !brandPageState?.toggleRefresh
            }))
            //refreshBrands()
            // refresh list of brands
        } catch (error) {
            let e = error as AxiosError
            //TODO
            console.log('handelDelete error', e.message)
        }
    }
    const handelEdit = async (id: number) => {
        //Call api to get the brand details.
        try {
            await httpCategoryService.get<iCategory>({ id: id })
                .then((value: any) => {
                    console.log('get brand to edit', value.data)
                    setBrandToSave(value.data)
                    toggleEdit()
                })
        } catch (error) {
            let e = error as AxiosError
            //TODO
            console.log('handelEdit error', e.message)
        }
    }

    const renderBrand = (): ReactNode[] => {
        const returnNode: ReactNode[] = brands?.map((brand: iCategory) => (
            <div className="brand-categories-box" key={brand.id}>
                <div className="brand-categories-box-in">
                    <div className="brand-img"></div>
                    <div>
                        <a onClick={() => handelNameClick(brand.id)} href="javascript:void(0)" className="list-link">{brand.name}({brand.childCount! || 0})</a>
                    </div>
                    <div className="box-action-btns">
                        {/* <a onClick={() => handelDelete(brand.id)} href="javascript:void(0)" className="delete"><span className="icon dripicons-trash"></span></a> */}
                        <a onClick={() => handelEdit(brand.id)} href="javascript:void(0)" className="edit" title="Edit"><span className="icon dripicons-pencil"></span></a>
                    </div>
                </div>
            </div>
        ))
        return returnNode
    }

    const onAddBrandClick = () => {
        resetBrandToSave()
        toggle()

    }
    const cancelAdd = () => {
        resetBrandToSave()
        toggle()
    }
    const cancelEdit = () => {
        resetBrandToSave()
        toggleEdit()
    }

    const onEditBrandClick = () => {
        toggleEdit()
    }

    //START _MANJU_ CHANGES
    //Brand's foreground image
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState('');

    //Brand's background image
    const [fileBackground, setFileBackground] = useState(null);
    const [fileBackgroundURL, setFileBackgroundURL] = useState('');


    const handleFileChange = (event: any) => {
        setFile(event.target.files[0]);
        setFileURL(URL.createObjectURL(event.target.files[0]))
    };

    const handleFileBackgroundChange = (event: any) => {
        setFileBackground(event.target.files[0]);
        setFileBackgroundURL(URL.createObjectURL(event.target.files[0]))
    };


    async function uploadImage(containerName: string, fileSiffix: string, file: any): Promise<string> {
        console.log('uploadURL', uploadURL)
        var tempArr: Array<string> = file.name.split('.');
        var extension: string = tempArr[tempArr.length - 1];
        let p_Utils = new CommonUtil()
        if (p_Utils.isAttachmentSupported(extension)) {
            const blobServiceClient = new BlobServiceClient(
                uploadURL
                //process.env.REACT_APP_BLOB_STORAGE_URL!
            );
            let brandName
            brandName = brandToSave.name.replace(/[^a-zA-Z^0-9]+/g, '')
            console.log('brandName', brandName)

            let fileName = brandName + '-' + fileSiffix + '-' + p_Utils.getTimeStamp()
            const blobname = fileName + "." + extension;

            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blobClient = containerClient.getBlobClient(blobname);
            const blockBlobClient = blobClient.getBlockBlobClient();
            await blockBlobClient.uploadBrowserData(file, {
                blockSize: 4 * 1024 * 1024,
                concurrency: 20,
                onProgress: ev => console.log(ev)
            });
            return blobname;
        }
        return "";
    }
    //END _MANJU_ CHANGES
    const onExecuteAddBrand = async () => {


        const p1 = new Promise((resolve, reject) => {
            uploadImage("brand", 'brand', file).then(async (fileId) => {
                if (file != null && fileId != "") {
                    resolve(fileId)
                }
            })
        })
        const p2 = new Promise((resolve, reject) => {
            uploadImage("brand", 'brandBackground', fileBackground).then(async (fileId) => {
                if (fileBackground != null && fileId != "") {
                    resolve(fileId)
                }
            })
        })

        Promise.all([p1, p2]).then((results) => {
            console.log('results', results)
            // setBrandToSave({ ...brandToSave, image: results[0], bgImage: results[1] })
            httpCategoryService.add<iCategory>({ ...brandToSave, image: results[0] as string, bgImage: results[1] as string })
                .then((value) => {
                    toast.success(t`Add_success_msg`)
                    toggle()
                    resetBrandToSave()
                    //refreshBrands()
                    dispatch(appPageSliceActions.savePageFilters({
                        ...brandPageState!,
                        currentPage: 1,
                        toggleRefresh: !brandPageState?.toggleRefresh
                    }))
                })

        }).catch(error => console.log(error));

    }
    const resetBrandToSave = () => {
        setBrandToSave(initialBrandToSave)
        setFile(null)
        setFileBackground(null)
        setFileURL('')
        setFileBackgroundURL('')
    }
    const afterSaveBrandByApiCall = (edit: boolean) => {
        if (edit) {
            toast.success(t`Edit_success_msg`)
            toggleEdit()
        } else {
            toast.success(t`Add_success_msg`)
            toggle()
        }
        resetBrandToSave()
        if (edit) {
            dispatch(appPageSliceActions.savePageFilters({
                ...brandPageState!,
                toggleRefresh: !brandPageState?.toggleRefresh
            }))
            //refreshBrands(brandPageState?.currentPage)
        } else {
            dispatch(appPageSliceActions.savePageFilters({
                ...brandPageState!,
                currentPage: 1,
                toggleRefresh: !brandPageState?.toggleRefresh
            }))
            //refreshBrands()
        }
    }
    const onExecuteBrand = (edit: boolean) => {

        let p1: any = '', p2: any = ''
        if (file) {
            p1 = new Promise<string>((resolve, reject): any => {
                uploadImage("brand", 'brand', file).then(async (fileId) => {
                    if (file != null && fileId != "") {
                        resolve(fileId)
                    }
                })
            })
        }
        if (fileBackground) {
            p2 = new Promise((resolve, reject) => {
                uploadImage("brand", 'brandBackground', fileBackground).then(async (fileId) => {
                    if (fileBackground != null && fileId != "") {
                        resolve(fileId)
                    }
                })
            })
        }
        let getPromises: Promise<any>[] = []
        if (p1 && p2) getPromises = [p1, p2]
        if (p1 && !p2) getPromises = [p1]
        if (!p1 && p2) getPromises = [p2]
        if (!p1 && !p2) getPromises = []
        if (getPromises.length) {
            Promise.all(getPromises).then((results) => {
                console.log('results', results)
                let tempBrandToSave = { ...brandToSave }
                if (p1 && p2) {
                    tempBrandToSave = { ...brandToSave, image: results[0] as string, bgImage: results[1] as string }
                }
                if (p1 && !p2) {
                    tempBrandToSave = { ...brandToSave, image: results[0] as string, bgImage: '' }
                }
                if (!p1 && p2) {
                    tempBrandToSave = { ...brandToSave, image: '', bgImage: results[0] as string }
                }
                console.log('tempBrandToSave', tempBrandToSave)
                // setBrandToSave({ ...brandToSave, image: results[0], bgImage: results[1] })
                httpCategoryService.add<iCategory>({ ...tempBrandToSave })
                    .then((value) => {
                        afterSaveBrandByApiCall(edit)
                    })

            }).catch(error => console.log(error));
        } else {
            httpCategoryService.add<iCategory>({ ...brandToSave })
                .then((value) => {
                    afterSaveBrandByApiCall(edit)
                })
        }
    }

    return (

        <div id="app">
            <Sidebar />
            <div className="content-wrapper">
                {/* <% include top_header.ejs %> */}
                {/* <div className="content"> */}
                {/* <%- body %> */}
                <div className="content">

                    <section className="page-content container-fluid">

                        {/* -- Begin Brands List -- */}
                        <div className="admin-content-wrapper">
                            <div className="admin-header">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-lg-6">
                                        <h3>{t("Taxonomy")}</h3>
                                    </div>
                                    <div className="col-sm-8 col-md-8 col-lg-6 action-btn-main">
                                        <div className="action-btn">
                                            <div className="input-group">
                                                <DebounceInput
                                                    className="form-control"
                                                    minLength={1}
                                                    debounceTimeout={500}
                                                    onChange={event =>
                                                        dispatch(appPageSliceActions.savePageFilters({
                                                            ...brandPageState!,
                                                            currentPage: 1,
                                                            filter: {
                                                                ...brandPageState!.filter,
                                                                searchBrand: event.target.value
                                                            }
                                                        }))
                                                        //setSearchBrand(event.target.value)
                                                    }
                                                    placeholder={"Search..."}
                                                    value={(brandPageState!.filter as iBrandFilters).searchBrand} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button"><span className="icon dripicons-search"></span></button>
                                                </div>
                                            </div>
                                            <a href="javascript:void(0)" className="bulk-primary-btn bulk-sm add-brand"
                                                data-toggle="modal"
                                                data-target="#addCategory"
                                                id="id-add-brand"
                                                onClick={() => onAddBrandClick()}>{t("Brand_Add_Brand")}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="admin-breadcrumb">
                                <div className="mb-1">{t("Brand_Brands")}</div>
                            </div>
                            <div className="brand-categories-list">
                                {renderBrand()}
                            </div>
                        </div>
                        {totalBrandResults && totalBrandPages ?
                            <CustomPagination
                                totalResults={totalBrandResults}
                                totalPages={totalBrandPages}
                                p_iPageState={brandPageState!}
                                onPageChanged={onPageChanged}
                            />
                            : null
                        }

                        {/* <div className="nav-direction">
                            {
                                (totalBrandResults > brandPageState!.pageLimit) && totalBrandPages ?
                                    <>
                                        <div className="d-flex flex-row py-4 align-items-center justify-content-center">
                                            <div>
                                                <label>{t('Page_Size')}:</label>
                                                <DebounceInput
                                                    className="form-control"
                                                    minLength={1}
                                                    debounceTimeout={500}
                                                    value={brandPageState?.pageLimit}
                                                    onChange={event => {
                                                        if(!event.target.value || +event.target.value < 1) return
                                                        dispatch(appPageSliceActions.savePageFilters({
                                                            ...brandPageState,
                                                            currentPage: 1,
                                                            pageLimit: event.target.value
                                                        }))
                                                    }}
                                                    placeholder={t('Page_Size')!} />
                                            </div>
                                            <Pagination totalRecords={totalBrandResults}
                                                totalPages={totalBrandPages}
                                                currentPage={brandPageState?.currentPage}
                                                pageLimit={brandPageState?.pageLimit}
                                                pageNeighbours={1}
                                                onPageChanged={onPageChanged}


                                            />
                                        </div>
                                    </>
                                    :
                                    null
                            }
                        </div> */}
                        <ModalDialog toggle={() => onAddBrandClick()}
                            cancel={() => cancelAdd()}
                            executeButtonTitle={() => { return t("MD_Add") }}
                            isOpen={isOpen}
                            execute={() => { onExecuteBrand(false) }}
                            enableExecute={brandToSave.name &&
                                (fileURL ||
                                    fileBackgroundURL) ? true : false}
                            modalTitle={t("Brand_Add_Brand")}
                            useButtons={t("MD_Cancel") + '/'  + t("MD_Add")}
                        >
                            {/* The Body of Modal Dialog */}

                            <div className="add-category-modal">
                                <div className="form-group mb-3">
                                    <label>{t("Brand_Brand_Name")}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => setBrandToSave({ ...brandToSave, name: evt.target.value })}
                                        value={brandToSave.name}
                                    />
                                </div>
                                <label>{t("Brand_Brand_Image")}</label>
                                <div className="category-img">
                                    <div className="uploaded-img">
                                        <img id="output" width="200" src={fileURL} />
                                    </div>
                                    <div className="upload-img">
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(evt) => handleFileChange(evt)}
                                            className="file_upload custom-file-input"
                                            name="file"></input>
                                        <div className="img-icon"></div>
                                    </div>
                                </div>
                                <label>{t("Brand_Background_Image")}</label>
                                <div className="category-img">
                                    <div className="uploaded-img">
                                        <img id="output" width="200" src={fileBackgroundURL} />
                                    </div>
                                    <div className="upload-img">
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(evt) => handleFileBackgroundChange(evt)}
                                            className="file_upload custom-file-input"
                                            name="file"></input>
                                        <div className="img-icon"></div>
                                    </div>
                                </div>

                            </div>
                        </ModalDialog>


                        <ModalDialog toggle={() => onEditBrandClick()}
                            cancel={() => cancelEdit()}
                            executeButtonTitle={() => { return t("MD_Update") }}
                            isOpen={isOpenEdit}
                            execute={() => { onExecuteBrand(true) }}
                            enableExecute={brandToSave.name &&
                                (fileURL ||
                                    fileBackgroundURL) ? true : false}
                            modalTitle={t("MD_Edit_Brand")}
                            useButtons={t("MD_Cancel") + '/'  + t("MD_Update")}
                        >
                            {/* The Body of Modal Dialog */}
                            <div className="modal-body add-category-modal">
                                <div className="form-group mb-3">
                                    <label>{t("Brand_Brand_Name")}</label>
                                    <input type="text" className="form-control"
                                        value={brandToSave.name}
                                        disabled={true}
                                        onChange={(evt) => setBrandToSave({ ...brandToSave, name: evt.target.value })}
                                    />
                                </div>
                                <label>{t("Brand_Brand_Image")}</label>
                                <div className="category-img">
                                    <div className="uploaded-img">
                                        <img id="output" width="200" src={fileURL ? fileURL : brandToSave.image} />
                                    </div>
                                    <div className="upload-img">
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(evt) => handleFileChange(evt)}
                                            className="file_upload custom-file-input"
                                            name="file"></input>
                                        <div className="img-icon"></div>
                                    </div>
                                </div>
                                <label>{t("Brand_Background_Image")}</label>
                                <div className="category-img">
                                    <div className="uploaded-img">
                                        <img id="output" width="200" src={fileBackgroundURL ? fileBackgroundURL : brandToSave.bgImage} />
                                    </div>
                                    <div className="upload-img">
                                        <input type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(evt) => handleFileBackgroundChange(evt)}
                                            className="file_upload custom-file-input"
                                            name="file"></input>
                                        <div className="img-icon"></div>
                                    </div>
                                </div>
                            </div>

                        </ModalDialog>


                        {/* -- End Brands List -- */}
                        {/* -- Begin Modal -- */}
                        <div className="modal fade" id="addCategory" role="dialog" aria-labelledby="addCategoryLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Add Brand</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body add-category-modal">
                                        <div className="form-group">
                                            <label>Brand Name:</label>
                                            <input type="text" className="form-control" />
                                        </div>
                                        <label>Brand Image:</label>
                                        <div className="category-img">
                                            <div className="uploaded-img"><img id="output" width="200" /></div>
                                            <div className="upload-img">
                                                <input type="file" accept="image/*" />
                                                <div className="img-icon"></div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                        <button type="button" className="btn btn-primary">Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* -- End Modal -- */}
                    </section>
                </div>
            </div>
        </div >




    );
};

export default BrandPage;